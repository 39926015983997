<template>
    <div id="login">
        <div class="columns" v-if="messages.length > 0">
            <div class="column is-half is-offset-one-quarter has-margin-top-15">
					<b-notification
						type="is-info"
						v-for="(message, i) in messages" :key="i"
						
					>
						<span class="content" v-html="message"></span>
					</b-notification>
            </div>
        </div>
        <!-- <verification-form :token="$route.params.token" v-if="isVerification" /> -->
        <login-form @pw-reset-sent="pwResetSent"/>
    </div>
</template>
<script>
import LoginForm from "@/components/authentication/LoginForm.vue";

export default {
    name: "Login",
    components: {
        LoginForm
    },
    props: {
        passedMessages: {
			type: Array,
            default: function() {
                return [];
            }
		}
	},
	beforeRouteUpdate(to, from, next) {
		this.messages = to.params.passedMessages;
		// console.log('before route');
		// console.log(this.messages);
		
		next();
	},
    data() {
        return {
			messages: []
            //   test: this.$store.getters.test
            // isVerification: this.$route.params.pathMatch === "/verify/" && this.$route.params.token
            // isVerification:
            //     this.$route.name == "verify" ||
            //     this.$route.name == "password-reset"
        };
    },
    computed: {
        // isVerification: function() {
        //   return this.$route.params.pathMatch === "/verify/"
        // }
    },
    mounted() {
		this.messages = this.passedMessages;
		// console.log('login mounted');
		// console.log(this.messages);
		// console.log(this.passedMessages);
		
        // console.log(this.$route);
        // console.log(this.$route.pathMatch === "/verify/");
        // console.log(this.isVerification);
    },
    methods: {
		pwResetSent(e) {
			// console.log('pwResetSent');
			
			this.messages = [];
			this.messages.push(e);

			// console.log(this.messages);
			
		}
	}
};
</script>
