var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"login-form"}},[_c('b-loading',{attrs:{"is-full-page":true,"active":_vm.isLoading,"can-cancel":false},on:{"update:active":function($event){_vm.isLoading=$event}}}),_c('section',{staticClass:"hero"},[_c('div',{staticClass:"hero-body"},[(_vm.errors.length > 0)?_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-half is-offset-one-quarter errors"},[_c('div',{staticClass:"notification is-danger"},[_c('ul',_vm._l((_vm.errors),function(error,i){return _c('li',{key:i},[_c('span',{staticClass:"content",domProps:{"innerHTML":_vm._s(error)}})])}),0)])])]):_vm._e(),_vm._m(0),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-half is-offset-one-quarter"},[_c('b-field',{attrs:{"horizontal":"","label":"Email","type":_vm.errorFields.includes('email') ? 'is-danger' : ''}},[_c('b-input',{attrs:{"name":"email"},nativeOn:{"keyup":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.submit.apply(null, arguments)},function($event){return _vm.removeErrorField('email')}]},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1),_c('b-field',{attrs:{"horizontal":"","label":"Temp Password","type":_vm.errorFields.includes('tmpPassword')
                                ? 'is-danger'
                                : ''}},[_c('b-input',{attrs:{"name":"tmp-password","type":"password","password-reveal":""},nativeOn:{"keyup":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.submit.apply(null, arguments)},function($event){return _vm.removeErrorField('tmpPassword')}]},model:{value:(_vm.tmpPassword),callback:function ($$v) {_vm.tmpPassword=$$v},expression:"tmpPassword"}})],1),_c('hr'),_c('b-notification',{attrs:{"type":"is-info","has-icon":""}},[_vm._v(" Password must contain: "),_c('div',{staticClass:"content"},[_c('ul',[_c('li',[_vm._v("At least 1 lowercase letter.")]),_c('li',[_vm._v("At least 1 uppercase letter.")]),_c('li',[_vm._v("At least 1 numeric character.")]),_c('li',[_vm._v("At least 1 special character.")]),_c('li',[_vm._v("Be at least 8 characters long.")])])])]),_c('b-field',{attrs:{"horizontal":"","label":"New Password","type":_vm.errorFields.includes('newPassword')
                                ? 'is-danger'
                                : ''}},[_c('b-input',{attrs:{"name":"new-password","type":"password","password-reveal":""},nativeOn:{"keyup":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.submit.apply(null, arguments)},function($event){return _vm.removeErrorField('newPassword')}]},model:{value:(_vm.newPassword),callback:function ($$v) {_vm.newPassword=$$v},expression:"newPassword"}})],1),_c('b-field',{attrs:{"horizontal":"","label":"Confirm Password","type":_vm.errorFields.includes('passwordConf')
                                ? 'is-danger'
                                : ''}},[_c('b-input',{attrs:{"name":"confirm-password","type":"password","password-reveal":""},nativeOn:{"keyup":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.submit.apply(null, arguments)},function($event){return _vm.removeErrorField('passwordConf')}]},model:{value:(_vm.passwordConf),callback:function ($$v) {_vm.passwordConf=$$v},expression:"passwordConf"}})],1),_c('b-field',{attrs:{"horizontal":""}},[_c('p',{staticClass:"control"},[_c('button',{staticClass:"button is-primary",on:{"click":_vm.handleSubmit}},[_vm._v(" Confirm ")])])])],1)])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-half is-offset-one-quarter has-text-centered"},[_c('img',{attrs:{"id":"banner-logo","src":require("@/assets/logo.svg")}}),_c('h1',{staticClass:"title is-2",attrs:{"id":"banner-title"}},[_vm._v(" PROPERTY PORTAL ")])])])
}]

export { render, staticRenderFns }