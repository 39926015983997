<template>
    <div id="external-user">
        <b-field
            horizontal
            custom-class="is-small"
            label="Email"
            :type="errorFields.includes('extEmail') ? 'is-danger' : ''"
        >
            <b-input
                type="email"
                size="is-small"
                v-model="extUser.email"
                :disabled="this.mode === 'edit'"
            ></b-input>
        </b-field>

        <b-field
            horizontal
            custom-class="is-small"
            label="Name"
            :type="errorFields.includes('extName') ? 'is-danger' : ''"
        >
            <b-input size="is-small" v-model="extUser.name"></b-input>
        </b-field>

        <b-field
            horizontal
            custom-class="is-small"
            label="Telephone"
            :type="errorFields.includes('extPhone') ? 'is-danger' : ''"
        >
            <b-input size="is-small" v-model="extUser.phone"></b-input>
        </b-field>

        <b-field
            horizontal
            custom-class="is-small"
            label="Mobile"
            :type="errorFields.includes('extMobile') ? 'is-danger' : ''"
        >
            <b-input size="is-small" v-model="extUser.mobile"></b-input>
        </b-field>

        <b-field horizontal v-if="mode == 'edit'">
            <!-- Label left empty for spacing -->
            <p class="control">
                <b-button
                    type="is-primary"
                    :disabled="extUser.verified || verRequestIP"
                    :title="resendVerificationTitle"
                    @click="resendVerification"
                >
                    Resend Verification
                </b-button>
            </p>
        </b-field>
    </div>
</template>

<script>
export default {
    name: "ExternalUser",
    components: {},
    props: {
        mode: {
            type: String,
            required: true,
            validator: function(value) {
                // The value must match one of these strings
                return ["new", "edit"].indexOf(value) !== -1;
            }
        },
        extUser: Object,
        errorFields: Array
    },
    computed: {
        resendVerificationTitle: function() {
            return this.extUser.verified
                ? "User has already verified their email address"
                : "Resend the verification email to the user";
        }
    },
    data() {
        return {
            verRequestIP: false,
        };
    },
    mounted() {},
    methods: {
        removeErrorField(field) {
            this.$emit("remove-error-field", field);
        },
        resendVerification() {
            this.verRequestIP = true;
            let url = `users/${this.extUser._id.$oid}/resend-verification`
            this.$ppClient
            .get(url)
            .then(response => {
                // console.log(response);
                this.verRequestIP = false;
                let body = response.data.body;
                if( !body.status.complete ) {
                    this.$emit('errors', body.status.errors);
                } else {
                    this.verificationResent = true;
                }
            });
        }
    }
};
</script>

<style lang="scss" scoped></style>
