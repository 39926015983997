/**
 * helpers for getting/setting selected layers
 */
import { mapGetters } from 'vuex';
export default {
    computed: {
        ...mapGetters({
            layerSelected: 'mapState/layerSelected',
            internalLayers: 'mapState/internalLayers',
            externalLayers: 'mapState/externalLayers',
        }),
        layers: {
            get() {
                return this.$store.getters["mapState/layers"];
            },
            set(layers) {
                this.$store.commit("mapState/setLayers", layers);
            }
        },
        layerSummaries: {
            get() {
                return this.$store.getters["mapState/layerSummaries"];
            },
            set(layerSummaries) {
                this.$store.commit("mapState/setLayerSummaries", layerSummaries);
            }
        },
        selectedLayer: {
            get() {
                return this.$store.getters["mapState/selectedLayer"];
            },
            set(layer) {
                this.$store.commit("mapState/setSelectedLayer", layer);
            }
        }
    },
    methods: {
        siteCount(layer) {
            return layer.sites.length;
        }
    }
}