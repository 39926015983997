/**
 * helpers for getting/setting selected features (sites)
 */
import { mapGetters } from 'vuex';
import FieldMapHelpers from "@/mixins/FieldMapHelpers.js";
export default {
    mixins: [FieldMapHelpers],
    computed: {
        ...mapGetters({
            featureSelected: 'mapState/featureSelected'
        }),
        selectedFeature: {
            get() {
                return this.$store.getters["mapState/selectedFeature"];
            },
            set(selectedFeature) {
                this.$store.commit("mapState/setSelectedFeature", selectedFeature);
            }
        },
        fetchedFeatureData: {
            get() {
                return this.$store.getters["mapState/fetchedFeatureData"];
            },
            set(data) {
                this.$store.commit("mapState/setFetchedFeatureData", data);
            }
        },
        fetchedFeatureFieldTranslations() {
            if(this.fetchedFeatureData && this.fetchedFeatureData.fieldTranslations) {
                return this.fetchedFeatureData.fieldTranslations;
            }

            return [];
        },
        selectedFeatureW3W: {
            get() {
                return this.$store.getters["mapState/selectedFeatureW3W"];
            },
            set(data) {
                this.$store.commit("mapState/setSelectedFeatureW3W", data);
            }
        },
        selectedFeatureLayer: function() {
            let layer = this.$store.getters["mapState/layers"].find(
                l => l._id.$oid == this.selectedFeature._site.layerId.$oid
            );

            return layer;
        },
        selectedFeatureFields: function() {
            if (this.selectedFeatureLayer) {
                let site = this.selectedFeatureLayer.sites.find(
                    s => s._id.$oid == this.selectedFeature._site.siteId.$oid
                );
                return site.fields;
            }

            return false;
        },
    },
    methods: {
        // bit of a long way round,
        // get the site name (display ref) from the selected layer sites
        // @todo simplify
        // may be worth tracking selectedSite with selectedFeature
        // getSiteName(selectedSite) {
        //     console.log('get site name');
        //     // this.$nextTick().then( t => {
        //         let selectedLayer = this.$store.getters["mapState/selectedLayer"];
                
        //         console.log('selectedLayer 1');
        //         console.log(selectedLayer);
                

        //         // selected layer can sometimes be out of whack if it gets changed pragmatically
        //         if( !selectedLayer ) {
        //             selectedLayer = this.layers.find(l => l._id.$oid === selectedSite._site.layerIdId.$oid)
        //         }
                
        //         console.log('selectedLayer 2');
        //         console.log(selectedLayer);
                
        //         let site = selectedLayer.sites.find(s => s._id.$oid === selectedSite._site.siteId.$oid);

        //         console.log('selectedSite');
        //         console.log(selectedSite);

        //         console.log('site');
        //         console.log(site);

        //         // is there a display ref field set?
        //         // used for custom layers
        //         if ( site.displayRef ) {
        //             // return site[site.displayRef]
        //             //     ? site[site.displayRef]
        //             //     : false;
        //             return site[site.displayRef] || false;
        //         }

        //         // fg managed layers have multiple field collections
        //         if( site.fields ) {

        //             if( site.fields.portal ) {
        //                 let displayRef = site.fields.portal.find(field => field.isDisplayRef );
        //                 return displayRef && displayRef.value
        //                 ? displayRef.value
        //                 : false;
        //             }

        //             // is there a field translation set
        //             if( this.fieldTranslation ) {
        //                 // prioritise propman fields
        //                 if ( site.fields.propman ){
        //                     let fields = site.fields.propman;
        //                     let fieldMap = this.fieldMapFor('propman', 'propman');

        //                     return this.getDisplayRefFromMap(fields, fieldMap) || 'error';                        
        //                 }

        //                 if ( site.fields.geostore ){
        //                     let fields = site.fields.geostore;
        //                     let fieldMap = this.fieldMapFor(selectedLayer.popup, 'geostore');

        //                     return this.getDisplayRefFromMap(fields, fieldMap) || 'error';                        
        //                 }
        //             }
                    

        //         }

        //         return false;
        //     // });

            
            
        //     // console.log('dn');
        //     // console.log(dn);
            
            
        //     // return 'testing';
        //     // return dn;
            
           
        // },
        getCentrePoint(feature) {
            if(typeof feature.getBounds === 'function') {
                let centrePoint = feature.getBounds().getCenter();

                return {
                    lat: centrePoint.lat, 
                    lng: centrePoint.lng
                };
                // this.zoom -= 1;
            }else{
                return {
                    lat: feature._latlng.lat, 
                    lng: feature._latlng.lng
                };
            }
        },
        globalHandleFeatureChange() {
            this.$eventHub.$emit('site-data-loading', true);
            // console.log('featureChange');
            
            // console.log(this.selectedFeature);
            // console.log(this.$store.getters["mapState/selectedLayer"]);
            
            
            // bring selected geometry to the front
            if( this.$store.getters["mapState/selectedLayer"] && this.$store.getters["mapState/selectedLayer"].featureGroup ) {
                this.$store.getters["mapState/selectedLayer"].featureGroup.bringToFront();
            }
            if( this.selectedFeature ) {
                this.selectedFeature.bringToFront();
            }
            
            // get the details from the server
            this.fetchFeatureDetails();


        },
        fetchFeatureDetails(feature) {
            this.$eventHub.$emit('site-data-loading', true);
            
            // console.log('featureStateHelper: fetchFeatureDetails');
            // console.log(this.selectedFeature);
            // console.log(this.selectedLayer);

            // let layer = this.$store.getters["mapState/layers"].find(
            //     l => l._id.$oid == this.selectedFeature._site.layerId.$oid
            // );
            // console.log('layer');
            // console.log(layer);

            // let site = layer.sites.find(
            //     s => s._id.$oid == this.selectedFeature._site.siteId.$oid
            // );
            // console.log('site');
            // console.log(site);
            
            if(this.featureSelected) {

                // newly added feature
                if(this.selectedFeature._site.isNew) {
                    // no fields added yet, hydrate from selected layer
                    if(!this.selectedFeature.fields) {

                    }
                } else {
                    // get site data from server

                    let source = this.selectedFeature._site.source;
                    let layerId = this.selectedFeature._site.layerId.$oid;
                    let siteId = this.selectedFeature._site.siteId.$oid;
                    let showSiteUrl = `/site/${this.selectedPortal}/${source}/${layerId}/${siteId}`;
                    // let url = `/site/${source}/${siteId}/${centerPoint.lat}/${centerPoint.lng}`;
                    this.$ppClient
                        .get(showSiteUrl)
                        .then(response => {
                            // console.log(response.data.body);

                            let body =  response.data.body;
                            this.fetchedFeatureData = body;
                            
                            this.$eventHub.$emit('site-data-loading', false);
                            // JSON responses are automatically parsed.
                            // this.layers = body.portal.layers;
                            // this.$store.commit("mapState/setLayers", body.portal.layers);
                        })
                        .catch(e => {
                            this.$eventHub.$emit('site-data-loading', false);
                            console.log(e);
                        });
                }

                // get w3w data
                let centerPoint = this.getCentrePoint(this.selectedFeature); //.getBounds().getCenter();
                let w3wUrl = `/convert-to-3wa?coordinates=${centerPoint.lat},${centerPoint.lng}`;
                this.$w3wClient.get(w3wUrl)
                .then(response => {
                    // this.$eventHub.$emit('site-data-loading', false);
                    this.selectedFeatureW3W = response.data;
                })
                .catch(e => {
                    // this.$eventHub.$emit('site-data-loading', false);
                    console.log(e);
                });
            }else{
                // this.$eventHub.$emit('site-data-loading', false);
                this.fetchedFeatureData = {};
                this.selectedFeatureW3W = {};
            }
        }
    }
}