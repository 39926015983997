<template>
    <div id="new-portal-modal">
        <b-loading
            :is-full-page="false"
            :active.sync="loadingData"
            :can-cancel="false"
        >
            <font-awesome-icon
                :icon="['fad', 'sync']"
                :style="$iconStyle"
                size="4x"
                spin
            />
        </b-loading>
        
        <div class="modal-card" style="width: auto">
            <header class="modal-card-head">
                <p class="modal-card-title">{{ title }}</p>
                <span v-if="mode === 'edit'" class="has-text-right">
                    <div class="buttons">
                        <!-- sysAdmin and portalAdmin -->
                        <b-button
                            v-if="sysAccess <= 1 && portalAccess <= 1"
                            type="is-warning"
                            @click="launchImporter"
                        >
                            Importer
                        </b-button>

                        <b-button type="is-danger" @click="confirmDeleteLayer">
                            Delete Layer
                        </b-button>
                    </div>
                </span>
            </header>

            <section class="modal-card-body">
                <section>
                    <b-notification
                        type="is-danger"
                        class="margin-bottom-15"
                        role="alert"
                        v-if="errors.length > 0"
                        aria-close-label="Close notification"
                    >
                        <ul>
                            <li v-for="(error, i) in errors" :key="i">
                                {{ error }}
                            </li>
                        </ul>
                    </b-notification>
                </section>

                <div class="columns">
                    <div class="column">
                        <b-field grouped class="nested-label-field">
                            <b-field
                                expanded
                                label="Layer Name"
                                :type="
                                    errorFields.includes('name')
                                        ? 'is-danger'
                                        : ''
                                "
                            >
                                <b-input
                                    @keyup.native="removeErrorField('name')"
                                    size="is-small"
                                    v-model.trim="name"
                                ></b-input>
                            </b-field>

                            <!-- @todo: these can be selects by using the  -->
                            <!-- getLayerDetails with clientId param -->

                            <b-field
                                :type="
                                    errorFields.includes('color')
                                        ? 'is-danger'
                                        : ''
                                "
                            >
                                <template slot="label">
                                    Colour
                                    <help-tooltip
                                        position="is-bottom-left"
                                        padding-side="left"
                                    >
                                        <p>
                                            When picking a colour, you may want
                                            to change the opacity.
                                        </p>
                                        <p>
                                            Not changing this, will mean that
                                            you will not be able to see the map
                                            or any other features below the
                                            layer.
                                        </p>
                                        <p>
                                            A good default value is 0.5 (can be
                                            changed directly in the 'A' text
                                            box)
                                        </p>
                                    </help-tooltip>
                                </template>
                                <b-dropdown
                                    id="color-picker-dropdown"
                                    position="is-bottom-left"
                                    style="padding-left: 5px;"
                                    @active-change="removeErrorField('color')"
                                >
                                    <div slot="trigger">
                                        <div id="color-picker-wrap">
                                            <b-tag
                                                class="color-picker-tag"
                                                :class="
                                                    errorFields.includes(
                                                        'color'
                                                    )
                                                        ? 'has-error'
                                                        : ''
                                                "
                                                :style="colorTagStyle"
                                            >
                                                <span>
                                                    {{
                                                        !color
                                                            ? "Choose A Color"
                                                            : color
                                                    }}
                                                </span>
                                                <span
                                                    v-if="
                                                        errorFields.includes(
                                                            'color'
                                                        )
                                                    "
                                                    class="icon is-right has-text-danger is-small"
                                                >
                                                    <i
                                                        class="fas fa-exclamation-circle"
                                                    ></i>
                                                </span>
                                            </b-tag>
                                            <div
                                                id="color-picker-checkboard"
                                            ></div>
                                        </div>
                                    </div>
                                    <b-dropdown-item
                                        id="color-picker-dropdown-item"
                                        :focusable="false"
                                        custom
                                    >
                                        <sketch-picker
                                            id="color-picker"
                                            :presetColors="[
                                                'rgba(165, 0, 0, 0.5)',
                                                'rgba(255, 0, 0, 0.5)',
                                                'rgba(255, 128, 0, 0.5)',
                                                'rgba(255, 255, 0, 0.5)',
                                                'rgba(0, 128, 0, 0.5)',
                                                'rgba(0, 255, 0, 0.5)',
                                                'rgba(0, 255, 144, 0.5)',
                                                'rgba(0, 255, 255, 0.5)',
                                                'rgba(0, 128, 255, 0.5)',
                                                'rgba(128, 128, 255, 0.5)',
                                                'rgba(0, 0, 255, 0.5)',
                                                'rgba(0, 0, 109, 0.5)',
                                                'rgba(128, 0, 255, 0.5)',
                                                'rgba(128, 0, 128, 0.5)',
                                                'rgba(255, 0, 255, 0.5)',
                                                'rgba(255, 128, 255, 0.5)'
                                            ]"
                                            v-model="colors"
                                            @input="updateColor($event)"
                                        />

                                        <!-- :value="!color ? '#00FF00' : colorHsl" -->
                                    </b-dropdown-item>
                                </b-dropdown>
                            </b-field>
                        </b-field>
                    </div>
                </div>

                <h3
                    class="subtitle is-5 header-with-button"
                    :class="
                        errorFields.includes('fields') ? 'has-text-danger' : ''
                    "
                >
                    Layer Fields
                    <help-tooltip padding-side="left">
                        <p>
                            These are the fields for each site in this layer.
                        </p>
                    </help-tooltip>

                    <b-button
                        type=""
                        title="Add a field to the layer"
                        @click="addField"
                        class="has-margin-left-5"
                    >
                        <font-awesome-icon :icon="['fas', 'plus']" />
                    </b-button>
                </h3>
                <section>
                    <b-notification
                        v-if="mode === 'edit'"
                        type="is-warning"
                        class="margin-bottom-15"
                        role="alert"
                        aria-close-label="Close notification"
                    >
                        <p>
                            Editing field types could cause unexpected results.
                        </p>
                        <p>
                            If you wish to change the type of a field, you
                            should first delete the old field and recreate it.
                        </p>
                    </b-notification>
                </section>
                <div
                    class="columns nested-form-fields"
                    v-for="field in fields"
                    :key="field.index"
                >
                    <div class="column">
                        <b-field grouped class="nested-label-field">
                            <b-field
                                label="Field Name"
                                :type="
                                    errorFields.includes(`${field.index}-name`)
                                        ? 'is-danger'
                                        : ''
                                "
                            >
                                <b-input
                                    @keyup.native="
                                        removeErrorField(`${field.index}-name`)
                                    "
                                    @change.native="
                                        handleFieldNameChange($event, field)
                                    "
                                    size="is-small"
                                    v-model.trim="field.name"
                                ></b-input>
                            </b-field>

                            <b-field
                                label="Field Type"
                                :type="
                                    errorFields.includes(`${field.index}-type`)
                                        ? 'is-danger'
                                        : ''
                                "
                            >
                                <b-select
                                    size="is-small"
                                    v-model="field.type"
                                    @change.native="fieldTypeChange(field)"
                                    :disabled="editingExistingField(field)"
                                >
                                    <!-- :disabled="mode === 'edit' && loadedFields.includes(field.index)" -->
                                    <option
                                        v-for="fieldType in fieldTypes"
                                        :key="fieldType.value"
                                        :value="fieldType.value"
                                        >{{ fieldType.name }}</option
                                    >
                                </b-select>
                            </b-field>

                            <!-- add a max length modifier for string fields -->
                            <div v-if="field.type == 'string'" class="field">
                                <b-field
                                    :type="
                                        errorFields.includes(
                                            `${field.index}-length`
                                        )
                                            ? 'is-danger'
                                            : ''
                                    "
                                >
                                    <template slot="label">
                                        Max Lengh
                                        <help-tooltip
                                            position="is-top-right"
                                            padding-side="left"
                                            item-width="20vw"
                                        >
                                            <p>
                                                Max Length is 255 characters by
                                                default.
                                            </p>
                                            <p>
                                                Use this field to further limit
                                                the number of characters.
                                            </p>
                                            <p>
                                                leave blank to use the deafualt
                                                maximum value of 255 characters.
                                            </p>
                                        </help-tooltip>
                                    </template>
                                    <b-input
                                        @keyup.native="
                                            removeErrorField(
                                                `${field.index}-length`
                                            )
                                        "
                                        size="is-small"
                                        type="number"
                                        min="0"
                                        step="1"
                                        v-model="field.length"
                                        :disabled="editingExistingField(field)"
                                    ></b-input>
                                </b-field>
                            </div>

                            <!-- add a tag input to add options to the select box -->
                            <div v-if="field.type == 'select'" class="field">
                                <b-field
                                    :type="
                                        errorFields.includes(
                                            `${field.index}-options`
                                        )
                                            ? 'is-danger'
                                            : ''
                                    "
                                >
                                    <template slot="label">
                                        Selection Choices
                                        <help-tooltip
                                            padding-side="left"
                                            item-width="21vw"
                                        >
                                            <p>
                                                Type in the options you would
                                                like to add to the select field.
                                            </p>
                                            <p>
                                                After each option press 'enter'
                                                or 'comma' to add it.
                                            </p>
                                            <p>
                                                you can also copy and paste
                                                terms seperated by commas eg:
                                            </p>
                                            <br />
                                            <p>
                                                option 1, option 2, option 3
                                            </p>
                                            <br />
                                            <p>
                                                then press enter to add all
                                                options.
                                            </p>
                                            <p>
                                                Options can be deleted by using
                                                the `backspace` key or clicking
                                                the 'x' icon.
                                            </p>
                                        </help-tooltip>
                                    </template>
                                    <b-taginput
                                        style="max-width: 25vw;"
                                        size="is-small"
                                        v-model="field.options"
                                        :allow-new="true"
                                        :placeholder="
                                            field.options.length == 0
                                                ? 'Add some options'
                                                : ''
                                        "
                                        @add="
                                            removeErrorField(
                                                `${field.index}-options`
                                            )
                                        "
                                    >
                                    </b-taginput>
                                </b-field>
                            </div>

                            <!-- may need to add -->
                            <!-- <div v-if="field.type == 'measurement'" class="field">
                                <b-field
                                    :type="
                                        errorFields.includes(
                                            `${field.index}-unit`
                                        )
                                            ? 'is-danger'
                                            : ''
                                    "
                                >
                                    <template slot="label">
                                        Unit
                                        <help-tooltip
                                            padding-side="left"
                                            item-width="21vw"
                                        >
                                            <p>
                                                The measurement unit to be displayed with the value.
                                            </p>
                                        </help-tooltip>
                                    </template>
                                    
                                    <b-input
                                        @keyup.native="
                                            removeErrorField(`${field.index}-unit`)
                                        "
                                        @change.native="
                                            handleFieldNameChange($event, field)
                                        "
                                        size="is-small"
                                        v-model.trim="field.name"
                                    ></b-input>
                                </b-field>
                            </div> -->

                            <!-- <div v-if="field.type == 'string'" class="field">
                            </div> -->

                            <b-field
                                v-if="
                                    field.type == 'string' ||
                                        field.type == 'number'
                                "
                                :custom-class="
                                    errorFields.includes('disp')
                                        ? 'has-text-danger'
                                        : ''
                                "
                            >
                                <template slot="label">
                                    <span>
                                        Ref.
                                    </span>

                                    <help-tooltip
                                        padding-side="left"
                                        item-width="15vw"
                                    >
                                        <p>
                                            You need a field to use as a display
                                            reference.
                                        </p>
                                        <p>
                                            This is used for distinguishing the
                                            various sites.
                                        </p>
                                        <p>
                                            For example, in the site list tab.
                                        </p>
                                        <p>
                                            It needs to be either a text or
                                            number field
                                        </p>
                                    </help-tooltip>
                                </template>
                                <b-switch
                                    type="is-success"
                                    v-model="field.isDisplayRef"
                                    @input="
                                        handleDisplayRefChange(
                                            $event,
                                            field.index
                                        )
                                    "
                                />
                            </b-field>

                            <b-field
                                v-if="field.type != 'checkbox'"
                                label="Required"
                            >
                                <b-switch
                                    type="is-success"
                                    v-model="field.required"
                                />
                            </b-field>

                            <b-field label="Delete">
                                <b-button
                                    size="is-small"
                                    type=""
                                    title="Delete this field"
                                    @click="confirmRemoveField(field)"
                                >
                                    <font-awesome-icon
                                        :icon="['fas', 'trash']"
                                        class="fa-w-18"
                                    />
                                </b-button>
                            </b-field>
                        </b-field>
                    </div>
                </div>
            </section>
            <footer class="modal-card-foot">
                <button class="button" type="button" @click="$parent.close()">
                    Cancel
                </button>
                <button class="button is-primary" @click="submit">
                    {{ mode === "new" ? "Create" : "Update" }}
                </button>
            </footer>
        </div>
    </div>
</template>

<script>
import { Sketch } from "vue-color";
import uuidv1 from "uuid/v1";
import tinycolor from "tinycolor2";
// import { log } from 'util';
// import ColorPicker from '@radial-color-picker/vue-color-picker';
import AccessControlHelpers from "@/mixins/AccessControlHelpers.js";
import ClientDetailsHelpers from "@/mixins/ClientDetailsHelpers.js";
import LayerStateHelpers from "@/mixins/LayerStateHelpers.js";
import FeatureStateHelpers from "@/mixins/FeatureStateHelpers.js";
import ColourHelpers from "@/mixins/ColourHelpers.js";

import HelpTooltip from "@/components/notifications/HelpTooltip.vue";

import LayerImporterModal from "@/components/modals/LayerImporterModal.vue";

export default {
    name: "LayerConfigModal",
    mixins: [
        AccessControlHelpers,
        ClientDetailsHelpers,
        LayerStateHelpers,
        FeatureStateHelpers,
        ColourHelpers
    ],
    components: {
        // "slider-picker": Slider,
        "sketch-picker": Sketch,
        HelpTooltip,
        LayerImporterModal
    },
    props: {
        mode: {
            type: String,
            required: true,
            validator: function(value) {
                // The value must match one of these strings
                return ["new", "edit"].indexOf(value) !== -1;
            }
        },
        layer: {
            type: Object,
            default: function() {
                return {
                    name: "",
                    color: false,
                    opacity: 0.5,
                    fields: []
                };
            }
        }
    },
    data() {
        return {
            loadingData: false,
            title: "",
            fieldTypes: [
                { value: "string", name: "Text" },
                { value: "multi", name: "Multiline" },
                { value: "checkbox", name: "Yes/No" },
                { value: "number", name: "Number" },
                // { value: "measurement", name: "Measurement" },
                { value: "select", name: "Select" },
                { value: "date", name: "Date" }
            ],
            colors: {},
            errorFields: [],
            errors: [],

            // data used interanlly but pulled from props
            name: "",
            color: false,
            opacity: 0.5,
            fields: [],
            // fields that were loaded into the modal (edit mode)
            _loadedFields: [],
            // track field names that need updating
            editedFields: {
                deletions: [],
                nameChanges: []
            }
        };
    },
    mounted() {
        // console.log('layer cfg');
        // console.log(this.layer);
        // assign data from props
        // shouldn't directly mutate props
        // these values will be emitted on success
        this.name = this.layer.name;
        this.color = this.layer.color;
        this.opacity = this.layer.opacity || 0.5;

        // object to use as v-model for color picker
        // seems to be the only way to get the picker to load with
        // the correct alpha value
        this.colors = this.color
            ? this.getColorObj(`${this.color}${this.alphaToHex(this.opacity)}`)
            : this.getColorObj(`rgba(51, 136, 255, ${this.opacity})`);

        // console.log(this.layer.fields);
        this.fields = this.layer.fields.map(function(field) {
            // console.log(field);
            // assign a temporary identifier
            field.index = uuidv1();
            // need to handle storage format =0[
            // select options are stored as [{"text" : "option 1"}, {"text" : " option 2"}, ...]
            // but we need to use v-model on the select box so need an array like:
            // ["option 1", "option 2", ...]
            if (field.type === "select") {
                field.options = field.options.map(option => option.text);
            }
            return field;
        });

        // console.log(this.fields);

        // this._loadedFields = [...this.fields];
        this._loadedFields = this.fields.map(f => Object.assign({}, f));

        // assign the modal title based on which mode we're in
        this.title =
            this.mode === "new"
                ? "Add a New Layer"
                : `Edit Layer: ${this.name}`;
    },
    computed: {
        displayRefSet: function() {
            return this.fields.some(e => e.isDisplayRef);
        },
        colorTagStyle: function() {
            if (this.color) {
                let bgColor = `${this.color}${this.alphaToHex(this.opacity)}`;
                let textColor = this.getContrastedColor(this.color);
                // console.log("compColor");
                // console.log(bgColor);
                // console.log(textColor);

                return `background-color: ${bgColor}; color: ${textColor}`;
            }
            return false;
        }
        // colorHsl() {
        //     return tinycolor(this.color);
        // }
    },
    methods: {
        editingExistingField(field) {
            return (
                this.mode === "edit" &&
                this._loadedFields.some(el => el.index === field.index)
            );
        },
        handleFieldNameChange(event, field) {
            // console.log("handleNameChange");
            // console.log(event);
            // console.log(field);
            // console.log(this.fields);

            // console.log(this._loadedFields);

            // editing an existing field
            if (this.editingExistingField(field)) {
                // editing a field that has already been edited
                if (
                    this.editedFields.nameChanges.some(
                        el => el.index === field.index
                    )
                ) {
                    // console.log("if");

                    let editedField = this.editedFields.nameChanges.find(
                        el => el.index === field.index
                    );
                    editedField.newName = field.name;

                    // editedField.updatedName
                } else {
                    // console.log("else");

                    this.editedFields.nameChanges.push({
                        index: field.index,
                        id: field.id,
                        newName: field.name,
                        oldName: this._loadedFields.find(
                            el => el.index === field.index
                        ).name
                    });
                }
            }
            // console.log(this.editedFields);
        },
        confirmDeleteLayer() {
            // this.deleteLayer();
            // console.log(this.selectedFeature);

            let message = `<p>Are you sure you want to delete:</p>
                <br>
                <p><strong>${this.layer.name}</strong></p>
                <br>
                <p>This will remove <strong>ALL</strong> associated data from your portal.</p>
                <br>
                <p><strong>This action cannot be undone</strong></p>`;

            this.$buefy.dialog.confirm({
                message: message,
                type: "is-danger",
                hasIcon: true,
                icon: `exclamation-circle`,
                onConfirm: () => this.deleteLayer()
            });
        },
        deleteLayer() {
            // console.log("deleteLayer");
            // console.log(this.layer);

            this.loadingData = true;

            let layerId = this.layer._id.$oid;
            let layerName = this.layer.name;

            let url = `layer/${layerId}`;

            this.$ppClient
                .delete(url)
                .then(response => {
                    // console.log(response.data);

                    let body = response.data.body;
                    if (body.deleteSuccess) {
                        // get index of the layer to remove
                        let removeIndex = this.layers
                            .map(function(layer) {
                                return layer._id.$oid;
                            })
                            .indexOf(layerId);

                        // remove layer
                        this.layers.splice(removeIndex, 1);

                        // unset the selectedLayer if it was delete
                        if (
                            this.selectedLayer &&
                            this.selectedLayer._id.$oid === layerId
                        ) {
                            this.selectedLayer = null;
                        }

                        // console.log('selectedFeature');
                        // console.log(this.selectedFeature);

                        // unset selected feature if it was part of the deleted layer
                        let selectedFeatureInLayer =
                            this.selectedFeature &&
                            this.selectedFeature._site.layerId.$oid === layerId;

                        if (selectedFeatureInLayer) {
                            this.selectedFeature = null;
                        }

                        this.$eventHub.$emit("layer-deleted", layerId);

                        this.$parent.close();
                        this.$buefy.toast.open(
                            `Successfully Deleted Layer: ${layerName}`
                        );
                    } else {
                        this.loadingData = false;
                        this.$buefy.toast.open({
                            type: "is-danger",
                            message: `Something went wrong when deleting: ${layerName}`
                        });
                    }
                })
                .catch(error => {
                    this.loadingData = false;
                    this.handleResponseError(error);
                });
        },
        confirmRemoveField(field) {
            // console.log("confirmRemoveField");
            // console.log(field);

            // user feedback
            let message = "";
            if (!this.editingExistingField(field)) {
                message = field.name
                    ? `Delete ${field.name}?`
                    : "Delete this field?";
            } else {
                // if they have changed the name in the text box before clicking delete
                // we'll show them both the old name and what they've changed it to
                let loadedField = this._loadedFields.find(
                    el => el.index === field.index
                );
                let nameString =
                    loadedField.name !== field.name
                        ? `Field Name: <strong>${loadedField.name}</strong> <br> <ul><li>unsaved rename: ${field.name}</li></ul>`
                        : `Field Name: <strong>${loadedField.name}</strong>`;

                message = `<p>Are you sure you want to delete:</p>
                <br>
                <p>${nameString}</p>
                <br>
                <p>This will remove the associated data from</p>
                <p><strong>all sites</strong> in this layer.</p>
                <br>
                <p><strong>This action cannot be undone</strong></p>`;
            }

            this.$buefy.dialog.confirm({
                message: message,
                type: "is-danger",
                hasIcon: true,
                icon: `exclamation-circle`,
                onConfirm: () => this.removeField(field)
            });
        },
        removeField(field) {
            // change needs to be tracked to push up to server
            if (this.editingExistingField(field)) {
                this.editedFields.deletions.push({
                    index: field.index,
                    id: field.id,
                    name: field.name
                });
            }

            // get index of field object
            let removeIndex = this.fields
                .map(function(item) {
                    return item.index;
                })
                .indexOf(field.index);

            // remove object
            this.fields.splice(removeIndex, 1);
        },
        handleDisplayRefChange(val, index) {
            // console.log('d ref change');

            // console.log(val);
            // console.log(index);
            // remove if there was an error because not set
            this.removeErrorField(`disp`);

            // clear previous selection (there can be only one)
            this.fields.forEach(field => {
                field.isDisplayRef = false;
            });
            // set the new display ref
            let field = this.fields.find(field => field.index == index);
            field.isDisplayRef = true;
            // console.log(field);
        },
        submit() {
            this.validateForm().then(
                valid => {
                    if (valid) {
                        this.compileFormData().then(
                            formData => {
                                this.mode === "new"
                                    ? this.submitNew(formData)
                                    : this.submitUpdate(formData);
                            },
                            error => {
                                console.log("error in compileFormData");
                                console.log(error);
                            }
                        );
                    }
                },
                error => {
                    console.log("error in validateForm promise");
                    console.log(error);
                }
            );

            // console.log(formData);

            // window.setTimeout(2);
            // for ( var pair of formData.entries() ) {
            //         console.log(pair[0]+ ': ' + pair[1]);
            //     }
        },
        submitUpdate(formData) {
            // console.log("submitUpdate");
            // console.log(this.editedFields);
            // console.log(this.layers);
            // console.log(this.selectedLayer);

            this.loadingData = true;

            let layerId = this.selectedLayer._id.$oid;
            let url = `layer/${layerId}`;
            let payload = {
                formData: formData,
                editedFields: this.editedFields
            };

            this.$ppClient
                .patch(url, payload, {
                    headers: {
                        // "Content-Type":
                        // "multipart/form-data"
                        // 'Content-Type': 'x-www-form-urlencoded'
                    }
                })
                .then(response => {
                    // console.log(response.data);

                    let body = response.data.body;
                    // successfully updated Layer
                    if (body.layerUpdated) {
                        // this.selectedLayer = body.updatedLayer;
                        // this.layers = [body.newLayer, ...this.layers];
                        // this.selectedLayer = body.newLayer;
                        // // also set the selected feature to null
                        // this.$store.commit("mapState/setSelectedFeature", null);

                        // need to set some loading data flags for user feedback
                        this.reloadData().then(reloaded => {

                            this.$parent.close();
                            this.$buefy.toast.open(
                                `Successfully Added Layer: ${body.updatedLayer.name}`
                            );
                        }).catch(e => {
                            this.loadingData = false;
                            // should be caught/handled in reloadData()
                        });


                    }

                    // if (body.portalCreated) {
                    //     this.$store.commit(
                    //         "mapState/addUserPortal",
                    //         body.newPortal
                    //     );
                    //     this.$parent.close();
                    // }
                })
                .catch(error => {
                    this.handleResponseError(error);
                });
        },
        async reloadData() {
           

            try {
                let response = await this.$ppClient
                .get(`/portal/${this.selectedPortal}`);

                let body = response.data.body;
                // console.log("change portal");
                // console.log(body);

                // JSON responses are automatically parsed.
                this.layers = body.portal.layers;
                this.layerSummaries = body.portal.layerSummaries;
                // this.$store.commit("mapState/setLayers", body.portal.layers);
                this.clientLogo = body.portal.config.logo;
                this.clientName = body.portal.config.name;
                this.documentConfig = body.portal.config.documents;
                this.notifications = body.notifications;

            } catch (error) {
                this.handleResponseError(error)
            }


            return true;
        },
        submitNew(formData) {
            this.loadingData = true;

            let portalId = this.$store.getters["mapState/selectedPortal"];
            let url = `/portal/${portalId}/layer`;
            // let url = `/test/put`;
            this.$ppClient
                .post(url, formData, {
                    headers: {
                        // "Content-Type":
                        // "multipart/form-data"
                        // 'Content-Type': 'x-www-form-urlencoded'
                    }
                })
                .then(response => {
                    // console.log(response.data);

                    let body = response.data.body;
                    // successfully added layer
                    // lats add it to the users layers and set it as selected
                    if (body.newLayer) {
                        this.layers = [body.newLayer, ...this.layers];
                        this.selectedLayer = body.newLayer;
                        // also set the selected feature to null
                        this.$store.commit("mapState/setSelectedFeature", null);
                        this.$parent.close();
                        this.$buefy.toast.open(
                            `Successfully Added Layer: ${body.newLayer.name}`
                        );
                    }
                })
                .catch(error => {
                    this.loadingData = false;
                    this.handleResponseError(error);
                });
        },
        handleResponseError(error) {
            if (error.response) {
                // Request made and server responded
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);

                let errors = error.response.data.errors;
                if (errors) {
                    for (let key in errors) {
                        // add affected field
                        this.errorFields.push(key);
                        // merge errors
                        this.errors = [...this.errors, ...errors[key]];
                    }
                } else {
                    this.errors.push("Something went wrong. Please try again");
                }
            } else if (error.request) {
                // The request was made but no response was received
                console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log("Error", error.message);
                this.errors.push("Something went wrong. Please try again");
            }
        },

        async compileFormData() {
            // console.log(this.croppaImage.chosenFile);

            // var formData = new FormData();

            // formData.append("name", this.name);
            // formData.append("color", this.color);
            // formData.append("opacity", this.opacity);

            let composedFields = [];
            for (let field of this.fields) {
                let length = false;
                if (field.type === "string" && field.length) {
                    length = field.length;
                }

                let options = false;
                if (field.type === "select" && field.options.length > 0) {
                    options = field.options;
                }

                composedFields.push({
                    name: field.name,
                    type: field.type,
                    isDisplayRef: field.isDisplayRef,
                    required: field.required,
                    length: length,
                    options: options
                });
            }

            // formData.append("fields", JSON.stringify(composedFields));

            // return a POJSO as FormData objects are only required when dealing with files
            let formData = {
                name: this.name,
                color: this.color,
                opacity: this.opacity,
                fields: composedFields
            };

            return formData;

            //  =>  if display name not set, use GS layer name
        },
        async validateForm() {
            // reset errors for validation pass
            this.errorFields = [];
            this.errors = [];
            // validate name
            if (this.name === "") {
                this.errorFields.push("name");
                this.errors.push("Layer Name: is required");
            }

            if (!this.color) {
                this.errorFields.push("color");
                this.errors.push("Colour: is required");
            }

            if (this.fields.length === 0) {
                this.errorFields.push("fields");
                this.errors.push("At least one field is required");
            } else {
                if (!this.displayRefSet) {
                    this.errorFields.push("disp");
                    this.errors.push(
                        "Display Reference: is required (text or number field)"
                    );
                }
                // field name unique check

                // tally of all field names
                // { field1: x, field2: y}
                // values should all be 1 if unique
                let fieldNameTally = this.fields.reduce(function(
                    grouped,
                    item
                ) {
                    if (item.name) {
                        let propertyValue = item.name.toLowerCase();
                        grouped[propertyValue] =
                            (grouped[propertyValue] || 0) + 1;
                    }

                    return grouped;
                },
                {});

                // console.log('fieldNameTally');
                // console.log(fieldNameTally);

                for (name of Object.keys(fieldNameTally)) {
                    // Does the name appear more than once
                    if (fieldNameTally[name] > 1) {
                        // get the field objects with the duplicate name
                        let dupFields = this.fields.filter(
                            field => field.name.toLowerCase() === name
                        );
                        // console.log('dupFields');
                        // console.log(dupFields);

                        // add the error/errorFields
                        for (let field of dupFields) {
                            this.errorFields.push(`${field.index}-name`);
                            if (
                                !this.errors.includes(
                                    "Field Names Must Be Unique"
                                )
                            ) {
                                this.errors.push("Field Names Must Be Unique");
                            }
                        }
                    }
                }

                for (let field of this.fields) {
                    if (field.name === "") {
                        this.errorFields.push(`${field.index}-name`);
                        if (!this.errors.includes("Field Name: is required")) {
                            this.errors.push("Field Name: is required");
                        }
                    }

                    if (field.type === "") {
                        this.errorFields.push(`${field.index}-type`);
                        if (!this.errors.includes("Field Type: is required")) {
                            this.errors.push("Field Type: is required");
                        }
                    }

                    if (field.type === "select" && field.options.length === 0) {
                        this.errorFields.push(`${field.index}-options`);
                        if (
                            !this.errors.includes(
                                "At least one selection choice: is required"
                            )
                        ) {
                            this.errors.push(
                                "At least one selection choice: is required"
                            );
                        }
                    }
                }
            }

            // was the form valid?
            return this.errors.length === 0;
        },

        removeErrorField(field) {
            this.errorFields = this.errorFields.filter(el => el !== field);
            this.errors = [];
        },

        updateColor(newVal) {
            // console.log(newVal);
            // console.log(layer);
            // alpha value from newVal
            this.opacity = newVal.a;
            this.color = newVal.hex;
            // console.log(this.color);
            // console.log(this.opacity);
        },

        fieldTypeChange(field) {
            // remove the field type errors
            this.removeErrorField(`${field.index}-type`);
            // reset context sensitive values
            field.length = null;
            field.options = [];
        },
        addField() {
            this.removeErrorField("fields");
            let newField = {
                // temp id for reliably deleting new layers (no mongo id)
                index: uuidv1(),
                name: "",
                type: "",
                options: [],
                length: null,
                isDisplayRef: false,
                required: false
            };

            this.fields.push(newField);
        },

        // @todo share between modals
        getContrastedColor(hex) {
            if (!hex) {
                return "black";
            }

            if (this.opacity <= 0.4) {
                return "black";
            }

            // console.log(hex);

            /*
             * From this W3C document: http://www.webmasterworld.com/r.cgi?f=88&d=9769&url=http://www.w3.org/TR/AERT#color-contrast
             *
             * Color brightness is determined by the following formula:
             * ((Red value X 299) + (Green value X 587) + (Blue value X 114)) / 1000
             *
             * I know this could be more compact, but I think this is easier to read/explain.
             *
             */

            let threshold = 130; /* about half of 256. Lower threshold equals more dark text on dark background  */

            let hRed = hexToR(hex);
            let hGreen = hexToG(hex);
            let hBlue = hexToB(hex);

            function hexToR(h) {
                return parseInt(cutHex(h).substring(0, 2), 16);
            }
            function hexToG(h) {
                return parseInt(cutHex(h).substring(2, 4), 16);
            }
            function hexToB(h) {
                return parseInt(cutHex(h).substring(4, 6), 16);
            }
            function cutHex(h) {
                return h.charAt(0) == "#" ? h.substring(1, 7) : h;
            }

            let cBrightness = (hRed * 299 + hGreen * 587 + hBlue * 114) / 1000;
            if (cBrightness > threshold) {
                return "black";
            } else {
                return "white";
            }
        },
        launchImporter() {
            let props = {
                layer: this.layer
            };

            this.$buefy.modal.open({
                component: LayerImporterModal,
                props: props,
                canCancel: false,
                parent: this,
                events: {
                    "import-success": success => {
                        // console.log('user added:');
                        // console.log(user)
                            this.loadingData = true;
                            this.reloadData().then(reloaded => {
                                
                                this.$parent.close();
                                // this.$buefy.toast.open(
                                //     `Successfully Added Layer: ${body.updatedLayer.name}`
                                // );
                            }).catch(e => {
                                this.loadingData = false;
                                // should be caught/handled in reloadData()
                            });
                    }
                }
            });
        }
    }
};
</script>

<style lang="scss">
#color-picker-dropdown {
    .dropdown-menu {
        z-index: 2500 !important;
    }
    &:focus {
        outline: none;
    }

    & div:focus,
    & a:focus {
        outline: none;
    }

    & a:hover {
        background-color: transparent;
    }
}
#color-picker {
    // &:focus{
    //     outline: none;
    // }
    // & div:focus{
    //     outline: none;
    // }
    .vc-slider-swatches {
        display: none !important;
    }
}

div.dialog.modal.is-active {
    div.media {
        align-items: center;
    }
}
</style>

<style lang="scss" scoped>
.modal-card-body {
    min-height: 400px;
}
.columns.nested-form-fields {
    .column {
        padding-top: 0;
        padding-bottom: 0;
    }
}

.info-dropdown:hover {
    color: var(--fg-grey);
}

.croppa-container {
    border: 2px solid hsl(0, 0%, 86%);
    background-color: #ffffff;
}

#color-picker-dropdown-item {
    display: flex;
    align-items: middle;
    justify-content: center;
}

#color-picker-wrap {
    width: 100px;
    height: 24px;
    #color-picker-checkboard {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        // width: 100%;
        // height: 100%;
        background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAMElEQVQ4T2N89uzZfwY8QFJSEp80A+OoAcMiDP7//483HTx//hx/Ohg1gIFx6IcBALl+VXknOCvFAAAAAElFTkSuQmCC");
        background-size: contain;
        overflow: hidden;

        border-radius: 4px;

        // z-index: 39;
    }

    .color-picker-tag {
        // width: 100%;
        // height: 100%;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;

        z-index: 1;

        &:hover {
            cursor: pointer;
        }

        &.has-error {
            border: 1px solid red;
        }
    }
}
</style>
