import Vue from "vue";
// import ppClient from "./clients/ppClient.js";
import store from "./store";

import Router from "vue-router";
import Home from "./views/Home.vue";
import Login from "./views/Login.vue";
import Verify from "./views/Verify.vue";
import Map from "./views/Map.vue";
import PageNotFound from "./views/PageNotFound.vue";
import Maintenance from "./views/Maintenance.vue";

Vue.use(Router);

let router = new Router({
    mode: "history",
    routes: [
        {
            path: "/",
            name: "home",
            component: Home
        },
        {
            // account verification/password change is just a login with extra steps
            // the verification email is sent with a url containing a token
            // and the user has a temporary password
            path: "/maintenance",
            // path: "/login/:subType(\\'verify'|'reset')?/:token?",
            name: "maintenance",
            component: Maintenance,
            // props: {messages: []}
            // props: true
        },
        {
            // account verification/password change is just a login with extra steps
            // the verification email is sent with a url containing a token
            // and the user has a temporary password
            path: "/login",
            // path: "/login/:subType(\\'verify'|'reset')?/:token?",
            name: "login",
            component: Login,
            // props: {messages: []}
            props: true
        },
        {
            // account verification/password change is just a login with extra steps
            // the verification email is sent with a url containing a token
            // and the user has a temporary password
            // path: "/login(/verify/)?:token?",
            path: "/verify/:token",
            name: "verify",
            component: Verify
        },
        {
            // account verification/password change is just a login with extra steps
            // the verification email is sent with a url containing a token
            // and the user has a temporary password
            // path: "/login(/verify/)?:token?",
            path: "/reset-password/:token",
            name: "reset-password",
            component: Verify
        },
        {
            path: "/map",
            name: "map",
            component: Map,
            meta: {
                requiresAuth: true
            },
            // before we load the map we need to load the users map defaults for their default portal
            // we do it before the route is entered so that the event handlers bound to the update actions
            // don't trigger after the data is loaded.
            beforeEnter: (to, from, next) => {
                router.app.$ppClient.get('/portal/map-defaults').then(response => {
                    let body = response.data.body;
                    // console.log('defaults response');
                    // console.log(body);
                    
                    if( body.mapDefaults ) {
                        let mapDefaults = response.data.body.mapDefaults;
                        store.commit(
                            "mapState/setLatitude",
                            mapDefaults.center.lat || 52.7445
                        );
                        store.commit(
                            "mapState/setLongitude",
                            mapDefaults.center.lng || -1.4757
                        );
                        store.commit(
                            "mapState/setZoom",
                            mapDefaults.zoom || 13
                        );
                        // this.latitude = mapDefaults.center.lat;
                        // this.longitude = mapDefaults.center.lng;
                        // this.zoom = mapDefaults.zoom;
                    }

                    next();
                });
            }
        },
        // catch all for showing 404
        { path: "*", component: PageNotFound }
    ]
});
function hasQueryParams(route) {
    return !!Object.keys(route.query).length
  }

// route guarding
// set meta: {
//   requiresAuth: true
// }
// on routes that should be authenticated before visiting.a1
// users will be redirected to login. the nextUrl param is then used to redirect back
// after successful authentication
router.beforeEach((to, from, next) => {
    // needs some work but does allow acess to sie as a developer
    // when site is under maintenance
    if(to.query.developer) {
        if(!hasQueryParams(to) && hasQueryParams(from)){
            let toWithQuery = Object.assign({}, to, {query: from.query});
            next(toWithQuery);
         } else {
            next();
         }
    }
    else if( 
        JSON.parse(process.env.VUE_APP_MAINTENANCE_MODE) 
        && to.path !== '/maintenance'
        && !to.query.developer) {
            next({
                name: "maintenance",
                // params: { nextUrl: to.fullPath }
            });
        
    }
    else if (to.matched.some(record => record.meta.requiresAuth)) {
        let token = Vue.cookie.get("token", true);
        // let sessionExpires = store.getters["sessionState/expires"];
        let sessionExpires = Vue.cookie.get("exp");

        // console.log('router');
        // console.log(new Date());
        // console.log(token);
        // console.log(sessionExpires);
        
        

        let sessionExpired =
            !sessionExpires || new Date(sessionExpires) <= new Date();

        if (!token || sessionExpired) {
            // send to login if not
            next({
                name: "login",
                params: { nextUrl: to.fullPath }
            });
        } else {
            store.commit(
                "sessionState/setExpires",
                new Date(sessionExpires)
            );
            next();
            // THIS IS TAKEN CARE OF via ppclient now
            // make an auth request (is the token valid?)
            // router.app.$ppClient
            //     .get("/validate")
            //     .then(response => {
            //         if (response.status === 200) {
            //             if (response.data.body.valid) {
            //                 store.commit(
            //                     "sessionState/setExpires",
            //                     new Date(response.data.body.expires)
            //                 );
            //                 next();
            //             }
            //         } else {
            //             // send to login if not
            //             next({
            //                 name: "login",
            //                 params: { nextUrl: to.fullPath }
            //             });
            //         }
            //     })
            //     .catch(error => {
            //         console.log("router error");

            //         if (error.response) {
            //             // Request made and server responded
            //             console.log("error.response");
            //             console.log(error.response.data);
            //             console.log(error.response.status);
            //             console.log(error.response.headers);
            //         } else if (error.request) {
            //             // The request was made but no response was received
            //             console.log("error.request");
            //             console.log(error.request);
            //         } else {
            //             // Something happened in setting up the request that triggered an Error
            //             console.log("undefined error");
            //             console.log("Error", error.message);
            //         }
            //         // send to login if not validated
            //         next({
            //             name: "login",
            //             params: { nextUrl: to.fullPath }
            //         });
            //     });
        }
    } else {
        next();
    }
});

export default router;
