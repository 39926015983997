<template>
    <l-geo-json
        :geojson="feature"
        :optionsStyle="{
            stroke: true,
            weight: 1,
            opacity: 1,
            color: color,
            fillOpacity: 0.4,
            fillColor: color
        }"
        :options="{ onEachFeature: this.onEachFeatureFunction }"
        @add="added"
        @click="clicked"
        @mouseover="mouseover"
        @mouseout="mouseout"
        
    >
        <l-tooltip v-if="displayRef">
            <div>
                <!-- <h4> {{ popup.title }} </h4> -->
                <!-- <h4> {{ displayRef }} </h4> -->
                test
            </div>
        </l-tooltip>
    </l-geo-json>
</template>

<script>
import { latLng } from "leaflet";
import { LGeoJson, LTooltip } from "vue2-leaflet";
import MapFeature from "./MapFeature.js";
export default {
    extends: MapFeature,
    name: "MMultiPolygon",
    components: {
        LGeoJson,
        LTooltip
    },
    props: {
        // title for tooltip
        color: String,
        // multiPolygon: Object,
        layerId: String,
        layer: Object
    },
    data() {
        return {
            // defaultStyle: {
            //     weight: 1,
            //     opacity: 1,
            //     color: this.color,
            //     fillOpacity: 0.6,
            // },
            // focusStyle: {
            //     weight: 3,
            //     opacity: 1,
            //     color: '#FFF',
            //     fillOpacity: 0.8,
            // }
        };
    },
    created(){
        this.$eventHub.$on("m-multipolygon-updated", newFeature => {
            newFeature.setStyle(this.focusStyle);
        });
    },
    mounted() {
        // console.log('mounted multi polygon');
        // console.log(this.feature);
        
        
    },
    computed: {
        onEachFeatureFunction() {
            if (!this.displayRef) {
                return () => {};
            }
            return (feature, layer) => {
                layer.bindTooltip(
                    `<h4>${this.displayRef}</h4>`,
                    { permanent: false, sticky: true }
                );
            };
        }
    },
    methods: {}
};
</script>

<style lang="scss" scoped></style>
