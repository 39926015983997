var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"document-upload-modal"}},[_c('b-loading',{attrs:{"is-full-page":false,"active":_vm.loadingData,"can-cancel":false},on:{"update:active":function($event){_vm.loadingData=$event}}},[_c('font-awesome-icon',{style:(_vm.$iconStyle),attrs:{"icon":['fad', 'sync'],"size":"4x","spin":""}})],1),_c('div',{staticClass:"modal-card",staticStyle:{"width":"auto"}},[_c('header',{staticClass:"modal-card-head"},[_c('p',{staticClass:"modal-card-title"},[(_vm.mode == 'new')?_c('span',[_vm._v("Upload a new Document")]):_c('span',[_vm._v("Edit Data for Document")])])]),_c('section',{staticClass:"modal-card-body"},[_c('section',[(_vm.errors.length > 0)?_c('b-notification',{staticClass:"margin-bottom-15",attrs:{"type":"is-danger","role":"alert","aria-close-label":"Close notification"}},[_c('ul',_vm._l((_vm.errors),function(error,i){return _c('li',{key:i},[_vm._v(" "+_vm._s(error)+" ")])}),0)]):_vm._e()],1),(_vm.mode === 'new')?_c('b-field',{staticClass:"file"},[_c('b-upload',{on:{"input":function($event){return _vm.removeErrorField('file')}},model:{value:(_vm.file),callback:function ($$v) {_vm.file=$$v},expression:"file"}},[_c('a',{staticClass:"button",class:_vm.errorFields.includes('file')
                                ? 'is-danger'
                                : 'is-primary'},[_c('b-icon',{attrs:{"icon":"upload"}}),_c('span',[_vm._v("Select File to Upload")])],1)]),(_vm.file)?_c('span',{staticClass:"file-name"},[_vm._v(" "+_vm._s(_vm.file.name)+" ")]):_vm._e()],1):_vm._e(),(_vm.mode === 'new')?_c('div',{staticClass:"field"},[_c('b-switch',{attrs:{"type":"is-success"},model:{value:(_vm.addNotification),callback:function ($$v) {_vm.addNotification=$$v},expression:"addNotification"}},[_vm._v(" Add Notification after upload ")])],1):_vm._e(),(_vm.fetchedFeatureData.siteType === 'registeredtitle')?_c('div',[_c('b-field',{attrs:{"grouped":""}},[_c('b-field',{attrs:{"label":"Regsitered Title Doc Type","type":_vm.errorFields.includes('regType')
                                ? 'is-danger'
                                : ''}},[_c('b-select',{attrs:{"placeholder":"Select A Document Type","size":"is-small","expanded":""},nativeOn:{"change":function($event){return _vm.removeErrorField('regType')}},model:{value:(_vm.regType),callback:function ($$v) {_vm.regType=$$v},expression:"regType"}},_vm._l((_vm.registeredTitleDocTypes),function(docType){return _c('option',{key:docType.name,domProps:{"value":docType.value}},[_vm._v(_vm._s(docType.name)+" ")])}),0)],1)],1)],1):_c('div',[_c('b-field',{attrs:{"label":"Document Title","type":_vm.errorFields.includes('title') ? 'is-danger' : ''}},[_c('b-input',{attrs:{"size":"is-small"},nativeOn:{"keyup":function($event){return _vm.removeErrorField('title')}},model:{value:(_vm.title),callback:function ($$v) {_vm.title=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"title"}})],1),_c('b-field',{attrs:{"grouped":""}},[_c('b-field',{attrs:{"label":"Valid From"}},[_c('b-field',[_c('b-datepicker',{attrs:{"years-range":[-5, 15],"size":"is-small","placeholder":"Click to select...","icon":"calendar"},model:{value:(_vm.valid.from),callback:function ($$v) {_vm.$set(_vm.valid, "from", $$v)},expression:"valid.from"}}),_c('p',{staticClass:"control"},[_c('b-button',{attrs:{"size":"is-small","type":"is-danger"},on:{"click":function($event){_vm.valid.from = null}}},[_vm._v(" Clear ")])],1)],1)],1),_c('b-field',{attrs:{"label":"Valid To"}},[_c('b-field',[_c('b-datepicker',{attrs:{"years-range":[-5, 15],"size":"is-small","placeholder":"Click to select...","icon":"calendar"},model:{value:(_vm.valid.to),callback:function ($$v) {_vm.$set(_vm.valid, "to", $$v)},expression:"valid.to"}}),_c('p',{staticClass:"control"},[_c('b-button',{attrs:{"size":"is-small","type":"is-danger"},on:{"click":function($event){_vm.valid.to = null}}},[_vm._v(" Clear ")])],1)],1)],1)],1),_c('b-field',{attrs:{"grouped":""}},[_c('b-field',{attrs:{"label":"Category","type":_vm.errorFields.includes('category')
                                ? 'is-danger'
                                : ''}},[_c('b-select',{attrs:{"placeholder":"Please Select A Document Category","size":"is-small","expanded":""},nativeOn:{"change":function($event){return _vm.removeErrorField('category')}},model:{value:(_vm.category),callback:function ($$v) {_vm.category=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"category"}},_vm._l((_vm.documentConfig.cats),function(confCategory){return _c('option',{key:confCategory.name,domProps:{"value":confCategory.name}},[_vm._v(_vm._s(confCategory.name)+" ")])}),0)],1),_c('b-field',{attrs:{"label":"Subcategory"}},[_c('b-select',{attrs:{"placeholder":"Please Select A Document Subcategory","size":"is-small","expanded":""},model:{value:(_vm.subCategory),callback:function ($$v) {_vm.subCategory=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"subCategory"}},_vm._l((_vm.selectedSubcategories),function(confSubCategory){return _c('option',{key:confSubCategory.name,domProps:{"value":confSubCategory.name}},[_vm._v(_vm._s(confSubCategory.name)+" ")])}),0)],1)],1),_c('b-field',{attrs:{"label":"Description"}},[_c('b-input',{attrs:{"type":"textarea","size":"is-small"},model:{value:(_vm.description),callback:function ($$v) {_vm.description=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"description"}})],1)],1)],1),_c('footer',{staticClass:"modal-card-foot"},[_c('button',{staticClass:"button",on:{"click":function($event){return _vm.$parent.close()}}},[_vm._v("Cancel")]),_c('button',{staticClass:"button is-primary",attrs:{"type":"button"},on:{"click":_vm.upload}},[(_vm.mode == 'new')?_c('span',[_vm._v("Upload")]):_c('span',[_vm._v("Update")])])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }