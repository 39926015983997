import Vue from "vue";
import App from "./App.vue";

// import VueCookie from "vue-cookie";
// Vue.use(VueCookie);

let cookie = require('tiny-cookie');
Vue.prototype.$cookie = cookie;
Vue.cookie = cookie;

import router from "./router";
import store from "./store";
// Vue.store = store;

// create a custom instance of axios and add it as a prototype att
import ppClient from "./clients/ppClient.js";
Vue.prototype.$ppClient = ppClient;

// create a custom instance of axios and add it as a prototype att
import w3wClient from "./clients/w3wClient.js";
Vue.prototype.$w3wClient = w3wClient;



// fontawesome
import { library } from "@fortawesome/fontawesome-svg-core";
import {
    faUserSecret,
    faMapSigns,
    faTimes,
    faPlus,
    faMinus,
    faInfoCircle,
    faCaretLeft,
    faCaretRight,
    faCaretUp,
    faCaretDown,
    faEye,
    faEyeSlash,
    faEdit,
    faQuestion,
    faExclamationCircle,
    faExclamationTriangle,
    faUpload,
    faDownload,
    faDirections,
    faCheckCircle,
    faCheck,
    faTrash,
    faExpandArrows,
    faSearchLocation,
    faCommentLines,
    faClinicMedical,
    faLock,
    faSave,
    faEllipsisV,
    faUserPlus,
    faCommentExclamation,
    faAddressBook,
    faAt,
    faPhone,
    faMobileAlt,
    faList
} from "@fortawesome/pro-solid-svg-icons";
import {
    faUser,
    faMapMarkerQuestion,
    faMapMarkedAlt,
    faBars,
    faEnvelope,
    faUsersCog,
    faPhoneRotary,
    faDotCircle,
    faLayerGroup,
    faCity,
    faThList,
    faSync,
    faCircleNotch,
    faAddressCard,
    faHome,
    // faGalaxy
} from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import "@fortawesome/fontawesome-pro-webfonts/css/fontawesome.css";
// import "@fortawesome/fontawesome-pro-webfonts/css/fa-light.css";
import "@fortawesome/fontawesome-pro-webfonts/css/fa-solid.css";

// buefy
import Buefy from "buefy";
// ommitted as we overload the styles
//  this is currently done in APp.vue but will get moved to it's own scss file
// import "buefy/dist/buefy.css";
Vue.use(Buefy, {
    defaultIconPack: "fas"
});
// import "https://jenil.github.io/bulmaswatch/journal/bulmaswatch.min.css";

import "bulma-helpers/css/bulma-helpers.min.css";

library.add(
    faMapSigns,
    faUserSecret,
    faTimes,
    faCaretLeft,
    faCaretRight,
    faEye,
    faEyeSlash,
    faEdit,
    faQuestion,
    faExclamationCircle,
    faExclamationTriangle,
    faUpload,
    faDownload,
    faCaretUp,
    faCaretDown,
    faDirections,
    faCheckCircle,
    faExpandArrows,
    faSearchLocation,
    faCommentLines,
    faCheck,
    faTrash,
    faClinicMedical,
    faPlus,
    faInfoCircle,
    faLock,
    faSave,
    faMinus,
    faEllipsisV,
    faUserPlus,
    faCommentExclamation,
    faAddressBook,
    faSync,
    faAt,
    faPhone,
    faMobileAlt,
    faList
);
library.add(
    faUser,
    faMapMarkerQuestion,
    faMapMarkedAlt,
    faBars,
    faEnvelope,
    faUsersCog,
    faPhoneRotary,
    faDotCircle,
    faLayerGroup,
    faCity,
    faThList,
    faSync,
    faCircleNotch,
    faAddressCard,
    faHome,
    // faGalaxy
);
Vue.component("font-awesome-icon", FontAwesomeIcon);

// vue2leaflet config
// import { LMap, LTileLayer, LMarker } from "vue2-leaflet";
import { Icon } from "leaflet";
import "leaflet/dist/leaflet.css";

// to use standard leaflet.draw
// however, a bit twitchy and not quite as modern as vue plugin.
// import LeafletDraw from 'leaflet-draw';
// import "leaflet-draw/dist/leaflet.draw.css";
// Vue.use(LeafletDraw);

// this part resolve an issue where the markers would not appear
delete Icon.Default.prototype._getIconUrl;

Icon.Default.mergeOptions({
    iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
    iconUrl: require("leaflet/dist/images/marker-icon.png"),
    shadowUrl: require("leaflet/dist/images/marker-shadow.png")
});

import "leaflet-sidebar-v2";
import "leaflet-sidebar-v2/css/leaflet-sidebar.css";
import "leaflet-sidebar-v2/js/leaflet-sidebar.js";

// import leafletMeasure from "leaflet-measure";

import "leaflet-measure";
import "leaflet-measure/dist/leaflet-measure.css";

// leaflet sidebar 2

Vue.config.productionTip = false;

// add some styling options globally
Vue.prototype.$styles = {
    fgBlue: "#1A3062",
    fgRed: "#EE2E24",
    fgLightGrey: "#D4D4D7",
    fgDarkGrey: "#898A93",
    fgGreen: "#87B04A",
    fgOrange: "#ff8a00"
};

Vue.prototype.$iconStyle = {
    "--fa-primary-color": Vue.prototype.$styles.fgBlue,
    "--fa-secondary-color": Vue.prototype.$styles.fgRed,
    "--fa-secondary-opacity": 1.0
};

Vue.prototype.$iconStyleInverse = {
    "--fa-primary-color": Vue.prototype.$styles.fgRed,
    "--fa-secondary-color": Vue.prototype.$styles.fgBlue,
    "--fa-secondary-opacity": 1.0
};

import vueDebounce from "vue-debounce";
Vue.use(vueDebounce);

// global event hub
// try not to overuse but sometimes useful
// access in-code with this.$eventHub (.$emit and .$on)
Vue.prototype.$eventHub = new Vue(); // Global event bus

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount("#app");
