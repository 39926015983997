<template>
<article>
    <div id="content">
        <img src="@/assets/logo.svg" alt="PropertyPortal" class="center">
        <h1>We'll be back soon!</h1>
        <div>
            <p>Sorry for the inconvenience but we're performing some maintenance at the moment.</p>
            <p>We'll be back online shortly!</p>
            <p> - The Team</p>
        </div>
    </div>
    
</article>
</template>
<script>
export default {
    name: "Maintenance",
    components: {},
    data() {
        return {
            //   test: this.$store.getters.test
        };
    },
    mounted() {
        // console.log(this.$route);
    },
    methods: {}
};
</script>

<style lang="scss" scoped>
body { text-align: center; padding: 150px; }
h1 { font-size: 50px; margin-top: 25px; }
// body { font: 20px Helvetica, sans-serif; color: #333; }
article { display: block; text-align: left; width: 650px; margin: 0 auto; }
a { color: #dc8100; text-decoration: none; }
a:hover { color: #333; text-decoration: none; }
.center {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
}
#content {
    margin-top: 50px;
}
</style>
