<template>
    <div data-loading-notification>
        <b-loading :is-full-page="true" :active.sync="isLoading" :can-cancel="false">
        <div class="columns is-mobile is-multiline is-centered">
            <div class="column">
                <div id="loading-icon-div">
                    <font-awesome-icon
                        :icon="['fad', 'sync']"
                        :style="$iconStyle"
                        size="6x"
                        spin
                    />
                </div>
                <br>
                <div id="loading-message">
                    <section class="hero">
                        <div class="hero-body">
                            <div class="container">
                                <h1 class="title">
                                    We're just getting your data ready for you.
                                </h1>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
        </b-loading>
    </div>
    
</template>

<script>
    export default {
        name: "LoadingNotification",
        components: {},
        props: {
            isLoading: Boolean
        },
        data() {
            return {}
        },
        mounted () {
        },
        methods: {}
    }
</script>

<style lang="scss">
// work around for nested scoped css (>>> doesn't work)
    div[data-loading-notification]{
        // height: 100%;
        .loading-overlay{
            z-index: 2001;
            // height: 100%;
            .loading-background{
                background-color: rgba(255, 255, 255, 0.9);
                // height: 100%;
                // background-color: blue;
            }

            #loading-icon-div{
                text-align: center;
                svg{
                    margin: 0 auto;
                }
            }

            #loading-message{
                // z-index: 2010;
                .title{
                    color: var(--fg-grey-med) !important;
                }
                
            }
        }
    }

    

</style>