<template>
    <div id="propman-leases">
        <b-collapse class="card" aria-id="fetchedLeaseData" :open="false">
            <!-- v-if="fetchedFeatureLeases" -->
            <div
                slot="trigger"
                slot-scope="props"
                class="card-header"
                role="button"
                aria-controls="fetchedLeaseData"
            >
                <p class="card-header-title is-capitalized">
                    Lease Data
                </p>
                <a class="card-header-icon">
                    <font-awesome-icon
                        :icon="
                            props.open
                                ? ['fas', 'caret-up']
                                : ['fas', 'caret-down']
                        "
                    />
                </a>
            </div>

            <div class="card-content">
                <div class="buttons">
                    <b-button
                        v-if="!fieldMapperEnabled"
                        title="Edit Field Name Mappings"
                        @click="fieldMapperEnabled = !fieldMapperEnabled"
                    >
                        <font-awesome-icon icon="edit" />
                    </b-button>
                </div>

                <div v-if="fieldMapperEnabled">
                    <external-site-field-mapper
                        @cancel-edit-field-map="fieldMapperEnabled = false"
                        @field-map-updated="fieldMapperEnabled = false"
                        :fields="fetchedFeatureLeases[0]"
                        :siteType="'propman'"
                        :dataSource="'lease'"
                    />
                </div>
                <div v-else>
                    <b-collapse
                        class="card"
                        aria-id="leaseData"
                        v-for="lease in fetchedFeatureLeases"
                        :key="lease['Lease-No']"
                        :open="lease['Active'] === '1'"
                    >
                        <div
                            slot="trigger"
                            slot-scope="props"
                            class="card-header"
                            role="button"
                            aria-controls="leaseData"
                        >
                            <p
                                class="card-header-title is-capitalized"
                                style="justify-content: space-between;"
                            >
                                Lease Number: {{ lease["Lease-No"] }}
                                <b-tag
                                    v-if="lease['Active'] == '1'"
                                    type="is-success"
                                >
                                    Active
                                </b-tag>
                                <b-tag v-else type="is-danger">
                                    Inactive
                                </b-tag>
                            </p>
                            <a class="card-header-icon">
                                <font-awesome-icon
                                    :icon="
                                        props.open
                                            ? ['fas', 'caret-up']
                                            : ['fas', 'caret-down']
                                    "
                                />
                            </a>
                        </div>

                        <div class="card-content">
                            <div>
                                <ul class="selected-feature-list">
                                    <li
                                        v-for="(val, key) in mapped(lease)"
                                        :key="key"
                                    >
                                        <strong class="is-capitalized">
                                            {{ key }}:
                                        </strong>
                                        {{ val | scolon2space }}
                                    </li>
                                </ul>

                                <propman-rent
                                    :lease-rent-data="
                                        leaseRent(lease['Lease-No'])
                                    "
                                />
                            </div>
                        </div>
                    </b-collapse>
                </div>
            </div>
        </b-collapse>
    </div>
</template>

<script>
import FeatureStateHelpers from "@/mixins/FeatureStateHelpers.js";
import FieldMapHelpers from "@/mixins/FieldMapHelpers.js";

import PropmanRent from "@/components/sidebar/FeatureDetails/PropmanRent.vue";
import ExternalSiteFieldMapper from "@/components/sidebar/FeatureDetails/ExternalSiteFieldMapper.vue";

export default {
    name: "PropmanLeases",
    mixins: [FeatureStateHelpers, FieldMapHelpers],
    components: { PropmanRent, ExternalSiteFieldMapper },
    props: {
        fetchedFeatureLeases: Array,
        fetchedFeatureRent: Array
    },
    data() {
        return {
            fieldMapperEnabled: false
        };
    },
    mounted() {},
    methods: {
        mapped(lease) {
            // let fieldMap = this.fetchedFeatureFieldTranslations["propman"][
            //     "lease"
            // ];

            // console.log('fieldTRanslation');
            // console.log(this.fieldTranslation);
            

            // let fieldMap = ( (this.fieldTranslation || {})['propman'] || {} )['lease'];
            let fieldMap = this.fieldMapFor('propman', 'lease');

            if (fieldMap) {
                return this.mapFieldsIn(fieldMap, lease);
            } else {
                return lease;
            }
        },
        leaseRent(leaseNo) {
            // console.log("leaseRent");
            // console.log(this.fetchedFeatureRent);

            return this.fetchedFeatureRent.filter(
                r => r["Lease-No"] === leaseNo
            );
        }
    }
};
</script>

<style lang="scss" scoped></style>
