<template>
    <div id="portal-notifications">
        <b-table :data="portalNotifications" class="trans-table">
            <template slot-scope="props">
                <b-table-column field="date" label="Date">
                    {{
                        new Date(
                            props.row.notificationDate
                        ).toLocaleDateString()
                    }}
                </b-table-column>

                <b-table-column field="title" label="Title">
                    {{ props.row.title }}
                </b-table-column>
                <!-- :visible="width > 1"  -->

                <b-table-column centered field="view" label="View">
                    <b-button
                        @click="launchEditNotificationModal(props.row)"
                        type=""
                    >
                        <font-awesome-icon :icon="'eye'"></font-awesome-icon>
                    </b-button>
                </b-table-column>

                <!-- <b-table-column field="dismiss-notification" label="Dismiss">
                    <b-button
                        @click="confirmDismissNotification(props.row)"
                        title="Dismiss Notificaton"
                    >
                        <font-awesome-icon :icon="'check'" />
                    </b-button>
                </b-table-column> -->
            </template>
        </b-table>
    </div>
</template>

<script>
import NotificationHelpers from "@/mixins/NotificationHelpers.js";
// import NotificationConfigModal from "@/components/modals/NotificationConfigModal.vue";

export default {
    name: "PortalNotifications",
    mixins: [NotificationHelpers],
    components: { 
        // NotificationConfigModal 
    },
    props: {
        portalNotifications: Array
    },
    data() {
        return {};
    },
    mounted() {
        // console.log("p notifs");
        // console.log(this.portalNotifications);
    },
    methods: {
        launchEditNotificationModal(notification) {
            let props = {
                mode: "edit",
                portalId: notification.portalId,
                notificationType: notification.type,
                passedNotification: notification
            };
            this.launchNotificationModal(props);
        }
    }
};
</script>

<style lang="scss" scoped></style>
