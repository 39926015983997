<template>
    <div id="session-expiration">
        <div class="columns">
            <div class="column has-text-centered">
                Logged in as: {{ userName }} | <a @click="logout">Logout</a>
            </div>
        </div>
        <div class="columns">
            <div class="column has-text-centered">
                <!-- <h3 class="heading is-3">
                    Time left in current session:
                </h3> -->
                <countdown
                    v-if="expires"
                    :endDate="expires"
                    :warning="60"
                    :warningFunction="promptValidate"
                    :endFunction="autoLogout"
                />
                <!-- showMinutes
                showSeconds -->
                <br>
            </div>
        </div>
        
    </div>
</template>

<script>
import Countdown from "@/components/utilities/Countdown.vue";

export default {
    name: "SessionExpiration",
    components: { Countdown },
    props: {},
    data() {
        return {
            // warningDisplayed: false,
            warning: null,
            // expires: null
        };
    },
    mounted() {
        // window.setInterval(this.setExpiresTime(), 1000)
    },
    computed: {
        expires: function() {
            // console.log("sess");
            // console.log(this.$store.getters["sessionState/expires"]);
            return this.$store.getters["sessionState/expires"];
        },
        userName: function() {
            return this.$store.getters["mapState/userName"];
        }
    },
    methods: {
        logout() {
            this.$ppClient.post("/logout").then(response => {
                // console.log('logout then');
                this.$store.commit(
                    "sessionState/setExpires",
                    null
                );
                if( this.$cookie.get('exp') ) this.$cookie.remove('exp');
                if( this.$cookie.get('token') ) this.$cookie.remove('token');

                this.$router.push("login");
                this.$router.go(0);
            }).catch(error => {
                console.log(error);
            });
            
        },
        promptValidate() {
            // console.log();
            
            // this needs adding to vuex if it gets added anywhere else
            // other wise multiple warnings are displayed and only one is closed on auto-logout
            if (!this.warning) {
                // this.warningDisplayed = true;

                this.warning = this.$buefy.dialog.alert({
                    title: "Auto logout Notification.",
                    message:
                        "You are about to be logged out due to inactivity.",
                    confirmText: "I'm not finished yet",
                    type: "is-warning",
                    hasIcon: true,
                    onConfirm: () => this.revalidate()
                });

                // console.log(this.warning);
            }
        },
        revalidate() {
            this.$ppClient.get("/validate").then(response => {
                let body = response.data.body;
                if (body.session && body.session.valid) {
                    // update sesison state
                    this.$cookie.set( 'exp', body.session.exp, { SameSite: 'Lax' } );
                    this.$store.commit(
                        "sessionState/setExpires",
                        new Date(body.session.exp)
                    );
                    // this.warningDisplayed = false;
                    this.warning = null;
                }
            });
        },
        autoLogout() {
            // console.log("WARNING OBJ");
            // console.log(this.warning);
            if (this.warning) {
                this.warning.close();
                this.warning = null;
            }

            this.logout();
        }
    }
};
</script>

<style lang="scss" scoped></style>
