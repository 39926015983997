import { Pie, Doughnut, mixins } from "vue-chartjs";
const { reactiveProp } = mixins;

export default {
    name: "doughnut",
    extends: Doughnut,
    mixins: [reactiveProp],
    props: {
        options: {
            type: Object,
            default: null
        }
    },
    mounted() {
        this.renderChart(this.chartData, this.options);
        this.generateLegend();
    },
    methods: {}
};
