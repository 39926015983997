<template>
    <div id="notification-config-modal">
        <b-loading
            :is-full-page="false"
            :active.sync="loadingData"
            :can-cancel="false"
        >
            <font-awesome-icon
                :icon="['fad', 'sync']"
                :style="$iconStyle"
                size="4x"
                spin
            />
        </b-loading>

        <div class="modal-card" style="width: auto">
            <header class="modal-card-head">
                <p class="modal-card-title is-capitalized">
                    {{ mode }} {{ notification.type }} Notification
                </p>

                <span v-if="mode === 'edit'" class="has-text-right">
                    <div class="buttons">
                        <b-button
                            type="is-success"
                            @click="
                                confirmDismissNotification(notification)
                            "
                            title="Dismiss Notificaton"
                        >
                            <font-awesome-icon :icon="'check'" />
                        </b-button>

                        <b-button type="is-danger" @click="confirmDelete(notification)">
                            <font-awesome-icon :icon="'trash'" />
                        </b-button>
                    </div>
                </span>
            </header>

            <section class="modal-card-body">
                <section>
                    <b-notification
                        type="is-danger"
                        class="margin-bottom-15"
                        role="alert"
                        v-if="errors.length > 0"
                        aria-close-label="Close notification"
                    >
                        <ul>
                            <li v-for="(error, i) in errors" :key="i">
                                {{ error }}
                            </li>
                        </ul>
                    </b-notification>
                </section>

                <section>
                    <b-field
                        custom-class="is-small"
                        label="Title"
                        :type="errorFields.includes('title') ? 'is-danger' : ''"
                    >
                        <b-input
                            size="is-small"
                            v-model.trim="notification.title"
                            @keyup.native="removeErrorField('title')"
                        ></b-input>
                    </b-field>

                    <b-field
                        custom-class="is-small"
                        label="Message"
                        :type="
                            errorFields.includes('message') ? 'is-danger' : ''
                        "
                    >
                        <b-input
                            type="textarea"
                            size="is-small"
                            v-model.trim="notification.message"
                        ></b-input>
                    </b-field>

                    <div class="columns">
                        <div class="column is-one-third">
                            <b-field
                                label="Notification Date"
                                :custom-class="
                                    errorFields.includes('notificationDate')
                                        ? 'is-small has-text-danger'
                                        : 'is-small'
                                "
                            >
                                <b-datepicker
                                    inline
                                    v-model="notification.notificationDate"
                                    size="is-small"
                                    placeholder="Click to select..."
                                    icon="calendar"
                                    :years-range="[-5, 15]"
                                    :min-date="todaysDate"
                                    @input="
                                        removeErrorField('notificationDate')
                                    "
                                >
                                    <div class="has-text-centered">
                                        <span
                                            v-if="notification.notificationDate"
                                        >
                                            <!-- <h6 class="header is-6"> -->
                                            Selected:
                                            <strong>
                                                {{
                                                    notification.notificationDate.toLocaleDateString()
                                                }}
                                            </strong>
                                            <!-- </h6> -->
                                        </span>
                                        <span v-else>
                                            <strong class="has-text-danger">
                                                No Date Selected
                                            </strong>
                                        </span>
                                    </div>
                                </b-datepicker>
                                <!-- :value="notification.notificationDate ? new Date(notification.notificationDate) : null" -->
                                <!-- @input="dateChanged($event, field)" -->
                                <!-- :date-parser="dateFieldFormatter" -->
                                <!-- v-model="notification.notificationDate" -->
                            </b-field>
                        </div>

                        <div class="column">
                            <b-field
                                custom-class="is-small"
                                label="Recipients"
                            />
                            <div class="buttons">
                                <b-button
                                    type="is-danger"
                                    @click="removeAllUsers"
                                >
                                    Remove All
                                </b-button>
                                <b-button
                                    type="is-success"
                                    @click="selectAllUsers"
                                >
                                    Select All
                                </b-button>
                            </div>
                            <b-field
                                :type="
                                    errorFields.includes('users')
                                        ? 'is-danger'
                                        : ''
                                "
                            >
                                <b-taginput
                                    v-if="portalUsersLoaded"
                                    id="userInput"
                                    ref="userInput"
                                    v-model="notification.users"
                                    :data="filteredPortalUsers"
                                    autocomplete
                                    :allow-new="false"
                                    :open-on-focus="true"
                                    field="name"
                                    placeholder="Add Recipients"
                                    @typing="applyTextFilterToPortalUsers"
                                    @add="removeErrorField('users')"
                                />
                                <div v-else>
                                    
                                    <font-awesome-icon
                                        :icon="['fad', 'sync']"
                                        :style="$iconStyle"
                                        spin
                                    />
                                    <span class="has-margin-left-15">
                                        Loading Users
                                    </span>
                                </div>
                                
                                <!-- @add="userAdded" -->
                            </b-field>
                        </div>
                    </div>

                    <!-- </b-field> -->
                </section>
            </section>

            <footer class="modal-card-foot">
                <button class="button" @click="$parent.close()">Cancel</button>

                <button
                    class="button is-success"
                    type="button"
                    @click="handleSubmit"
                >
                    Confirm
                </button>
            </footer>
        </div>
    </div>
</template>

<script>
// import LoadingNotification from "@/components/notifications/LoadingNotification.vue";
// import NotificationHelpers from "@/mixins/NotificationHelpers.js";
// import HelpTooltip from "@/components/notifications/HelpTooltip.vue";

export default {
    name: "NotificationConfigModal",
    mixins: [
        // NotificationHelpers
    ],
    components: {
        // LoadingNotification,
        // HelpTooltip,
    },
    beforeMount() {
        // console.log('before mount');
        // console.log(NotificationHelpers);
    },
    props: {
        mode: {
            type: String,
            required: true,
            validator: function(value) {
                // The value must match one of these strings
                return ["new", "edit"].indexOf(value) !== -1;
            }
        },
        notificationType: {
            type: String,
            required: false,
            validator: function(value) {
                // The value must match one of these strings
                return ["portal", "site", "document"].indexOf(value) !== -1;
            }
        },
        portalId: String,
        layerId: {
            type: String,
            default: null
        },
        siteId: {
            type: String,
            default: null
        },
        doc: Object,
        passedNotification: {
            type: Object,
            default: function() {
                return {
                    portalId: null,
                    layerId: null,
                    siteId: null,
                    documentId: null,
                    type: null,
                    title: null,
                    message: null,
                    notificationDate: null,
                    users: []
                };
            }
        }
    },
    data() {
        return {
            loadingData: false,
            errors: [],
            errorFields: [],
            portalUsersLoaded: false,
            portalUsers: [],
            filteredPortalUsers: [],

            notification: {
                portalId: null,
                layerId: null,
                siteId: null,
                documentId: null,
                type: null,
                title: null,
                message: null,
                notificationDate: null,
                users: []
            }
        };
    },
    computed: {
        todaysDate: function() {
            // addresses a bit of weirdness with JS dates
            // date select would give tomorrow as a minimum date without this
            const today = new Date();
            return new Date(
                today.getFullYear(),
                today.getMonth(),
                today.getDate()
            );
        },
        unselectedUsers: function() {
            var notification = this.notification;
            return this.portalUsers.filter(function(obj) {
                return !notification.users.some(function(obj2) {
                    return obj.name == obj2.name;
                });
            });
        }
    },
    mounted() {
        // console.log("NCModal");
        // console.log(this.doc);
        
        
        // set up new notification properties
        if (this.mode === "new") {
            this.notification.portalId = this.portalId;
            this.notification.type = this.notificationType;
            if( this.siteId ) {
                this.notification.siteId = this.siteId
            }
            if( this.layerId ) {
                this.notification.layerId = this.layerId
            }
            if( this.notificationType === 'document' ) {
                this.notification.documentId = this.doc.id;
                this.notification.title = this.doc.title
            }
        }

        // properties should already exist in existing notification
        if (this.mode == "edit") {
            this.notification = Object.assign({}, this.passedNotification);
            this.notification.notificationDate = new Date(this.notification.notificationDate);

        }
        this.fetchPortalUsers().then(() => {
            if (this.mode == "edit") {
                // reassign users (map inwards)
                var notification = this.notification;
                let users = this.portalUsers.filter( obj => {
                    return notification.users.some( obj2 => {
                        return obj._id.$oid == obj2._id.$oid;
                    });
                });
                this.notification.users = users;

            }
            this.portalUsersLoaded = true;
            // add current user to selected tags
            // console.log(this.portalUsers);
            // this.portalUsers.find( user => user.name)
            // this.notification.users.push({'name': 'test', '_id': { '$oid': '123' }})
        });
        // console.log('in notif cfg modal');
        // console.log(this.notification);
        
        
    },
    watch: {
        // filter the user list to show only users that haven't yet been added
        "notification.users": function() {
            // var notification = this.notification;
            // this.filteredPortalUsers = this.portalUsers.filter(function(obj) {
            //     return !notification.users.some(function(obj2) {
            //         return obj.name == obj2.name;
            //     });
            // });
            this.filteredPortalUsers = this.unselectedUsers;
        }
        // filteredPortalUsers: function() {
        //     console.log('filteredPortalUsers');
        //     console.log(this.filteredPortalUsers);
        //     // this.$refs.userInput.$refs.autocomplete.hasFocus = false;
        //     // document.getElementById('userInput').blur();
        //     console.log(this.$refs.userInput.$refs.autocomplete.$refs.dropdown);
        //     this.$nextTick(() => {
        //         // this.$refs.userInput.$refs.autocomplete.data = this.filteredPortalUsers;
        //         // this.$refs.userInput.$refs.autocomplete.isActive = true;
        //         // console.log(this.$refs.userInput.$refs.autocomplete.data);

        //         // let elem = this.$refs.userInput.$refs.autocomplete.$refs.dropdown.querySelector('.dropdown-content');
        //         // elem.classList.remove('fade-leave-active', 'fade-leave');
        //         // elem.style.display = 'block'
        //         // elem.classList.add('fade-enter-active', 'fade-enter')
        //         // console.log(elem);
        //     })

        //     // this.$refs.userInput.focus();
        // }
    },
    methods: {
        // blur(){
        //     console.log('test');

        // },
        // dateChanged(newDate, field) {
        //     this.notification.notificationDate = newDate.toISOString();
        // },
        removeErrorField(field) {
            this.errorFields = this.errorFields.filter(el => el !== field);
            this.errors = [];
        },
        async fetchPortalUsers() {
            return this.$ppClient
                .get(`/portal/${this.portalId}/users`)
                .then(response => {
                    let body = response.data.body;
                    this.portalUsers = body.users;
                    this.filteredPortalUsers = body.users;
                });
        },
        selectAllUsers() {
            this.notification.users = this.portalUsers;
        },
        removeAllUsers() {
            this.notification.users = [];
        },
        // userAdded( user ) {
        //     console.log(user);

        //     let userIndex = this.filteredPortalUsers
        //         .map(function(elem) {
        //             console.log(elem);

        //             return elem._id.$oid;
        //         })
        //         .indexOf(user._id.$oid);
        //         // console.log();

        //         // console.log(userIndex);

        //     // remove object
        //     this.filteredPortalUsers.splice(userIndex, 1);
        //     console.log(this.filteredPortalUsers);

        //     // this.filteredPortalUsers = this.portalUsers.filter(function(obj) {
        //     //     return !notification.users.some(function(obj2) {
        //     //         return obj.name == obj2.name;
        //     //     });
        //     // });
        // },
        applyTextFilterToPortalUsers(text) {
            // this.removeErrorField("notificationDate");
            // var notification = this.notification;
            // let unselectedUsers = this.portalUsers.filter(function(obj) {
            //     return !notification.users.some(function(obj2) {
            //         return obj.name == obj2.name;
            //     });
            // });

            this.filteredPortalUsers = this.unselectedUsers.filter(option => {
                return (
                    option.name
                        .toString()
                        .toLowerCase()
                        .indexOf(text.toLowerCase()) >= 0
                );
            });
        },
        handleSubmit() {
            this.validateForm().then(valid => {
                if (valid) {
                    this.compileFormData().then(
                        formData => {
                            // console.log("formData");
                            // console.log(formData);
                            // console.log(this.camelToKebab(this.userType));

                            this.mode === "new"
                                ? this.submitNew(formData)
                                : this.submitUpdate(formData);
                        },
                        error => {
                            console.log("error in compileFormData");
                            console.log(error);
                        }
                    );
                }
            });
        },
        // this component is basically a compound form.
        // it's comprised of 3 major components.
        // we centralise the validation of all the components
        // here and push any errors to the child components
        // by using the errorFields array
        async validateForm() {
            // console.log("validate");
            // console.log(this.notification.title);
            // console.log(!this.notification.title);

            this.errorFields = [];
            this.errors = [];

            if (!this.notification.title || this.notification.title == "") {
                this.errorFields.push("title");
                this.errors.push("Title: is required");
            }

            if (!this.notification.notificationDate) {
                this.errorFields.push("notificationDate");
                this.errors.push("Notification Date: is required");
            }

            if (this.notification.users.length == 0) {
                this.errorFields.push("users");
                this.errors.push("Recipient: At least one is required");
            }

            // console.log(this.errors);

            // if (!this.adUser.guid) {
            //     this.errorFields.push("employee");
            //     this.errors.push("Employee: is required");
            // }

            // was the form valid?
            return this.errors.length === 0;
        },
        async compileFormData() {
            let fd = {
                notification: Object.assign({}, this.notification)
            };

            let nDate = fd.notification.notificationDate;
            // let transDate = new Date(
            //     nDate.getFullYear(),
            //     nDate.getMonth(),
            //     nDate.getDate()
            // );

            // fd.notification.notificationDate = transDate.toLocaleDateString();
            // console.log(nDate);
            // convert date into something we can use in the back-end
            // months are 0 indexed in JS
            fd.notification.notificationDate = 
            `${nDate.getFullYear()}-${nDate.getMonth() + 1}-${nDate.getDate()}`;

            return fd;
        },
        submitNew(formData) {
            this.loadingData = true;
            let url = `/notifications`;

            this.$ppClient.post(url, formData).then(response => {
                let body = response.data.body;
                // console.log("resposne");
                // console.log(body);

                if (!body.status.valid || !body.newNotification) {
                    this.loadingData = false;
                    this.errorFields = body.status.errors.fields;
                    this.errors = body.status.errors.messages;
                } else {
                    // this.$emit("notification-added", body.newNotification);
                    this.$eventHub.$emit("notification-added", body.newNotification);
                    this.$parent.close();
                }
            });
        },
        submitUpdate(formData) {
            this.loadingData = true;
            let url = `/notifications/${this.notification["_id"]["$oid"]}`;

            this.$ppClient.patch(url, formData).then(response => {
                let body = response.data.body;

                if (!body.updatedNotification) {
                    this.loadingData = false;
                    this.errors.push("Error updating notification");
                } else {
                    this.$eventHub.$emit(
                        "notification-updated",
                        body.updatedNotification
                    );
                    this.$parent.close();
                }
            });
        },
        confirmDismissNotification( notification ) {
            // this.deleteLayer();
            // console.log(this.selectedFeature);

            let message = `<p>Dismiss ${notification.title}?</p>
                <p><strong>This action cannot be undone</strong></p>`;

            this.$buefy.dialog.confirm({
                message: message,
                type: "is-danger",
                hasIcon: true,
                icon: `exclamation-circle`,
                onConfirm: () => this.dismissNotification( notification )
            });
        },
        confirmDelete() {
            // console.log(this.user);

            let message = `<p>Are you sure you want to delete:</p>
                <br>
                <p><strong>${this.notification.title}</strong></p>
                <br>
                <p>This will delete the notification for all recipients:</p>
                <br>
                <p><strong>This action cannot be undone</strong></p>`;

            this.$buefy.dialog.confirm({
                message: message,
                type: "is-danger",
                hasIcon: true,
                icon: `exclamation-circle`,
                onConfirm: () => this.delete()
            });
        },
        dismissNotification( notification ) {
            // console.log('dismiss action');
            // console.log(notification);
            this.loadingData = true;
            let url = `/notifications/dismiss/${notification["_id"]["$oid"]}`;

            this.$ppClient.patch(url).then(response => {
                let body = response.data.body;
                if ( !body.status.completed ) {
                    this.loadingData = false;
                    this.errors.push("Error dismissing notification");
                } else {
                    this.$eventHub.$emit(
                        "notification-dismissed",
                        notification
                    );
                    this.$parent.close()
                }
            });
        },
        delete() {
            this.loadingData = true;

            let url = `/notifications/${this.notification["_id"]["$oid"]}`;
            this.$ppClient
                .delete(url)
                .then(response => {
                    // console.log(response.data);

                    let body = response.data.body;
                    if ( body.status.completed ) {
                        this.$eventHub.$emit("notification-deleted", this.notification);
                        this.$parent.close();
                    } else {
                        this.loadingData = false;
                        this.errors.push(
                            "Something went wrong. Please try again"
                        );
                    }
                })
                .catch(error => {
                    this.errors.push("Something went wrong. Please try again");
                    if (error.response) {
                        // Request made and server responded
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                    } else if (error.request) {
                        // The request was made but no response was received
                        console.log(error.request);
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        console.log("Error", error.message);
                    }
                });
        }
    }
};
</script>

<style lang="scss" scoped>
.columns.nested-form-fields {
    .column {
        padding-top: 0;
        padding-bottom: 0;
    }
}
</style>
