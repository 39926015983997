var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"contact-config-modal"}},[_c('b-loading',{attrs:{"is-full-page":false,"active":_vm.loadingData,"can-cancel":false},on:{"update:active":function($event){_vm.loadingData=$event}}},[_c('font-awesome-icon',{style:(_vm.$iconStyle),attrs:{"icon":['fad', 'sync'],"size":"4x","spin":""}})],1),_c('div',{staticClass:"modal-card",staticStyle:{"width":"auto"}},[_c('header',{staticClass:"modal-card-head"},[_c('p',{staticClass:"modal-card-title is-capitalized"},[_vm._v(" "+_vm._s(_vm.mode)+" Contact ")]),(_vm.mode === 'edit')?_c('span',{staticClass:"has-text-right"},[_c('div',{staticClass:"buttons"},[_c('b-button',{attrs:{"type":"is-danger"},on:{"click":_vm.confirmDeleteContact}},[_vm._v(" Delete Contact ")])],1)]):_vm._e()]),_c('section',{staticClass:"modal-card-body"},[_c('section',[(_vm.errors.length > 0)?_c('b-notification',{staticClass:"margin-bottom-15",attrs:{"type":"is-danger","role":"alert","aria-close-label":"Close notification"}},[_c('ul',_vm._l((_vm.errors),function(error,i){return _c('li',{key:i},[_vm._v(" "+_vm._s(error)+" ")])}),0)]):_vm._e()],1),_c('b-tabs',{attrs:{"type":"is-boxed","id":"user-type-tabs"},model:{value:(_vm.contactTypeTab),callback:function ($$v) {_vm.contactTypeTab=$$v},expression:"contactTypeTab"}},[_c('b-tab-item',{attrs:{"label":"Add user as contact","disabled":_vm.contactTypeTabDisabled('user')}},[(
                            _vm.mode == 'new' ||
                                (_vm.mode == 'edit' &&
                                    _vm.contact.contactType == 'user')
                        )?_c('user-contacts',{attrs:{"mode":_vm.mode,"contact":_vm.mode == 'edit' ? _vm.contact : null,"error-fields":_vm.errorFields,"selectedTab":_vm.contactTypeTab},on:{"remove-error-field":_vm.removeErrorField,"user-contact-id-changed":_vm.updateContactUserId,"user-contact-description-changed":_vm.updateContactDescription}}):_vm._e()],1),_c('b-tab-item',{attrs:{"label":"Add non-user contact","disabled":_vm.contactTypeTabDisabled('nonUser')}},[(
                            _vm.mode == 'new' ||
                                (_vm.mode == 'edit' &&
                                    _vm.contact.contactType == 'nonUser')
                        )?_c('non-user-contacts',{attrs:{"mode":_vm.mode,"passed-contact":_vm.mode == 'edit' ? _vm.contact : null,"error-fields":_vm.errorFields,"selectedTab":_vm.contactTypeTab},on:{"remove-error-field":_vm.removeErrorField,"non-user-contact-updated":_vm.updateContactData}}):_vm._e()],1)],1)],1),_c('footer',{staticClass:"modal-card-foot"},[_c('button',{staticClass:"button",on:{"click":function($event){return _vm.$parent.close()}}},[_vm._v("Cancel")]),_c('button',{staticClass:"button is-success",attrs:{"type":"button"},on:{"click":_vm.confirm}},[_vm._v(" Confirm ")])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }