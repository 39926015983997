<template>
    <div id="site-notifications">
        <b-table :data="siteNotifications" class="trans-table">
            <template slot-scope="props">
                <b-table-column field="date" label="Date">
                    {{ new Date(props.row.notificationDate).toLocaleDateString() }}
                </b-table-column>

                <b-table-column field="title" label="Title">
                    {{ props.row.title }}
                </b-table-column>
                <!-- :visible="width > 1"  -->

                <b-table-column centered field="view" label="View">
                    <b-button @click="launchEditNotificationModal(props.row)" type="">
                        <font-awesome-icon :icon="'eye'"></font-awesome-icon>
                    </b-button>
                </b-table-column>

                <b-table-column field="show-feature-details" label="Site">
                    <b-button
                        @click="changeTab(props.row, 'feature-details')"
                        title="Reveal site and display the details for it"
                    >
                        <font-awesome-icon
                            :icon="['fad', 'city']"
                            :style="$iconStyle"
                        />
                    </b-button>
                </b-table-column>
            </template>
        </b-table>
    </div>
</template>

<script>
import NotificationHelpers from "@/mixins/NotificationHelpers.js";
import FeatureStateHelpers from "@/mixins/FeatureStateHelpers.js";

// import NotificationConfigModal from "@/components/modals/NotificationConfigModal.vue";

export default {
    name: "SiteNotifications",
    mixins: [NotificationHelpers],
    components: {
        // NotificationConfigModal
    },
    props: {
        siteNotifications: Array
    },
    data() {
        return {};
    },
    mounted() {
        // console.log('d notifs');
        // console.log(this.documentNotifications);
        
        
    },
    methods: {
        // launchEditNotificationModal(notification) {
        //     let props = {
        //         mode: "edit",
        //         portalId: notification.portalId,
        //         layerId: notification.layerId,
        //         siteId: notification.siteId,
        //         notificationType: notification.type,
        //         passedNotification: notification
        //     };
        //     this.launchNotificationModal(props);
        // }
    }
};
</script>

<style lang="scss" scoped></style>
