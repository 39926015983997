var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"user-portals"}},[_c('b-loading',{attrs:{"is-full-page":false,"active":_vm.loadingData,"can-cancel":false},on:{"update:active":function($event){_vm.loadingData=$event}}},[_c('font-awesome-icon',{style:(_vm.$iconStyle),attrs:{"icon":['fad', 'sync'],"size":"4x","spin":""}})],1),_c('h3',{staticClass:"subtitle is-5 header-with-button",class:_vm.errorFields.includes('portals') ? 'has-text-danger' : ''},[_vm._v(" Portal Access for User "),_c('b-button',{staticClass:"has-margin-left-5",attrs:{"type":"","title":"Add a field to the layer"},on:{"click":_vm.addPortal}},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'plus']}})],1)],1),_vm._l((_vm.userPortals),function(userPortal){return _c('div',{key:userPortal.index,staticClass:"columns nested-form-fields"},[_c('div',{staticClass:"column"},[_c('b-field',{staticClass:"nested-label-field",attrs:{"grouped":""}},[_c('b-field',{attrs:{"label":"Portal","type":_vm.errorFields.includes(`${userPortal.index}-portalId`)
                            ? 'is-danger'
                            : ''}},[_c('b-select',{attrs:{"size":"is-small","disabled":_vm.isCurrentPortal(userPortal)},nativeOn:{"change":function($event){return _vm.removeErrorField(`${userPortal.index}-portalId`)}},model:{value:(userPortal.portalId),callback:function ($$v) {_vm.$set(userPortal, "portalId", $$v)},expression:"userPortal.portalId"}},_vm._l((_vm.systemPortals),function(systemPortal){return _c('option',{key:systemPortal._id.$oid,attrs:{"disabled":_vm.addedPortalIds.includes(
                                    systemPortal._id.$oid
                                )},domProps:{"value":systemPortal._id.$oid}},[_vm._v(" "+_vm._s(systemPortal.name)+" ")])}),0)],1),_c('b-field',{attrs:{"label":"Role","type":_vm.errorFields.includes(`${userPortal.index}-role`)
                            ? 'is-danger'
                            : ''}},[_c('b-select',{attrs:{"size":"is-small","disabled":_vm.isCurrentPortal(userPortal)},nativeOn:{"change":function($event){return _vm.removeErrorField(`${userPortal.index}-role`)}},model:{value:(userPortal.role),callback:function ($$v) {_vm.$set(userPortal, "role", $$v)},expression:"userPortal.role"}},_vm._l((_vm.portalRoles),function(portalRole){return _c('option',{key:portalRole._id,domProps:{"value":portalRole._id}},[_vm._v(" "+_vm._s(portalRole.name)+" ")])}),0)],1),_c('b-field',{attrs:{"label":"Default Portal","custom-class":_vm.errorFields.includes('defaultPortal')
                            ? 'has-text-danger'
                            : ''}},[_c('b-switch',{attrs:{"type":"is-success","disabled":_vm.isCurrentPortal(userPortal)},on:{"input":function($event){return _vm.handleDefaultPortalChange(
                                $event,
                                userPortal.index
                            )}},model:{value:(userPortal.isDefault),callback:function ($$v) {_vm.$set(userPortal, "isDefault", $$v)},expression:"userPortal.isDefault"}})],1),_c('b-field',{attrs:{"label":"Delete"}},[_c('b-button',{attrs:{"size":"is-small","type":"","title":"Remove access to this portal","disabled":_vm.isCurrentPortal(userPortal)},on:{"click":function($event){return _vm.confirmRemovePortal(userPortal)}}},[_c('font-awesome-icon',{staticClass:"fa-w-18",attrs:{"icon":['fas', 'trash']}})],1)],1)],1)],1)])})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }