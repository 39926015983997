<template>
    <div id="page-not-found">
        <div class="container is-fluid">
            <section class="section">
                <div class="container has-text-centered">
                    <div class="columns is-centered">
                        <div class="column is-7">
                            <h1 class="title is-1">404</h1>
                            <p class="subtitle is-3">Page not found</p>
                            <p></p>
                        </div>
                    </div>
                    <!-- <a class="button is-primary" href="#">Back to homepage</a> -->
                    <router-link to="/" class="button is-outlined">
                        <span class="icon">
                            <i class="fa fa-home"></i>
                        </span>
                        <span>Back to home page</span>
                    </router-link>
                </div>
            </section>
        </div>
    </div>
</template>
<script>
export default {
    name: "PageNotFound",
    components: {},
    data() {
        return {
            //   test: this.$store.getters.test
        };
    },
    mounted() {
        // console.log(this.$route);
    },
    methods: {}
};
</script>

<style lang="sscss" scoped></style>
