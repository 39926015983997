/**
 * helpers for getting/setting selected layers
 */
import LayerStateHelpers from "@/mixins/LayerStateHelpers.js";
import FeatureStateHelpers from "@/mixins/FeatureStateHelpers.js";
import MapStateHelpers from "@/mixins/MapStateHelpers.js";

import NotificationConfigModal from "@/components/modals/NotificationConfigModal.vue";
export default {
    name: "NotificationHelpers",
    mixins: [
        LayerStateHelpers,
        FeatureStateHelpers,
        MapStateHelpers
    ],
    components: {
        NotificationConfigModal
    },
    computed: {
        notifications: {
            get() {
                return this.$store.getters["mapState/notifications"];
            },
            set( notifications ) {
                this.$store.commit( "mapState/setNotifications", notifications );
            }
        },
    },
    methods: {
        launchEditNotificationModal(notification) {
            let props = {
                mode: "edit",
                portalId: notification.portalId,
                layerId: notification.layerId,
                siteId: notification.siteId,
                notificationType: notification.type,
                passedNotification: notification
            };
            this.launchNotificationModal(props);
        },
        launchNotificationModal( props ) {
            // console.log('in launch');
            // console.log(this);
            // console.log(NotificationConfigModal);
            
            
            
            this.$buefy.modal.open({
                component: NotificationConfigModal,
                props: props,
                parent: this,
                events: {
                    // "user-added": user => {
                    //     // console.log('user added:');
                    //     // console.log(user)
                    //     this.users.push(user);
                    // }
                }
            });
        },
        async changeTab(row, tabId) {
            // need to let the show feature function finish executing
            // so that the tab is no longer disabled
            var featureSelected = await this.showFeature(row);
            this.$nextTick(() => {
                if (featureSelected) {
                    let sidebar = this.$store.getters["sidebarState/sidebar"];
                    sidebar.enablePanel(tabId);
                    sidebar.open(tabId);
                }
            });
        },
        async showFeature(row) {
            
            
            let featureLayer, selectedLayerSite;

            featureLayer = this.layers.find(
                l => l._id.$oid === row.layerId
            );

            if (featureLayer) {
                selectedLayerSite = featureLayer.sites.find(
                    s => s._id.$oid === row.siteId
                );
            }

            this.selectedLayer = featureLayer;
            this.selectedLayer.visible = 1;
            

            // allows for the map to be updated
            this.$nextTick(() => {
                // @todo refactor
                // @todo extract into helper, also used in LayerDetails::showFetaure, PropertySearch
                // maybe generalise the show fetaure functionality
                let feature = this.selectedLayer.geometry[
                    selectedLayerSite.featureType
                ].find(f => f.siteId.$oid == selectedLayerSite._id.$oid);
                let mapFeature = this.$store.getters["mapState/map"]._layers[
                    feature._leaflet_id
                ];

                let centrePoint = this.getCentrePoint(mapFeature);
                this.latitude = centrePoint.lat;
                this.longitude = centrePoint.lng;

                // reset styling for any selected feature
                if (this.$store.getters["mapState/featureSelected"]) {
                    this.$store.getters["mapState/selectedFeature"].setStyle(
                        this.$store.getters["mapState/featureStyle"](
                            feature.type,
                            "defaultStyle",
                            this.selectedLayer.color
                        )
                    );
                }
                this.$store.commit("mapState/setSelectedFeature", mapFeature);
                mapFeature.setStyle(
                    this.$store.getters["mapState/featureStyle"](
                        feature.type,
                        "focusStyle"
                    )
                );
            });

            return featureLayer && selectedLayerSite;
        },
    }
};
