<template>
    <div id="sidebar-header">
        <h1 class="leaflet-sidebar-header">
            <div class="leaflet-sidebar-controls is-pulled-left">
                <font-awesome-icon
                    :icon="['fas', 'caret-left']"
                    class="leaflet-sidebar-control"
                    @click="decreaseWidth"
                />
                <span title="Sidebar Width">{{ width }}</span>
                <font-awesome-icon
                    :icon="['fas', 'caret-right']"
                    class="leaflet-sidebar-control"
                    @click="increaseWidth"
                />
            </div>

            <div id="header-title" class="has-text-centered">{{ headerTitle }}</div>

            <div class="leaflet-sidebar-close">
                <font-awesome-icon
                    :icon="['fas', 'times']"
                    @click="removeStyling"
                />
            </div>
        </h1>

        <div v-if="showClientLogo" id="sidebar-client-logo">
            <!-- <div style="height: 100px; width: 100%; position: relative;" v-if="isLoading"> -->
            <!-- <b-notification v-if="isLoading" :closable="false"> -->
                <!-- <div id="loading-logo"> -->
                    <!-- <b-loading :is-full-page="false" :active.sync="isLoading" :can-cancel="false">
                        <font-awesome-icon
                        :icon="['fad', 'sync']"
                        :style="$iconStyle"
                        size="4x"
                        spin
                    />
                    </b-loading> -->
                <!-- </div> -->
            <!-- </b-notification> -->
            <!-- </div> -->

            <div class="colums" v-if="isLoading">
                <div class="column">
                    <div style="border: none; box-shadow: none;" class="card">
                        <div class="card-content">
                            <div style="min-height: 100px;" class="content">
                                <b-loading :is-full-page="false" :active.sync="isLoading" :can-cancel="false">
                                    <font-awesome-icon
                                        :icon="['fad', 'sync']"
                                        :style="$iconStyle"
                                        size="4x"
                                        spin
                                    />
                                </b-loading>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            
            <div class="colums" v-else>
                <div class="column">
                    
                    <img
                        v-if="clientLogo"
                        :src="`data:image/png;base64,${clientLogo}`"
                        :alt="`${clientName} logo`"
                        height="100"
                        width="200"
                    />

                    <div v-else style="border: none; box-shadow: none; background-color: transparent;" class="card">
                        <div class="card-content">
                            <div class="content">
                                <div class="is-capitalized" id="placeholder-logo" title="Logo Missing for Client">
                                    {{clientName}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>     
                <!-- <img
                    v-else
                    :src="`https://fakeimg.pl//160x90?text=${clientName}`"
                    :alt="`${clientName} logo missing`"
                /> -->
            </div>
            
        </div>
    </div>
</template>

<script>
import ClientDetailsHelpers from "@/mixins/ClientDetailsHelpers.js";
export default {
    name: "SidebarHeader",
    mixins: [ClientDetailsHelpers],
    components: {},
    props: {
        headerTitle: String,
        isLoading: Boolean,
        showClientLogo: {
            type: Boolean,
            default: function() {
                return true;
            }
        }
    },
    data() {
        return {
            // sizeMap: {
            //     1: 460,
            //     2: 600,
            //     3: 800,
            //     4: 950
            // }
            sizeMap: {
                1: 25,
                2: 50,
                3: 75,
                4: 90
            }
        };
    },
    mounted() {},
    computed: {
        width: {
            get() {
                return this.$store.getters["sidebarState/width"];
            },
            set(newVal) {
                this.$store.commit("sidebarState/setWidth", newVal);
            }
        }
    },
    methods: {
        increaseWidth() {
            if (this.width < 4) {
                this.width++;
            }

            this.adjustSidebar();
        },
        decreaseWidth() {
            if (this.width > 1) {
                this.width--;
            }

            this.adjustSidebar();
        },
        adjustSidebar() {
            // to get going in the right direction:
            // https://github.com/nickpeihl/leaflet-sidebar-v2/issues/34
            // may need to remove in-line styling if window width < sidebar width
            // as it nicely snaps to width on smaller screens without it
            let newWidth = this.sizeMap[this.width];

            const sidebarElement = document.querySelector(".leaflet-sidebar");
            sidebarElement.style.width = `${newWidth}%`;
            sidebarElement.style.maxWidth = `${newWidth}%`;

            const controlElement = document.querySelector(
                ".leaflet-sidebar-left ~ .leaflet-control-container .leaflet-left"
            );
            controlElement.style.left = `${newWidth + 0.5}%`;
        },
        removeStyling() {
            this.width = 1;
            const sidebarElement = document.querySelector("#sidebar");
            sidebarElement.removeAttribute("style");

            const controlElement = document.querySelector(
                ".leaflet-sidebar-left ~ .leaflet-control-container .leaflet-left"
            );
            controlElement.removeAttribute("style");
            sidebarElement.classList.add("collapsed");
        }
    }
};
</script>

<style lang="scss">

// make sure the sidebar decreases nicely
#sidebar.leaflet-sidebar {
    transition: all 500ms;
}
</style>

<style lang="scss" scoped>

#header-title {
    margin-right: 35px;
}

#placeholder-logo{
    height: 100px;
    width: 200px;
    background-color: #c4c4c4;
    // background-color: #898A93;
    // color: rgb(40, 41, 43);
    color: #898A93;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5em;
    margin: 0 auto;
}

.leaflet-sidebar-header {
    padding-left: 0;
}
#sidebar-header .leaflet-sidebar-controls {
    // width: 50px;
    font-size: 1.5rem;
    padding-left: 10px;
}

#loading-logo {
    background-color: grey;
}

.leaflet-sidebar-control {
    margin-left: 5px;
    margin-right: 5px;
}

div#sidebar-client-logo {
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
}
</style>
