import { render, staticRenderFns } from "./DocCategory.vue?vue&type=template&id=e1a45506&scoped=true"
import script from "./DocCategory.vue?vue&type=script&lang=js"
export * from "./DocCategory.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e1a45506",
  null
  
)

export default component.exports