<template>
    <div id="user-portals">
        <b-loading
            :is-full-page="false"
            :active.sync="loadingData"
            :can-cancel="false"
        >
            <font-awesome-icon
                :icon="['fad', 'sync']"
                :style="$iconStyle"
                size="4x"
                spin
            />
        </b-loading>
        <h3
            class="subtitle is-5 header-with-button"
            :class="errorFields.includes('portals') ? 'has-text-danger' : ''"
        >
            Portal Access for User
            <!-- <help-tooltip padding-side="left">
                                <p>
                                    Assign users to portals
                                </p>
                            </help-tooltip> -->

            <b-button
                type=""
                title="Add a field to the layer"
                @click="addPortal"
                class="has-margin-left-5"
            >
                <font-awesome-icon :icon="['fas', 'plus']" />
            </b-button>
        </h3>

        <div
            class="columns nested-form-fields"
            v-for="userPortal in userPortals"
            :key="userPortal.index"
        >
            <div class="column">
                <b-field grouped class="nested-label-field">
                    <b-field
                        label="Portal"
                        :type="
                            errorFields.includes(`${userPortal.index}-portalId`)
                                ? 'is-danger'
                                : ''
                        "
                    >
                        <b-select
                            size="is-small"
                            v-model="userPortal.portalId"
                            @change.native="
                                removeErrorField(`${userPortal.index}-portalId`)
                            "
                            :disabled="isCurrentPortal(userPortal)"
                        >
                            <!-- :disabled="mode === 'edit' && loadedFields.includes(field.index)" -->
                            <option
                                v-for="systemPortal in systemPortals"
                                :key="systemPortal._id.$oid"
                                :value="systemPortal._id.$oid"
                                :disabled="
                                    addedPortalIds.includes(
                                        systemPortal._id.$oid
                                    )
                                "
                            >
                                {{ systemPortal.name }}
                            </option>
                        </b-select>
                    </b-field>

                    <b-field
                        label="Role"
                        :type="
                            errorFields.includes(`${userPortal.index}-role`)
                                ? 'is-danger'
                                : ''
                        "
                    >
                        <b-select
                            size="is-small"
                            v-model="userPortal.role"
                            @change.native="
                                removeErrorField(`${userPortal.index}-role`)
                            "
                            :disabled="isCurrentPortal(userPortal)"
                        >
                            <!-- :disabled="mode === 'edit' && loadedFields.includes(field.index)" -->
                            <option
                                v-for="portalRole in portalRoles"
                                :key="portalRole._id"
                                :value="portalRole._id"
                            >
                                {{ portalRole.name }}
                            </option>
                        </b-select>
                    </b-field>

                    <b-field
                        label="Default Portal"
                        :custom-class="
                            errorFields.includes('defaultPortal')
                                ? 'has-text-danger'
                                : ''
                        "
                    >
                        <b-switch
                            v-model="userPortal.isDefault"
                            type="is-success"
                            @input="
                                handleDefaultPortalChange(
                                    $event,
                                    userPortal.index
                                )
                            "
                            :disabled="isCurrentPortal(userPortal)"
                        />
                    </b-field>

                    <b-field label="Delete">
                        <b-button
                            size="is-small"
                            type=""
                            title="Remove access to this portal"
                            @click="confirmRemovePortal(userPortal)"
                            :disabled="isCurrentPortal(userPortal)"
                        >
                            <font-awesome-icon
                                :icon="['fas', 'trash']"
                                class="fa-w-18"
                            />
                        </b-button>
                    </b-field>
                </b-field>
            </div>
        </div>
    </div>
</template>

<script>
import uuidv1 from "uuid/v1";

export default {
    name: "UserPortals",
    components: {},
    props: {
        mode: {
            type: String,
            required: true,
            validator: function(value) {
                // The value must match one of these strings
                return ["new", "edit"].indexOf(value) !== -1;
            }
        },
        errorFields: Array,
        userPortals: Array,
        user: Object
    },
    data() {
        return {
            // portals
            loadingData: false,
            systemPortals: [],
            portalRoles: [],
            selectedPortal: null,
        };
    },
    computed: {
        addedPortalIds: function() {
            // console.log('up');

            // console.log(this.userPortals.map( portal => portal.portalId ));

            return this.userPortals.map(portal => portal.portalId);
        },
        editingSelf: function() {
            let currentPuid = this.$store.getters["sessionState/puid"];
            let editingPuid = this.user ? this.user._id.$oid.slice(-10) : null;
            return currentPuid === editingPuid;
        }
    },
    mounted() {
        this.fetchSystemPortals();
        this.fetchPortalRoles();
        this.selectedPortal = this.$store.getters["mapState/selectedPortal"];
    },
    methods: {
        isCurrentPortal(userPortal) {
            // console.log('isCurrentPortal');
            // console.log(userPortal);
            // console.log(this.selectedPortal);

            return this.editingSelf && userPortal.portalId === this.selectedPortal;
            // return true;
        },
        removeErrorField(field) {
            this.$emit("remove-error-field", field);
        },
        fetchSystemPortals() {
            this.loadingData = true;
            this.$ppClient.get("/portal/names").then(response => {
                this.loadingData = false;
                let body = response.data.body;
                this.systemPortals = body.portals;
            });
        },
        fetchPortalRoles() {
            this.$ppClient.get("/portal/roles").then(response => {
                let body = response.data.body;
                this.portalRoles = body.portalRoles;
            });
        },
        addPortal() {
            this.removeErrorField(`portals`);
            let newPortal = {
                // temp id for reliably deleting new layers (no mongo id)
                index: uuidv1(),
                portalId: null,
                role: null,
                // first portal added will be set as default
                isDefault: this.userPortals.length == 0 ? true : false
            };

            this.userPortals.push(newPortal);
            // console.log(this.userPortals);
        },
        handleDefaultPortalChange(val, index) {
            // console.log('d ref change');

            // console.log(val);
            // console.log(index);
            // remove if there was an error because not set
            this.removeErrorField(`defaultPortal`);

            // clear previous selection (there can be only one)
            this.userPortals.forEach(userPortal => {
                userPortal.isDefault = false;
            });
            // set the new display ref
            let userPortal = this.userPortals.find(
                userPortal => userPortal.index == index
            );
            userPortal.isDefault = val;
            // console.log(field);
        },
        getPortalName(portalId) {
            let portal = this.systemPortals.find(
                sysPortal => sysPortal._id.$oid == portalId
            );

            return portal ? portal.name : false;
        },
        confirmRemovePortal(portal) {
            let portalName = this.getPortalName(portal.portalId);

            // user feedback
            let message = portalName
                ? `Remove Access to ${portalName}?`
                : "Delete portal access?";

            this.$buefy.dialog.confirm({
                message: message,
                type: "is-danger",
                hasIcon: true,
                icon: `exclamation-circle`,
                onConfirm: () => this.removePortal(portal)
            });
        },
        removePortal(portal) {
            // get index of field object
            let removeIndex = this.userPortals
                .map(function(portal) {
                    return portal.index;
                })
                .indexOf(portal.index);

            // remove object
            this.userPortals.splice(removeIndex, 1);
        }
    }
};
</script>

<style lang="scss" scoped></style>
