<template>
    <div id="internal-user">
        <div class="columns">
            <div class="column">
                <b-field grouped class="nested-label-field">
                    <b-field
                        expanded
                        label="Employee Name"
                        :type="
                            errorFields.includes('employee') ? 'is-danger' : ''
                        "
                    >
                        <b-select
                            v-model="adUser.guid"
                            @change.native="removeErrorField('employee')"
                            size="is-small"
                            placeholder="Select an employee"
                            :disabled="irisUsersLoading || mode === 'edit'"
                            :loading="irisUsersLoading"
                        >
                            <option
                                v-for="irisUser in irisUsers"
                                :key="irisUser.guid"
                                :value="irisUser.guid"
                                :disabled="mode === 'new' && irisUser.alreadyAdded"
                            >
                                {{ irisUser.name }}
                            </option>
                        </b-select>
                    </b-field>
                </b-field>

                <b-field grouped class="nested-label-field">
                    <b-field
                        expanded
                        label="System Role"
                        :type="
                            errorFields.includes('systemRole')
                                ? 'is-danger'
                                : ''
                        "
                    >
                        <b-select
                            v-model="adUser.systemRole"
                            @change.native="removeErrorField('systemRole')"
                            size="is-small"
                            placeholder="Select a role"
                            :disabled="!sysRoleEditable"
                            :loading="irisUserDataLoading"
                        >
                            <option
                                v-for="systemRole in systemRoles"
                                :key="systemRole._id.$oid"
                                :value="systemRole._id.$oid"
                                :disabled="systemRole.accessLevel < sysAccess"
                            >
                                {{ systemRole.name }}
                            </option>
                        </b-select>
                    </b-field>
                </b-field>
            </div>

            <div class="column is-two-thirds">
                <div class="card">
                    <div class="card-content" id="iris-user-data-card" v-if="irisUserDataLoading">
                        <b-loading
                            :is-full-page="false"
                            :active.sync="irisUserDataLoading"
                            :can-cancel="false"
                        >
                            <p class="title is-6 has-text-centered">
                                <font-awesome-icon
                                    :icon="['fad', 'sync']"
                                    :style="$iconStyle"
                                    size="2x"
                                    spin
                                />
                            </p>
                        </b-loading>
                    </div>

                    <div v-else class="card-content">
                        <div v-if="irisUserData">
                            <div class="media">
                                <div class="media-left">
                                    <figure class="image is-64x64">
                                        <img
                                            :src="
                                                `data:${irisUserData.image.contentType};base64,${irisUserData.image.base64}`
                                            "
                                        />
                                    </figure>
                                </div>
                                <div class="media-content">
                                    <p class="title is-4">
                                        {{ irisUserData.data.name }}
                                    </p>
                                    <p class="subtitle is-6">
                                        {{ irisUserData.data.email }}
                                    </p>
                                </div>
                            </div>

                            <div class="content">
                                <strong>Job Title: </strong>
                                {{ irisUserData.data.jobTitle }}
                                <br />
                                <strong>Region (FP): </strong>
                                {{ 
                                    irisUserData.data.focalpoint.region ?
                                    irisUserData.data.focalpoint.region.name 
                                    : 'Not set'
                                }}
                                <br />
                                <strong>Team (FP): </strong>
                                {{ irisUserData.data.focalpoint.team || 'Not set' }}
                                <br />
                                <strong>Office: </strong>
                                {{ irisUserData.data.officeName }}
                                <br />
                                <strong>Phone: </strong>
                                {{ irisUserData.data.phone }}
                                <br />
                            </div>
                        </div>

                        <div v-else>
                            <p class="title is-6 has-text-centered">
                                Please select an employee
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AccessControlHelpers from "@/mixins/AccessControlHelpers.js";

export default {
    name: "InternalUser",
    mixins: [AccessControlHelpers],
    components: {},
    props: {
        mode: {
            type: String,
            required: true,
            validator: function(value) {
                // The value must match one of these strings
                return ["new", "edit"].indexOf(value) !== -1;
            }
        },
        adUser: Object,
        errorFields: Array
        // irisUserLoading: 
    },
    data() {
        return {
            irisUsersLoading: true,
            irisUsers: [],
            irisUserDataLoading: false,
            irisUserData: false,
            systemRoles: [],
            
        };
    },
    computed: {
        filteredSystemRoles: function() {
            if(this.sysAccess === 1) {
                return this.systemRoles;
            }

            return this.systemRoles.filter(role => role.accessLevel >= this.sysAccess);
        },
        // only allow admin users to edit the role of users that are lower or equal to themselves
        sysRoleEditable: function() {
            // console.log('in sysRoleEditable');
            // console.log(this.irisUserData);
            // console.log(this.adUser);
            // console.log(this.systemRoles);

            let selfEdit;
            if( this.adUser._id ) {
                let userPuid = this.adUser._id.$oid.slice(-10);
                selfEdit = userPuid == this.puid;
            } else {
                selfEdit = false;
            }

            let editingHigherRole = false;
            if( this.systemRoles.length > 0 && this.adUser.systemRole ){ 
                let adUserSysRole = this.systemRoles.find( r => r._id.$oid == this.adUser.systemRole );
                if( adUserSysRole.accessLevel < this.sysAccess ) {
                    editingHigherRole = true;
                }
                // console.log('adUserSysRole');
                // console.log(adUserSysRole);
                // console.log(this.sysAccess);

            }
            
            return !selfEdit && !editingHigherRole && this.irisUserData;
        }
    },
    mounted() {        
        this.fetchIrisUsers();
        this.fetchSystemRoles();
    },
    watch: {
        "adUser.guid": function() {
            this.irisUserDataLoading = true;
            this.irisUserData = false;
            this.selectedRole = null;
            // fetch iris details for admin user feedback
            this.fetchIrisUser();
            
            // update the name
            // will only be used as a reference in db
            // will use iris to make sure the correct employee details are displayed on FE
            // this breaks when loading edit modal as the iris users may not be loaded when watcher is assigned
            if(this.mode === 'new') {
                this.adUser.name = this.irisUsers.length > 0 
                ? this.irisUsers.find(
                    u => u.guid === this.adUser.guid
                ).name
                : '';
            }
            
            
        }
    },
    methods: {
        removeErrorField(field) {
            this.$emit('remove-error-field', field);
        },
        fetchIrisUsers() {
            this.$ppClient.get("/users/iris").then(response => {
                let body = response.data.body;
                this.irisUsers = body.irisUsers;
                this.irisUsersLoading = false;
            });
            // @todo catch
        },
        
        fetchSystemRoles() {
            this.$ppClient.get("/system-roles").then(response => {
                let body = response.data.body;
                this.systemRoles = body.systemRoles;
            });
            // @todo catch
        },
        fetchIrisUser() {
            this.irisUserDataLoading = true;
            let url = `/users/iris/${this.adUser.guid}`;
            this.$ppClient.get(url).then(response => {
                let body = response.data.body;
                console.log(body);
                this.irisUserData = body.user;
                this.irisUserDataLoading = false;
            });
            // @todo catch
        },
    }
};
</script>

<style lang="scss" scoped>

#iris-user-data-card {
    .loading-overlay{
        z-index: 980;
    }
}

</style>
