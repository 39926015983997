<template>
    <div id="site-doc-notifications">
        <div>
            <b-table
                :data="docNotifications"
            >
                <template slot-scope="props">
                    <b-table-column field="date" label="Date">
                        {{
                            new Date(
                                props.row.notificationDate
                            ).toLocaleDateString()
                        }}
                    </b-table-column>

                    <b-table-column field="title" label="Title">
                        {{ props.row.title }}
                    </b-table-column>

                    <b-table-column
                        field="show-notification"
                        label="Show"
                        centered
                    >
                        <b-button
                            @click="launchEditNotificationModal(props.row)"
                            title="reveal in notifications tab"
                        >
                            <font-awesome-icon :icon="'eye'" />
                        </b-button>
                    </b-table-column>
                </template>
            </b-table>
        </div>
    </div>
</template>

<script>
import FeatureStateHelpers from "@/mixins/FeatureStateHelpers.js";
import NotificationHelpers from "@/mixins/NotificationHelpers.js";

export default {
    name: "DocNotifications",
    mixins: [FeatureStateHelpers, NotificationHelpers],
    components: {},
    props: {
        docNotifications: Array
    },
    data() {
        return {};
    },
    computed: {
        // DocNotifications: function() {
        //     // console.log('site notifications');
        //     // console.log(this.selectedFeature);
        //     if (this.selectedFeature) {
        //         // console.log(this.selectedFeature._site.siteId.$oid);
        //         return this.notifications.filter(n => {
        //             // console.log(n);
        //             return (
        //                 n.type == "site" &&
        //                 n.siteId === this.selectedFeature._site.siteId.$oid
        //             );
        //         });
        //     }
        //     return [];
        // }
    },
    mounted() {
        // console.log();
    },
    methods: {
        // launchNewNotificationModal() {
        //     let selectedPortal = this.$store.getters["mapState/selectedPortal"];
        //     // console.log('launch modal');
        //     // console.log(this.selectedPortal);
        //     let props = {
        //         mode: "new",
        //         notificationType: "site",
        //         portalId: selectedPortal,
        //         siteId: this.selectedFeature._site.siteId.$oid,
        //         layerId: this.selectedLayer._id.$oid
        //     };
        //     this.launchNotificationModal(props);
        // }
    }
};
</script>

<style lang="scss" scoped></style>
