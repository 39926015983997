<template>
    <div id="user-config-modal">
        <b-loading
                :is-full-page="false"
                :active.sync="loadingData"
                :can-cancel="false"
            >
                <font-awesome-icon
                    :icon="['fad', 'sync']"
                    :style="$iconStyle"
                    size="4x"
                    spin
                />
            </b-loading>
        <div class="modal-card" style="width: auto">
            <header class="modal-card-head">
                <p class="modal-card-title is-capitalized">{{ mode }} User</p>

                <span v-if="mode === 'edit'" class="has-text-right">
                    <div class="buttons">
                        <b-button type="is-danger" @click="confirmDeleteUser">
                            Delete User
                        </b-button>
                    </div>
                </span>
            </header>

            <section class="modal-card-body">
                <section>
                    <b-notification
                        type="is-danger"
                        class="margin-bottom-15"
                        role="alert"
                        v-if="errors.length > 0"
                        aria-close-label="Close notification"
                    >
                        <ul>
                            <li v-for="(error, i) in errors" :key="i">
                                {{ error }}
                            </li>
                        </ul>
                    </b-notification>
                </section>

                <b-steps
                    v-model="activeStep"
                    :animated="true"
                    :has-navigation="false"
                    :mobile-mode="null"
                >
                    <b-step-item label="User Data" :clickable="true">
                        <!-- <h1 class="title has-text-centered">Account</h1> -->

                        <b-tabs v-model="userTypeTab" type="is-boxed" id="user-type-tabs">
                            <b-tab-item
                                label="Internal (FG) User"
                                :disabled="userTypeTabDisabled('adUser')"
                            >
                                <internal-user
                                    v-if="mode == 'new' || editUserType == 'adUser'"
                                    :mode="mode"
                                    :adUser.sync="adUser"
                                    :errorFields="errorFields"
                                    @iris-user-loading-state-change="
                                        loadingIrisUsers = $event
                                    "
                                    @remove-error-field="
                                        removeErrorField($event)
                                    "
                                />
                            </b-tab-item>

                            <b-tab-item
                                label="External User"
                                :disabled="userTypeTabDisabled('extUser')"
                            >
                                <external-user
                                    v-if="mode == 'new' || editUserType == 'extUser'"
                                    :mode="mode"
                                    :extUser.sync="extUser"
                                    :errorFields="errorFields"
                                    @remove-error-field="
                                        removeErrorField($event)
                                    "
                                    @errors="errorsEmitted"
                                />
                            </b-tab-item>
                        </b-tabs>
                    </b-step-item>

                    <b-step-item
                        label="Portals"
                        :clickable="true"
                        :type="{ 'is-danger': errorFields.includes('portals') }"
                    >
                        <user-portals
                            :mode="mode"
                            :userPortals.sync="userPortals"
                            :user="user"
                            :errorFields="errorFields"
                            @remove-error-field="removeErrorField($event)"
                        />
                    </b-step-item>
                </b-steps>
            </section>

            <footer class="modal-card-foot">
                <button class="button" @click="$parent.close()">Cancel</button>
                <button
                    v-if="this.userPortals.length == 0"
                    class="button is-primary"
                    type="button"
                    @click="activeStep = 1"
                >
                    Add Portals
                </button>

                <button
                    v-else
                    class="button is-success"
                    type="button"
                    @click="confirm"
                >
                    Confirm
                </button>
            </footer>
        </div>
    </div>
</template>

<script>
import LoadingNotification from "@/components/notifications/LoadingNotification.vue";
import utils from "@/mixins/utils.js";

import HelpTooltip from "@/components/notifications/HelpTooltip.vue";

import InternalUser from "@/components/forms/user/InternalUser.vue";
import ExternalUser from "@/components/forms/user/ExternalUser.vue";
import UserPortals from "@/components/forms/user/UserPortals.vue";

export default {
    name: "UserConfigModal",
    mixins: [utils],
    components: {
        LoadingNotification,
        HelpTooltip,
        InternalUser,
        ExternalUser,
        UserPortals
    },
    props: {
        mode: {
            type: String,
            required: true,
            validator: function(value) {
                // The value must match one of these strings
                return ["new", "edit"].indexOf(value) !== -1;
            }
        },
        user: Object

        // adUser: {
        //     type: Object,
        //     default: function() {
        //         return {
        //             name: "",
        //             color: false,
        //             opacity: 0.5,
        //             fields: []
        //         };
        //     }
        // },
        // extUser: {
        //     type: Object,
        //     default: function() {
        //         return {
        //             name: "",
        //             color: false,
        //             opacity: 0.5,
        //             fields: []
        //         };
        //     }
        // }
    },
    data() {
        return {
            // data affecting loading state
            loadingData: false,
            errors: [],
            errorFields: [],
            activeStep: 0,
            // user data
            userTypeTab: 0,
            availableUserTypes: ["adUser", "extUser"],
            adUser: {
                userType: "ad",
                guid: null,
                name: null,
                systemRole: null
            },
            extUser: {
                userType: "ext",
                name: null,
                email: null,
                phone: null,
                mobile: null
            },

            // portals
            userPortals: []
        };
    },
    computed: {
        selectedUserType: function() {
            return this.availableUserTypes[this.userTypeTab];
        },

        defaultPortalSet: function() {
            if (this.userPortals.length > 0) {
                return this.userPortals.some(e => e.isDefault);
            }
            // don't complain if none set
            // they get a message saying no portals added
            return true;
        },
        editUserType: function() {
            if (this.mode === "edit") {
                return `${this.user.userType}User`;
            }

            return false;
        }
    },
    mounted() {
        // console.log('mounted');
        // console.log(this.user);
        // console.log(this.mode);

        if (this.mode == "edit") {
            this[this.editUserType] = this.user;

            this[this.editUserType] = Object.assign(
                this[this.editUserType],
                this.user
            );
            // filter out unnesseccary keys
            // for( let key of Object.keys(this[this.editUserType])){
            //     this[this.editUserType][key] = this.user[key];
            // }
            // this[this.editUserType]['_id'] = this.user._id.$oid;
            this.userTypeTab = this.availableUserTypes.indexOf(
                this.editUserType
            );
            this.fetchUserPortals(this.user._id.$oid);
            // this.fetchIrisUsers();
            // this.fetchSystemRoles();
            // console.log();
            // console.log('mounted');
            // console.log(this[this.editUserType]);
            // console.log(this.adUser);
        }
    },
    watch: {
        userTypeTab: function() {
            // reset errors when tab is changed
            this.errorFields = [];
            this.errors = [];
        }
        // "adUser.guid": function() {
        //     console.log("emp changed");
        //     // console.log(this.selectedEmployee);
        //     console.log(this.adUser);

        // }
    },
    methods: {
        errorsEmitted(e) {
            // console.log('errors emitted');
            // console.log(e);
            this.errors = [...this.errors, ...e];
            // console.log(this.errors);
            
        },
        fetchUserPortals(userId) {
            let url = `/users/${userId}/portals`;
            this.$ppClient.get(url).then(response => {
                let body = response.data.body;
                // console.log("up");
                // console.log(body);

                this.userPortals = body.userPortals;
            });
        },
        userTypeTabDisabled(userType) {
            return this.mode === "edit" && this.editUserType !== userType;
        },
        removeErrorField(field) {
            this.errorFields = this.errorFields.filter(el => el !== field);
            this.errors = [];
        },
        confirm() {
            this.validateForm().then(valid => {
                if (valid) {
                    this.compileFormData().then(
                        formData => {
                            // console.log("formData");
                            // console.log(formData);
                            // console.log(this.camelToKebab(this.userType));

                            this.mode === "new"
                                ? this.submitNew(formData)
                                : this.submitUpdate(formData);
                        },
                        error => {
                            console.log("error in compileFormData");
                            console.log(error);
                        }
                    );
                }
            });
        },
        // this component is basically a compound form.
        // it's comprised of 3 major components.
        // we centralise the validation of all the components
        // here and push any errors to the child components
        // by using the errorFields array
        async validateForm() {
            // console.log('validate');
            // console.log(this.selectedUserType);

            // reset errors for validation pass
            this.errorFields = [];
            this.errors = [];

            if (this.selectedUserType === "adUser") {
                if (!this.adUser.guid) {
                    this.errorFields.push("employee");
                    this.errors.push("Employee: is required");
                }

                if (!this.adUser.systemRole) {
                    this.errorFields.push("systemRole");
                    this.errors.push("System Role: is required");
                }
            }

            if (this.selectedUserType === "extUser") {
                // console.log(this.extUser);

                if (!this.extUser.name) {
                    this.errorFields.push("extName");
                    this.errors.push("Name: is required");
                }

                if (!this.extUser.email) {
                    this.errorFields.push("extEmail");
                    this.errors.push("Email: is required");
                } else {
                    let emailRegEx = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                    if (!emailRegEx.test(this.extUser.email)) {
                        this.errorFields.push("extEmail");
                        this.errors.push("Email: please check format");
                    }
                }
            }

            if (this.userPortals.length > 0) {
                for (let userPortal of this.userPortals) {
                    if (!userPortal.portalId) {
                        this.errorFields.push(`${userPortal.index}-portalId`);
                        if (!this.errors.includes("Portal: is required")) {
                            this.errors.push("Portal: is required");
                        }
                    }

                    if (!userPortal.role) {
                        this.errorFields.push(`${userPortal.index}-role`);
                        if (!this.errors.includes("Portal Role: is required")) {
                            this.errors.push("Portal Role: is required");
                        }
                    }
                }
                if (!this.defaultPortalSet) {
                    this.errorFields.push("defaultPortal");
                    this.errors.push("Default Portal: not set");
                }
            } else {
                this.errorFields.push(`portals`);
                this.errors.push(
                    "Portal Access: User must be assigned to at least one portal"
                );
            }

            // was the form valid?
            return this.errors.length === 0;
        },
        async compileFormData() {
            return {
                user: this[this.selectedUserType],
                portalAccess: this.userPortals
            };
        },
        submitNew(formData) {
            this.loadingData = true;
            let url = `/users`;

            this.$ppClient.post(url, formData).then(response => {
                let body = response.data.body;

                if (!body.status.valid || !body.newUser) {
                    this.loadingData = false;
                    this.errorFields = body.status.errors.fields;
                    this.errors = body.status.errors.messages;
                } else {
                    this.loadingData = false;
                    this.$emit("user-added", body.newUser);
                    this.$parent.close();
                }
            }).catch(error => {
                console.log(error);
                this.errors.push('Something Went Wrong, Please try again.')
            });
        },
        submitUpdate(formData) {
            this.loadingData = true;
            let url = `/users/${this[this.editUserType]["_id"]["$oid"]}`;

            this.$ppClient.patch(url, formData).then(response => {
                let body = response.data.body;

                // console.log('response');
                // console.log(body.updatedUser);

                if (!body.updatedUser) {
                    this.loadingData = false;
                    this.errors.push("Error updating user");
                } else {
                    this.loadingData = false;
                    this.$emit("user-updated", body);
                    this.$parent.close();
                }
            }).catch(error => {
                console.log(error);
                this.errors.push('Something Went Wrong, Please try again.')
            });
        },
        confirmDeleteUser() {
            // console.log(this.user);
            
            let message = `<p>Are you sure you want to delete:</p>
                <br>
                <p><strong>${this.user.name}</strong></p>
                <br>
                <p><strong>This action cannot be undone</strong></p>`;

            this.$buefy.dialog.confirm({
                message: message,
                type: "is-danger",
                hasIcon: true,
                icon: `exclamation-circle`,
                onConfirm: () => this.deleteUser()
            });
        },
        deleteUser() {
            let url = `users/${this.user._id.$oid}`;
            this.$ppClient
                .delete(url)
                .then(response => {
                    // console.log(response.data);

                    let body = response.data.body;
                    if (body.deleteSuccess) {
                        this.$emit('user-deleted', this.user);
                        this.$parent.close();
                    } else {
                        this.errors.push(
                            "Something went wrong. Please try again"
                        );
                    }
                })
                .catch(error => {
                    this.errors.push(
                        "Something went wrong. Please try again"
                    );
                    if (error.response) {
                        // Request made and server responded
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                    } else if (error.request) {
                        // The request was made but no response was received
                        console.log(error.request);
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        console.log("Error", error.message);
                        
                    }
                });
        }
    }
};
</script>

<style lang="scss" scoped>
.columns.nested-form-fields {
    .column {
        padding-top: 0;
        padding-bottom: 0;
    }
}
</style>

<style lang="scss">
#user-type-tabs{
        nav.tabs {
            li.is-active{
                border: 2px solid #b3b3b3;
                border-bottom: none;
                border-top-left-radius: 5px;
                border-top-right-radius: 5px;
            }

            ul {
                border-bottom: 1px solid #b3b3b3;
            }
        }
    }
</style>
