<template>
    <div id="portal-contacts">
        <loading-notification :is-loading="isLoading"></loading-notification>
        <sidebar-header header-title="Contacts" :show-client-logo="false" />
        <div id="portal-contacts-header-actions" class="margin-top-15">
            <div v-if="portalAccess < 3" class="buttons">
                <b-button
                    type=""
                    @click="launchNewContactModal"
                    title="Add a user as a contact"
                >
                    <font-awesome-icon :icon="'user-plus'"></font-awesome-icon>
                </b-button>
            </div>
        </div>

        <div id="contact-display">
            <div
                class="columns"
                v-for="(contacts, i) in chunkedPortalContacts"
                :key="i"
            >
                <div
                    class="column"
                    :class="computedColsSize"
                    v-for="contact in contacts"
                    :key="contact['_id']['$oid']"
                >
                    <div class="card client-portal-select">
                        <div v-if="portalAccess < 3" class="card-header">
                            <div class="card-header-title">
                                <div class="buttons">
                                    <b-button
                                        class="has-margin-right-10"
                                        type="is-primary"
                                        title="Edit Contact"
                                        @click="
                                            launchEditContactModal(contact)
                                        "
                                    >
                                        <font-awesome-icon :icon="'edit'"/>
                                    </b-button>
                                </div>
                            </div>
                        </div>

                        <div class="card-content">
                            <div class="content">
                                <h5 class="title is-5">
                                    {{ contact.name }}
                                </h5>
                                <h6 class="subtitle is-6 contact-subtitle">
                                    {{ contact.description }}
                                </h6>
                                
                                <p class="contact-field">
                                    <span class="contact-field-icon"><font-awesome-icon :icon="'at'"/></span> <a :href="`mailto:contact.email`"> {{ contact.email }} </a>
                                </p>

                                <p class="contact-field">
                                    <span class="contact-field-icon"><font-awesome-icon :icon="'phone'"/></span> <a :href="`tel:contact.phone`"> {{ contact.phone }} </a>
                                </p>

                                <p class="contact-field">
                                    <span class="contact-field-icon"><font-awesome-icon :icon="'mobile-alt'"/></span> <a :href="`tel:contact.mobile`"> {{ contact.mobile }} </a>
                                </p>


                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import ClientDetailsHelpers from "@/mixins/ClientDetailsHelpers.js";
import AccessControlHelpers from "@/mixins/AccessControlHelpers.js";

import LoadingNotification from "@/components/notifications/LoadingNotification.vue";
import SidebarHeader from "@/components/sidebar/SidebarHeader.vue";
import ContactConfigModal from "@/components/modals/ContactConfigModal.vue";

import chunk from "chunk";
import LoginVue from '../../views/Login.vue';

export default {
    name: "PortalContacts",
    mixins: [ClientDetailsHelpers, AccessControlHelpers],
    components: {SidebarHeader, LoadingNotification},
    props: { isLoading: Boolean},
    data() { 
        return {
            portalContacts: []
        } 
    },
    created(){
        // if any users are deleted make suyre they get removed from the current contacts
        // doesn't work due to not all contacts having a userId
        // this.$eventHub.$on("user-deleted", userId => {
        //     console.log(this.portalContacts);
        //     console.log(userId);
        //     let removeIndex = this.portalContacts
        //         .map(function(c) {
        //             return c.userId.$oid;
        //         })
        //         .indexOf(userId);

        //     this.portalContacts.splice(removeIndex, 1);
        // });
    },
    mounted () {},
    watch: {
        selectedPortal: function() {
            if( this.selectedPortal ) {
                let url = `portal/${this.selectedPortal}/contacts`;

                this.$ppClient.get(url).then(response => {
                    let body = response.data.body;
                    this.portalContacts = body.portalContacts;
                    // console.log(this.portalContacts);
                });
            }
        }
    },
    computed: {
        chunkedPortalContacts() {
            let currentWidth = this.$store.getters["sidebarState/width"];
            // console.log(chunk(this.userPortals, currentWidth));
            // return this.userPortals;
            return chunk(this.portalContacts, currentWidth);
        },
        computedColsSize() {
            let currentWidth = this.$store.getters["sidebarState/width"];
            switch (currentWidth) {
                case 1:
                    return "is-full";
                    break;
                case 2:
                    return "is-one-half";
                    break;
                case 3:
                    return "is-one-third";
                    break;

                default:
                    return "is-one-quarter";
                    break;
            }
        }
    },
    methods: {
        launchNewContactModal() {
            let mode = "new";
            this.launchContactConfigModal(mode);
        },
        launchEditContactModal( contact ) {
            // console.log('launch edit modal');
            // console.log(contact);
            let mode = "edit";
            // clone the object so that changes in the modal 
            // don't affect the object in memory.
            // we'll propagate the changes after the update succeeds
            let contactClone = Object.assign({}, contact);
            // console.log(contactClone);
            this.launchContactConfigModal(mode, contactClone);
        },
        launchContactConfigModal( mode, contact=false ) {
            let props = {
                mode: mode
            };

            if (mode === "edit" && contact) {
                props.contact = contact;
            }

            this.$buefy.modal.open({
                component: ContactConfigModal,
                props: props,
                parent: this,
                events: {
                    'contact-added': contact => {
                        this.portalContacts.push(contact);
                        this.portalContacts.sort((a, b) => a.name.localeCompare(b.name));
                    },
                    'contact-updated': contact => {
                        let objIndex = this.portalContacts.findIndex((cont => cont._id.$oid == contact._id.$oid));
                        this.$set(this.portalContacts, objIndex, contact);
                        this.portalContacts.sort((a, b) => a.name.localeCompare(b.name));
                    },
                    'contact-deleted': contactId => {
                        let removeIndex = this.portalContacts
                            .map(function(c) {
                                return c._id.$oid;
                            })
                            .indexOf(contactId);

                        this.portalContacts.splice(removeIndex, 1);
                    }
                }
            });
        }
    }
};
</script>

<style lang="scss" scoped>

.contact-subtitle{
    color: var(--fg-grey-med);
}

.contact-field{
    font-size: 1.2em;
    display: flex;
    align-items: center;
    margin-bottom: 0.7em !important;
    .contact-field-icon{
        min-width: 20px;
    }
}

</style>
