<template>
    <ul class="vuejs-countdown">
        <li v-if="days > 0 && showDays">
            <p class="digit">{{ days | twoDigits }}</p>
            <p class="text">{{ days > 1 ? 'days' : 'day' }}</p>
        </li>
        <li v-if="showHours">
            <p class="digit">{{ hours | twoDigits }}</p>
            <p class="text">{{ hours > 1 ? 'hours' : 'hour' }}</p>
        </li>
        <li v-if="showMinutes">
            <p class="digit">{{ minutes | twoDigits }}</p>
            <p class="text">min</p>
        </li>
        <li v-if="showSeconds">
            <p class="digit">{{ seconds | twoDigits }}</p>
            <p class="text">Sec</p>
        </li>
    </ul>
</template>

<script>
let interval = null;
export default {
    name: 'Countdown',
    props: {
        endDate: {
            type: Date
        },
        warning: {
            type: Number
        },
        stop: {
            type: Boolean,
            default: false
        },
        showDays: {
            type: Boolean,
            default: false
        },
        showHours: {
            type: Boolean,
            default: false
        },
        showMinutes: {
            type: Boolean,
            default: false
        },
        showSeconds: {
            type: Boolean,
            default: false
        },
        endFunction: {
            type: Function,
            default: () => { console.log("Goodbye and thanks for all the fish.") }
        },
        warningFunction: {
            type: Function,
            default: () => { this.$emit('warning') }
        }
    },
    data() {
        return {
            now: new Date(),
            diff: 0
        }
    },
    created() {
        // if (!this.endDate) {
        //     throw new Error("Missing props 'endDate'");
        // }
        // update the current time every second
        interval = setInterval(() => {
            this.now = new Date();
        }, 1000);
    },
    computed: {
        seconds() {
            return Math.trunc(this.diff) % 60
        },
        minutes() {
            return Math.trunc(this.diff / 60) % 60
        },
        hours() {
            return Math.trunc(this.diff / 60 / 60) % 24
        },
        days() {
            return Math.trunc(this.diff / 60 / 60 / 24)
        }
    },
    watch: {
        now(value) {
            this.diff = (this.endDate - this.now) / 1000;
            if(this.diff <= 0 || this.stop){
                this.diff = 0;
                this.endFunction();
                // Remove interval
                clearInterval(interval);
            }
        },
        diff( value ) {
            // console.log('diff value');
            // console.log(value);
            if( this.warning && this.warning > 0 ){
                if( this.endDate && value <= this.warning ) {
                    this.warningFunction();
                }
            }
            
            
        }
    },
    filters: {
        twoDigits(value) {
            if ( value.toString().length <= 1 ) {
                return '0'+value.toString()
            }
            return value.toString()
        }
    },
    destroyed() {
        clearInterval(interval);
    }
}
</script>
<style>
.vuejs-countdown {
  padding: 0;
  margin: 0;
}
.vuejs-countdown li {
  display: inline-block;
  margin: 0 8px;
  text-align: center;
  position: relative;
}
.vuejs-countdown li p {
    margin: 0;
}
.vuejs-countdown li:after {
  content: ":";
  position: absolute;
  top: 0;
  right: -13px;
  font-size: 32px;
}
.vuejs-countdown li:first-of-type {
  margin-left: 0;
}
.vuejs-countdown li:last-of-type {
  margin-right: 0;
}
.vuejs-countdown li:last-of-type:after {
  content: "";
}
.vuejs-countdown .digit {
  font-size: 32px;
  font-weight: 600;
  line-height: 1.4;
  margin-bottom: 0;
}
.vuejs-countdown .text {
  text-transform: uppercase;
  margin-bottom: 0;
  font-size: 10px;
}
</style>