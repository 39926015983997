import FeatureStateHelpers from "@/mixins/FeatureStateHelpers.js";
import FieldMapHelpers from "@/mixins/FieldMapHelpers.js";

export default {
    mixins: [FeatureStateHelpers, FieldMapHelpers],
    props: {
        // title for tooltip
        feature: Object
    },
    data() {
        return {};
    },
    computed: {
        focusStrokeColour: function() {
            return this.$store.getters["mapState/selectedBaseLayer"].options.focusStrokeColour;
        },
        defaultStyle: function() {
            return this.$store.getters["mapState/featureStyle"](
                this.feature.type,
                "defaultStyle",
                this.color
            );
        },
        focusStyle: function() {
            // console.log(this.$store.getters["mapState/selectedBaseLayer"].options.focusStrokeColour);
            // console.log(this.focusStrokeColour);
            
            return this.$store.getters["mapState/featureStyle"](
                this.feature.type,
                "focusStyle",
                this.focusStrokeColour
            );
        },
        // get the display ref for this feature/site
        displayRef() {
            let site = this.layer.sites.find(site => site._id.$oid === this.feature.siteId.$oid);

            if( site.displayRef ) {
                return site[site.displayRef] || false;
            }

            if( this.layer.popup ) {
                let layerType = this.layer.popup;
                let fieldMap = false;
                let displayRef = false;
                switch (layerType) {
                    case 'propman':
                        fieldMap = this.fieldMapFor('propman', 'propman');
                        displayRef = this.getDisplayRefFromMap(site.fields.propman, fieldMap);
                        break;
                    case 'geostore':
                        fieldMap = this.fieldMapFor('geostore', 'geostore');
                        displayRef = this.getDisplayRefFromMap(site.fields.geostore, fieldMap);
                        break;
                    case 'registeredtitle':
                        fieldMap = this.fieldMapFor('registeredtitle', 'geostore');
                        displayRef = this.getDisplayRefFromMap(site.fields.geostore, fieldMap);
                        break;
                    default:
                        break;
                }

                return displayRef || false;
            }
            
        }
    },
    mounted() {},
    methods: {
        clicked(e) {
            
            // console.log('mapFeature: clicked');
            // console.log(e.target);
            
            
            // reset styling for any selected feature
            // if(this.$store.getters["mapState/featureSelected"]) {
            //     this.$store.getters["mapState/selectedFeature"].setStyle(this.defaultStyle);
            // }
            // this.$store.commit("mapState/setSelectedFeature", e.target);
            if (this.featureSelected) {
                this.selectedFeature.setStyle(this.defaultStyle);
            }
            this.selectedFeature = e.target;
            this.selectedFeature.setStyle(this.focusStyle);

            // console.log('in mapFeature');
            // console.log(this.$store.getters["mapState/selectedLayer"]);

            // console.log(this.selectedFeature);
            
            let layers = this.$store.getters["mapState/layers"];
            // console.log(layers);
            let selectedSiteLayer = layers.find( layer => layer._id.$oid == this.selectedFeature._site.layerId.$oid );
            // console.log(selectedSiteLayer);
            this.$store.commit("mapState/setSelectedLayer", selectedSiteLayer);
            
            // let layer = this.layers.find( layer => layer._id.$oid == row._id.$oid);
            // this.$store.commit("mapState/setSelectedLayer", layer)
            
            
            

            // this.$store.getters["mapState/map"].pm.addControls({
            //     position: "topleft",
            //     drawPolyline: false,
            //     editMode: true,
        
            //   });


            // let additionalData = this.fetchAdditionalData(this.selectedFeature._site.siteId.$oid)
            // console.log('additionalData');
            // console.log(additionalData);
            // let siteId = this.selectedFeature._site.siteId.$oid;
            // this.$ppClient
            //     .get(`/site/${siteId}`)
            //     .then(response => {
            //         console.log(response);

            //         let body =  response.data.body;
            //         this.fetchedFeatureData = body;

            //         // JSON responses are automatically parsed.
            //         // this.layers = body.portal.layers;
            //         // this.$store.commit("mapState/setLayers", body.portal.layers);
            //     })
            //     .catch(e => {
            //         console.log(e);
            //     });

            /**
             * @todo select layer pragmatically
             */
        },
        getFeaturePopup(targetId, layer){
            // console.log('map: getFeatureName');

            let details = layer.sites.find( s => s._id.$oid == targetId);
            
            // console.log(point);
            // console.log(layer);
            // console.log(details);

            if(details && details.name !== 'undefined') {
                return {title: details.name};
            }
            
            return false;
            
        },
        mouseover(e) {
            // e.target.setStyle({fillPattern: this.$store.getters["mapState/stripePattern"]})
            e.target.setStyle(this.focusStyle);
            // console.log( e.target );
            // console.log(this.feature);
            // console.log(this.layer);
        },
        mouseout(e) {
            if (this.$store.getters["mapState/featureSelected"]) {
                if (
                    e.target._leaflet_id !==
                    this.$store.getters["mapState/selectedFeature"]._leaflet_id
                ) {
                    e.target.setStyle(this.defaultStyle);
                }
            } else {
                e.target.setStyle(this.defaultStyle);
            }
        },
        added(e) {
            // console.log('added');
            // console.log(e);
            

            let map = this.$store.getters["mapState/map"];
            let layers = map._layers;
            // console.log(layers);

            let layer = layers[e.target._leaflet_id];
            // console.log('layer');
            // console.log(layer);
            
            
            layer._site = this.feature;
            layer._site.layerId = this.layer._id;
            layer._site.source = this.layer.source;
            // console.log(this.layer);

            // console.log(this.feature);
            // add a reference to the leaflet id for the added feature
            this.feature._leaflet_id = e.target._leaflet_id;
        }
    }
};
