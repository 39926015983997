var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"gs-layer-importer"}},[_c('b-loading',{attrs:{"is-full-page":false,"active":_vm.loadingData,"can-cancel":false},on:{"update:active":function($event){_vm.loadingData=$event}}},[_c('font-awesome-icon',{style:(_vm.$iconStyle),attrs:{"icon":['fad', 'sync'],"size":"4x","spin":""}})],1),_c('section',[(_vm.errors.length > 0)?_c('b-notification',{staticClass:"margin-bottom-15",attrs:{"type":"is-danger","role":"alert","aria-close-label":"Close notification"}},[_c('ul',_vm._l((_vm.errors),function(error,i){return _c('li',{key:i},[_vm._v(" "+_vm._s(error)+" ")])}),0)]):_vm._e()],1),_c('b-field',[_c('b-field',{attrs:{"label":"Geostore Layer","type":_vm.errorFields.includes('gsLayer') ? 'is-danger' : ''}},[_c('b-select',{attrs:{"placeholder":"Select a layer"},nativeOn:{"change":function($event){return _vm.removeErrorField('gsLayer')}},model:{value:(_vm.selectedGsLayer),callback:function ($$v) {_vm.selectedGsLayer=$$v},expression:"selectedGsLayer"}},_vm._l((_vm.gsLayers),function(layer){return _c('option',{key:layer.job_id,domProps:{"value":layer.job_id}},[_vm._v(" "+_vm._s(layer.layer)+" ")])}),0)],1)],1),_c('br'),(_vm.selectedGsLayer)?_c('h3',{staticClass:"subtitle is-5 margin-top-15"},[_vm._v(" Field Mapping "),_c('b-button',{staticClass:"has-margin-left-5",attrs:{"type":"","title":"Add an FG managed Layer"},on:{"click":_vm.addGsLayerFieldMap}},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'plus']}})],1)],1):_vm._e(),_vm._l((_vm.mappedGsLayerFields),function(mappedGsLayerField){return _c('div',{key:mappedGsLayerField.index,staticClass:"columns nested-form-fields"},[_c('div',{staticClass:"column"},[_c('b-field',{staticClass:"nested-label-field",attrs:{"grouped":""}},[_c('b-field',{attrs:{"label":"Geostore Field","type":_vm.errorFields.includes(
                            `${mappedGsLayerField.index}-gs-field`
                        )
                            ? 'is-danger'
                            : ''}},[_c('b-select',{attrs:{"size":"is-small"},nativeOn:{"change":function($event){return _vm.removeErrorField(
                                `${mappedGsLayerField.index}-gs-field`
                            )}},model:{value:(mappedGsLayerField.gsField),callback:function ($$v) {_vm.$set(mappedGsLayerField, "gsField", $$v)},expression:"mappedGsLayerField.gsField"}},_vm._l((_vm.selectedGsLayerFields),function(selectedGsLayerField,i){return _c('option',{key:i,domProps:{"value":selectedGsLayerField}},[_vm._v(_vm._s(selectedGsLayerField))])}),0)],1),_c('b-field',{attrs:{"label":"Local Field","type":_vm.errorFields.includes(
                            `${mappedGsLayerField.index}-layer-field`
                        )
                            ? 'is-danger'
                            : ''}},[_c('b-select',{attrs:{"size":"is-small"},nativeOn:{"change":function($event){return _vm.removeErrorField(
                                `${mappedGsLayerField.index}-layer-field`
                            )}},model:{value:(mappedGsLayerField.layerField),callback:function ($$v) {_vm.$set(mappedGsLayerField, "layerField", $$v)},expression:"mappedGsLayerField.layerField"}},_vm._l((_vm.layer.fields),function(field){return _c('option',{key:field.id,domProps:{"value":field.id}},[_vm._v(_vm._s(field.name))])}),0)],1),_c('b-field',{attrs:{"label":""}},[_c('p',{staticClass:"control"},[_c('label',{staticClass:"label"},[_vm._v(" "),_c('br')]),_c('b-button',{attrs:{"size":"is-small","type":"","title":"Delete this layer"},on:{"click":function($event){return _vm.removeMappedGsLayerField(
                                    mappedGsLayerField.index
                                )}}},[_c('font-awesome-icon',{staticClass:"fa-w-18",attrs:{"icon":['fas', 'trash']}})],1)],1)])],1)],1)])}),_c('button',{staticClass:"button is-primary",attrs:{"type":"button"},on:{"click":_vm.confirm}},[_vm._v(" Import From Geostore ")])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }