<template>
    <div id="external-site-field-mapper">
        <div class="buttons">
            <b-button
                type="is-danger"
                title="Cancel"
                @click="$emit('cancel-edit-field-map')"
            >
                <font-awesome-icon icon="times" />
            </b-button>

            <b-button
                @click="saveMap"
                type="is-success"
                title="save changes and apply"
            >
                <font-awesome-icon icon="save" />
            </b-button>
        </div>

        <b-table
            id="field-mapper-table"
            class="trans-table"
            :data.sync="fieldMap"
        >
            <template slot-scope="props">
                <b-table-column field="originalName" label="Original Name">
                    {{ props.row.originalName }}
                </b-table-column>

                <b-table-column field="mappedName" label="Mapped Name">
                    <b-input
                        size="is-small"
                        v-model.trim="props.row.mappedName"
                    >
                    </b-input>
                </b-table-column>

                <b-table-column field="show" label="Show">
                    <b-switch 
                        v-model="props.row.show"
                    />
                </b-table-column>

                <b-table-column
                    v-if="showDisplayRef"
                    field="isDisplayRef"
                    label="Ref"
                >
                    <template slot="header" slot-scope="{ column }">
                        Ref.

                        <b-dropdown
                            position="is-top-left"
                        >
                            <p slot="trigger">
                                <font-awesome-icon
                                    :icon="['fas', 'info-circle']"
                                    class="info-dropdown"
                                ></font-awesome-icon>
                            </p>
                            <b-dropdown-item
                                :focusable="false"
                                custom
                                id="help-tooltip-dd-item"
                                style="min-width: 250px; z-index: 10000;"
                            >
                                <div style="font-size: 0.8rem; font-weight: 400; width: 100%">
                                    <p>
                                        Choose a field to use as a display
                                        reference.
                                    </p>
                                    <p>
                                        This is used for distinguishing the
                                        various sites.
                                    </p>
                                    <p>
                                        For example, in the site list tab.
                                    </p>
                                </div>
                            </b-dropdown-item>
                        </b-dropdown>
                    </template> 
                    
                    <b-switch
                        v-model="props.row.isDisplayRef"
                        @input="handleDisplayRefChange(props.index)"
                    />
                    
                </b-table-column>
            </template>
        </b-table>
    </div>
</template>

<script>
import FeatureStateHelpers from "@/mixins/FeatureStateHelpers.js";
import FieldMapHelpers from "@/mixins/FieldMapHelpers.js";

import HelpTooltip from "@/components/notifications/HelpTooltip.vue";

export default {
    name: "ExternalSiteFieldMapper",
    mixins: [FeatureStateHelpers, FieldMapHelpers],
    components: {},
    props: {
        showDisplayRef: Boolean,
        fields: Object,
        siteType: String,
        dataSource: String
    },
    data() {
        return {};
    },
    mounted() {},
    computed: {
        fieldMap() {
            let map = [];

            // apply map after it gets populated (reactively)
            if (this.fetchedFeatureData) {
                let fieldTranslation = this.fieldMapFor(
                    this.fetchedFeatureData.siteType,
                    this.dataSource
                );

                for (let field in this.fields) {
                    let mappedField = {};
                    mappedField.originalName = field;

                    // is there a mapping already for this field
                    let fieldMatchAtt = field.toLowerCase();
                    if (fieldTranslation[fieldMatchAtt]) {
                        // console.log(`\t mapped to => ${fieldTranslation[fieldMatchAtt].name}`);
                        mappedField.mappedName =
                            fieldTranslation[fieldMatchAtt].name || "";
                        mappedField.show = fieldTranslation[fieldMatchAtt].show;
                        mappedField.isDisplayRef =
                            fieldTranslation[fieldMatchAtt].isDisplayRef ||
                            false;
                    } else {
                        mappedField.mappedName = "";
                        mappedField.show = true;
                        mappedField.isDisplayRef = false;
                    }

                    map.push(mappedField);
                }
            } else {
                let fieldNames = Object.keys(this.fields);
                map = fieldNames.map(f => ({
                    originalName: f,
                    mappedName: "",
                    show: true,
                    isDisplayRef: false
                }));
            }

            return map;
        }
    },

    methods: {
        saveMap() {
            // @todo probably needs refactoring into the usual, validate -> compile -> submit pattern
            // console.log(this.fieldMap);
            let selectedPortalId = this.$store.getters[
                "mapState/selectedPortal"
            ];
            let url = `portal/${selectedPortalId}/field-translation/${this.fetchedFeatureData.siteType}/${this.dataSource}`;

            // remap the fields to go back into db
            // FieldMapHelper
            let remapped = this.mapFieldsOut(this.fieldMap);

            this.$ppClient.put(url, remapped).then(response => {
                let body = response.data.body;
                if (body.status === "success") {
                    // update the map in-memory so that it can be reactively reapplied
                    this.fieldTranslation = body.updatedTranslation;
                    this.$emit("field-map-updated");
                } else {
                    // console.log(response);
                    this.$buefy.toast.open({
                        duration: 5000,
                        message: `Something went wrong. Please refresh and try again.`,
                        type: "is-danger"
                    });
                }
            });
        },
        // make sure only one display ref is set
        handleDisplayRefChange(index) {
            this.fieldMap.forEach(
                (field, i) => (field.isDisplayRef = i === index)
            );
        }
    }
};
</script>

<style lang="scss" scoped></style>
