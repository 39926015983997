<template>
    <div id="default-portal-update">
        <div class="modal-card" style="width: auto">
            <header class="modal-card-head">
                <p class="modal-card-title">No Default Portal Assigned</p>
            </header>

            <section class="modal-card-body">
                <p>You don't appear to have a default portal set.</p>
                <p>
                    This may mean that one hasn't been set up for you yet or if
                    you previously had one, it may have been removed.
                </p>
                <p>
                    If you think there is an error, please get in touch with
                    your account manager.
                </p>

                <div v-if="hasOtherPortalAccess">
                    <p>If you like, you may select a new default below.</p>
                    <br />
                    <b-field
                        label="Available portals"
                        :type="hasError ? 'is-danger' : ''"
                    >
                        <b-select
                            v-model="selectedPortal"
                            placeholder="Select a portal"
                        >
                            <option
                                v-for="userPortal in userPortals"
                                :key="userPortal._id.$oid"
                                :value="userPortal._id.$oid"
                            >
                                {{ userPortal.name }}
                            </option>
                        </b-select>
                    </b-field>
                </div>
            </section>

            <footer class="modal-card-foot">
                <b-button
                    v-if="hasOtherPortalAccess"
                    :disabled="(!selectedPortal) || processingRequest"
                    :loading="processingRequest"
                    type="is-success"
                    @click="updateDefaultPortal()"
                >
                    Update Default Portal
                </b-button>
            </footer>
        </div>
    </div>
</template>

<script>
export default {
    name: "UserDefaultPortalUpdateModal",
    mixins: [],
    components: {},
    props: {
        // documentDefaults: Object
        userPortals: Array
    },
    data() {
        return {
            hasError: false,
            selectedPortal: null,
            processingRequest: false
        };
    },
    computed: {
        hasOtherPortalAccess: function() {
            return this.userPortals && this.userPortals.length > 0;
        }
    },
    watch: {},
    mounted() {},
    methods: {
        updateDefaultPortal() {
            this.processingRequest = true;
            // console.log(this.selectedPortal);
            let url = `/users/update-default-portal/${this.selectedPortal}`;
            let data = {};
            let config = {
                headers: {
                    "Request-Portal": this.selectedPortal
                }
            };

            this.$ppClient
                .patch(url, data, config)
                .then(res => {
                    // console.log("response");
                    // console.log(res);
                    let body = res.data.body;
                    if( body.updated ) {
                        this.processingRequest = false;
                        this.$buefy.toast.open({
                            type: 'is-success',
                            message: 'Successfully updated default portal. Attempting to load data...',
                            duration: 5000
                        });
                        this.$router.go();
                    } else {
                        this.processingRequest = false;
                        this.$buefy.toast.open({
                            type: 'is-danger',
                            message: 'Something went wrong. Please try again, if the problem persists contact your account manager.',
                            duration: 5000
                        });
                    }
                })
                .catch(err => {
                    console.log(err);
                    if (err.response) {
                        console.log(err.response);
                    }
                });
        }
    }
};
</script>

<style lang="scss" scoped></style>
