<template>
    <div id="gs-layer-importer">
        <b-loading
            :is-full-page="false"
            :active.sync="loadingData"
            :can-cancel="false"
        >
            <font-awesome-icon
                :icon="['fad', 'sync']"
                :style="$iconStyle"
                size="4x"
                spin
            />
        </b-loading>
        <section>
            <b-notification
                type="is-danger"
                class="margin-bottom-15"
                role="alert"
                v-if="errors.length > 0"
                aria-close-label="Close notification"
            >
                <ul>
                    <li v-for="(error, i) in errors" :key="i">
                        {{ error }}
                    </li>
                </ul>
            </b-notification>
        </section>

        <b-field>
            <b-field
                label="Geostore Layer"
                :type="errorFields.includes('gsLayer') ? 'is-danger' : ''"
            >
                <b-select
                    v-model="selectedGsLayer"
                    @change.native="removeErrorField('gsLayer')"
                    placeholder="Select a layer"
                >
                    <option
                        v-for="layer in gsLayers"
                        :value="layer.job_id"
                        :key="layer.job_id"
                    >
                        {{ layer.layer }}
                    </option>
                </b-select>
            </b-field>
        </b-field>

        <br />
        <h3 v-if="selectedGsLayer" class="subtitle is-5 margin-top-15">
            Field Mapping

            <b-button
                type=""
                title="Add an FG managed Layer"
                @click="addGsLayerFieldMap"
                class="has-margin-left-5"
            >
                <font-awesome-icon :icon="['fas', 'plus']" />
            </b-button>
        </h3>

        <div
            class="columns nested-form-fields"
            v-for="mappedGsLayerField in mappedGsLayerFields"
            :key="mappedGsLayerField.index"
        >
            <div class="column">
                <b-field grouped class="nested-label-field">
                    <b-field
                        label="Geostore Field"
                        :type="
                            errorFields.includes(
                                `${mappedGsLayerField.index}-gs-field`
                            )
                                ? 'is-danger'
                                : ''
                        "
                    >
                        <b-select
                            size="is-small"
                            v-model="mappedGsLayerField.gsField"
                            @change.native="
                                removeErrorField(
                                    `${mappedGsLayerField.index}-gs-field`
                                )
                            "
                        >
                            <option
                                v-for="(selectedGsLayerField,
                                i) in selectedGsLayerFields"
                                :key="i"
                                :value="selectedGsLayerField"
                                >{{ selectedGsLayerField }}</option
                            >
                        </b-select>
                    </b-field>

                    <b-field
                        label="Local Field"
                        :type="
                            errorFields.includes(
                                `${mappedGsLayerField.index}-layer-field`
                            )
                                ? 'is-danger'
                                : ''
                        "
                    >
                        <b-select
                            size="is-small"
                            v-model="mappedGsLayerField.layerField"
                            @change.native="
                                removeErrorField(
                                    `${mappedGsLayerField.index}-layer-field`
                                )
                            "
                        >
                            <option
                                v-for="field in layer.fields"
                                :key="field.id"
                                :value="field.id"
                                >{{ field.name }}</option
                            >
                        </b-select>
                    </b-field>

                    <b-field label="">
                        <p class="control">
                            <label class="label">&nbsp;<br /></label>
                            <b-button
                                size="is-small"
                                type=""
                                title="Delete this layer"
                                @click="
                                    removeMappedGsLayerField(
                                        mappedGsLayerField.index
                                    )
                                "
                            >
                                <font-awesome-icon
                                    :icon="['fas', 'trash']"
                                    class="fa-w-18"
                                />
                            </b-button>
                        </p>
                    </b-field>
                </b-field>
            </div>
        </div>

        <button class="button is-primary" type="button" @click="confirm">
            Import From Geostore
        </button>
    </div>
</template>

<script>
import uuidv1 from "uuid/v1";

import ClientDetailsHelpers from "@/mixins/ClientDetailsHelpers.js";

export default {
    name: "GeostoreLayerImporter",
    mixins: [ClientDetailsHelpers],
    components: {},
    props: {
        // documentDefaults: Object
        layer: Object
    },
    data() {
        return {
            errors: [],
            errorFields: [],
            gsLayers: [],
            selectedGsLayer: null,
            selectedGsLayerFields: [],
            mappedGsLayerFields: [],
            loadingData: false
        };
    },
    computed: {
        // convert between array and object for use as options in v-for
        gsLayerFieldObj: function() {
            return Object.assign({}, this.selectedGsLayerFields);
        }
        // isLoading: function() {
        //     // console.log('in computed prop');
        //     // console.log(this.documentDefaults);
        //     // console.log(this.documentDefaults == null);
        //     return this.documentDefaults == null;
        // }
    },
    watch: {
        selectedGsLayer: function() {
            // console.log(this.selectedGsLayer);
            this.selectedGsLayerFields = [];
            this.loadGsLayerFields();
        }
    },
    mounted() {
        // console.log("gs layer importer");
        // console.log(this.selectedPortal);
        // console.log(this.layer);
        // console.log(this.mappedGsLayerFields);
        // console.log('docDefaults (cat)');
        // console.log(this.documentDefaults);

        this.loadGSLayers();
    },
    methods: {
        addGsLayerFieldMap() {
            this.mappedGsLayerFields.push({
                uuid: uuidv1(),
                gsField: false,
                layerField: false
            });
        },
        loadGsLayerFields() {
            this.loadingData = true;

            let url = `layer/geostore/${this.selectedGsLayer}/fields`;
            this.$ppClient
                .get(url)
                .then(response => {
                    console.log(response);
                    this.selectedGsLayerFields =
                        response.data.body.gsLayerFields;
                    
                    this.loadingData = false;
                })
                .catch(e => {
                    this.loadingData = false;
                    this.errors.push('Error fetching GS Layer Fields');
                    console.log(e);
                });
        },
        loadGSLayers() {
            this.loadingData = true;

            let url = `/portal/${this.selectedPortal}/geostore-layers`;
            this.$ppClient
                .get(url)
                .then(response => {
                    // console.log(response);
                    this.gsLayers = response.data.body.geostoreLayers;
                    this.loadingData = false;
                })
                .catch(e => {
                    this.loadingData = false;
                    this.errors.push('Error fetching GS Layers');
                    console.log(e);
                });
        },
        removeMappedGsLayerField(index) {
            // get index of object with id:37
            let removeIndex = this.mappedGsLayerFields
                .map(function(item) {
                    return item.index;
                })
                .indexOf(index);
            this.removeErrorField(`${index}-gs-field`);
            this.removeErrorField(`${index}-layer-field`);

            // remove object
            this.mappedGsLayerFields.splice(removeIndex, 1);
        },
        async validateForm() {
            if (!this.selectedGsLayer) {
                this.errorFields.push("gsLayer");
                this.errors.push("No Geostore Layer Selected");
            }

            for (let mapping of this.mappedGsLayerFields) {
                // console.log(externalLayer);
                //  =>  layer type selected
                if (!mapping.gsField) {
                    this.errorFields.push(`${mapping.index}-gs-field`);
                    // we only need to add the message once but it may apply to multiple layers
                    if (!this.errors.includes("Incomplete Field Mapping")) {
                        this.errors.push("Incomplete Field Mapping");
                    }
                }

                if (!mapping.layerField) {
                    this.errorFields.push(`${mapping.index}-layer-field`);
                    // we only need to add the message once but it may apply to multiple layers
                    if (!this.errors.includes("Incomplete Field Mapping")) {
                        this.errors.push("Incomplete Field Mapping");
                    }
                }
            }

            return this.errorFields.length === 0;
        },
        removeErrorField(field) {
            this.errorFields = this.errorFields.filter(e => e !== field);
            this.errors = [];
        },
        confirm() {
            this.loadingData = true;
            this.validateForm().then(valid => {
                if (valid) {
                    let url = `/layer/geostore/import/${this.selectedGsLayer}/${this.layer._id.$oid}`;
                    this.$ppClient
                        .patch(url, {mappedFields: this.mappedGsLayerFields})
                        .then(response => {
                            // console.log(response);
                            let body = response.data.body;
                            if (body.success) {
                                this.$emit('gs-import-success');
                            } else {
                                this.errors.push(
                                    "Something went wrong with import"
                                );
                            }
                        })
                        .catch(e => {
                            this.loadingData = false;
                            this.errors.push("Something went wrong with import");
                            console.log(e);
                        });

                    
                }
            });
        }
    }
};
</script>

<style lang="scss" scoped></style>
