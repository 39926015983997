<template>
    <div id="non-user-contacts">
        <b-field
            horizontal
            custom-class="is-small"
            label="Name *"
            :type="errorFields.includes('name') ? 'is-danger' : ''"
        >
            <b-input
                @keyup.native="$emit('remove-error-field', 'name')"
                size="is-small"
                v-model="contact.name"
            />
        </b-field>

        <b-field
            horizontal
            custom-class="is-small"
            label="Description *"
            :type="errorFields.includes('description') ? 'is-danger' : ''"
        >
            <b-input
                @keyup.native="$emit('remove-error-field', 'description')"
                size="is-small"
                v-model="contact.description"
            />
        </b-field>

        <b-field
            horizontal
            custom-class="is-small"
            label="Email"
            :type="errorFields.includes('contactField') ? 'is-danger' : ''"
        >
            <b-input
                @keyup.native="$emit('remove-error-field', 'contactField')"
                type="email"
                size="is-small"
                v-model="contact.email"
            />
        </b-field>

        <b-field
            horizontal
            custom-class="is-small"
            label="Telephone"
            :type="errorFields.includes('contactField') ? 'is-danger' : ''"
        >
            <b-input
                @keyup.native="$emit('remove-error-field', 'contactField')"
                size="is-small"
                v-model="contact.phone"
            />
        </b-field>

        <b-field
            horizontal
            custom-class="is-small"
            label="Mobile"
            :type="errorFields.includes('contactField') ? 'is-danger' : ''"
        >
            <b-input
                @keyup.native="$emit('remove-error-field', 'contactField')"
                size="is-small"
                v-model="contact.mobile"
            />
        </b-field>
    </div>
</template>

<script>

export default {
    name: "NonUserContacts",
    components: {},
    props: {
         mode: {
            type: String,
            required: true,
            validator: function(value) {
                // The value must match one of these strings
                return ["new", "edit"].indexOf(value) !== -1;
            }
        },
        errorFields: Array,
        selectedTab: Number,
        passedContact: Object
    },
    data() { 
        return {
            contact: {
                name: '',
                description: '',
                email: '',
                phone: '',
                mobile: '',
            }
        } 
    },
    watch: {
        contact: {
            deep: true,
            handler: function() {
                this.$emit('non-user-contact-updated', this.contact);
            }
        },
        selectedTab: function() {
            
            if(this.mode != 'edit') {
                this.contact = {
                    name: '',
                    description: '',
                    email: '',
                    phone: '',
                    mobile: '',
                }
            }
            
        }
    },
    mounted () {
        if(this.mode == 'edit') {
            this.contact = this.passedContact;

        }
    },
    methods: {
    }
};
</script>

<style lang="scss" scoped></style>
