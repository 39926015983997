export default {
    computed: {
        // ...mapGetters({
        //     zoom: 'mapState/zoom',
        //     latitude: 'mapState/latitude',
        //     longitude: 'mapState/longitude',
        // }),
        clientLogo: {
            get() {
                return this.$store.getters["mapState/clientLogo"];
            },
            set(clientLogo) {
                this.$store.commit("mapState/setClientLogo", clientLogo);
            }
        },
        userName: {
            get() {
                return this.$store.getters["mapState/userName"];
            },
            set(userName) {
                this.$store.commit("mapState/setUserName", userName);
            }
        },
        clientName: {
            get() {
                return this.$store.getters["mapState/clientName"];
            },
            set(clientName) {
                this.$store.commit("mapState/setClientName", clientName);
            }
        },
        clientPrivacyPolicy: {
            get() {
                return this.$store.getters["mapState/clientPrivacyPolicy"];
            },
            set(clientPrivacyPolicy) {
                this.$store.commit("mapState/setClientPrivacyPolicy", clientPrivacyPolicy);
            }
        },
        userPortals: {
            get() {
                return this.$store.getters["mapState/userPortals"];
            },
            set(userPortals) {
                this.$store.commit("mapState/setUserPortals", userPortals);
            }
        },
        selectedPortal: {
            get() {
                return this.$store.getters["mapState/selectedPortal"];
            },
            set(portalId) {
                this.$store.commit("mapState/setSelectedPortal", portalId);
            }
        },
        portalAllowsManagedLayers: {
            get() {
                return this.$store.getters["mapState/portalAllowsManagedLayers"];
            },
            set(portalAllowsManagedLayers) {
                this.$store.commit("mapState/setPortalAllowsManagedLayers", portalAllowsManagedLayers);
            }
        },
        portalAllowsClientLayers: {
            get() {
                return this.$store.getters["mapState/portalAllowsClientLayers"];
            },
            set(portalAllowsClientLayers) {
                this.$store.commit("mapState/setPortalAllowsClientLayers", portalAllowsClientLayers);
            }
        },
        portalAllowsDocUploads: {
            get() {
                return this.$store.getters["mapState/portalAllowsDocUploads"];
            },
            set(portalAllowsDocUploads) {
                this.$store.commit("mapState/setPortalAllowsDocUploads", portalAllowsDocUploads);
            }
        },
        documentConfig: {
            get() {
                return this.$store.getters["mapState/documentConfig"];
            },
            set(config) {
                this.$store.commit("mapState/setDocumentConfig", config);
            }
        },
        
    },
}