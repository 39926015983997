export default {
    computed: {
        fieldTranslation: {
            get() {
                return this.$store.getters["mapState/fieldTranslation"];
            },
            set(fieldTranslation) {
                this.$store.commit("mapState/setFieldTranslation", fieldTranslation);
            }
        },
    },
    methods: {
        fieldMapFor(siteType, dataSource){
            // funky syntax for safe nested roperty access
            return ( (this.fieldTranslation || {})[siteType] || {} )[dataSource] || {};
        },
        // take a field map and some fields and map them into the
        // format to be displayed to the user
        mapFieldsIn(fieldMap, fields) {
			let mapped = {};
            for (let field in fields) {
                // console.log(`${field}: ${fields[field]}`);

                let fieldMatchAtt = field.toLowerCase();
                // there is an entry in the field map
                if (fieldMap[fieldMatchAtt]) {
                    // console.log(`\t mapped to => ${fieldMap[fieldMatchAtt].name}`);
                    //
                    if (fieldMap[fieldMatchAtt].show) {
                        // name should be transformed
                        if (fieldMap[fieldMatchAtt].name) {
                            mapped[fieldMap[fieldMatchAtt].name] = fields[
                                field
                            ];
                        } else {
                            mapped[field] = fields[field];
                        }
                    }
                } else {
                    // no entry in the field map so display as-is
                    mapped[field] = fields[field];
                }
            }
            // return fields;
            // console.log('mapped');
            // console.log(mapped);
            return mapped;
        },
        // take the mapped fields and transform them into the format
        // that the API needs them in
        mapFieldsOut(fieldMap) {
			// syntax to map an array of objects into an object with the given key
			// eg: from
			// [{originalName: Abc, ...}, {originalName: Def, ...}, ...]
			// to:
			// {abc: {...}, def: {...}, ...}
			let remapped = fieldMap.reduce(
                (obj, item) => (
                    (obj[item.originalName.toLowerCase()] = {
                        name: item.mappedName == "" ? false : item.mappedName,
                        show: item.show,
                        isDisplayRef: item.isDisplayRef
                    }),
                    obj
                ),
                {}
            );
            // console.log("map in");
            // console.log(test);

            return remapped;
        },
        getDisplayRefFromMap(fields, fieldMap) {

            // console.log('in get ref from map');
            // console.log('fields');
            // console.log(fields);
            // console.log('map');
            // console.log(fieldMap);
            
            
            
            
            
            let refSet = false;
    
            for(let field in fieldMap ) {
                if(fieldMap[field].isDisplayRef) {
                    refSet = field;
                }
            }

            if( refSet ) {
                // case insensitive property access
                // get array of keys
                // perform find against it
                // with lowercased key val and search term
                return fields[
                    Object.keys(fields).find(
                        key => key.toLowerCase() === refSet.toLowerCase()
                    )
                ];
            } else {
                return 'Display Ref Not Set.';
            }
        }
	},
	filters: {
		// requiers either v-html or css white-space: pre;
		// doesn't work so well due to lack of wraping for large fields.
		scolon2nl: function (val) {
			// let rep = val.replace(/;/g, '\n');
			return val.replace(/;/g, '\n');
		},

		scolon2space: function (val) {
			// console.log('scolon2spcae: ');
			// console.log(val);
			
			
			// let rep = val.replace(/;/g, '\n');
			return val && String(val).includes(";") 
				? val.replace(/;/g, ' ') 
				: val;
		}
	}
};
