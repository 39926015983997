<template>
    <div id="login-form">
        <b-loading
            :is-full-page="true"
            :active.sync="isLoading"
            :can-cancel="false"
        ></b-loading>
        <section class="hero">
            <div class="hero-body">
                <div class="columns" v-if="errors.length > 0">
                    <div class="column is-half is-offset-one-quarter errors">
                        <div class="notification is-danger">
                            <ul>
                                <li v-for="(error, i) in errors" :key="i">
                                    {{ error }}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="columns">
                    <div class="column is-half is-offset-one-quarter has-text-centered">
                        <img id="banner-logo" src="@/assets/logo.svg">
                        <h1 class="title is-2" id="banner-title">
                            PROPERTY PORTAL
                        </h1>
                    </div>
                </div>

                <div class="columns">
                    <div class="column is-half is-offset-one-quarter">
                        <b-field
                            horizontal
                            label="Email"
                            :type="
                                errorFields.includes('email') ? 'is-danger' : ''
                            "
                        >
                            <b-input
                                @keyup.native.enter="handleSubmit"
                                @keyup.native="removeErrorField('email')"
                                name="email"
                                v-model="email"
                            ></b-input>
                        </b-field>

                        <b-field
                            v-if="!forgotPassword"
                            horizontal
                            label="Password"
                            :type="
                                errorFields.includes('password')
                                    ? 'is-danger'
                                    : ''
                            "
                        >
                            <b-input
                                @keyup.native.enter="handleSubmit"
                                @keyup.native="removeErrorField('password')"
                                name="password"
                                type="password"
                                v-model="password"
                                expanded
                            ></b-input>
                        </b-field>

                        <b-field horizontal
                            ><!-- Label left empty for spacing -->
                            <p class="control">
                                <button
                                    class="button is-primary"
                                    @click="handleSubmit"
                                >
                                    {{ forgotPassword ? "Send New Password" : "Log In" }}
                                </button>

                                <button
                                    class="button is-info has-margin-left-15"
                                    @click="handleForgotPasswordChange"
                                >
                                    {{ forgotPassword ? "Log In" : "Forgotten Password" }}
                                </button>

                            </p>
                        </b-field>

                         
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import axios from "axios";
export default {
    name: "LoginForm",
    components: {},
    data() {
        return {
            email: "",
            password: "",
            errors: [],
            errorFields: [],
            isLoading: false,
            forgotPassword: false
            //   test: this.$store.getters.test
        };
    },
    mounted() {},
    methods: {
        handleForgotPasswordChange() {
            this.errorFields = [];
            this.errors = [];
            this.forgotPassword = !this.forgotPassword
        },
        handleSubmit() {
            // clear these from memory
            this.$cookie.remove("token");
            this.$store.commit(
                "sessionState/setExpires",
                null
            );

            this.validateForm().then(valid => {
                if (valid) {
                    this.compileFormData().then(
                        formData => {
                            this.forgotPassword 
                                ? this.submitPWReset( formData )
                                : this.submitLogin( formData )
                        },
                        error => {
                            console.log("error in compileFormData");
                            console.log(error);
                        }
                    );
                }
            });
        },
        async validateForm() {
            // reset errors for validation pass
            this.errorFields = [];
            this.errors = [];
            
            if (this.email === "") {
                this.errorFields.push("email");
                this.errors.push("Email is required");
            }

            if( !this.forgotPassword ) {
                if (this.password === "") {
                    this.errorFields.push("password");
                    this.errors.push("Password is required");
                }
            }

            return this.errors.length === 0;
        },
        async compileFormData() {
            let formData = {
                email: this.email,
            };
            if( !this.forgotPassword ) {
                formData.password = this.password;
            }

            return formData;
        },
        async submitPWReset( formData ) {
            this.isLoading = true;

            this.$ppClient
                .post(`${process.env.VUE_APP_BACKEND_BASE_URL}/password-reset`, formData)
                .then(response => {
                    let body = response.data.body;
                    if(body.status.success) {
                        this.forgotPassword = false;
                        this.$emit('pw-reset-sent', "Check your email for a message containing instructions on how to update your password.")
                    } else {
                        this.errors.push("Something went wrong");
                    }
                    this.isLoading = false;
                });
            
            
        },
        async submitLogin( formData ) {
            

            this.isLoading = true;
            this.$ppClient
                .post(`${process.env.VUE_APP_BACKEND_BASE_URL}/login`, formData)
                .then(response => {
                    // console.log('login response');
                    // console.log(response);

                    if (response.status === 200) {
                        if (response.data.body.token) {
                            // let testDate = new Date();
                            // testDate.setSeconds(testDate.getSeconds() + 20);
                            // this.$store.commit("sessionState/setExpires", testDate);    
                            let body = response.data.body;
                            // let token = response.data.body.token;
                            this.$cookie.set("token", body.token.val, {
                                // expires: body.token.exp
                                // expires: testDate
                                // expires: -1
                                // secure: true,
                                // SameSite: 'None',
                            });
                            this.$cookie.set("exp", body.token.exp, { SameSite: 'Lax' });
                            this.$store.commit("sessionState/setExpires", new Date( body.token.exp ));
                            // this.$store.commit("sessionState/setSysAccess", body.acp.sysRole);
                            // this.$store.commit("sessionState/setPortalAccess", body.acp.portalRole);
                            // console.log(this.$cookie.get('exp'));
                            // this.$emit('loggedIn')
                            if (this.$route.params.nextUrl != null) {
                                this.$router.push(this.$route.params.nextUrl).catch((err) => {
                                    throw new Error(`Problem handling something: ${err}.`);
                                });
                            } else {
                                // where do we send them by default?
                                // eg admin users etc
                                this.$router.push("/map").catch((err) => {
                                    throw new Error(`Problem handling something: ${err}.`);
                                });
                            }
                        } else {
                            this.isLoading = false;
                            this.errors = response.data.errors;
                        }
                    } else {
                        this.isLoading = false;
                        this.errors = response.data.errors;
                    }
                })
                .catch(error => {
                    // console.log(this);
                    
                    this.errors.push("Something went wrong");
                    this.errorFields = ["email", "password"];
                    this.isLoading = false;
                    if (error.response) {
                        // Request made and server responded
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);

                        let errors = error.response.data.errors;
                        //   console.log(e.response);
                        this.errors = errors;
                    } else if (error.request) {
                        // The request was made but no response was received
                        console.log("error.request");

                        console.log(error.request);
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        console.log("undefined error");

                        console.log("Error", error.message);
                        // this.errors.push(
                        //     "Something went wrong. Please try again"
                        // );
                    }
                });
        },
        removeErrorField(field) {
            this.errorFields = this.errorFields.filter(el => el !== field);
            this.errors = [];
        }
    }
};
</script>

<style lang="scss">
    #banner-logo {
        display:block; 
        margin: 0 auto; 
        width: 200px; 
        height: 100px
    }
    #banner-title {
        font-family: "Segoe UI", "Roboto", Helvetica, Arial, sans-serif;
        padding: 25px;
        // border-top: 2px solid;
        // border-bottom: 2px solid;
    }
</style>
