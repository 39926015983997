import Vue from "vue";
import Vuex from "vuex";
// import { latLng } from "leaflet";
import utils from "@/mixins/utils.js";

Vue.use(Vuex);

const mapState = {
    mixins: [utils],
    namespaced: true,
    state: {
        map: {},
        tileProviders: [],
        selectedBaseLayer: {},
        clientLogo: null,
        clientName: null,
        clientPrivacyPolicy: null,
        userName: null,
        userPortals: [],
        selectedPortal: null,
        portalAllowsManagedLayers: false,
        portalAllowsClientLayers: false,
        portalAllowsDocUploads: false,
        documentConfig: {},
        fieldTranslation: {},
        notifications: [],
        layers: [],
        layerSummaries: [],
        selectedLayer: null,
        selectedFeature: null,
        fetchedFeatureData: null,
        selectedFeatureW3W: null,
        // zoom: 13,
        // latitude: 52.7445,
        // longitude: -1.4757,
        zoom: null,
        latitude: null,
        longitude: null,
        lastClicked: false,
        featureStyles: {
            Point: {
                defaultStyle: {
                    weight: "0.1",
                    fillOpacity: "0.6"
                },
                focusStyle: {
                    weight: "3",
                    // set by getter
                    // color: '#FFF',
                    fillOpacity: "0.8"
                }
            },
            Polygon: {
                defaultStyle: {
                    weight: 1,
                    opacity: 1,
                    // dashArray: null,
                    // set by getter
                    // color: this.color,
                    
                    fillOpacity: 0.6,
                },
                focusStyle: {
                    weight: 3,
                    // dashArray: '5, 10',
                    opacity: 1,
                    // set by getter
                    // color: '#FFF',
                    fillOpacity: 0.8,
                }
            },
            MultiPolygon: {
                defaultStyle: {
                    weight: 1,
                    opacity: 1,
                    // set by getter
                    // color: this.color,
                    fillOpacity: 0.6,
                },
                focusStyle: {
                    weight: 3,
                    opacity: 1,
                    // set by getter
                    // color: '#FFF',
                    fillOpacity: 0.8,
                }
            },
            LineString: {
                defaultStyle: {
                    weight: 10,
                    opacity: 1,
                    // set by getter
                    // color: this.color,
                    fillOpacity: 0.6,
                },
                focusStyle: {
                    weight: 10,
                    opacity: 1,
                    // set by getter
                    // color: '#FFF',
                    fillOpacity: 0.8,
                }
            },
            MultiLineString: {
                defaultStyle: {
                    weight: 10,
                    opacity: 1,
                    // set by getter
                    // color: this.color,
                    fillOpacity: 0.6,
                },
                focusStyle: {
                    weight: 10,
                    opacity: 1,
                    // set by getter
                    // color: '#FFF',
                    fillOpacity: 0.8,
                }
            }

        },
        stripePattern: null
    },
    mutations: {
        setMap(state, map) {
            state.map = map;
        },
        setTileProviders(state, tileProviders) {
            state.tileProviders = tileProviders;
        },
        setSelectedBaseLayer(state, baseLayer) {
            state.selectedBaseLayer = baseLayer;
        },
        setClientLogo(state, clientLogo) {
            state.clientLogo = clientLogo;
        },
        setClientName(state, clientName) {
            state.clientName = clientName;
        },
        setClientPrivacyPolicy(state, clientPrivacyPolicy) {
            state.clientPrivacyPolicy = clientPrivacyPolicy;
        },
        setUserName(state, userName) {
            state.userName = userName;
        },
        setUserPortals(state, userPortals) {
            state.userPortals = userPortals;
        },
        addUserPortal(state, newPortal) {
            state.userPortals.push(newPortal);
        },
        setSelectedPortal(state, portalId) {
            state.selectedPortal = portalId;
        },
        setPortalAllowsManagedLayers(state, portalAllowsManagedLayers) {
            state.portalAllowsManagedLayers = portalAllowsManagedLayers;
        },
        setPortalAllowsClientLayers(state, portalAllowsClientLayers) {
            state.portalAllowsClientLayers = portalAllowsClientLayers;
        },
        setPortalAllowsDocUploads(state, portalAllowsDocUploads) {
            state.portalAllowsDocUploads = portalAllowsDocUploads;
        },
        setDocumentConfig(state, config){
            state.documentConfig = config;
        },
        setFieldTranslation(state, fieldTranslation){
            state.fieldTranslation = fieldTranslation;
        },
        setNotifications(state, notifications) {
            state.notifications = notifications;
        },
        setLayers(state, layers) {
            state.layers = layers;
        },
        setLayerSummaries(state, layerSummaries) {
            state.layerSummaries = layerSummaries;
        },
        setLayerFeatureGroup(state, payload) {
            let addedLayer = state.layers.find(layer => layer._id.$oid == payload.layerId);
            addedLayer.featureGroup = payload.featureGroup;
        },
        setSelectedLayer(state, selectedLayer) {
            state.selectedLayer = selectedLayer;
        },
        setSelectedFeature(state, selectedFeature) {
            state.selectedFeature = selectedFeature;
        },
        setFetchedFeatureData(state, data) {
            state.fetchedFeatureData = data;
        },
        setSelectedFeatureW3W(state, data) {
            state.selectedFeatureW3W = data;
        },
        setZoom(state, level) {
            state.zoom = level;
        },
        setLatitude(state, latitude) {
            state.latitude = latitude;
        },
        setLongitude(state, longitude) {
            state.longitude = longitude;
        },
        setLastClicked(state, w3wResponse) {
            state.lastClicked = w3wResponse;
        },
        setStripePattern(state, stripes) {
            state.stripePattern = stripes;
        }
    },
    actions: {},
    getters: {
        map(state) {
            return state.map;
        },
        tileProviders(state) {
            return state.tileProviders;
        },
        selectedBaseLayer(state) {
            return state.selectedBaseLayer;
        },
        clientLogo(state) {
            return state.clientLogo;
        },
        clientName(state) {
            return state.clientName;
        },
        clientPrivacyPolicy(state) {
            return state.clientPrivacyPolicy;
        },
        userName(state) {
            return state.userName;
        },
        userPortals(state) {
            return state.userPortals;
        },
        selectedPortal(state) {
            return state.selectedPortal;
        },
        portalAllowsManagedLayers(state) {
            return state.portalAllowsManagedLayers;
        },
        portalAllowsClientLayers(state) {
            return state.portalAllowsClientLayers;
        },
        portalAllowsDocUploads(state) {
            return state.portalAllowsDocUploads;
        },
        documentConfig(state) {
            return state.documentConfig;
        },
        fieldTranslation(state) {
            return state.fieldTranslation;
        },
        notifications(state) {
            return state.notifications;
        },
        layers(state) {
            return state.layers;
        },
        layerSummaries(state) {
            return state.layerSummaries;
        },
        internalLayers(state){
            return state.layers.filter(layer => !layer.externalLayer);
        },
        externalLayers(state){
            return state.layers.filter(layer => layer.externalLayer);
        },
        selectedLayer(state) {
            return state.selectedLayer;  
        },
        layerSelected(state) {
            return state.selectedLayer !== null;
        },
        selectedFeature(state) {
            return state.selectedFeature;  
        },
        fetchedFeatureData(state) {
            return state.fetchedFeatureData;
        },
        selectedFeatureW3W(state) {
            return state.selectedFeatureW3W;
        },
        featureSelected(state) {
            return state.selectedFeature !== null && utils.methods.notEmptyObject(state.selectedFeature);
        },
        zoom(state) {
            return state.zoom;
        },
        latitude(state) {
            return state.latitude;
        },
        longitude(state) {
            return state.longitude;
        },
        lastClicked(state) {
            return state.lastClicked;
        },
        featureStyle: (state) => (featureType, styleType, color="#000") =>  {
            let style = state.featureStyles[featureType][styleType];
            style.color = color;
            return style;
        },
        stripePattern(state) {
            return state.stripePattern;
        }
    }
};

const sidebarState = {
    namespaced: true,
    state: {
        sidebar: null,
        width: 1,
        currentTab: ''
    },
    getters: {
        sidebar(state) {
            return state.sidebar;
        },
        width(state) {
            return state.width;
        },
        currentTab(state) {
            return state.currentTab;
        }

    },
    mutations: {
        setSidebar(state, sidebar) {
            state.sidebar = sidebar;
        },
        setWidth(state, width){
            state.width = width;
        },
        setCurrentTab(state, currentTab) {
            state.currentTab = currentTab;
        }
    },
    actions: {},
}

const sessionState = {
    namespaced: true,
    state: {
        expires: null,
        puid: false,
        sysAccess: false,
        portalAccess: false
    },
    getters: {
        expires(state) {
            return state.expires;
        },
        puid(state) {
            return state.puid;
        },
        sysAccess(state) {
            return state.sysAccess;
        },
        portalAccess(state) {
            return state.portalAccess;
        },
    },
    mutations: {
        setExpires(state, date) {
            state.expires = date;
        },
        setPuid(state, puid) {
            state.puid = puid;
        },
        setSysAccess(state, sysAccess) {
            state.sysAccess = sysAccess;
        },
        setPortalAccess(state, portalAccess) {
            state.portalAccess = portalAccess;
        },
    },
    actions: {},
}

 const store = new Vuex.Store({
    modules: {
        mapState: mapState,
        sidebarState: sidebarState,
        sessionState: sessionState
    }
});



export default store;
