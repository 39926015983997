var render = function render(){var _vm=this,_c=_vm._self._c;return _c('l-geo-json',{attrs:{"geojson":_vm.feature,"optionsStyle":{
        stroke: true,
        weight: 1,
        opacity: 1,
        color: _vm.color,
        fillOpacity: 0.4,
        fillColor: _vm.color
    },"options":{ onEachFeature: this.onEachFeatureFunction }},on:{"add":_vm.added,"click":_vm.clicked,"mouseover":_vm.mouseover,"mouseout":_vm.mouseout}},[(_vm.displayRef)?_c('l-tooltip',[_c('div',[_vm._v(" test ")])]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }