<template>
    <div id="gs-layer-importer">
        <b-loading
            :is-full-page="false"
            :active.sync="loadingData"
            :can-cancel="false"
        >
            <font-awesome-icon
                :icon="['fad', 'sync']"
                :style="$iconStyle"
                size="4x"
                spin
            />
        </b-loading>
        <section>
            <b-notification
                type="is-danger"
                class="margin-bottom-15"
                role="alert"
                v-if="errors.length > 0"
                aria-close-label="Close notification"
            >
                <ul>
                    <li v-for="(error, i) in errors" :key="i">
                        {{ error }}
                    </li>
                </ul>
            </b-notification>
        </section>

        <div class="columns">
            <div class="column is-centered" id="right-border-column">
                <!-- <div class="width-control"> -->
                <button
                    class="button is-primary"
                    type="button"
                    @click="downloadTemplate"
                >
                    Download Template
                </button>
                <!-- </div> -->
            </div>
            <!-- </div> -->

            <!-- <div class="columns"> -->
            <div class="column is-centered">
                <b-field class="file">
                    <b-upload
                        @input="removeErrorField('file')"
                        drag-drop
                        v-model="file"
                        accept=".csv"
                    >
                        <section class="section">
                            <div
                                class="content has-text-centered"
                                :class="
                                    errorFields.includes('file')
                                        ? 'has-text-danger'
                                        : 'has-text-primary'
                                "
                            >
                                <p>
                                    <b-icon icon="upload" size="is-large">
                                    </b-icon>
                                </p>
                                <p>Drop your files here or click to upload</p>
                            </div>
                        </section>
                    </b-upload>
                </b-field>
            </div>
        </div>

        <div class="columns">
            <div class="column"></div>
            <div class="column is-centered">
                <b-field v-if="file">
                    <span class="file-name">
                        {{ file.name }}
                    </span>
                    <p class="control">
                        <button
                            class="button is-primary"
                            type="button"
                            @click="upload"
                        >
                            Import Template
                        </button>
                    </p>
                </b-field>
            </div>
        </div>
    </div>
</template>

<script>
import uuidv1 from "uuid/v1";

// import ClientDetailsHelpers from "@/mixins/ClientDetailsHelpers.js";

export default {
    name: "LayerDataImporter",
    mixins: [],
    components: {},
    props: {
        // documentDefaults: Object
        layer: Object
    },
    data() {
        return {
            errors: [],
            errorFields: [],
            loadingData: false,
            file: null
        };
    },
    computed: {
        // isLoading: function() {
        //     // console.log('in computed prop');
        //     // console.log(this.documentDefaults);
        //     // console.log(this.documentDefaults == null);
        //     return this.documentDefaults == null;
        // }
    },
    watch: {},
    mounted() {
        // console.log("layer data importer");
    },
    methods: {
        getFormattedDate() {
            var d = new Date(),
                month = "" + (d.getMonth() + 1),
                day = "" + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2) month = "0" + month;
            if (day.length < 2) day = "0" + day;

            return [year, month, day].join("-");
        },
        getKebabLayerName() {
            return this.layer.name.replace(/\s+/g, "-").toLowerCase();
        },
        downloadTemplate() {
            let url = `/layer/${this.layer._id.$oid}/import/template`;
            this.$ppClient
                .get(url, { mappedFields: this.mappedGsLayerFields })
                .then(response => {
                    console.log(response);
                    let kebabLayerName = this.getKebabLayerName();
                    let formattedDate = this.getFormattedDate();
                    saveAs(
                        new Blob([response.data]),
                        `${kebabLayerName}-template-${formattedDate}.csv`
                    );
                    // this.$emit('gs-import-success');
                })
                .catch(e => {
                    console.log(e);
                });
        },
        async validateForm() {
            // if (!this.selectedGsLayer) {
            //     this.errorFields.push("gsLayer");
            //     this.errors.push("No Geostore Layer Selected");
            // }

            // kind of irrelevent
            if (!this.file) {
                this.errorFields.push("file");
                this.errors.push("No Import Template Added");
            }

            return this.errorFields.length === 0;
        },
        removeErrorField(field) {
            this.errorFields = this.errorFields.filter(e => e !== field);
            this.errors = [];
        },
        upload() {
            // console.log("upload");
            this.validateForm().then(valid => {
                if (valid) {
                    this.compileFormData().then(formData => {
                        // this.mode === "new"
                        //     ? this.submitNew(formData)
                        //     : this.submitUpdate(formData);
                        this.submit(formData);
                    });
                }
            });
        },
        async compileFormData() {
            // need to use form data as request will have a file
            let formData = new FormData();
            formData.append("file", this.file);

            return formData;
        },
        submit(formData) {
            this.loadingData = true;

            this.validateForm().then(valid => {
                if (valid) {
                    // this.$emit("updateCat", {
                    //     uuid: this.uuid,
                    //     name: this.name,
                    //     // type: this.findRetentionPolicy( this.retentionPolicy ),
                    //     // retentionPolicy: this.documentDefaults.retentionPolicies.find( rp => rp._id.$oid  == this.retentionPolicy )._id.$oid,
                    //     subcats: this.subcats
                    // });
                    let url = `/layer/${this.layer._id.$oid}/import`;
                    this.$ppClient
                        .post(url, formData, {
                            headers: {
                                "Content-Type": "multipart/form-data"
                            }
                        })
                        .then(response => {
                            // console.log('pre emit');
                            // console.log(response);
                            let body = response.data.body;
                            if (body.success) {
                                this.$emit("data-import-success");
                            } else {
                                this.loadingData = false;
                                this.errors.push(
                                    "Something went wrong with import"
                                );
                            }
                        })
                        .catch(error => {
                            console.log('response error');
                            console.log(error.response);
                            this.loadingData = false;
                            if (error.response) {
                                this.errors.push(error.response.data.body.error);
                            } else {
                                this.errors.push(
                                    "Something went wrong with import"
                                );
                            }
                        });
                }
            });
        }
    }
};
</script>

<style lang="scss" scoped>
#right-border-column {
    border-right: 1px solid black;
}

.column.is-centered {
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
