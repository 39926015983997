<template>
    <div id="verify">
        <verification-form :token="$route.params.token"/>
    </div>
</template>
<script>
import VerificationForm from "@/components/authentication/VerificationForm.vue";

export default {
    name: "Verify",
    components: {
        VerificationForm
    },
    props: {},
    data() {
      return {};
    },
    computed: {
        // isVerification: function() {
        //   return this.$route.params.pathMatch === "/verify/"
        // }
    },
    mounted() {
        // console.log(this.$route);
        // console.log(this.$route.pathMatch === "/verify/");
        // console.log(this.isVerification);
    },
    methods: {}
};
</script>
