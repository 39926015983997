<template>
    <div id="full-div">

        <div id="logo" class="leaflet-control leaflet-control-layers">
            <img id="logo-img" src="@/assets/logo.svg" alt="" />
        </div>
        <!-- <div id="branded-seperator">
            <div id="logo-wrap">
                <img id="logo" src="@/assets/logo.svg" alt="" />
                <h1>Property Portal</h1>
            </div>
        </div> -->

        <!-- <b-navbar type="is-primary" :mobile-burger="false" style="min-height: 50px; height: 50px; max-height: 100px">
        <template #brand>
            <b-navbar-item id="logo-item" style="height: 60px;">
                <img id="logo" src="@/assets/logo-red-grey-only.svg" alt="" style="width: 100px; min-height: 50px; height: 60px;" />
            </b-navbar-item>
            <b-navbar-item id="text-item">
                <div id="tagline"><h1 class="title is-5">PROPERTY PORTAL</h1></div>
            </b-navbar-item>
        </template>
    </b-navbar> -->

        <!-- <loading-notification :is-loading="!showMap"></loading-notification> -->

        <l-map
            v-if="showMap"
            ref="map"
            :zoom="zoom"
            :center="center"
            :options="mapOptions"
            @update:center="centerUpdate"
            @update:zoom="zoomUpdate"
            @baselayerchange="baselayerchange"
            @pm:globaldragmodeenabled="dragEnabled"
            @pm:globaleditmodeenabled="editEnabled"
            @pm:drawstart="drawStart"
            @pm:create="drawCreate"
            @click="mapClicked"
            @mousemove="mouseMoveMap"
            @mouseout="mouseOutMap"
        >
        <!-- style="height: 96%" -->
            <!-- @pm:globaldragmodedisabled="dragDisabled" -->
            <!-- @mousemove="mouseMoveMap" 
      @mouseout="mouseOutMap" 
      @pm:drawend="drawEnd"
            @pm:vertexadded="vertexAdded"
            @pm:create="addGeoToLayer"-->

            <l-control-layers position="topright"></l-control-layers>
            <!-- <l-tile-layer url="http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}" :options="{subdomains:['mt0','mt1','mt2','mt3']}"></l-tile-layer> -->

            <l-control v-if="mouseCoordinates" position="bottomright">
                <div
                    id="mouse-position-display"
                    class="leaflet-control leaflet-bar"
                >
                    <strong>Lat:</strong> {{ mouseCoordinates.lat.toFixed(4) }}
                    <strong>Long:</strong> {{ mouseCoordinates.lng.toFixed(4) }}
                </div>
            </l-control>

            <l-tile-layer
                v-for="tileProvider in tileProviders"
                :options="tileProvider.options"
                :key="tileProvider.title"
                :name="tileProvider.title"
                :visible="tileProvider.visible"
                :url="tileProvider.url"
                :attribution="tileProvider.attribution"
                :token="tileProvider.token || ''"
                :enabled="false"
                layer-type="base"
            />

            <l-feature-group
                v-for="layer in visibleLayers"
                :name="layer.name"
                :key="layer._id.$oid"
                @add="featureGroupAdded($event, layer._id.$oid)"
                @click="log"
                @pm:cut="handleCutGeo"
                @pm:edit="handleEditGeo"
                @pm:dragend="featureDragged = true"
            >
                <!-- @pm:dragend="updateGeo" -->
                <m-polyline
                    v-for="line in layer.geometry.LineString"
                    :key="line.featureId.$oid"
                    :color="layer.color"
                    :feature="line"
                    :layer-id="layer._id.$oid"
                    :layer="layer"
                />

                <m-multi-polyline
                    v-for="line in layer.geometry.MultiLineString"
                    :key="line.featureId.$oid"
                    :color="layer.color"
                    :feature="line"
                    :layer-id="layer._id.$oid"
                    :layer="layer"
                />

                <m-circle-marker
                    v-for="point in layer.geometry.Point"
                    :key="point.featureId.$oid"
                    :color="layer.color"
                    :layer-id="layer._id.$oid"
                    :layer="layer"
                    :feature="point"
                />
                <!-- :point="point" -->

                <m-polygon
                    v-for="polygon in layer.geometry.Polygon"
                    :key="polygon.featureId.$oid"
                    :color="layer.color"
                    :opacity="parseFloat(layer.opacity)"
                    :feature="polygon"
                    :layer-id="layer._id.$oid"
                    :layer="layer"
                />
                <!-- :polygon="polygon" -->

                <m-multi-polygon
                    v-for="multiPolygon in layer.geometry.MultiPolygon"
                    :key="multiPolygon.featureId.$oid"
                    :color="layer.color"
                    :feature="multiPolygon"
                    :layer-id="layer._id.$oid"
                    :layer="layer"
                />
                <!-- :multi-polygon="multiPolygon" -->
            </l-feature-group>
        </l-map>

        <sidebar
            v-if="showMap"
            :user-portals="userPortals"
            :is-loading="isLoading"
            @layerRenamed="layerRenamed"
            @updateLatLng="updateLatLng"
        ></sidebar>
        <!-- :clientLogo="clientLogo" -->
        <!-- :clientName="clientName" -->
    </div>
</template>

<script>
import axios from "axios";

// import turfArea from "@turf/area";

// import turfCombine from "@turf/combine";
// import turfUnkinkPoly from "@turf/unkink-polygon";
// import turfSimplify from "@turf/simplify";
// import turfCleanCoords from "@turf/clean-coords";
// import turfPolygon from "turf-polygon";
// import turfMultiPolygon from "turf-multipolygon";

// let geom = turf.polygon(geometry.coordinates);
// geom = turf.simplify(geom, { tolerance: 1e-7 });
// geom = turf.cleanCoords(geom);
// geom = turf.unkinkPolygon(geom);
// geom = turf.combine(geom);
// return geom.features[0].geometry;
// convenience getters from vuex
import { mapGetters } from "vuex";

import MapStateHelpers from "@/mixins/MapStateHelpers.js";
import ClientDetailsHelpers from "@/mixins/ClientDetailsHelpers.js";
import LayerStateHelpers from "@/mixins/LayerStateHelpers.js";
import FeatureStateHelpers from "@/mixins/FeatureStateHelpers.js";
import FieldMapHelpers from "@/mixins/FieldMapHelpers.js";
import NotificationHelpers from "@/mixins/NotificationHelpers.js";
import AccessControlHelpers from "@/mixins/AccessControlHelpers.js";

import GeomanUtil from "@/mixins/GeomanUtil.js";

import { latLng } from "leaflet";
import {
    LMap,
    LTileLayer,
    LMarker,
    LIcon,
    LRectangle,
    // LPolygon,
    LPolyline,
    LCircle,
    // LCircleMarker,
    LPopup,
    LTooltip,
    LControlLayers,
    LControl,
    LLayerGroup,
    LFeatureGroup
    // LGeoJson
} from "vue2-leaflet";

// import StripePattern from "leaflet.pattern";
// import "leaflet-sidebar-v2";
// console.log(LSidebar);

// import LDrawToolbar from "vue2-leaflet-draw-toolbar";

// @ is an alias to /src
import Sidebar from "@/components/sidebar/Sidebar.vue";
import MCircleMarker from "@/components/mapElements/MCircleMarker.vue";
import MPolygon from "@/components/mapElements/MPolygon.vue";
import MMultiPolygon from "@/components/mapElements/MMultiPolygon.vue";
import MPolyline from "@/components/mapElements/MPolyline.vue";
import MMultiPolyline from "@/components/mapElements/MMultiPolyline.vue";
import UserDefaultPortalUpdateModal from "@/components/modals/UserDefaultPortalUpdateModal.vue";
// import LoadingNotification from "@/components/notifications/LoadingNotification.vue";
import { log } from "util";

import uuidv1 from "uuid/v1";

// import 'leaflet.pm';
// import 'leaflet.pm/dist/leaflet.pm.css';

export default {
    name: "Map",
    mixins: [
        MapStateHelpers,
        ClientDetailsHelpers,
        LayerStateHelpers,
        FeatureStateHelpers,
        FieldMapHelpers,
        NotificationHelpers,
        AccessControlHelpers,
        GeomanUtil
    ],
    components: {
        MCircleMarker,
        MPolygon,
        MMultiPolygon,
        MPolyline,
        MMultiPolyline,
        LMap,
        LTileLayer,
        LControlLayers,
        LControl,
        LMarker,
        LIcon,
        LRectangle,
        // LPolygon,
        LPolyline,
        LCircle,
        // LCircleMarker,
        LPopup,
        // LTooltip,
        Sidebar,
        LLayerGroup,
        LFeatureGroup,
        UserDefaultPortalUpdateModal
        // LoadingNotification
        // LGeoJson
        // LDrawToolbar
    },
    data() {
        return {
            isLoading: true,
            open: true,
            showParagraph: false,
            mapOptions: {
                zoomSnap: 0.5,
                layers: this.baselayers
                // measureControl: true
                // preferCanvas: true
            },
            showMap: true,
            // map: {},
            // layers: [],
            // userPortals: [],
            mouseCoordinates: false,
            localDragMode: false,
            // pm:edit gets fired after dragging
            // we need to know if some geometry was dragged
            featureDragged: false
            // mountFinished: false,
            // defaultsLoaded: false
        };
    },
    computed: {
        // vuex getters mapped
        // ...mapGetters({
        //     map: 'mapState/map',
        //     zoom: 'mapState/zoom',
        //     latitude: 'mapState/latitude',
        //     longitude: 'mapState/longitude'
        // }),
        // showMap: function() {
        //     return this.defaultsLoaded;
        // },
        center: function() {
            return latLng(this.latitude, this.longitude);
        },
        visibleLayers: function() {
            // console.log(this.layers.filter(layer => layer.visible));

            // return this.layers.filter(layer => layer.visible);
            return this.layers.filter(layer => layer.visible);
        }
    },
    created() {
        this.$eventHub.$on("set-sidebar-loading-state", isLoading => {
            // console.log('loading state');
            // console.log(isLoading);

            this.isLoading = isLoading;
        });
    },
    mounted() {
        this.$ppClient
            .get(`/portal`)
            .then(response => {
                let body = response.data.body;
                // console.log(body);

                // JSON responses are automatically parsed.
                // this.layers = body.portal.layers;
                // this.$store.commit("mapState/setLayers", body.portal.layers);
                if (body.portal) {
                    this.tileProviders = body.portal.config.tileProviders;
                    this.layers = body.portal.layers;
                    this.layerSummaries = body.portal.layerSummaries;
                    this.clientLogo = body.portal.config.logo;
                    this.clientName = body.portal.config.name;
                    this.selectedPortal = body.portal.config._id.$oid;
                    this.documentConfig = body.portal.config.documents;
                    this.fieldTranslation = body.portal.config.fieldTranslation;
                    // control certain aspects of ui based on portal config
                    this.portalAllowsManagedLayers =
                        body.portal.config.allowManagedLayers || false;
                    this.portalAllowsClientLayers =
                        body.portal.config.allowClientLayers || false;
                    this.portalAllowsDocUploads =
                        body.portal.config.allowDocUploads || false;
                    this.clientPrivacyPolicy =
                        body.portal.config.privacyPolicy || null;
                } else {
                    let props = {
                        userPortals:  body.userPortals
                    };

                    this.$buefy.modal.open({
                        component: UserDefaultPortalUpdateModal,
                        props: props,
                        canCancel: false,
                        parent: this,
                        events: {
                            "default-portal-updated": success => {
                                // console.log('user added:');
                                // console.log(user)
                                this.$router.go();    
                            }
                        }
                    });
                }

                this.userName = body.user;
                this.userPortals = body.userPortals;

                // console.log(this.fieldTranslation);

                this.isLoading = false;
                this.notifications = body.notifications;
            })
            .catch(e => {
                console.log(e);
            });

        let mapComponent = this.$refs.map;
        this.map = mapComponent.mapObject;

        this.$nextTick(() => {
            let map = this.map;

            // console.log(map.pm.Draw.Line);

            // add sidebar to map
            let sidebar = window.L.control
                .sidebar({
                    autopan: false, // whether to maintain the centered map point when opening the sidebar
                    closeButton: true, // whether t add a close button to the panes
                    container: "sidebar", // the DOM container or #ID of a predefined sidebar container that should be used
                    position: "left" // left or right
                })
                .addTo(map);

            // was asked to auto open sidebar as default
            // will bundle into user preferences at some point
            sidebar.open("home");

            // we set this afterwards so that opening the sidebar at the start
            // doesn't affect the loaded center point for the user
            sidebar.options.autopan = true;

            // commit sidebar instance to store
            this.$store.commit("sidebarState/setSidebar", sidebar);

            // add the logo from DOM into Map
            let logoEl = document.getElementById("logo");
            let topRightControls = document.getElementsByClassName(
                "leaflet-top leaflet-right"
            )[0];

            // console.log(topLeftControls);
            // topLeftControls.appendChild(test);
            topRightControls.prepend(logoEl);

            var measureControl = L.control.measure({
                primaryAreaUnit: "acres",
                secondaryAreaUnit: "sqmeters",
                primaryLengthUnit: "meters",
                secondaryLengthUnit: "kilometers",
                activeColor: "#8d9bfc",
                completedColor: "#1530f9"
            });
            measureControl.addTo(map);

            // GEOMAN CONFIG
            // hacky way to prevent enabling global edit and drag modes

            // @see GeomanUtil.js
            // this.overrideGeomanModeEvents();
            map.pm.Draw.options.allowSelfIntersection = false;
            map.pm._fireDragModeEvent = function(enabled) {
                let eType = enabled ? "enabled" : "disabled";
                map.fire(`pm:globaldragmode${eType}`, {
                    enabled,
                    map: map
                });
            };
            map.pm.enableGlobalEditMode = function(options) {
                // find all layers handled by leaflet-geoman
                // const layers = this.findLayers();

                this._globalEditMode = true;
                // note layerAddHandler seems to be removed and caused a
                // bug, threw a null pointer exception when trying to enable edit mode
                // handle layers that are added while in removal  xmode
                // this.map.on("layeradd", this.layerAddHandler, this);

                // toggle the button in the toolbar
                this.Toolbar.toggleButton("editPolygon", this._globalEditMode);

                // fire event
                this._fireEditModeEvent(true);
            };
            map.pm._fireEditModeEvent = function(enabled) {
                let eType = enabled ? "enabled" : "disabled";
                map.fire(`pm:globaleditmode${eType}`, {
                    enabled,
                    map: map
                });
            };

            // GOOGLE TILES
            // googleStreets = L.tileLayer('http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}',{
            //     maxZoom: 20,
            //     subdomains:['mt0','mt1','mt2','mt3']
            // }).addTo(map);

            // googleHybrid = L.tileLayer('http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}',{
            //     maxZoom: 20,
            //     subdomains:['mt0','mt1','mt2','mt3']
            // }).addTo(map);

            // googleSat = L.tileLayer('http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}',{
            //     maxZoom: 20,
            //     subdomains:['mt0','mt1','mt2','mt3']
            // }).addTo(map);
            // googleTerrain = L.tileLayer('http://{s}.google.com/vt/lyrs=p&x={x}&y={y}&z={z}',{
            //     maxZoom: 20,
            //     subdomains:['mt0','mt1','mt2','mt3']
            // }).addTo(map);

            // stripe fill effect
            // kind of twitchy,
            // not very dynamic
            //  npm i leaflet.pattern
            // var stripes = new L.StripePattern();
            // stripes.addTo(map);
            // this.$store.commit("mapState/setStripePattern", stripes);
        });

        this.mountFinished = true;
    },
    watch: {
        // sysAccess: function( v ) {
        //     console.log(`sysAccess changed ${v}`);
        //     console.log(this.sysAccess);
        // },
        // portalAccess: function( v ) {
        //     console.log('portalAccess changed');
        //     console.log(this.portalAccess);
        // },
        // selectedFeature: 'fetchFeatureDetails',
        selectedFeature: "globalHandleFeatureChange",
        selectedLayer: "layerChanged"
    },
    methods: {
        // fetchFeatureDetails(feature) {

        //     console.log('map.vue: fetchFeatureDetails');
        //     console.log(this.selectedFeature);
        //     console.log(this.selectedLayer);

        //     let layer = this.layers.find(
        //         l => l._id.$oid == this.selectedFeature._site.layerId.$oid
        //     );
        //     console.log('layer');

        //     console.log(layer);

        //     let site = layer.sites.find(
        //         s => s._id.$oid == this.selectedFeature._site.siteId.$oid
        //     );
        //     console.log('site');
        //     console.log(site);

        //     if(this.featureSelected){

        //         // newly added feature
        //         if(this.selectedFeature._site.isNew) {
        //             // no fields added yet, hydrate from selected layer
        //             if(!this.selectedFeature.fields) {

        //             }
        //         } else {
        //             // get site data from server

        //             let source = this.selectedFeature._site.source;
        //             let layerId = this.selectedFeature._site.layerId.$oid;
        //             let siteId = this.selectedFeature._site.siteId.$oid;
        //             let showSiteUrl = `/site/${this.selectedPortal}/${source}/${layerId}/${siteId}`;
        //             // let url = `/site/${source}/${siteId}/${centerPoint.lat}/${centerPoint.lng}`;
        //             this.$ppClient
        //                 .get(showSiteUrl)
        //                 .then(response => {
        //                     console.log(response.data.body);

        //                     let body =  response.data.body;
        //                     this.fetchedFeatureData = body;

        //                     // JSON responses are automatically parsed.
        //                     // this.layers = body.portal.layers;
        //                     // this.$store.commit("mapState/setLayers", body.portal.layers);
        //                 })
        //                 .catch(e => {
        //                     console.log(e);
        //                 });
        //         }

        //         // get w3w data
        //         let centerPoint = this.getCentrePoint(this.selectedFeature); //.getBounds().getCenter();
        //         let w3wUrl = `/convert-to-3wa?coordinates=${centerPoint.lat},${centerPoint.lng}`;
        //         this.$w3wClient.get(w3wUrl)
        //         .then(response => {
        //             this.selectedFeatureW3W = response.data;
        //         })
        //         .catch(e => {
        //             console.log(e);
        //         });
        //     }else{
        //         this.fetchedFeatureData = {};
        //         this.selectedFeatureW3W = {};
        //     }
        // },
        layerChanged(newLayer) {
            // console.log('map.vue: layerChanged');
            // console.log(newLayer);
            // this.selectedFeature = null;
        },
        featureGroupAdded(e, id) {
            // console.log('layer added');
            // console.log(`passed id: ${id}`);
            // console.log(e.target);

            this.$store.commit("mapState/setLayerFeatureGroup", {
                layerId: id,
                featureGroup: e.target
            });
        },
        setHoverStyle(e) {
            // console.log("hoverStyle");
            // console.log(e);
        },
        mouseMoveMap(e) {
            // console.log("mousemove");
            // console.log(e);

            this.mouseCoordinates = e.latlng;
        },
        mouseOutMap(e) {
            this.mouseCoordinates = false;
        },
        updateLatLng(type, val) {
            this[type] = val;
        },
        // getLatLng(lat, lng) {
        //     return latLng(lat, lng);
        // },
        // getLatLngs(coordinates) {
        //     // console.log( coordinates.map( outer => outer.map(inner => [ inner[0], inner[1] ]) ) );

        //     // return coordinates.map( c => [c[0], c[1]]);
        //     return coordinates.map(outer =>
        //         outer.map(inner => [inner[1], inner[0]])
        //     );
        // },
        handleCutGeo(e) {
            // console.log("handleCutGeo");
            // console.log(e);
            // console.log("ST");
            // console.log(e.sourceTarget.toGeoJSON());
            // console.log("L");
            // console.log(e.layer.toGeoJSON());
            // console.log("OL");
            // console.log(e.originalLayer.toGeoJSON());
            // console.log("T");
            // console.log(e.target.toGeoJSON());

            // let featureId = e.sourceTarget._site.featureId.$oid;
            let featureId;
            let originalGeoType;
            if (e.sourceTarget.feature) {
                featureId = e.sourceTarget.feature.geometry.featureId.$oid;
                originalGeoType = e.sourceTarget.feature.geometry.type;
            } else {
                featureId = e.sourceTarget._site.featureId.$oid;
                originalGeoType = e.sourceTarget._site.type;
            }

            let newGeo = e.layer.toGeoJSON().features[0].geometry;

            // cut tool gives some stupid behaviour when dealing with cutting a multipolygon
            // it basically gives only the affected polygon of the multi in souceTarget
            //  and performing layer.toGeojson gives some bad nesting that mongo doen'y like one bit
            if (newGeo.type === "MultiPolygon") {
                // console.log('for loop layer geojson');
                // lets fix that and remap them friggin coordinates
                var newCoords = [];
                for (let c of e.layer.toGeoJSON().features[0].geometry
                    .coordinates) {
                    // console.log(c);
                    for (let d of c) {
                        newCoords.push([d]);
                    }
                }

                newGeo.coordinates = newCoords;

                // let geom = turfMultiPolygon(newCoords);
                // geom = turfSimplify(geom, { tolerance: 1e-7 });
                // geom = turfCleanCoords(geom);
                // geom = turfUnkinkPoly(geom);
                // geom = turfCombine(geom);
                // // return geom.features[0].geometry;
                // console.log('TURF GEO TEST');
                // console.log(geom);
            }

            // console.log('newGeo');
            // console.log(newGeo);
            // console.log(JSON.stringify(newGeo));
            // console.log('newCoords');
            // console.log(newCoords);

            // update geo on server and show status synchronously
            // but update the geometry in-memory asynchronously
            // doing this as an await would basically have the effect of a synchronous opertaion
            // if (await this.updateGeo(featureId, newGeo)) {
            //     this.$buefy.toast.open(`Successfully Updated Site Data`);
            // } else {
            //     this.$buefy.toast.open(
            //         `Something went wrong: please try again`
            //     );
            // }
            this.updateGeo(featureId, newGeo).then(
                updated => {
                    if (updated) {
                        this.$buefy.toast.open(
                            `Successfully Updated Site Data`
                        );
                    } else {
                        this.$buefy.toast.open(
                            `Something went wrong: please try again`
                        );
                    }
                },
                error => {
                    this.$buefy.toast.open(
                        `Something went wrong: please try again`
                    );
                    console.log(
                        "error in edit geo handler (passed from updateGeo)"
                    );
                    console.log(error);
                }
            );

            // console.log("ng type");
            // console.log(newGeo.type);

            // console.log('layers')
            // console.log(this.layers);

            // let theLayer = false;
            let theLayer = this.layers.find(layer => {
                return (
                    layer.visible &&
                    layer.geometry[originalGeoType] &&
                    layer.geometry[originalGeoType]
                        .map(geo => geo.featureId.$oid)
                        .includes(featureId)
                );
            });

            // console.log("theLayer");
            // console.log(theLayer);

            let theGeometry = theLayer.geometry[originalGeoType].find(
                shape => shape.featureId.$oid == featureId
            );

            theGeometry.coordinates = newGeo.coordinates;

            // console.log("theGeometry");
            // console.log(theGeometry);

            // cutting geometry may result in it changing type
            // for example from a polygon to a multipolygon
            if (!(newGeo.type === originalGeoType)) {
                // does the layer currently have an entry for the new type of geometry
                if (!theLayer.geometry[newGeo.type]) {
                    theLayer.geometry[newGeo.type] = [];
                }

                // remove the old entry  for the geometry
                theLayer.geometry[originalGeoType] = theLayer.geometry[
                    originalGeoType
                ].filter(shape => !(shape.featureId.$oid == featureId));
                // update the geometry type
                theGeometry.type = newGeo.type;
                // add it to the correct entry
                theLayer.geometry[newGeo.type].push(theGeometry);
            }

            if (newGeo.type == "MultiPolygon") {
                this.selectedFeature = null;
                let sidebar = this.$store.getters["sidebarState/sidebar"];
                // ensure the panel isn't disabled
                // sidebar.enablePanel(tabId);
                sidebar.open("layer-management");
            }

            // for some reason cut works slighly differently and the layer needs to be
            // shown again after updating geometry
            // theLayer.featureGroup._layers[e.sourceTarget._leaflet_id] = e.sourceTarget;

            this.map.removeLayer(e.originalLayer);
            this.map.addLayer(e.originalLayer);
            this.map.removeLayer(e.layer);

            // console.log(this.selectedFeature);
            // console.log(this.map);
        },
        // dragEnd(e) {
        //     console.log('multipoly drag end');
        //     console.log(e);
        // },
        async handleEditGeo(e) {
            // console.log('handle edit geo');
            // console.log(e);
            // multipolygons have the attributes stored differently
            let featureId;
            if (e.sourceTarget.feature) {
                featureId = e.sourceTarget.feature.geometry.featureId.$oid;
                // originalGeoType = e.sourceTarget.feature.geometry.type;
            } else {
                featureId = e.sourceTarget._site.featureId.$oid;
                // originalGeoType = e.sourceTarget._site.type;
            }

            // let featureId = e.sourceTarget._site.featureId.$oid;
            let newGeo = e.sourceTarget.toGeoJSON().geometry;

            // circles neeed to store the radius with the point coordinates
            if (e.sourceTarget instanceof L.Circle) {
                newGeo.radius = e.sourceTarget.getRadius();
            }

            // update geo on server and show status synchronously
            // but update the geometry in-memory asynchronously
            // doing this as an await would basically have the effect of a synchronous opertaion
            // if (await this.updateGeo(featureId, newGeo)) {
            //     this.$buefy.toast.open(`Successfully Updated Site Data`);
            // } else {
            //     this.$buefy.toast.open(
            //         `Something went wrong: please try again`
            //     );
            // }
            this.updateGeo(featureId, newGeo).then(
                updated => {
                    if (updated) {
                        this.$buefy.toast.open(
                            `Successfully Updated Site Data`
                        );
                    } else {
                        this.$buefy.toast.open(
                            `Something went wrong: please try again`
                        );
                    }
                },
                error => {
                    this.$buefy.toast.open(
                        `Something went wrong: please try again`
                    );
                    console.log(
                        "error in edit geo handler (passed from updateGeo)"
                    );
                    console.log(error);
                }
            );

            // this code will execute without waiting for the server response
            // find the correct layer in-memory
            let theLayer = this.layers.find(layer => {
                return (
                    layer.visible &&
                    layer.geometry[newGeo.type] &&
                    layer.geometry[newGeo.type]
                        .map(geo => geo.featureId.$oid)
                        .includes(featureId)
                );
            });

            // find the correct geometry within the layer
            let theGeometry = theLayer.geometry[newGeo.type].find(
                shape => shape.featureId.$oid == featureId
            );

            // console.log('selectedFeature');
            // console.log(this.selectedFeature);
            // console.log('theLayer');
            // console.log(theLayer);

            // let oldGeo = Object.assign({}, theGeometry);
            // console.log('oldGeo');
            // console.log(oldGeo);
            // console.log('newGeo');
            // console.log(newGeo);

            // update the coordinates in-memory
            // otherwise hiding the layer and showing again
            // will show the old coordinates
            theGeometry.coordinates = newGeo.coordinates;

            // multi poly gons are handled differently
            // not sure why but updating the coordinates for them
            // will make PM lose it's reference and the edit handles disappear
            if (newGeo.type == "MultiPolygon") {
                // multi polygons get deselected after updating the geometry for some reason
                // pm:edit gets fired after dragging,
                // we only want to renable the edit mode after updating geometry
                if (!this.featureDragged) {
                    this.$nextTick(() => {
                        this.enableSelectedFeatureEdit();
                        this.$eventHub.$emit(
                            "m-multipolygon-updated",
                            this.selectedFeature
                        );
                    });
                }
            }

            // set back to false
            this.featureDragged = false;

            // e.sourceTarget.pm.enable();
            // update the radius for a cirle so that it
            // can recalculate the area
            // if (e.sourceTarget instanceof L.Circle) {
            //     theGeometry.radius = newGeo.radius;
            // }
        },
        async updateGeo(featureId, newGeo) {
            // let featureId = e.sourceTarget._site.featureId.$oid || e.sourceTarget.feature.geometry.featureId.$oid;

            let url = `/feature/${featureId}/geometry`;
            // we do this call as an await so we can return the server response status
            // but handle the in-memory updates asynchronously
            try {
                let response = await this.$ppClient.patch(url, {
                    geometry: newGeo
                });

                return response.data.body.status === "success";
            } catch (error) {
                console.log("update geo error");

                if (error.response) {
                    // Request made and server responded
                    console.log("error.response");
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                } else if (error.request) {
                    // The request was made but no response was received
                    console.log("error.request");
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log("undefined error");

                    console.log("Error", error.message);
                }

                return false;
            }
        },
        layerRenamed(layerId, newName) {
            let layer = this.layers.find(layer => layer.id == layerId);
            layer.name = newName;
        },
        dragstart(e) {
            // console.log("dragstart");
            // console.log(e);
        },
        lComponent(type) {
            return `l-${type}`;
        },
        drawStart(e) {
            // console.log("drawstart");
            // console.log(e);
            // e.workingLayer = this.layers.find(layer => layer._id.$oid == this.selectedLayer._id.$oid).featureGroup;
        },
        drawCreate(l) {
            // l = null;
            // console.log("pm:create:");
            // console.log(l);
            // console.log(l.layer.getRadius());

            // console.log('geojson');
            // console.log(this.selectedLayer);

            let geometry = l.layer.toGeoJSON().geometry;
            if (l.layer instanceof L.Circle) {
                geometry.radius = l.layer.getRadius();
            }
            // console.log("geometry");
            // console.log(geometry);

            // return;
            // console.log('map');
            // console.log(this.map);
            // console.log('selected layer');
            // console.log(this.selectedLayer);

            // geometry added, create a new site on server

            let layerFields = this.selectedLayer.fields;
            let newFields = [];

            for (let field of layerFields) {
                // console.log(field);
                // newSite[field.id] = ''
                field.value = "";
                newFields.push(field);
            }

            let portalId = this.$store.getters["mapState/selectedPortal"];
            // let layerId = this.selectedFeature._site.layerId.$oid;
            let layerId = this.selectedLayer._id.$oid;
            let url = `/portal/${portalId}/layer/${layerId}/site`;
            this.$ppClient
                .post(url, {
                    fields: {
                        portal: newFields
                    },
                    geometry: geometry
                })
                .then(response => {
                    // console.log("in drawcreate response");
                    // console.log(this.selectedLayer);

                    // console.log(response.data);

                    let body = response.data.body;
                    if (body.createdGeom) {
                        // new type of geometry for this layer.
                        // add it as a property
                        if (
                            !this.selectedLayer.geometry[
                                body.createdGeom.geometry.type
                            ]
                        ) {
                            this.selectedLayer.geometry[
                                body.createdGeom.geometry.type
                            ] = [];
                        }

                        // console.log(
                        //     this.selectedLayer.geometry[
                        //         body.createdGeom.geometry.type
                        //     ]
                        // );

                        let newFeature = {
                            coordinates: body.createdGeom.geometry.coordinates,
                            type: body.createdGeom.geometry.type,
                            layerId: body.createdGeom._layer,
                            featureId: body.createdGeom.featureId,
                            siteId: body.createdGeom.site_id
                        };

                        if (body.createdGeom.radius) {
                            newFeature.radius = body.createdGeom.radius;
                        }

                        // this.selectedLayer.geometry[geometry.type].push(body.createdGeom);
                        this.selectedLayer.geometry[
                            body.createdGeom.geometry.type
                        ].push(newFeature);
                    }

                    if (body.createdSite) {
                        this.selectedLayer.sites.push(body.createdSite);

                        // console.log('body createdSite');
                        // console.log(this.selectedLayer);
                        // console.log(this.layers);
                    }
                    l.layer.remove();
                })
                .catch(error => {
                    l.layer.remove();
                    this.$buefy.toast.open(
                        `Something went wrong: verify that the geometry was sound`
                    );
                    if (error.response) {
                        // Request made and server responded
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);

                        let errors = error.response.data.errors;
                        if (errors) {
                            for (let key in errors) {
                                // // add affected field
                                // this.errorFields.push(key);
                                // // merge errors
                                // this.errors = [
                                //     ...this.errors,
                                //     ...errors[key]
                                // ];
                            }
                        } else {
                            // this.errors.push(
                            //     "Something went wrong. Please try again"
                            // );
                        }
                    } else if (error.request) {
                        // The request was made but no response was received
                        console.log("error.request");

                        console.log(error.request);
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        console.log("undefined error");

                        console.log("Error", error.message);
                        // this.errors.push(
                        //     "Something went wrong. Please try again"
                        // );
                    }
                });

            // // new type of geometry for this layer.
            // // add it as a property
            // if( !this.selectedLayer.geometry[geometry.type] ) {
            //     this.selectedLayer.geometry[geometry.type] = [];
            // }

            // temp keys for joing site and feature data
            // let featureId = uuidv1();
            // let siteId = uuidv1();

            // this.selectedLayer.geometry[geometry.type].push({
            //     coordinates: geometry.coordinates,
            //     type: geometry.type,
            //     isNew: true,
            //     layerId: this.selectedLayer._id,
            //     featureId: {
            //         $oid: featureId,
            //     },
            //     siteId: {
            //         $oid: siteId
            //     }
            // });

            // let layerFields = this.selectedLayer.fields;
            // let newSite = {
            //     _id: {
            //         $oid: siteId
            //     },
            //     _layer: {
            //         $oid: this.selectedLayer._id
            //     },
            //     featureId: {
            //         $oid: featureId,
            //     },
            //     fields: {}

            // };

            // let newFields = [];

            // for(let field of layerFields) {
            //     // console.log(field);
            //     // newSite[field.id] = ''
            //     field.value = '';
            //     newFields.push(field);

            // }

            // newSite.fields.portal = newFields;

            // this.selectedLayer.sites.push(
            //     newSite
            // )

            // l.layer.remove();
        },
        // drawEnd(e) {
        //     console.log("draw end:");
        //     console.log(e);
        //     // console.log(this.map);
        // },
        // vertexAdded(e) {
        //     console.log("vertex added:");
        //     console.log(e);
        //     // console.log(this.map);
        // },
        mapClicked(e) {
            // console.log(this.mouseCoordinates);

            // get w3w data
            // let centerPoint = this.getCentrePoint(this.selectedFeature); //.getBounds().getCenter();
            let w3wUrl = `/convert-to-3wa?coordinates=${this.mouseCoordinates.lat},${this.mouseCoordinates.lng}`;
            this.$w3wClient
                .get(w3wUrl)
                .then(response => {
                    this.lastClicked = response.data;
                })
                .catch(e => {
                    console.log(e);
                });

            // console.log("map clicked:");
            // console.log(e);
        },
        zoomUpdate(zoom) {
            // this.currentZoom = zoom;
            if (this.zoom != zoom) {
                this.zoom = zoom;
                let url = `/portal/${this.selectedPortal}/update-zoom`;
                this.$ppClient.patch(url, { zoom: zoom });
            }
        },
        centerUpdate(center) {
            // console.log('map.vue: before centreUpdate');
            // console.log(center);
            // console.log(this.selectedPortal);
            this.latitude = center.lat;
            this.longitude = center.lng;

            let url = `/portal/${this.selectedPortal}/update-center`;

            this.$ppClient.patch(url, { center: center });
        },
        showLongText() {
            this.showParagraph = !this.showParagraph;
        },
        innerClick() {
            // alert("Click!");
            // console.log("entered");

            this.showLongText();
        },
        baselayerchange(e) {
            // console.log(e);
            // console.log(e.name);
            // console.log(e.layer.options.focusStrokeColour);
            // console.log(this.map);
            this.selectedBaseLayer = e.layer;
            let url = `/portal/${this.selectedPortal}/update-base-layer`;

            this.$ppClient.patch(url, {
                baseLayer: this.selectedBaseLayer.options._id.$oid
            });
            // @todo update any selected geometry
        },
        log(e) {
            let l = e.layer;
            // l.options.interactive = false;
            // l.pm = this.map.pm
            // l.pm.disable();
            // this.map.pm.toggleGlobalEditMode();

            // e.target._map.removeLayer(l);
            // l._initHooksCalled = false;
            // e.target._map.addLayer(l);
            // console.log('log function');
            // e.target.pm.enable();
            // console.log(l);
            // console.log(e);
        }
    }
};
</script>

<style lang="scss">
// div#branded-seperator {
//     height: 5%;
//     width: 100%;
//     display: flex;
//     // border-radius: 0.2em;
//     // margin-bottom: 15px;
//     // background: linear-gradient(<angle>, color1 color1-stop-at, color2 color2-start-at);
//     // background: -webkit-linear-gradient(45deg, #1A3062 65%, rgba(0, 0, 0, 0) 65%), -webkit-linear-gradient(45deg, #898A93 84%, #EE2E24 86%);
//     // background: -o-linear-gradient(45deg, #1A3062 65%, rgba(0, 0, 0, 0) 65%), -o-linear-gradient(45deg, #898A93 84%, #EE2E24 86%);
//     // background: -moz-linear-gradient(45deg, #1A3062 65%, rgba(0, 0, 0, 0) 65%), -moz-linear-gradient(45deg, #898A93 84%, #EE2E24 86%);
//     // background: linear-gradient(45deg, #1A3062 0, #1A3062 70%), linear-gradient(45deg, #898A93 69%, #EE2E24 86%);
//     // background:linear-gradient(45deg, #1A3062 0, #1A3062 70%, #898A93 70%, #898A93 85%, #EE2E24 85%, #EE2E24 100%);

//     // tri-color
//     // background: linear-gradient(
//     //     45deg,
//     //     #FFF 0, #FFF 40%,
//     //     #1A3062 40%, #1A3062 59%,
//     //     #FFF 59%, #FFF 60%,
//     //     #898A93 60%, #898A93 79%,
//     //     #FFF 79%, #FFF 80%,
//     //     #EE2E24 80%, #EE2E24 100%,
//     // );
//     // single color with slash
//     // background: linear-gradient(
//     //     135deg,
//     //     #FFF 0, #FFF 25%,
//     //     #1A3062 25%, #1A3062 100%
//     // );
//     background-color: #1a3062;
//     // url("../assets/logo.svg");
// }

// #logo-wrap {
//     height: 100%;
//     float: left;

//     background-color: white;
//     padding: 5px;
//     max-width: 70px;
// }
#logo {
    text-align: right;
    float: right;
    background-color: white;
    // background-color: #5b657c;
    // background-color: var(--fg-grey-med);
    padding: 5px;
    max-width: 100px;
    border-radius: 5%;;
}

// #logo-item, #text-item{
//     pointer-events: none;
// }   

// #logo-item {
//     padding-top: 1px;
// }

#text-item {

    // background: url("~@/assets/logo.svg") no-repeat left center;
    // background-size: contain;
    position: absolute;
    text-shadow: 1px 1px black;
    left: 30px;
    top: 12px;
    
    h1 {
        color: white;
    }
    // &:hover {
    //     pointer-events: none;
    // }
}

.leaflet-control-measure-toggle {
    width: 36px !important;
    height: 36px !important;
    background-size: 20px 20px !important;
}

.margin-top-15 {
    margin-top: 15px;
}

.margin-bottom-15 {
    margin-bottom: 15px;
}

.leaflet-container {
    font-family: "Roboto", Helvetica, Arial, sans-serif !important;
}

#full-div {
    position: absolute;
    // overflow-x: auto;
    // overflow-y: auto;
    overflow: hidden;
    // top: 150px;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    height: 100%;
}

#mouse-position-display {
    background-color: white;
    padding: 5px;
}

.empty-label {
    .field-label {
        display: none;
    }
}

.leaflet-control-layers-base {
    text-align: left;
    input[type="radio"]:disabled + span {
        color: #e3e3e3;
    }
}

.modal.is-active,
.dialog.modal.is-active {
    z-index: 2000;
}

.b-table {
    .table {
        td {
            vertical-align: middle;
        }
    }
}

// .leaflet-control {
//   .leaflet-sidebar-tabs,
//   .leaflet-sidebar-content {
//     color: rgb(255, 255, 255);
//     background: rgba(2, 3, 8, 0.425);
//   }
// }
</style>
