<template>
    <div class="propman-rent">
        <h4 class="rent-data-header is-capitalized">
            Rent Data:
        </h4>
        <div class="buttons">
            <b-button
                v-if="!fieldMapperEnabled"
                title="Edit Field Name Mappings"
                @click="fieldMapperEnabled = !fieldMapperEnabled"
            >
                <font-awesome-icon icon="edit" />
            </b-button>
        </div>

        <div v-if="fieldMapperEnabled">
            <external-site-field-mapper
                @cancel-edit-field-map="fieldMapperEnabled = false"
                @field-map-updated="fieldMapperEnabled = false"
                :showDisplayRef="true"
                :fields="leaseRentData[0]"
                :siteType="'propman'"
                :dataSource="'rent'"
            />
        </div>

        <div v-else>
            <div v-if="leaseRentData">
                <b-collapse
                    class="card"
                    aria-id="rentData"
                    v-for="rent in leaseRentData"
                    :key="`${rent['Rent-No']}-${rent['Lease-No']}`"
                    :open="false"
                >
                    <div
                        slot="trigger"
                        slot-scope="props"
                        class="card-header"
                        role="button"
                        aria-controls="rentData"
                    >
                        <p
                            class="card-header-title is-capitalized"
                            style="justify-content: space-between;"
                        >
                            <!-- Rent No. {{ rent["rent-no"] }} Lease No
                            {{ rent["Lease-No"] }} -->
                            <!-- {{ rent["description"] }} -->
                            <!-- {{ getDisplayRef(rent) | scolon2space }} -->
                            {{
                                getDisplayRefFromMap(rent, fieldMap)
                                    | scolon2space
                            }}
                        </p>
                        <a class="card-header-icon">
                            <font-awesome-icon
                                :icon="
                                    props.open
                                        ? ['fas', 'caret-up']
                                        : ['fas', 'caret-down']
                                "
                            />
                        </a>
                    </div>

                    <div class="card-content">
                        <div>
                            <ul class="selected-feature-list">
                                <!-- v-if="leaseRentData" -->
                                <li
                                    v-for="(val, key) in mapped(rent)"
                                    :key="key"
                                >
                                    <strong class="is-capitalized">
                                        {{ key }}:
                                    </strong>
                                    {{ val | scolon2space }}
                                </li>
                            </ul>
                        </div>
                    </div>
                </b-collapse>
            </div>
        </div>

        <!-- </div>
        </b-collapse> -->
    </div>
</template>

<script>
import FeatureStateHelpers from "@/mixins/FeatureStateHelpers.js";
import FieldMapHelpers from "@/mixins/FieldMapHelpers.js";

import ExternalSiteFieldMapper from "@/components/sidebar/FeatureDetails/ExternalSiteFieldMapper.vue";

export default {
    name: "PropmanRent",
    mixins: [FeatureStateHelpers, FieldMapHelpers],
    components: { ExternalSiteFieldMapper },
    props: {
        leaseRentData: Array
    },
    data() {
        return {
            fieldMapperEnabled: false
        };
    },
    mounted() {},
    computed: {
        fieldMap: function() {
            // return this.fieldTranslation['propman']['rent'];
            return this.fieldMapFor("propman", "rent");
        }
    },
    methods: {
        mapped(rent) {
            if (this.fieldMap) {
                return this.mapFieldsIn(this.fieldMap, rent);
            } else {
                return lease;
            }
        }
        // getDisplayRef(rent) {

        //     let refSet = false;

        //     for(let field in this.fieldMap ) {
        //         if(this.fieldMap[field].isDisplayRef) {
        //             refSet = field;
        //         }
        //     }

        //     if( refSet ) {
        //         // case insensitive property access
        //         // get array of keys
        //         // perform find against it
        //         // with lowercased key val and search term
        //         return rent[
        //             Object.keys(rent).find(
        //                 key => key.toLowerCase() === refSet.toLowerCase()
        //             )
        //         ];
        //     } else {
        //         return 'Display Ref Not Set.';
        //     }
        // }
    }
};
</script>

<style lang="scss" scoped>
.rent-data-header {
    font-size: 1.2rem;
    font-weight: 700;
    padding: 15px 0;
}
</style>
