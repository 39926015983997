<template>
    <div id="document-upload-modal">
        <b-loading
            :is-full-page="false"
            :active.sync="loadingData"
            :can-cancel="false"
        >
            <font-awesome-icon
                :icon="['fad', 'sync']"
                :style="$iconStyle"
                size="4x"
                spin
            />
        </b-loading>

        <div class="modal-card" style="width: auto">
            <header class="modal-card-head">
                <p class="modal-card-title">
                    <span v-if="mode == 'new'">Upload a new Document</span>
                    <span v-else>Edit Data for Document</span>
                </p>
            </header>

            <section class="modal-card-body">
                <section>
                    <!-- validation errors -->
                    <b-notification
                        type="is-danger"
                        class="margin-bottom-15"
                        role="alert"
                        v-if="errors.length > 0"
                        aria-close-label="Close notification"
                    >
                        <ul>
                            <li v-for="(error, i) in errors" :key="i">
                                {{ error }}
                            </li>
                        </ul>
                    </b-notification>
                </section>

                <b-field v-if="mode === 'new'" class="file">
                    <b-upload @input="removeErrorField('file')" v-model="file">
                        <a
                            class="button"
                            :class="
                                errorFields.includes('file')
                                    ? 'is-danger'
                                    : 'is-primary'
                            "
                        >
                            <b-icon icon="upload"></b-icon>
                            <span>Select File to Upload</span>
                        </a>
                    </b-upload>
                    <span class="file-name" v-if="file">
                        {{ file.name }}
                    </span>
                </b-field>

                <div v-if="mode === 'new'" class="field">
                    <b-switch type="is-success" v-model="addNotification">
                        Add Notification after upload
                    </b-switch>

                </div>

                <!-- registered title only needs doc type -->
                <div v-if="fetchedFeatureData.siteType === 'registeredtitle'">
                    <b-field grouped>
                        <b-field
                            label="Regsitered Title Doc Type"
                            :type="
                                errorFields.includes('regType')
                                    ? 'is-danger'
                                    : ''
                            "
                        >
                            <b-select
                                @change.native="removeErrorField('regType')"
                                placeholder="Select A Document Type"
                                v-model="regType"
                                size="is-small"
                                expanded
                            >
                                <!-- @change.native="geostoreClientIdUpdated" -->
                                <option
                                    v-for="docType in registeredTitleDocTypes"
                                    :key="docType.name"
                                    :value="docType.value"
                                    >{{ docType.name }}
                                </option>
                            </b-select>
                        </b-field>
                    </b-field>
                </div>

                <div v-else>
                    <b-field
                        label="Document Title"
                        :type="errorFields.includes('title') ? 'is-danger' : ''"
                    >
                        <b-input
                            size="is-small"
                            v-model.trim="title"
                            @keyup.native="removeErrorField('title')"
                        ></b-input>
                    </b-field>

                    <b-field grouped>
                        <b-field label="Valid From">
                            <b-field>
                                <b-datepicker
                                    :years-range="[-5, 15]"
                                    size="is-small"
                                    placeholder="Click to select..."
                                    icon="calendar"
                                    v-model="valid.from"
                                    
                                >
                                </b-datepicker>
                                <p class="control">
                                    <b-button
                                        size="is-small"
                                        type="is-danger"
                                        @click="valid.from = null"
                                    >
                                        Clear
                                    </b-button>
                                </p>
                            </b-field>
                        </b-field>

                        <b-field label="Valid To">
                            <b-field>
                                <b-datepicker
                                    :years-range="[-5, 15]"
                                    size="is-small"
                                    placeholder="Click to select..."
                                    icon="calendar"
                                    v-model="valid.to"
                                >
                                <!-- @input="
                                        valid.to = new Date(
                                            $event
                                        ).toISOString()
                                    " -->
                                </b-datepicker>
                                <p class="control">
                                    <b-button
                                        size="is-small"
                                        type="is-danger"
                                        @click="valid.to = null"
                                    >
                                        Clear
                                    </b-button>
                                </p>
                            </b-field>
                        </b-field>
                    </b-field>

                    <b-field grouped>
                        <b-field
                            label="Category"
                            :type="
                                errorFields.includes('category')
                                    ? 'is-danger'
                                    : ''
                            "
                        >
                            <b-select
                                placeholder="Please Select A Document Category"
                                v-model.trim="category"
                                @change.native="removeErrorField('category')"
                                size="is-small"
                                expanded
                            >
                                <!-- @change.native="geostoreClientIdUpdated" -->
                                <option
                                    v-for="confCategory in documentConfig.cats"
                                    :key="confCategory.name"
                                    :value="confCategory.name"
                                    >{{ confCategory.name }}
                                </option>
                            </b-select>
                        </b-field>

                        <b-field label="Subcategory">
                            <b-select
                                placeholder="Please Select A Document Subcategory"
                                v-model.trim="subCategory"
                                size="is-small"
                                expanded
                            >
                                <!-- @change.native="geostoreClientIdUpdated" -->
                                <option
                                    v-for="confSubCategory in selectedSubcategories"
                                    :key="confSubCategory.name"
                                    :value="confSubCategory.name"
                                    >{{ confSubCategory.name }}
                                </option>
                            </b-select>
                        </b-field>
                    </b-field>

                    <b-field label="Description">
                        <b-input
                            type="textarea"
                            size="is-small"
                            v-model.trim="description"
                        ></b-input>
                    </b-field>
                </div>
            </section>

            <footer class="modal-card-foot">
                <button class="button" @click="$parent.close()">Cancel</button>
                <button class="button is-primary" type="button" @click="upload">
                    <span v-if="mode == 'new'">Upload</span>
                    <span v-else>Update</span>
                </button>
            </footer>
        </div>
    </div>
</template>

<script>
import ClientDetailsHelpers from "@/mixins/ClientDetailsHelpers.js";
import FeatureStateHelpers from "@/mixins/FeatureStateHelpers.js";

// import NotificationHelpers from "@/mixins/NotificationHelpers.js";
import utils from "@/mixins/utils.js";

export default {
    name: "DocUpload",
    mixins: [ClientDetailsHelpers, FeatureStateHelpers, utils],
    components: {},
    props: {
        mode: {
            type: String,
            required: true,
            validator: function(value) {
                // The value must match one of these strings
                return ["new", "edit"].indexOf(value) !== -1;
            }
        },
        fileMeta: {
            type: Object,
            default: function() {
                return {
                    id: "",
                    title: "",
                    regType: null,
                    category: "",
                    subCategory: "",
                    description: "",
                    valid: {
                        from: null,
                        to: null
                    }
                };
            }
        }
    },
    data() {
        return {
            loadingData: false,
            errorFields: [],
            errors: [],
            addNotification: false,
            registeredTitleDocTypes: [
                {
                    name: "Register",
                    value: "register"
                },
                {
                    name: "Title Plan",
                    value: "titleplan"
                }
            ],
            file: null,
            title: "",
            regType: null,
            category: "",
            subCategory: "",
            description: "",
            valid: {
                from: null,
                to: null
            }
        };
    },
    computed: {
        selectedCategory: function() {
            return this.documentConfig.cats.find(
                cat => cat.name === this.category
            );
        },
        selectedSubcategories: function() {
            if (!this.selectedCategory) {
                return [];
            }
            return this.selectedCategory.subcats;
        }
    },
    mounted() {
        // console.log("modal launched");
        // console.log(this.$parent);
        
        // console.log(this.selectedFeature);
        // console.log(this.documentConfig);
        // console.log(this.fileMeta);

        this.title = this.fileMeta.title;
        this.regType = this.fileMeta.regType;
        this.category = this.fileMeta.category;
        this.subCategory = this.fileMeta.subCategory;
        this.description = this.fileMeta.description;
        this.valid.from = this.fileMeta.valid.from
            ? new Date(this.fileMeta.valid.from)
            : null;
        this.valid.to = this.fileMeta.valid.to
            ? new Date(this.fileMeta.valid.to)
            : null;

        // console.log(this.valid.from);
        // console.log(this.valid.to);
    },
    methods: {
        upload() {
            // console.log("upload");

            this.validateForm().then(valid => {
                if (valid) {
                    this.compileFormData().then(formData => {
                        this.mode === "new"
                            ? this.submitNew(formData)
                            : this.submitUpdate(formData);
                    });
                }
            });
        },
        async validateForm() {
            this.errorFields = [];
            this.errors = [];

            if (this.mode == 'new' && !this.file) {
                this.errorFields.push("file");
                this.errors.push("File: is required");
            }

            // rt only needs a doc type
            if (this.fetchedFeatureData.siteType === "registeredtitle") {
                if (!this.regType) {
                    this.errorFields.push("regType");
                    this.errors.push("Document Type: is required");
                }
            } else {
                if (this.title === "") {
                    this.errorFields.push("title");
                    this.errors.push("Title: is required");
                }

                if (this.category === "") {
                    this.errorFields.push("category");
                    this.errors.push("Category: is required");
                }
            }

            return this.errors.length === 0;
        },
        removeErrorField(field) {
            this.errorFields = this.errorFields.filter(el => el !== field);
            this.errors = [];
        },
        async compileFormData() {
            let formData = {};
            // need to use form data as erquest will have a file
            // if(this.mode == 'new') {
                formData = new FormData();
                formData.append("file", this.file);

                if (this.fetchedFeatureData.siteType === "registeredtitle") {
                    formData.append("regType", this.regType);
                } else {
                    formData.append("title", this.title);
                    formData.append("category", this.category);
                    formData.append("subCategory", this.subCategory);
                    formData.append("description", this.description);
                    formData.append("valid", JSON.stringify(this.valid));
                }
            // } else {
                
            //     // we only need a JSON object as php doesn't like form data objects with put/patch requests
            //     // https://bugs.php.net/bug.php?id=55815
            //     if (this.fetchedFeatureData.siteType === "registeredtitle") {
            //         formData.regType = this.regType;
            //     } else {
            //         formData.title = this.title;
            //         formData.category = this.category;
            //         formData.subCategory = this.subCategory;
            //         formData.description = this.description;
            //         formData.valid = JSON.stringify(this.valid);
            //     }
            // }
            

            return formData;
        },
        submitUpdate(formData) {
            this.loadingData = true;
            let url = `document/${this.fileMeta.id}`;

            this.$ppClient
                .patch(url, formData)
                .then(response => {
                    // console.log(response.data);
                    let body = response.data.body;
                    if(body.status === 'success') {
                        this.loadingData = false;
                        this.fetchFeatureDetails(this.selectedFeature);
                        this.$parent.close();
                        this.$buefy.toast.open(
                            `Successfully Updated document: ${ this.sanitize(this.title) }`
                        );
                    } else {
                        this.loadingData = false;
                        this.errors.push('Update Failed')
                        // console.log(body.message);
                        
                    }
                    
                }).catch(error => {
                    this.loadingData = false;
                    if (error.response) {
                        // Request made and server responded
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                    } else if (error.request) {
                        // The request was made but no response was received
                        console.log("error.request");

                        console.log(error.request);
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        console.log("undefined error");

                        console.log("Error", error.message);
                    }
                });
        },
        submitNew(formData) {
            this.loadingData = true;
            let selectedPortalId = this.$store.getters[
                "mapState/selectedPortal"
            ];

            // let siteId = this.selectedFeature._site.siteId.$oid;

            // console.log("selectedFeature");
            // console.log(this.selectedFeature);
            // console.log("fetchedFeatureData");
            // console.log(this.fetchedFeatureData);

            // need to distinguish between different site types:
            // site
            // propman
            // RT
            // Geostore
            let type = this.fetchedFeatureData.siteType;

            let docId = false;
            switch (this.fetchedFeatureData.siteType) {
                case "registeredtitle":
                    docId = this.fetchedFeatureData.siteDetails["geom"][
                        "features"
                    ][0]["properties"]["Title_No"];
                    break;
                case "propman":
                    docId = this.fetchedFeatureData.siteDetails["geom"][
                        "features"
                    ][0]["properties"]["Propman"];
                    break;
                default:
                    docId = this.selectedFeature._site.siteId.$oid;
                    break;
            }

            // let url = `portal/${selectedPortalId}/site/${siteId}/document/${type}`;
            // let url = `portal/${selectedPortalId}/site/${type}/document/${docId}`;
            let url = `document/${type}/${docId}/${selectedPortalId}`;

            this.$ppClient
                .post(url, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data"
                    }
                })
                .then(response => {
                    // console.log('pre emit');
                    // console.log(response);
                    let body = response.data.body;
                    if( body.uploadedDocument ) {
                        this.loadingData = false;
                        this.fetchFeatureDetails(this.selectedFeature);
                        this.$parent.close();
                        if( this.addNotification ) {
                            this.$emit('trigger-add-notification', body.uploadedDocument);
                        }
                        
                        this.$buefy.toast.open(
                            `Successfully Uploaded document ${ this.sanitize(this.title) }`
                        );
                    } else {
                        this.loadingData = false;
                        this.errors.push('Something went wrong');
                    }
                    
                });
        }
    }
};
</script>

<style lang="scss" scoped></style>
