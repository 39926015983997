import sanitizeHtml from 'sanitize-html';

export default {
    methods: {
        mixinTest(){
          // console.log('mixin test');
          
        },
        notEmptyObject(someObject){
          return Object.keys(someObject).length > 0
        },
        camelToKebab(string){
          return string.replace(/([a-z0-9]|(?=[A-Z]))([A-Z])/g, '$1-$2').toLowerCase();
        },
        sanitize(string) {
          return sanitizeHtml(string);
        }
      }
}