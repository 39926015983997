<!-- 
@todo
This could probably be changed to a simple JS file
with the template removed as the only difference
between the polyline and multipolyine is the getLatLngs() implementation 
-->
<template>
<!-- :lat-lngs="getLatLngs(feature.coordinates)" -->
    <l-polyline
        :lat-lngs="getLatLngs(feature.coordinates)"
        :stroke="true"
        :weight="defaultStyle.weight"
        :opacity="defaultStyle.opacity"
        :color="'#FF0000'"
        :fillOpacity="defaultStyle.fillOpacity"
        :fillColor="'#FF0000'"
        @add="added"
        @click="clicked"
        @mouseover="mouseover"
        @mouseout="mouseout"
    >
        <l-tooltip v-if="displayRef">
            <div>
                <!-- <h4> {{ popup.title }} </h4> -->
                <h4> {{ displayRef }} </h4>
            </div>
        </l-tooltip>
    </l-polyline>
</template>

<script>
import { latLng } from "leaflet";
import { LPolyline, LTooltip } from "vue2-leaflet";
import MPolyline from './MPolyline.vue';
export default {
    extends: MPolyline,
    name: "MMultiPolyline",
    components: {
        LPolyline, 
        LTooltip
    },
    props: {
        // title for tooltip
        color: String,
        // polygon: Object,
        layerId: String,
        layer: Object
    },
    data() {
        return {
            // defaultStyle: {
            //     weight: 1,
            //     opacity: 1,
            //     color: this.color,
            //     fillOpacity: 0.6,
            // },
            // focusStyle: {
            //     weight: 3,
            //     opacity: 1,
            //     color: '#FFF',
            //     fillOpacity: 0.8,
            // }
        }
    },
    mounted () {
    },
    methods: {
        getLatLngs(coordinates) {
            // console.log('GLLs in multi');
            // console.log(coordinates);
            
            
            // return coordinates.map( c => [c[1], c[0]]);
            return coordinates.map(outer =>
                outer.map(inner => [inner[1], inner[0]])
            );
        }
    }
};
</script>

<style lang="scss" scoped></style>
