<template>
    <b-collapse class="card" :aria-id="dataSource" :open="false">
        <div
            slot="trigger"
            slot-scope="props"
            class="card-header"
            role="button"
            :aria-controls="dataSource"
        >
            <p class="card-header-title is-capitalized">
                {{ translateDataSource( dataSource ) }} Data
            </p>
            <a class="card-header-icon">
                <font-awesome-icon
                    :icon="
                        props.open ? ['fas', 'caret-up'] : ['fas', 'caret-down']
                    "
                />
            </a>
        </div>

        <div class="card-content">
            <div class="buttons" v-if="portalAccess < 2">
                <b-button v-if="showEditMapButton()"
                    :type="fieldMapperEnabled ? 'is-danger' : ''"
                    :title="fieldMapperEnabled ? 'Cancel' : 'Edit Field Name Mappings'"
                    @click="fieldMapperEnabled = !fieldMapperEnabled"
                >
                    <font-awesome-icon
                        :icon="fieldMapperEnabled ? 'times' : 'edit'"
                    ></font-awesome-icon>
                </b-button>
            </div>
            <div v-if="fieldMapperEnabled">
                <external-site-field-mapper 
                    @cancel-edit-field-map="fieldMapperEnabled = false" 
                    @field-map-updated="fieldMapperEnabled = false"
                    :showDisplayRef="showDisplayRef(fetchedFeatureData.siteType, dataSource)"
                    :fields="fields"
                    :siteType="fetchedFeatureData.siteType"
                    :dataSource="dataSource">
                </external-site-field-mapper>
            </div>
            <div v-else>
                <ul class="selected-feature-list">
                    <li class="feature-list-item" v-for="(value, name) in mappedFields" :key="name">
                        <strong class="is-capitalized">{{ name }}:</strong>
                        {{ value | scolon2space }}
                    </li>
                </ul>
            </div>
        </div>
    </b-collapse>
</template>

<script>
import FeatureStateHelpers from "@/mixins/FeatureStateHelpers.js";
import FieldMapHelpers from "@/mixins/FieldMapHelpers.js";
import AccessControlHelpers from "@/mixins/AccessControlHelpers.js";

import ExternalSiteFieldMapper from "@/components/sidebar/FeatureDetails/ExternalSiteFieldMapper.vue";
export default {
    name: "ExternalSiteDetails",
    mixins: [FeatureStateHelpers, FieldMapHelpers, AccessControlHelpers],
    components: { ExternalSiteFieldMapper },
    props: {
        fields: Object,
        dataSource: String
    },
    data() {
        return {
            fieldMapperEnabled: false
        };
    },
    mounted() {},
    computed: {
        mappedFields() {
            
            // console.log("externalSiteDetails");
            // console.log('mappedFields');
            
            
            
            // let mapped = {};
            // let fieldMap = this.fetchedFeatureData.fieldTranslations[ this.dataSource ];
            if ( this.fetchedFeatureData && this.fetchedFeatureData.siteType != 'internal' ) {

                
            // console.log('this.fields');
            // console.log(this.fields);
            // console.log("fetchedFeatureData on load");
            // console.log(this.fetchedFeatureData);
            // console.log('selectedFeature');
            // console.log(this.selectedFeature);
            // console.log('this.fetchedFeatureData.siteType');
            // console.log(this.fetchedFeatureData.siteType || 'not set');
            // console.log('this.dataSource');
            // console.log(this.dataSource);



                // let fieldMap = this.fetchedFeatureFieldTranslations[this.fetchedFeatureData.siteType][this.dataSource];
                // let fieldMap = this.fieldTranslation[this.fetchedFeatureData.siteType][this.dataSource]
                let fieldMap = this.fieldMapFor(this.fetchedFeatureData.siteType, this.dataSource);
                // fieldMapHelper
                return this.mapFieldsIn(fieldMap, this.fields);

                // for (let field in this.fields) {
                //     // console.log(`${field}: ${this.fields[field]}`);

                //     let fieldMatchAtt = field.toLowerCase();
                //     // there is an entry in the field map
                //     if ( fieldMap[fieldMatchAtt] ) {
                //         // console.log(`\t mapped to => ${fieldMap[fieldMatchAtt].name}`);
                //         // 
                //         if (fieldMap[fieldMatchAtt].show) {
                //             // name should be transformed
                //             if( fieldMap[fieldMatchAtt].name ) {
                //                 mapped[ fieldMap[fieldMatchAtt].name ] = this.fields[
                //                     field
                //                 ];
                //             }else {
                //                 mapped[field] = this.fields[field];
                //             }
                            
                //         }
                //     } else {
                //         // no entry in the field map so display as-is
                //         mapped[field] = this.fields[field];
                //     }
                // }
                // // return this.fields;
                // // console.log('mapped');
                // // console.log(mapped);
                // return mapped;
            } else {
                return this.fields
            }
        }
    },
    methods: {
        translateDataSource( dataSource ) {
            return dataSource == 'geostore' ? 'FG Managed' : dataSource;
        },
        showEditMapButton() {
            // data couldn't be joined to gs data and so no fields to populate mapper
            if( !this.fetchedFeatureData || (this.fetchedFeatureData.siteType == 'propman' && this.mappedFields.error) ) {
                return false;
            }
            
            // if field mapper is enabled, it has it's own buttons
            return !this.fieldMapperEnabled 
        },
        showDisplayRef(siteType, dataSource) {
            
            // only show for propman datasource 
            if(dataSource === 'propman') {
                return siteType === 'propman';
            }
            
            // geostore siteType may have rt and gs datasource
            if( dataSource === 'geostore') {
                return ['registeredtitle', 'geostore'].includes(siteType)
            }
            
            return false;
        }
    }
};
</script>

<style lang="scss" scoped>
// li.feature-list-item{
//     white-space: pre;
// }
</style>
