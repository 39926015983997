<template>
    <!-- <div class="summary-legend" v-html="compiled"></div> -->
    <div class="summary-legend columns has-margin-top-15">
        <div class="column" v-if="dataset">
            <b-collapse
                class="card"
                :aria-id="`chart-legend-${chart.id}`"
                :open="false"
            >
                <div
                    slot="trigger"
                    slot-scope="props"
                    class="card-header"
                    role="button"
                    :aria-controls="`chart-legend-${chart.id}`"
                >
                    <p class="card-header-title is-capitalized">
                        {{ title }} Legend
                    </p>
                    <a class="card-header-icon">
                        <font-awesome-icon
                            :icon="
                                props.open
                                    ? ['fas', 'caret-up']
                                    : ['fas', 'caret-down']
                            "
                        />
                    </a>
                </div>

                <div class="card-content">
                    <b-table
                        class="trans-table"
                        :data="datasetObjArr"
                        checkable
                        :checked-rows.sync="checkedRows"
                        @check="handleCheck"
                    >
                        <!--  -->
                        <template slot-scope="props">
                            <!-- <b-table-column field="index" label="Index">
                                {{ props.index }}
                            </b-table-column> -->

                            <b-table-column field="colour" label="Colour" :style="`background-color: ${dataset.backgroundColor[props.index]}`">
                                <span class="chart-legend" :style="`background-color: ${dataset.backgroundColor[props.index]}`"></span>
                                <!-- {{dataset.backgroundColor[props.index]}} -->
                                
                            </b-table-column>

                            <b-table-column field="label" label="Label">
                                {{ chart.data.labels[props.index] }}
                            </b-table-column>

                            <b-table-column field="count" label="Count">
                                {{ props.row.count }}
                            </b-table-column>
                            
                        </template>
                    </b-table>
                </div>
            </b-collapse>
        </div>
    </div>
</template>

<script>
import uuidv1 from "uuid/v1";

export default {
    name: "PSummaryLegend",
    components: {},
    props: {
        chart: Object,
        title: String
    },
    data() {
        return {
            // compiled: false,
            // dataset: false,
            // datasetObjArr: false,
            hidden: [],
            checkedRows: []
        };
    },
    computed: {
        dataset: function() {
            return this.chart.data.datasets[0];
        },
        datasetObjArr: function() {
            // once the dataset has been loaded, objectify it
            // as we need a uuid for showing and hiding data
            return this.dataset ? this.dataset.data.map(d => {
                let obj = {
                    id: uuidv1(),
                    count: d
                };
                return obj;
            }) : false;
        }
    },
    watch: {
        // wait for the datasetObjArr to be set
        datasetObjArr: function() {
            this.checkedRows = this.datasetObjArr ? [...this.datasetObjArr] : [];
        }
    },
    mounted() {},
    methods: {
        // arr is the newly checked rows
        // row doesn't get passed when the header box is (un)checked
        handleCheck(arr, row) {
            // header checkbox (un)checked
            if( !row ) {
                // unchecked
                if ( arr.length === 0 ) {
                    this.hideAll()
                // checked
                } else {
                    this.showAll();
                }
            } else {
                let i = this.datasetObjArr.findIndex( e => e.id === row.id);
                if(this.hidden.includes(i)) {
                    this.showItem(i);
                }else{
                    this.hideItem(i);
                }
            }            
        },
        showItem(i) {
            var index = this.hidden.indexOf(i);
            if (index > -1) {
                this.hidden.splice(index, 1);
                // this.chart.legend.legendItems[i].hidden = false;
                this.chart.getDatasetMeta(0).data[i].hidden = false;
                this.chart.update();
            }
        },
        showAll() {
            for (const i in this.chart.getDatasetMeta(0).data) {
                this.showItem( parseInt(i) );
            }
        },
        hideItem(i) {            
            if( !this.hidden.includes(i) ) {
                this.hidden.push(i);
                
                // this.chart.legend.legendItems[i].hidden = true;
                this.chart.getDatasetMeta(0).data[i].hidden = true;                
                this.chart.update();
            }
        },
        hideAll() {
            for (let i in this.chart.getDatasetMeta(0).data) {
                this.hideItem( parseInt(i) );
            }
        },
    }
};
</script>

<style lang="scss">
.summary-legend {
    font-family: Roboto;
    display: inline-block;

    ul {
        list-style: none;
    }

    li {
        display: flex;
        align-items: center;
        margin-bottom: 4px;

        span {
            display: inline-block;
        }

        span.chart-legend {
            width: 25px;
            height: 25px;
            margin-right: 10px;
        }

        span.chart-legend-label-text {
            &.hidden-item{
                text-decoration: line-through;
            }
        }
    }
}
</style>
