<template>
    <div>
        <loading-notification :is-loading="isLoading"></loading-notification>
        <sidebar-header
            :headerTitle="`${clientName || 'Loading'} Dashboard`"
        ></sidebar-header>

        <span v-if="clientPrivacyPolicy">
            <center>
                <a :href="clientPrivacyPolicy" target="_blank">Privacy Policy</a>
            </center>
            <br />
        </span>

        <session-expiration />

        <!-- <div id="map-details-client-logo" v-show="!isLoading">
            <img
                v-if="clientLogo"
                :src="`data:image/png;base64,${clientLogo}`"
                :alt="`${clientName} logo`"
                height="90"
                max-width="160"
            />
            <img
                v-else
                src="https://via.placeholder.com/160x90?text=Logo Missing for Client"
                :alt="`${clientName} logo missing`"
            />
        </div> -->

        <property-search />
        <br />
        <h6 class="title is-6 header-with-button">
            Notifications
            <b-button
                type=""
                title="Add a portal notification"
                @click="launchNewNotificationModal"
                class="has-margin-left-10"
            >
                <font-awesome-icon :icon="['fas', 'plus']" />
            </b-button>
        </h6>
        <div>
            <b-collapse
                class="card"
                aria-id="map-details-last-clicked"
                :open="true"
            >
                <div
                    slot="trigger"
                    slot-scope="props"
                    class="card-header"
                    role="button"
                    aria-controls="map-details-last-clicked"
                >
                    <p class="card-header-title is-capitalized">
                        <font-awesome-icon
                            v-if="upcomingNotifications.length > 0"
                            icon="exclamation-triangle"
                            class="has-margin-right-10"
                        />

                        Upcoming Notifications
                    </p>
                    <a class="card-header-icon">
                        <font-awesome-icon
                            :icon="
                                props.open
                                    ? ['fas', 'caret-up']
                                    : ['fas', 'caret-down']
                            "
                        />
                    </a>
                </div>

                <div class="card-content">
                    <b-table
                        v-if="upcomingNotifications.length > 0"
                        :data="upcomingNotifications"
                    >
                        <template slot-scope="props">
                            <b-table-column field="date" label="Date">
                                {{
                                    new Date(
                                        props.row.notificationDate
                                    ).toLocaleDateString()
                                }}
                            </b-table-column>

                            <b-table-column field="title" label="Title">
                                {{ props.row.title }}
                            </b-table-column>

                            <b-table-column
                                centered
                                field="show-notification"
                                label="Show"
                            >
                                <b-button
                                    @click="
                                        showInNotificationsTab(props.row)
                                    "
                                    title="reveal in notifications tab"
                                >
                                    <font-awesome-icon
                                        :icon="['fas', 'comment-lines']"
                                        :color="$styles.fgBlue"
                                    />
                                </b-button>
                            </b-table-column>
                        </template>
                    </b-table>

                    <div v-else>
                        No Notifications.
                    </div>
                </div>
            </b-collapse>
        </div>

        <div v-if="isVisible">
            <property-summaries></property-summaries>
        </div>

        <br />

        <h6 class="title is-6">Navigation</h6>
        <div>
            <b-collapse
                class="card"
                aria-id="map-details-last-clicked"
                :open="true"
            >
                <div
                    slot="trigger"
                    slot-scope="props"
                    class="card-header"
                    role="button"
                    aria-controls="map-details-last-clicked"
                >
                    <p class="card-header-title is-capitalized">
                        Last clicked details
                    </p>
                    <a class="card-header-icon">
                        <font-awesome-icon
                            :icon="
                                props.open
                                    ? ['fas', 'caret-up']
                                    : ['fas', 'caret-down']
                            "
                        />
                    </a>
                </div>

                <div class="card-content">
                    <br />
                    <div v-if="lastClicked">
                        <div class="buttons">
                            <b-button
                                type=""
                                @click="recentreOnLastClicked"
                                title="recentre map on last clicked coordinates"
                            >
                                <font-awesome-icon
                                    :icon="'eye'"
                                ></font-awesome-icon>
                            </b-button>
                        </div>
                        <ul class="carded-list">
                            <li>
                                <strong>Latitude: </strong>
                                {{ lastClicked.coordinates.lat }}
                            </li>
                            <li>
                                <strong>Longitude: </strong>
                                {{ lastClicked.coordinates.lng }}
                            </li>
                            <li>
                                <strong>What3Words: </strong>
                                <a :href="lastClicked.map" target="_blank">
                                    {{ lastClicked.words }}
                                </a>
                            </li>
                            <li>
                                <strong>Nearest Place: </strong>
                                {{ lastClicked.nearestPlace }}
                            </li>
                        </ul>
                    </div>

                    <div v-else>
                        Click somewhere on the map to see details.
                    </div>
                </div>
            </b-collapse>
        </div>

        <!-- <div>
            <p>
                <b-field label="Zoom:" class="nested-label-field">
                    <b-input
                        v-model="zoom"
                        size="is-small"
                        type="number"
                        step="1"
                        min="1"
                    >
                    </b-input>
                </b-field>
            </p>
            <br />
            <p>
                <b-field grouped group-multiline horizontal class="empty-label">
                    <b-field label="Latitude" class="nested-label-field">
                        <b-input
                            v-model="latitude"
                            size="is-small"
                            type="number"
                            placeholder="Latitude"
                            step="any"
                        ></b-input>
                    </b-field>
                    <b-field label="Longitude" class="nested-label-field">
                        <b-input
                            v-model="longitude"
                            size="is-small"
                            type="number"
                            placeholder="Longitude"
                            step="any"
                        ></b-input>
                    </b-field>
                </b-field>
            </p>
        </div> -->
    </div>
</template>

<script>
// convenience getters from vuex
// import { mapGetters } from 'vuex';
import ClientDetailsHelpers from "@/mixins/ClientDetailsHelpers.js";
import MapStateHelpers from "@/mixins/MapStateHelpers.js";
import NotificationHelpers from "@/mixins/NotificationHelpers.js";

import LoadingNotification from "@/components/notifications/LoadingNotification.vue";
import SidebarHeader from "@/components/sidebar/SidebarHeader.vue";
import PropertySummaries from "@/components/sidebar/PropertySummaries.vue";
import PropertySearch from "@/components/forms/propertySearch/PropertySearch.vue";

import SessionExpiration from "@/components/authentication/SessionExpiration.vue";

// import NotificationConfigModal from "@/components/modals/NotificationConfigModal.vue";

export default {
    name: "Dashboard",
    mixins: [
        ClientDetailsHelpers, 
        MapStateHelpers, 
        NotificationHelpers
    ],
    components: {
        LoadingNotification,
        SidebarHeader,
        PropertySummaries,
        PropertySearch,
        SessionExpiration,
        // NotificationConfigModal
    },
    props: {
        isLoading: Boolean
    },
    data() {
        return {
            tabName: "home",
            isVisible: false
        };
    },
    computed: {
        currentTab: {
            get() {
                return this.$store.getters["sidebarState/currentTab"];
            },
            set(newVal) {
                this.$store.commit("sidebarState/setCurrentTab", newVal);
            }
        },
        upcomingNotifications: function() {
            // console.log(this.notifications);
            
            return this.notifications.length > 10 ? this.notifications.slice(0, 10) : this.notifications;
        }
        // ...mapGetters({
        //     zoom: 'mapState/zoom',
        //     latitude: 'mapState/latitude',
        //     longitude: 'mapState/longitude',
        // }),
        // zoom: {
        //     get() {
        //         return this.$store.getters["mapState/zoom"];
        //     },
        //     set(newVal) {
        //         this.$store.commit("mapState/setZoom", Number(newVal));
        //     }
        // },
        // latitude: {
        //     get() {
        //         return this.$store.getters["mapState/latitude"];
        //     },
        //     set(newVal) {
        //         this.$store.commit("mapState/setLatitude", newVal);
        //     }
        // },
        // longitude: {
        //     get() {
        //         return this.$store.getters["mapState/longitude"];
        //     },
        //     set(newVal) {
        //         this.$store.commit("mapState/setLongitude", newVal);
        //     }
        // }
    },
    mounted() {
        // console.log("dash mounted");
        // console.log(NotificationHelpers);
        
        // console.log(this.notifications);
    },
    watch: {
        currentTab: "setVisibility"
    },
    methods: {
        setVisibility() {
            // console.log(this.currentTab);
            this.isVisible = this.currentTab === this.tabName;
        },
        recentreOnLastClicked() {
            // console.log(this.lastClicked);

            this.latitude = this.lastClicked.coordinates.lat;
            this.longitude = this.lastClicked.coordinates.lng;
        },
        launchNewNotificationModal() {
            // console.log('launch modal');
            // console.log(this.selectedPortal);
            let props = {
                mode: "new",
                notificationType: "portal",
                portalId: this.selectedPortal
            };
            this.launchNotificationModal(props);
        },
        launchEditNotificationModal(notification) {
            let props = {
                mode: "edit",
                notificationType: notification.type,
                passedNotification: notification
            };
        },
        showInNotificationsTab( notification ) {
            this.$eventHub.$emit('display-notification-in-tab', notification);
            let sidebar = this.$store.getters["sidebarState/sidebar"];
            // sidebar.enablePanel(tabId);
            sidebar.open('user-notifications');
        }
        // launchNotificationModal(props) {
        //     this.$buefy.modal.open({
        //         component: NotificationConfigModal,
        //         props: props,
        //         parent: this,
        //         events: {
        //             // "user-added": user => {
        //             //     // console.log('user added:');
        //             //     // console.log(user)
        //             //     this.users.push(user);
        //             // }
        //         }
        //     });
        // }
    }
};
</script>

<style lang="scss">

ul.carded-list {
    font-size: 1.2em;
    list-style-type: none;
    ul {
        list-style-position: inside;
        list-style-type: disc;
    }
}
</style>
