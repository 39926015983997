<template>
    <div>
        <b-collapse class="card" :aria-id="dataSource">
            <div
                slot="trigger"
                slot-scope="props"
                class="card-header"
                role="button"
                :aria-controls="dataSource"
            >
                <p class="card-header-title is-capitalized">
                    {{ dataSource }} Data
                </p>
                <a class="card-header-icon">
                    <font-awesome-icon
                        :icon="
                            props.open
                                ? ['fas', 'caret-up']
                                : ['fas', 'caret-down']
                        "
                    />
                </a>
            </div>

            <div class="card-content">
                <b-loading
                    :is-full-page="false"
                    :active.sync="loadingData"
                    :can-cancel="false"
                >
                    <font-awesome-icon
                        :icon="['fad', 'sync']"
                        :style="$iconStyle"
                        size="4x"
                        spin
                    />
                </b-loading>
                <div class="buttons" v-if="portalAccess < 3">
                    <b-button
                        type=""
                        @click="toggleInternalDataEditable"
                        title="Edit Portal Data for Site"
                    >
                        <font-awesome-icon
                            :icon="internalDataEditable ? 'lock' : 'edit'"
                        />
                    </b-button>

                    <b-button
                        type=""
                        @click="submit"
                        title="Save Portal Data for Site"
                        :disabled="!internalDataEditable"
                    >
                        <font-awesome-icon :icon="'save'" />
                    </b-button>

                    <b-button
                        type="is-danger"
                        @click="confirmDeleteSite"
                        title="Delete this site and all data"
                        :disabled="!internalDataEditable"
                    >
                        <font-awesome-icon :icon="'trash'" />
                    </b-button>
                </div>

                <!-- only show a list if the data isn't editable -->
                <div v-if="!internalDataEditable">
                    <ul class="selected-feature-list">
                        <!-- v-if="featureSelected" -->
                        <li v-for="field in fields" :key="field.id">
                            <strong class="is-capitalized"
                                >{{ field.name }}:
                            </strong>
                            <span v-if="field.type == 'date'">
                                {{ formatDateField(field.value) }}
                            </span>
                            <span v-else-if="field.type == 'checkbox'">
                                {{ field.value === true ? "Yes" : "No" }}
                            </span>
                            <span v-else style="white-space: pre-line">
                                <br v-if="field.type == 'multi'" />
                                {{ field.value }}
                            </span>
                        </li>
                    </ul>
                </div>

                <div
                    v-else
                    v-for="field in fields"
                    :key="field.id"
                    class="has-margin-top-5 has-margin-bottom-5"
                >
                    <b-field
                        v-if="field.type === 'checkbox'"
                        class="nested-label-field"
                    >
                        <b-switch
                            v-model="field.value"
                            :disabled="!internalDataEditable"
                        >
                            <!-- <strong>{{ field.name }}</strong> -->
                            <label
                                class="label is-capitalized has-margin-top-5 has-margin-bottom-5"
                                >{{ getFieldLabel(field) }}</label
                            >
                        </b-switch>
                    </b-field>

                    <b-field
                        v-else
                        :label="getFieldLabel(field)"
                        class="nested-label-field is-capitalized"
                        :type="
                            errorFields.includes(field.id) ? 'is-danger' : ''
                        "
                        :message="
                            errorFields.includes(field.id)
                                ? getFieldErrorString(field)
                                : ''
                        "
                    >
                        <!-- :type="
                                        errorFields.includes('portalName')
                                            ? 'is-danger'
                                            : ''
                                    " -->
                        <b-input
                            v-if="field.type === 'string'"
                            size="is-small"
                            :maxlength="field.length"
                            v-model.trim="field.value"
                            :disabled="!internalDataEditable"
                        >
                        </b-input>

                        <b-input
                            v-if="field.type === 'number'"
                            type="number"
                            step="any"
                            size="is-small"
                            v-model.trim="field.value"
                            :disabled="!internalDataEditable"
                        >
                        </b-input>

                        <b-input
                            v-if="field.type === 'multi'"
                            type="textarea"
                            size="is-small"
                            v-model.trim="field.value"
                            :disabled="!internalDataEditable"
                        >
                        </b-input>

                        <b-select
                            v-if="field.type === 'select'"
                            placeholder="Select a name"
                            size="is-small"
                            v-model="field.value"
                            :disabled="!internalDataEditable"
                        >
                            <option
                                v-for="option in field.options"
                                :value="option.text"
                                :key="option.text"
                            >
                                {{ option.text }}
                            </option>
                            <!-- <option
                                            v-for='option in [{text: "a"}, {text: "b"}, {text: "c"}]'
                                            :value="option.text"
                                            :key="option.text">
                                            {{option.text}}
                                        </option> -->
                        </b-select>
                        <!-- v-model.trim="portalName" -->
                        <!-- @keyup.native="removeErrorField('portalName')" -->

                        <b-field
                            v-if="field.type === 'date'"
                            :type="
                                errorFields.includes(field.id)
                                    ? 'is-danger'
                                    : ''
                            "
                        >
                            <b-datepicker
                                :value="
                                    field.value ? new Date(field.value) : null
                                "
                                @input="dateChanged($event, field)"
                                size="is-small"
                                placeholder="Click to select..."
                                icon="calendar"
                                :years-range="[-50, 50]"
                                :disabled="!internalDataEditable"
                            >
                            </b-datepicker>
                            <p class="control">
                                <b-button
                                    size="is-small"
                                    type="is-danger"
                                    @click="field.value = null"
                                >
                                    Clear
                                </b-button>
                            </p>
                        </b-field>

                        <!-- :date-parser="dateFieldFormatter" -->
                        <!-- v-model="field.value" -->
                    </b-field>
                </div>
            </div>
        </b-collapse>
    </div>
</template>
<script>
import FeatureStateHelpers from "@/mixins/FeatureStateHelpers.js";
import LayerStateHelpers from "@/mixins/LayerStateHelpers.js";
import AccessControlHelpers from "@/mixins/AccessControlHelpers.js";
// import FieldMapHelpers from "@/mixins/FieldMapHelpers.js";
export default {
    name: "ExternalSiteDetails",
    mixins: [FeatureStateHelpers, LayerStateHelpers, AccessControlHelpers],
    components: {},
    props: {
        fields: Array,
        dataSource: String
    },
    data() {
        return {
            loadingData: false,
            internalDataEditable: false,
            errors: {}
        };
    },
    mounted() {
        // console.log('internalSiteDetails');
        // console.log(this.portalAccess);
        // console.log(this.fields);
    },
    computed: {
        errorFields: function() {
            return Object.keys(this.errors);
        }
    },
    watch: {
        selectedFeature: function() {
            this.internalDataEditable = false;
        }
    },
    methods: {
        getFieldLabel(field) {
            let label = field.name;

            if (field.required) {
                label = label + " *";
            }

            return label;
        },
        getFieldErrorString(field) {
            return this.errors[field.id].join(", ");
        },
        dateChanged(newDate, field) {
            field.value = newDate.toISOString();
        },
        formatDateField(dateStringVal) {
            if (!dateStringVal || dateStringVal == "") {
                return "Not Set";
            }
            return new Date(dateStringVal).toLocaleDateString("en-GB", {
                weekday: "long",
                year: "numeric",
                month: "long",
                day: "numeric"
            });
        },
        toggleInternalDataEditable() {
            this.internalDataEditable = !this.internalDataEditable;
            // console.log(this.internalDataEditable);
        },
        submit() {
            this.validateForm().then(
                valid => {
                    if (valid) {
                        this.updateSiteData();
                    }
                },
                error => {
                    console.log("error in validateForm promise");
                    console.log(error);
                }
            );
        },

        async validateForm() {
            // console.log("validate");
            // console.log(this.fields);
            // reset errors for validation pass
            // this.errorFields = [];
            this.errors = {};

            // console.log(this.selectedFeatureFields);
            for (let field of this.selectedFeatureFields.portal) {
                // console.log(field.name);
                // console.log(field.required);
                // console.log(field.value);

                if (field.required) {
                    if (field.value === "") {
                        if (!this.errorFields.includes(field.id)) {
                            // reactive set object property
                            this.$set(this.errors, field.id, ["Required"]);
                        } else {
                            this.errors[field.id].push("Required");
                        }
                    }

                    // max-length set on the field, not needed
                    // if( field.length && (field.value.length > field.length) ) {
                    //     if( !this.errorFields.includes(field.id) ) {
                    //         // reactive set object property
                    //         this.$set(this.errors, field.id, [`Max length: ${field.length}`]);
                    //     } else {
                    //         this.errors[field.id].push(`Max length: ${field.length}`);
                    //     }
                    // }
                }
            }

            // console.log(this.errors);
            // console.log(this.errorFields);

            return this.errorFields.length == 0;
        },

        updateSiteData() {
            // console.log("save data");

            // console.log(this.selectedFeature);
            // console.log(this.selectedFeatureFields);

            this.loadingData = true;

            // @todo validate data
            let siteId = this.selectedFeature._site.siteId.$oid;
            let url = `/site/${siteId}/data`;

            this.$ppClient
                .patch(url, {
                    fields: this.selectedFeatureFields
                })
                .then(response => {
                    // console.log(response.data);

                    let body = response.data.body;

                    if (body.status === "success") {
                        this.loadingData = false;
                        // emit that the portal data was updated
                        this.$eventHub.$emit("portal-site-data-updated", {
                            siteId: siteId,
                            updatedFields: this.selectedFeatureFields.portal
                        });
                        // disable fields again
                        this.internalDataEditable = false;
                        // give user feedback
                        this.$buefy.toast.open(
                            `Successfully Updated Site Data`
                        );
                    } else {
                        this.loadingData = false;
                        this.$buefy.toast.open(
                            `Something went wrong: please try again`
                        );
                    }
                })
                .catch(error => {
                    this.loadingData = false;
                    if (error.response) {
                        // Request made and server responded
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);

                        let errors = error.response.data.errors;
                        if (errors) {
                            for (let key in errors) {
                                // add affected field
                                this.errorFields.push(key);
                                // merge errors
                                this.errors = [...this.errors, ...errors[key]];
                            }
                        } else {
                            this.errors.push(
                                "Something went wrong. Please try again"
                            );
                        }
                    } else if (error.request) {
                        // The request was made but no response was received
                        console.log("error.request");
                        console.log(error.request);
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        console.log("undefined error");

                        console.log("Error", error.message);
                        this.errors.push(
                            "Something went wrong. Please try again"
                        );
                    }
                });
        },
        confirmDeleteSite() {
            // console.log(this.selectedFeature);
            // console.log(this.selectedLayer);

            let message = `<p>Are you sure you want to delete this site?:</p>
                <br>
                <p>This will remove all associated data.</p>
                <br>
                <p><strong>This action cannot be undone</strong></p>`;

            this.$buefy.dialog.confirm({
                message: message,
                type: "is-danger",
                hasIcon: true,
                icon: `exclamation-circle`,
                onConfirm: () => this.deleteSite()
            });
        },
        deleteSite() {
            this.loadingData = true;
            let siteId = this.selectedFeature._site.siteId.$oid;
            let url = `/site/${siteId}`;

            this.$ppClient
                .delete(url)
                .then(response => {
                    let body = response.data.body;
                    if (body.deleteSuccess) {
                        this.loadingData = false;
                        this.$buefy.toast.open({
                            message: `Site Deleted`
                        });
                        //  find the index of the site/geometry rater than the objects
                        // because we need to splice the array to remove
                        let siteIndex = this.selectedLayer.sites.findIndex(
                            site =>
                                site._id.$oid ===
                                this.selectedFeature._site.siteId.$oid
                        );
                        let geoIndex = this.selectedLayer.geometry[
                            this.selectedFeature._site.type
                        ].findIndex(
                            geo =>
                                geo.siteId.$oid ===
                                this.selectedFeature._site.siteId.$oid
                        );

                        // change the tab to the site list
                        let sidebar = this.$store.getters[
                            "sidebarState/sidebar"
                        ].open("layer-details");

                        // remove the site data
                        this.selectedLayer.sites.splice(siteIndex, 1);
                        // remove the geometry
                        this.selectedLayer.geometry[
                            this.selectedFeature._site.type
                        ].splice(geoIndex, 1);
                        // unset the selected feature
                        this.selectedFeature = null;

                        this.$eventHub.$emit("site-deleted", siteId);
                    } else {
                        this.loadingData = false;
                        this.$buefy.toast.open({
                            type: "is-danger",
                            message: `Something went wrong: please try again`
                        });
                    }
                })
                .catch(error => {
                    this.loadingData = false;
                    this.$buefy.toast.open({
                        type: "is-danger",
                        message: `Something went wrong: please try again`
                    });
                    if (error.response) {
                        // Request made and server responded
                        console.log("error.response");

                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                    } else if (error.request) {
                        // The request was made but no response was received
                        console.log("error.request");
                        console.log(error.request);
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        console.log("undefined error");
                        console.log("Error", error.message);
                    }
                });
        }
    }
};
</script>

<style lang="scss" scoped></style>
