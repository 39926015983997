<template>
    <div id="contact-config-modal">
        <b-loading
            :is-full-page="false"
            :active.sync="loadingData"
            :can-cancel="false"
        >
            <font-awesome-icon
                :icon="['fad', 'sync']"
                :style="$iconStyle"
                size="4x"
                spin
            />
        </b-loading>

        <div class="modal-card" style="width: auto">
            <header class="modal-card-head">
                <p class="modal-card-title is-capitalized">
                    {{ mode }} Contact
                </p>

                <span v-if="mode === 'edit'" class="has-text-right">
                    <div class="buttons">
                        <b-button
                            type="is-danger"
                            @click="confirmDeleteContact"
                        >
                            Delete Contact
                        </b-button>
                    </div>
                </span>
            </header>

            <section class="modal-card-body">
                <section>
                    <b-notification
                        type="is-danger"
                        class="margin-bottom-15"
                        role="alert"
                        v-if="errors.length > 0"
                        aria-close-label="Close notification"
                    >
                        <ul>
                            <li v-for="(error, i) in errors" :key="i">
                                {{ error }}
                            </li>
                        </ul>
                    </b-notification>
                </section>

                <b-tabs
                    v-model="contactTypeTab"
                    type="is-boxed"
                    id="user-type-tabs"
                >
                    <b-tab-item
                        label="Add user as contact"
                        :disabled="contactTypeTabDisabled('user')"
                    >
                        <user-contacts
                            v-if="
                                mode == 'new' ||
                                    (mode == 'edit' &&
                                        contact.contactType == 'user')
                            "
                            :mode="mode"
                            :contact="mode == 'edit' ? contact : null"
                            :error-fields="errorFields"
                            :selectedTab="contactTypeTab"
                            @remove-error-field="removeErrorField"
                            @user-contact-id-changed="updateContactUserId"
                            @user-contact-description-changed="
                                updateContactDescription
                            "
                        />
                    </b-tab-item>

                    <b-tab-item
                        label="Add non-user contact"
                        :disabled="contactTypeTabDisabled('nonUser')"
                    >
                        <non-user-contacts
                            v-if="
                                mode == 'new' ||
                                    (mode == 'edit' &&
                                        contact.contactType == 'nonUser')
                            "
                            :mode="mode"
                            :passed-contact="mode == 'edit' ? contact : null"
                            :error-fields="errorFields"
                            :selectedTab="contactTypeTab"
                            @remove-error-field="removeErrorField"
                            @non-user-contact-updated="updateContactData"
                        />
                    </b-tab-item>
                </b-tabs>
            </section>

            <footer class="modal-card-foot">
                <button class="button" @click="$parent.close()">Cancel</button>

                <button
                    class="button is-success"
                    type="button"
                    @click="confirm"
                >
                    Confirm
                </button>
            </footer>
        </div>
    </div>
</template>

<script>
import ClientDetailsHelpers from "@/mixins/ClientDetailsHelpers.js";

import UserContacts from "@/components/forms/contacts/UserContacts.vue";
import NonUserContacts from "@/components/forms/contacts/NonUserContacts.vue";
import { LMarker } from "vue2-leaflet";

export default {
    name: "ContactConfigModal",
    mixins: [ClientDetailsHelpers],
    components: {
        UserContacts,
        NonUserContacts
    },
    props: {
        mode: {
            type: String,
            required: true,
            validator: function(value) {
                // The value must match one of these strings
                return ["new", "edit"].indexOf(value) !== -1;
            }
        },
        contact: Object
        // contactData: {
        //     type: Object,
        //     default: function() {
        //         return {
        //             _id: null,
        //             userType: null,
        //             contactType: "",
        //             description: "",
        //             name: "",
        //             email: "",
        //             phone: "",
        //             mobile: ""
        //         };
        //     }
        // },
    },
    data() {
        return {
            loadingData: false,
            contactTypeTab: 0,
            errorFields: [],
            errors: [],
            contactTypes: ["user", "nonUser"],
            contactData: {
                _id: null,
                userType: null,
                contactType: "",
                description: "",
                name: "",
                email: "",
                phone: "",
                mobile: ""
            }
        };
    },
    computed: {
        contactType: function() {
            return this.contactTypes[this.contactTypeTab];
        }
    },
    watch: {
        contactTypeTab: function() {
            this.errors = [];
            this.errorFields = [];
            this.reinitContactData();

            this.contactData.contactType = this.contactType;
            // clear contactData??
        }
    },
    mounted() {
        // console.log(this.contact);
        if (this.mode == "edit") {
            this.contactTypeTab = this.contactTypes.indexOf(
                this.contact.contactType
            );
            this.contactData = this.contact;
        }
    },
    methods: {
        contactTypeTabDisabled(contactType) {
            return (
                this.mode === "edit" &&
                this.contactData.contactType !== contactType
            );
        },
        reinitContactData() {
            this.contactData = {
                _id: null,
                userId: null,
                userType: null,
                contactType: "",
                description: "",
                name: "",
                email: "",
                phone: "",
                mobile: ""
            };
        },
        removeErrorField(field) {
            this.errorFields = this.errorFields.filter(el => el !== field);
            this.errors = [];
        },
        updateContactUserId(user) {
            this.removeErrorField("description");
            this.contactData.userId = user.userId;
            this.contactData.userType = user.userType;
            // console.log(this.contactData);
        },
        updateContactDescription(description) {
            this.contactData.description = description;
            // console.log(this.contactData);
        },
        updateContactData(contact) {
            this.contactData.userId = null;
            this.contactData.userType = null;

            this.contactData.name = contact.name;
            this.contactData.description = contact.description;
            this.contactData.email = contact.email;
            this.contactData.phone = contact.phone;
            this.contactData.mobile = contact.mobile;
            // console.log(this.contactData);
        },
        confirmDeleteContact() {
            let message = `<p>Remove Contact Details for ${this.contact.name}?</p>
                <p><strong>This action cannot be undone</strong></p>`;

            this.$buefy.dialog.confirm({
                message: message,
                type: "is-danger",
                hasIcon: true,
                icon: `exclamation-circle`,
                onConfirm: () => this.deleteContact(this.contact._id.$oid)
            });
        },
        deleteContact(contactId) {
            this.loadingData = true;
            let url = `contacts/${contactId}`;
            this.$ppClient
                .delete(url)
                .then(response => {
                    let body = response.data.body;
                    if (body.contactDeleted) {
                        this.loadingData = false;
                        this.$emit("contact-deleted", contactId);
                        this.$parent.close();
                    } else {
                        this.loadingData = false;
                        this.errors.push("Something went wrong");
                    }
                })
                .catch(error => {
                    this.loadingData = false;
                    this.errors.push("Something went wrong");
                });
        },
        confirm() {
            this.validateForm().then(valid => {
                if (valid) {
                    this.compileFormData().then(
                        formData => {
                            // console.log("formData");
                            // console.log(formData);
                            // console.log(this.camelToKebab(this.userType));

                            this.mode === "new"
                                ? this.submitNew(formData)
                                : this.submitUpdate(formData);
                        },
                        error => {
                            console.log("error in compileFormData");
                            console.log(error);
                        }
                    );
                }
            });
        },
        async validateForm() {
            if (this.contactType == "user") {
                // make sure a user has been selected
                if (this.contactData.userId == null) {
                    this.errorFields.push("user");
                    this.errors.push("please select a user");
                }
                // job title will be used for FG users
                if (this.contactData.userType == "ext") {
                    if (this.contactData.description == "") {
                        this.errorFields.push("description");
                        this.errors.push(
                            "A description is required for a contact (who are they)"
                        );
                    }
                }
            } else {
                // name and description required
                if (this.contactData.name == "") {
                    this.errorFields.push("name");
                    this.errors.push("Name is required");
                }

                if (this.contactData.description == "") {
                    this.errorFields.push("description");
                    this.errors.push(
                        "A description is required for a contact (who are they)"
                    );
                }

                // at least one form of contact detail erquired
                let contactFields = ["email", "phone", "mobile"];
                let contactFieldCount = 0;

                for (let field of contactFields) {
                    if (this.contactData[field] != "") {
                        contactFieldCount++;
                    }
                }

                if (contactFieldCount == 0) {
                    this.errorFields.push("contactField");
                    this.errors.push(
                        "At least one form of contact information needs adding"
                    );
                }
            }

            return this.errors.length === 0;
        },
        async compileFormData() {
            let formData = {};
            formData.contactType = this.contactType;
            if (this.contactType == "user") {
                formData.userId = this.contactData.userId;
                formData.userType = this.contactData.userType;
                if (this.contactData.userType == "ext") {
                    formData.description = this.contactData.description;
                }
            } else {
                formData.name = this.contactData.name;
                formData.description = this.contactData.description;
                formData.email = this.contactData.email;
                formData.phone = this.contactData.phone;
                formData.mobile = this.contactData.mobile;
            }

            return formData;
        },
        submitNew(formData) {
            this.loadingData = true;
            let url = `portal/${this.selectedPortal}/contacts`;
            this.$ppClient
                .post(url, formData)
                .then(response => {
                    let body = response.data.body;
                    if (body.newContact) {
                        this.loadingData = false;
                        this.$emit("contact-added", body.newContact);
                        this.$parent.close();
                    } else {
                        this.loadingData = false;
                        this.errors.push("Error adding contact");
                    }
                })
                .catch(error => {
                    this.loadingData = false;
                    this.errors.push("Error adding contact");
                });
        },
        submitUpdate(formData) {
            this.loadingData = true;
            let url = `/contacts/${this.contact._id.$oid}`;
            // console.log("submit update");
            // console.log(formData);
            // console.log(this.contact);
            this.$ppClient
                .patch(url, formData)
                .then(response => {
                    let body = response.data.body;
                    if (body.updatedContact) {
                        this.loadingData = false;
                        this.$emit("contact-updated", body.updatedContact);
                        this.$parent.close();
                    } else {
                        this.loadingData = false;
                        this.errors.push("Error updating contact");
                    }
                })
                .catch(error => {
                    this.loadingData = false;
                    this.errors.push("Error updating contact");
                });
        }
    }
};
</script>

<style lang="scss" scoped></style>
