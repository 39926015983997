export default {
    computed: {
        puid: {
            get() {
                return this.$store.getters["sessionState/puid"];
            }
        },
        sysAccess: {
            get() {
                return this.$store.getters["sessionState/sysAccess"];
            }
        },
        portalAccess: {
            get() {
                return this.$store.getters["sessionState/portalAccess"];
            }
        }
    }
}