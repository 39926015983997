<template>
    <div id="site-details">

        <div class="modal-card" style="width: auto">
            <header class="modal-card-head">
                <p class="modal-card-title">New Document Category</p>
            </header>

            <!-- <section v-if="isLoading" class="modal-card-body">
                <b-loading :is-full-page="false" :active.sync="isLoading" :can-cancel="false" />
            </section> -->

            <section class="modal-card-body">
                <b-field grouped>
                    <b-field 
                        label="Category Name"
                        @keyup.native="removeErrorField('name')"
                        :type="
                            errorFields.includes('name')
                                ? 'is-danger'
                                : ''
                        "
                    >
                        <b-input size="is-small" v-model.trim="name"></b-input>
                    </b-field>

                    <!-- <b-field 
                        label="Retention Policy"
                        :type="
                            errorFields.includes('retentionPolicy')
                                ? 'is-danger'
                                : ''
                        "
                    >
                        <b-select 
                            v-if="documentDefaults" 
                            size="is-small" 
                            v-model="retentionPolicy"
                            @change.native="removeErrorField('retentionPolicy')"
                        >
                            <option
                                v-for="rPolicy in documentDefaults.retentionPolicies"
                                :key="rPolicy._id.$oid"
                                :value="rPolicy._id.$oid"
                                >{{ rPolicy.retention == 0 ? "Do not Delete"  : `${rPolicy.retention} years` }} </option
                            >
                        </b-select>
                    </b-field> -->
                </b-field>

                <b-field label="Subcategories">
                    <b-taginput
                        style="max-width: 25vw;"
                        size="is-small"
                        v-model="subcats"
                        :allow-new="true"
                        :placeholder="
                            subcats.length == 0 ? 'Add some subcategories' : ''
                        "
                    >
                    </b-taginput>
                </b-field>
            </section>
            
            <footer class="modal-card-foot">
                <button class="button" @click="$parent.close()">Cancel</button>
                <button
                    class="button is-primary"
                    type="button"
                    @click="confirm"
                >
                    Confirm
                </button>
                
            </footer>
        </div>
    </div>
</template>

<script>
export default {
    name: "DocCategory",
    components: {},
    props: {
        catProp: Object,
        // documentDefaults: Object
    },
    data() {
        return {
            uuid: "",
            name: "",
            // @todo make configurable and server-side via $parent.docuemtCategories
            // retentionPolicys: ["general", "registeredtitle", "client", "fg"],
            // retentionPolicy: "",
            subcats: [],
            errorFields: [],
            
        };
    },
    computed: {
        // isLoading: function() {
        //     // console.log('in computed prop');
        //     // console.log(this.documentDefaults);
        //     // console.log(this.documentDefaults == null);
            
            
        //     return this.documentDefaults == null;
        // }
    },
    watch: {},
    mounted() {
        // console.log('docDefaults (cat)');
        // console.log(this.documentDefaults);
        
        
        
        // if( !this.documentDefaults ) {
        //     this.fetchDocDefaults();
        // }

        // console.log(this.documentDefaults);
        
        this.uuid = this.catProp.uuid;
        this.name = this.catProp.name;
        // this.retentionPolicy = this.catProp.type;
        this.subcats = this.catProp.subcats;

    },
    methods: {
        // fetchDocDefaults() {
        //     let url = `document/defaults`;

        //     this.$ppClient.get(url).then(response => {

        //         delete response.data.body.session;

        //         let body = response.data.body;

        //         console.log(body);
                
        //         // this.documentDefaults = body;
        //         this.$emit('docDefaultsFetched', body);
                
        //         // this.documentDefaults = body;
                
        //         // return body;
                
        //     });
        // },
        async validateForm() {
            if( !this.name ) {
                this.errorFields.push('name');
            }

            // if( !this.retentionPolicy ) {
            //     this.errorFields.push('retentionPolicy')
            // }

            return this.errorFields.length === 0;
        },
        removeErrorField( field ) {
            this.errorFields = this.errorFields.filter( e => e !== field );
        },
        confirm() {
            this.validateForm().then(valid => {
                if (valid) {
                    this.$emit("updateCat", {
                        uuid: this.uuid,
                        name: this.name,
                        // type: this.findRetentionPolicy( this.retentionPolicy ),
                        // retentionPolicy: this.documentDefaults.retentionPolicies.find( rp => rp._id.$oid  == this.retentionPolicy )._id.$oid,
                        subcats: this.subcats
                    });
                    this.$parent.close();
                }
            });
        }
    }
};
</script>

<style lang="scss" scoped></style>
