<template>
    <div id="manage-users">
        <sidebar-header header-title="Manage Users" :show-client-logo="false" />
         <b-loading
                :is-full-page="false"
                :active.sync="loadingData"
                :can-cancel="false"
            >
                <font-awesome-icon
                    :icon="['fad', 'sync']"
                    :style="$iconStyle"
                    size="4x"
                    spin
                />
            </b-loading>
        <div id="manage-users-header-actions" class="margin-top-15">
            <div class="buttons">
                <b-button
                    type=""
                    @click="launchNewUserModal"
                    title="Add a user to Property portal"
                >
                    <font-awesome-icon :icon="'user-plus'"></font-awesome-icon>
                </b-button>
            </div>

            <b-tabs id="user-list-tabs" type="is-boxed" v-model="userListTab">
                <b-tab-item label="FG Users">
                    <b-table
                        class="trans-table"
                        :data="adUsers"
                        default-sort="name"
                    >

                        <template slot-scope="props">
                            <b-table-column sortable field="name" label="Name">
                                {{ props.row.name }}
                            </b-table-column>

                            <b-table-column field="edit-user" label="Edit" centered>
                                <!-- <b-button @click="renameLayerPrompt(props.row.id)"> -->
                                <b-button @click="launchEditUserModal(props.row)" :disabled="editUserDisabled(props.row)">
                                    <font-awesome-icon :icon="'edit'" />
                                </b-button>
                            </b-table-column>

                            <b-table-column field="disable-user" label="Active" centered>
                                <b-switch
                                    :disabled="editUserDisabled(props.row)"
                                    v-model="props.row.active"
                                    type="is-success"
                                    @input="
                                        handleUserActive(
                                            $event,
                                            props.row._id.$oid
                                        )
                                    "
                                />
                            </b-table-column>

                        </template>
                    </b-table>


                </b-tab-item>

                <b-tab-item label="External Users">
                    <b-table
                        class="trans-table"
                        :data="extUsers"
                        default-sort="name"
                    >

                        <template slot-scope="props">
                            <b-table-column sortable field="name" label="Name">
                                {{ props.row.name }}
                            </b-table-column>

                            <b-table-column field="edit-user" label="Edit" centered>
                                <!-- <b-button @click="renameLayerPrompt(props.row.id)"> -->
                                <b-button @click="launchEditUserModal(props.row)">
                                    <font-awesome-icon :icon="'edit'" />
                                </b-button>
                            </b-table-column>

                            <b-table-column field="disable-user" label="Active" centered>
                                <b-switch
                                    :disabled="!props.row.verified"
                                    v-model="props.row.active"
                                    type="is-success"
                                    @input="
                                        handleUserActive(
                                            $event,
                                            props.row._id.$oid
                                        )
                                    "
                                />
                            </b-table-column>

                        </template>
                    </b-table>

                </b-tab-item>
            </b-tabs>

        </div>
    </div>
</template>

<script>
import SidebarHeader from "@/components/sidebar/SidebarHeader.vue";
import UserConfigModal from "@/components/modals/UserConfigModal.vue";

import AccessControlHelpers from "@/mixins/AccessControlHelpers.js";

export default {
    name: "ManageUsers",
    mixins: [AccessControlHelpers],
    components: {SidebarHeader, UserConfigModal},
    props: {
        currentTab: String
    },
    data() { 
        return {
            loadingData: false,
            tabName: "users",
            userListTab: 0,
            users: []
        } 
    },
    computed: {
        adUsers: function() {
            return this.users.filter( user => user.userType == 'ad' );
        },
        extUsers: function() {
            return this.users.filter( user => user.userType == 'ext' );
        }
    },
    mounted () {
    },
    watch: {
        currentTab: function() {
            // console.log('tab change');
            // console.log(this.users);
            
            
            if(this.currentTab === this.tabName){
                if( this.users.length == 0 ) {
                    // console.log('load users');
                    // this.users = true;
                    this.loadUsers();
                }
                
            }
            else {
                // if(this.users){
                    // console.log('clear users');
                    this.users = [];
                // }
            }
        }
    },
    methods: {
        editUserDisabled(user) {
            // console.log('user');
            // console.log(user);
            // sys admins can edit any user
            // this allows them to add themselves to a portal
            if(this.sysAccess === 1) { return false };
            // normal users can't edit themselves
            let userPuid = user._id.$oid.slice(-10);
            return this.puid == userPuid;
        },
        loadUsers() {
            this.loadingData = true;
            this.$ppClient.get('/system-users')
            .then( response => {
                let body = response.data.body;
                this.users = body.users;
                this.loadingData = false;
                // console.log(this.users);
                
            }).catch(error => {
                console.log(error);
                this.loadingData = false;
            });
        },
        launchNewUserModal() {
            let mode = "new";
            this.launchUserConfigModal(mode);
        },
        launchEditUserModal(user) {
            let mode = "edit";
            // clone the object so that changes in the modal 
            // don't affect the object in memory.
            // we'll propagate the changes after the update succeeds
            let userClone = Object.assign({}, user);
            this.launchUserConfigModal(mode, userClone);
        },
        launchUserConfigModal(mode, user = false) {
            let props = {
                mode: mode
            };

            if (mode === "edit" && user) {
                props.user = user;
            }

            this.$buefy.modal.open({
                component: UserConfigModal,
                props: props,
                parent: this,
                events: {
                    'user-added': user => {
                        // console.log('user added:');
                        // console.log(user)
                        this.users.push(user);
                    },
                    'user-updated': resp => {
                        let user = resp.updatedUser;
                        // console.log('user updated:');
                        // console.log(user)

                        let userPuid = user._id.$oid.slice(-10);
                        if( this.puid == userPuid ) {
                            this.$store.commit("mapState/setUserPortals", resp.updatedPortals);
                        }

                        this.users.splice(
                            this.users.findIndex(e => e._id.$oid === user._id.$oid),
                            1,
                            user
                        );
                        // console.log(this.users);
                        
                    },
                    'user-deleted': user => {
                        // console.log('user updated:');
                        // console.log(user)
                        this.users.splice(
                            this.users.findIndex(e => e._id.$oid === user._id.$oid),
                            1
                        );

                        this.$eventHub.$emit('user-deleted', user._id.$oid);
                        // console.log(this.users);
                        
                    },
                }
                
            });
        },
        handleUserActive(e, userId) {
            // console.log('user active');
            // console.log(e);
            // console.log(userId);

            this.$ppClient
                .patch(`users/${userId}/active`, {active: e});
            
            
        }
    }
};
</script>

<style lang="scss">
    // reset the styling on tabs back to the buefy default
    #user-list-tabs{
        .tabs {
            li {
                &.is-active{
                    border: 2px solid #b3b3b3;
                    border-bottom: none;
                    border-top-left-radius: 5px;
                    border-top-right-radius: 5px;

                    a {
                        color: #1a3062;
                    }
                }
                a {
                    color: #4a4a4a;
                }
            }

            ul {
                border-bottom: 1px solid #b3b3b3;
            }
        }
    }

    
        
</style>
