import tinycolor from "tinycolor2";
export default {
    methods: {
        alphaToHex(a) {
            // console.log(a);
            a = parseFloat(a) * 100;
            const percent = Math.max(0, Math.min(100, a)); // bound percent from 0 to 100
            const intValue = Math.round((a / 100) * 255); // map percent to nearest integer (0 - 255)
            const hexValue = intValue.toString(16); // get hexadecimal representation
            let h = hexValue.padStart(2, "0").toUpperCase(); // format with leading 0 and upper case characters
            // console.log(h);

            return h;
        },
        getColorObj(cString) {
            // console.log('cString');
            // console.log(cString);
            
            let c = tinycolor(cString);
            let hsl = c.toHsl();
            let hsv = c.toHsv();

            // console.log(hsl);
            // console.log(hsv);

            return {
                hex: c.hex,
                hsl: { h: hsl.h, s: hsl.s, l: hsl.l, a: +hsl.a.toFixed(2) },
                hsv: { h: hsv.h, s: hsv.s, v: hsv.v, a: +hsv.a.toFixed(2) },
                rgba: { r: c._r, g: c._g, b: c._b, a: +c._a.toFixed(2) },
                a: +c._a.toFixed(2)
            };
        },
    }
};
