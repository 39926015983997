<template>
    <div>
        <loading-notification :is-loading="isLoading"></loading-notification>
        <sidebar-header headerTitle="Notifications"></sidebar-header>

        <h3 class="subtitle is-5 margin-top-15">Outstanding Notifications</h3>

         <b-field label="Day/Month View">
            <b-switch v-model="monthView"></b-switch>
        </b-field>

        

        <div class="columns is-vcentered">
            <div class="column">
            <b-datepicker
                :type="monthView ? 'month' : null"
                inline
                :events="notificationDates"
                :years-range="[-5, 15]"
                :indicators="'bars'"
                v-model="selectedDate"
                size="is-small"
            >
                <div class="has-text-centered">
                    <span
                        v-if="selectedDate"
                    >
                        <!-- <h6 class="header is-6"> -->
                        Selected:
                        <strong>
                            {{
                                monthView ? selectedDate.toLocaleString('default', { year: 'numeric', month: 'long' }) : selectedDate.toLocaleDateString()
                            }}
                        </strong>
                        <!-- </h6> -->
                    </span>
                    <span v-else>
                        <strong class="has-text-danger">
                            No Date Selected
                        </strong>
                    </span>
                </div>
            </b-datepicker>
            
            </div>
        </div>
        
        <div v-if="notifications">
            
            <b-tabs id="notification-tabs" type="is-boxed" size="is-small" v-model="notificationListTab">
                <b-tab-item label="Portal" :icon="portalNotifications.length > 0 ? 'exclamation-triangle' : ''">
                    <portal-notifications  :portalNotifications="portalNotifications"/>
                </b-tab-item>
                <b-tab-item label="Site" :icon="siteNotifications.length > 0 ? 'exclamation-triangle' : ''">
                    <site-notifications  :siteNotifications="siteNotifications"/>
                </b-tab-item>
                <b-tab-item label="Document" :icon="documentNotifications.length > 0 ? 'exclamation-triangle' : ''">
                    <document-notifications  :documentNotifications="documentNotifications"/>
                </b-tab-item>
            </b-tabs>

                
        </div>

        <div v-else>
            No Notifications
        </div>

        <!-- <div class="buttons">
            <b-button type="" title="recentre map on feature">
                New Notification
            </b-button>
        </div> -->
    </div>
</template>

<script>
import LoadingNotification from "@/components/notifications/LoadingNotification.vue";
import SidebarHeader from "@/components/sidebar/SidebarHeader.vue";

// import ClientDetailsHelpers from "@/mixins/ClientDetailsHelpers.js";
import NotificationHelpers from "@/mixins/NotificationHelpers.js";

import PortalNotifications from '@/components/sidebar/Notifications/PortalNotifications.vue';
import SiteNotifications from '@/components/sidebar/Notifications/SiteNotifications.vue';
import DocumentNotifications from '@/components/sidebar/Notifications/DocumentNotifications.vue';

export default {
    name: "Notifications",
    mixins: [
        // ClientDetailsHelpers,
        NotificationHelpers
    ],
    components: { LoadingNotification, SidebarHeader, PortalNotifications, SiteNotifications, DocumentNotifications },
    props: {
        isLoading: Boolean
    },
    created(){
        this.$eventHub.$on("display-notification-in-tab", notification => {
            this.selectedDate = new Date(notification.notificationDate);
            this.notificationListTab = this.tabMap[notification.type];
        });
        this.$eventHub.$on("notification-added", args => {
            // console.log('notification added');
            // console.log(args);
            this.notifications.push(args)
        });

        this.$eventHub.$on("notification-updated", args => {
            // console.log('notification updated');
            // console.log(this.notifications);
            // console.log(args);

            // replace the notification and trigger reactive hooks
            let objIndex = this.notifications.findIndex((notif => notif._id.$oid == args._id.$oid));
            this.$set(this.notifications, objIndex, args);
        });

        this.$eventHub.$on("notification-dismissed", args =>{
            let removeIndex = this.notifications
                .map(function(notification) {
                    return notification._id.$oid;
                })
                .indexOf(args._id.$oid);

            // remove notification
            // currently dismissed are removed,
            // @todo optimise notifications so that we can still show dismissed
            this.notifications.splice(removeIndex, 1);
        });

        this.$eventHub.$on("notification-deleted", args =>{
            // console.log('notification deleted');
            // console.log(args);
            
            
            let removeIndex = this.notifications
                .map(function(notification) {
                    return notification._id.$oid;
                })
                .indexOf(args._id.$oid);

            this.notifications.splice(removeIndex, 1);
        });

        this.$eventHub.$on("document-deleted", documentId =>{
            // console.log('document deleted (update notifications)');
            // console.log(args);
            this.notifications = this.notifications.filter( n => n.documentId !== documentId);
        });

        this.$eventHub.$on("site-deleted", siteId =>{
            // console.log('site deleted (update notifications)');
            // console.log(args);
            this.notifications = this.notifications.filter( n => n.siteId !== siteId);
        });

        this.$eventHub.$on("layer-deleted", layerId =>{
            // console.log('layer deleted (update notifications)');
            // console.log(args);
            this.notifications = this.notifications.filter( n => n.layerId !== layerId);
        });
    },
    data() {
        return {
            notificationListTab: 0,
            tabMap: {
                portal: 0,
                site: 1,
                document: 2
            },
            selectedDate: null,
            monthView: false
        };
    },
    computed: {
        todaysDate: function() {
            // addresses a bit of weirdness with JS dates
            // date select would give tomorrow as a minimum date without this
            const today = new Date();
            return new Date(
                today.getFullYear(),
                today.getMonth(),
                today.getDate()
            );
        },
        width: {
            get() {
                return this.$store.getters["sidebarState/width"];
            },
            set(newVal) {
                this.$store.commit("sidebarState/setWidth", newVal);
            }
        },
        notificationDates(){
            return this.notifications.map(n => {
                return {date: new Date(n.notificationDate), type: 'is-danger'}
            });
        },
        // work with notifications that have the selected date only
        filteredNotifications: function() {
            // console.log(this.notifications);
            // console.log(this.notificationDates);
            
            if( this.monthView ) {
                return this.selectedDate 
            ? this.notifications.filter( n => new Date(n.notificationDate).getMonth() == this.selectedDate.getMonth() )
            : [];
            } else {
                return this.selectedDate 
            ? this.notifications.filter( n => new Date(n.notificationDate).getTime() == this.selectedDate.getTime() )
            : [];
            }
            
            
        },
        portalNotifications: function() {
            // return this.notifications.filter(notification => notification.type == 'portal');
            return this.filteredNotifications.filter(notification => notification.type == 'portal');
        },
        siteNotifications: function() {
            // return this.notifications.filter(notification => notification.type == 'site');
            return this.filteredNotifications.filter(notification => notification.type == 'site');
        },
        documentNotifications: function() {
            // return this.notifications.filter(notification => notification.type == 'document');
            return this.filteredNotifications.filter(notification => notification.type == 'document');
        }
    },
    mounted() {
        this.selectedDate = this.todaysDate;
    },
    methods: {
        labelFor(notType) {
            
            
            
        }
    }
};
</script>

<style lang="scss">
    #notification-tabs{
        nav.tabs {
            li.is-active{
                border: 2px solid #b3b3b3;
                border-bottom: none;
                border-top-left-radius: 5px;
                border-top-right-radius: 5px;
            }

            ul {
                border-bottom: 1px solid #b3b3b3;
            }
        }
    }
</style>
