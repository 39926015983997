<template>
    <div id="layer-management" class="edit-sidebar">
        <sidebar-header headerTitle="Layers"></sidebar-header>

        <div class="sidebar-pm-controls">
            <loading-notification
                :is-loading="isLoading"
            ></loading-notification>

            <h3
                v-if="portalAllowsManagedLayers && externalLayers.length > 0"
                class="title is-5 margin-top-15"
            >
                FG Managed Layers:
            </h3>
            <b-table
                v-if="portalAllowsManagedLayers && externalLayers.length > 0"
                class="layer-table"
                :class="tableClass"
                :data="externalLayers"
                :selected.sync="selectedLayer"
                @select="layerRowSelected"
                default-sort="name"
            >
                <!-- draggable -->
                <!-- @dragstart="dragstart" -->
                <!-- @drop="drop" -->
                <!-- @dragover="dragover" -->
                <!-- @dragleave="dragleave" -->
                <!-- :row-class="(row, index) => isSelectedLayer(row)" -->
                <!-- :selected.sync="selected" -->
                <template slot-scope="props">
                    <b-table-column sortable field="name" label="Name (# sites)" :style="layerNameColourStyle(props.row)">
                        {{ props.row.name }} ({{ siteCount(props.row) }})
                    </b-table-column>

                    <b-table-column field="visible" label="Visible" centered>
                        <b-button @click="changeLayerVisibility(props.row)">
                            <font-awesome-icon
                                :icon="props.row.visible ? 'eye' : 'eye-slash'"
                            />
                        </b-button>
                    </b-table-column>

                    <b-table-column
                        field="show-layer-sites"
                        label="Sites"
                        centered
                    >
                        <b-button @click="changeTab('layer-details')">
                            <font-awesome-icon
                                :icon="['fad', 'th-list']"
                                :style="$iconStyle"
                            />
                        </b-button>
                    </b-table-column>
                </template>
            </b-table>

            <h3
                v-if="portalAllowsClientLayers"
                class="title is-5 margin-top-15 header-with-button"
            >
                Client Layers:
                <b-button
                    v-if="portalAccess < 2"
                    type=""
                    title="New Layer"
                    @click="launchNewLayerModal"
                    class="has-margin-left-10"
                >
                    <font-awesome-icon :icon="['fas', 'plus']" />
                </b-button>
            </h3>

            <b-table
                v-if="portalAllowsClientLayers && internalLayers"
                class="layer-table"
                :class="tableClass"
                :data="internalLayers"
                :selected.sync="selectedLayer"
                default-sort="name"
            >
                <!-- draggable -->
                <!-- @select="layerRowSelected" -->
                <!-- @dragstart="dragstart" -->
                <!-- @drop="drop" -->
                <!-- @dragover="dragover" -->
                <!-- @dragleave="dragleave" -->
                <template slot-scope="props">
                    <b-table-column sortable field="name" label="Name (# Sites)" :style="layerNameColourStyle(props.row)">
                        {{ props.row.name }} ({{ siteCount(props.row) }})
                    </b-table-column>

                    <b-table-column field="visible" label="Visible" centered="">
                        <b-button @click="changeLayerVisibility(props.row)">
                            <font-awesome-icon
                                :icon="props.row.visible ? 'eye' : 'eye-slash'"
                            />
                        </b-button>
                    </b-table-column>

                    <b-table-column
                        field="show-layer-sites"
                        label="Sites"
                        centered
                    >
                        <b-button @click="changeTab('layer-details')">
                            <font-awesome-icon
                                :icon="['fad', 'th-list']"
                                :style="$iconStyle"
                            />
                        </b-button>
                    </b-table-column>

                    <b-table-column v-if="portalAccess < 2" field="edit-layer" label="Edit" centered>
                        <!-- <b-button @click="renameLayerPrompt(props.row.id)"> -->
                        <b-button @click="launchEditLayerModal(props.row)">
                            <font-awesome-icon
                                :icon="'edit'"
                            />
                        </b-button>
                    </b-table-column>
                </template>
            </b-table>
        </div>
    </div>
</template>

<script>
// convenience getters from vuex
import { mapGetters } from "vuex";

import "@geoman-io/leaflet-geoman-free";
import "@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css";
import { DialogProgrammatic as Dialog } from "buefy";

import SidebarHeader from "@/components/sidebar/SidebarHeader.vue";
import LayerStateHelpers from "@/mixins/LayerStateHelpers.js";
import FeatureStateHelpers from "@/mixins/FeatureStateHelpers.js";
import ClientDetailsHelpers from "@/mixins/ClientDetailsHelpers.js";
import ColourHelpers from "@/mixins/ColourHelpers.js";
import AccessControlHelpers from "@/mixins/AccessControlHelpers.js";

import LayerConfigModal from "@/components/modals/LayerConfigModal.vue";
import LoadingNotification from "@/components/notifications/LoadingNotification.vue";


export default {
    name: "LayerManagement",
    mixins: [
        LayerStateHelpers,
        FeatureStateHelpers,
        ClientDetailsHelpers,
        ColourHelpers,
        AccessControlHelpers
    ],
    components: {
        LoadingNotification,
        SidebarHeader,
        LayerConfigModal
    },
    props: {
        isLoading: Boolean
        // layers: {
        //   type: Array,
        //   default: function() {
        //     return [];
        //   }
        // },
        // selectedLayer: {
        //   type: Object,
        //   default: function() {
        //     return {};
        //   }
        // }
    },
    data() {
        return {
            tableClass: "trans-table",
            tabName: "edit",
            columns: [
                {
                    field: "name",
                    label: "Name"
                }
            ],
            draggingRow: null,
            draggingRowIndex: null,
            selected: {},
            color: "green"
        };
    },
    mounted() {
        // console.log('layer management');
        // console.log(this.portalAccess);
        // console.log('this.internalLayers');
        // console.log(this.internalLayers);
        // console.log('this.externalLayers');
        // console.log(this.externalLayers);
        // console.log(this.visibleIcon(true));
        // console.log(this.visibleIcon(false));

        this.$nextTick(() => {
            // const customTranslation = {
            //   tooltips: {
            //     placeMarker: "Click to place marker",
            //     firstVertex: "Click to place first vertex",
            //     continueLine: "Click to continue drawing",
            //     finishLine: "Click any existing marker to finish",
            //     finishPoly: "Click first marker to finish",
            //     finishRect: "Click to finish",
            //     startCircle: "Click to place circle center",
            //     finishCircle: "Click to finish circle",
            //     placeCircleMarker: "Click to place circle marker"
            //   },
            //   actions: {
            //     finish: "Finish",
            //     cancel: "Cancel",
            //     removeLastVertex: "Remove Last Vertex"
            //   },
            //   buttonTitles: {
            //     drawMarkerButton: "Draw Marker",
            //     drawPolyButton: "Draw Polygons",
            //     drawLineButton: "Draw Polyline",
            //     drawCircleButton: "Draw Circle",
            //     drawRectButton: "Draw Rectangle",
            //     editButton: "Edit Layers",
            //     dragButton: "Drag Layers",
            //     cutButton: "Cut Layers",
            //     deleteButton: "Remove Layers",
            //     drawCircleMarkerButton: "Draw Circle Marker"
            //   }
            // };
            const customTranslation = {
              buttonTitles: {
                cutButton: "Cut Polygons (doesn't affect circles)",
              }
            };
            this.map.pm.setLang("customName", customTranslation, "en");
            this.showControls();
            // this.addControls

            // let pmControls = Array.from(
            //   document.getElementsByClassName("leaflet-pm-toolbar")
            // );
            // // console.log(pmControls);
            // // console.log(test.$appendTo(map._controlContainer));
            // // let controls = document.getElementsByClassName("leaflet-control-container");
            // let pmToolbarNew = document.getElementById("pm-toolbar-new");
            // // console.log(topLeftControls);
            // // topLeftControls.appendChild(test);
            // pmControls.forEach(function(pmControl, index) {
            //   pmToolbarNew.prepend(pmControl);
            // });
        });
    },
    watch: {
        currentTab: function() {
            this.showControls();
        },
        selectedLayer: function() {
            this.showControls();
        },
        selectedFeature: function() {
            // this.showControls();
            this.map.pm.disableGlobalEditMode();
            this.showControls();
        }
    },
    computed: {
        ...mapGetters({
            map: "mapState/map",
            layers: "mapState/layers",
            // selectedLayer: 'mapState/selectedLayer',
            // selectedFeature: "mapState/selectedFeature",
            currentTab: "sidebarState/currentTab"
        }),
        // isSelectedLayer: function (row) {
        //   console.log(row);
        // },
        open: function() {
            return this.currentTab === this.tabName;
        },
        hasBothExternalAndInternal() {
            // console.log('layerManagement: hasBothExAndIn');

            // console.log(this.externalLayers);
            // console.log(this.internalLayers);

            return (
                this.externalLayers.length > 0 && this.internalLayers.length > 0
            );
        }
    },
    methods: {
        layerNameColourStyle(row){
            return `border-left: 3px solid ${row.color}${this.alphaToHex(row.opacity || 1)}`;
        },
        changeLayerVisibility(row) {
            // this.selectedFeature = null;
            // console.log('change layer visibility');
            
            // selected feature was in the hidden layer.
            // unselect that feature
            if(this.selectedFeature && this.selectedFeature._site.layerId.$oid === row._id.$oid) {
                this.selectedFeature = null;
            }
            
            row.visible = !row.visible;
            this.showControls();
            // console.log("changeLayerVisibility");
            // console.log(row);
            // console.log(this.selectedPortal);

            let layerId = row._id.$oid;
            let visibility = row.visible ? "show" : "hide";
            let url = `portal/${this.selectedPortal}/layer/${layerId}/${row.source}/visibility`;
            let payload = {
                visibility: visibility
            };

            this.$ppClient
                .put(url, payload, {
                    headers: {
                        // "Content-Type":
                        // "multipart/form-data"
                        // 'Content-Type': 'x-www-form-urlencoded'
                    }
                })
                .then(response => {
                    // console.log(response);
                    
                })
                .catch(e => {
                    console.log(e);
                });
        },
        launchNewLayerModal() {
            let mode = "new";
            this.launchLayerConfigModal(mode);
        },
        launchEditLayerModal(layer) {
            // console.log(layer);

            let mode = "edit";
            // clone the layer object so that changes in the modal don't affect the
            // layer object in memory.
            // we'll propagate the changes after the update succeeds
            let layerClone = Object.assign({}, layer);
            layerClone.fields = layerClone.fields.map(f =>
                Object.assign({}, f)
            );
            this.launchLayerConfigModal(mode, layerClone);
        },
        launchLayerConfigModal(mode, layer = false) {
            let props = {
                mode: mode
            };

            if (mode === "edit" && layer) {
                props.layer = layer;
            }

            this.$buefy.modal.open({
                component: LayerConfigModal,
                props: props,
                canCancel: false,
                parent: this
            });
        },
        isSelectedLayer(row) {
            if (this.selectedLayer) {
                if (row._id.$oid === this.selectedLayer._id.$oid) {
                    return "is-selected";
                }
            }
            return false;

            // return this.selectedLayer && this.selectedLayer._id.$oid === row._id.$oid;
            // console.log(row);
        },
        changeTab(tabId) {
            this.$nextTick(() => {
                let sidebar = this.$store.getters["sidebarState/sidebar"];
                // ensure the panel isn't disabled
                sidebar.enablePanel(tabId);
                sidebar.open(tabId);
            });
        },
        showControls() {
            // this.removeControls();
            // console.log("layerManagement: showControls");
            // console.log(`open: ${this.open}`);
            // console.log("selected: ");
            // console.log(this.selectedLayer);

            // this.open &&
            this.selectedLayer &&
            this.selectedLayer.visible &&
            this.selectedLayer.source === "internal"
                ? this.addControls()
                : this.removeControls();
        },
        addControls: function() {
            if( this.portalAccess < 3 ) {
                this.map.pm.Draw.options.allowSelfIntersection = false;
                this.map.pm.Draw.options.snapDistance = 10;
                this.map.pm.addControls({
                    position: "topleft",
                    drawPolyline: false,
                    // drawCircleMarker: false
                    drawMarker: false,
                    removalMode: false
                    // editMode: this.selectedFeature ? true : false,
                });
            }
        },
        removeControls: function() {
            // console.log('remove controls');
            this.map.pm.disableGlobalEditMode();
            this.map.pm.removeControls();
        },
        renameLayerPrompt(layerId) {
            let layer = this.layers.find(layer => layer.id == layerId);
            let oldName = layer.name;

            this.$buefy.dialog.prompt({
                message: `Rename: ${oldName}`,
                onConfirm: value => this.$emit("layerRenamed", layerId, value)
            });
        },
        layerRowSelected(row, oldRow) {
            // let layer = this.layers.find( layer => layer._id.$oid == row._id.$oid);
            // this.$store.commit("mapState/setSelectedLayer", layer);
            // this.selectedLayer = layer;
            // this.$store.commit("mapState/setSelectedFeature", null);
            if (this.selectedFeature) {
                this.selectedFeature.setStyle(
                    this.$store.getters["mapState/featureStyle"](
                        this.selectedFeature._site.type,
                        "defaultStyle",
                        oldRow.color
                    )
                );

                this.selectedFeature = null;
            }

            // console.log(oldRow);
            // this.$emit("layerRowSelected", row.id);
            // console.log("currentLayer");
            // console.log(this.selectedLayer);
        },
        visibleIcon(visible) {
            return visible ? "eye-slash" : "eye";
        },
        toggleControls: function() {
            this.open = !this.open;
        },
        // table drag/drop
        dragstart(payload) {
            this.draggingRow = payload.row;
            this.draggingRowIndex = payload.index;
            payload.event.dataTransfer.effectAllowed = "copy";
        },
        dragover(payload) {
            payload.event.dataTransfer.dropEffect = "copy";
            payload.event.target.closest("tr").classList.add("is-selected");
            payload.event.preventDefault();
        },
        dragleave(payload) {
            payload.event.target.closest("tr").classList.remove("is-selected");
            payload.event.preventDefault();
        },
        drop(payload) {
            payload.event.target.closest("tr").classList.remove("is-selected");
            const droppedOnRowIndex = payload.index;
            this.$buefy.toast.open(
                `Moved ${this.draggingRow.first_name} from row ${this
                    .draggingRowIndex + 1} to ${droppedOnRowIndex + 1}`
            );
        }
    }
};
</script>



<!-- Add "scoped" attribute to limit CSS to this component only -->
<!--
<style lang="scss" scoped>
</style>
-->
<style lang="scss">
.layer-table {
    .table {
        tr.is-selected {
            background-color: var(--fg-grey);
        }
    }
}
// // make the button align center correctly
// .header-with-button {
//     display: inline-flex;
//     align-items: center;
// }
// #layer-table {
//   & > .table tr.is-selected {
//     background-color: --fg-green;
//   }
// }
// #layer-table {
//   tr{
//     background-color: green;
//   }
// }
// .table {
//         tr {
//             &.is-selected {
//                 background: --fg-green;
//             }
//         }
//     }
</style>
