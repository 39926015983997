<template>
    <div>
        <sidebar-header header-title="Selected Site Details"></sidebar-header>

        <!-- <b-button @click="$eventHub.$emit('site-name-updated', {siteId: siteId, updatedFields: this.selectedFeatureFields})">click me</b-button> -->

        <b-loading
            :is-full-page="false"
            :active.sync="loadingData"
            :can-cancel="false"
        >
            <font-awesome-icon
                :icon="['fad', 'sync']"
                :style="$iconStyle"
                size="4x"
                spin
            />
        </b-loading>

        <div id="feature-details-header-actions" class="margin-top-15">
            <div class="buttons">
                <b-button
                    type=""
                    @click="recentreOnFeature"
                    title="recentre map on feature"
                >
                    <font-awesome-icon :icon="'eye'"></font-awesome-icon>
                </b-button>

                <b-button
                    type=""
                    @click="fitToFeature"
                    title="fit map to feature"
                >
                    <font-awesome-icon
                        icon="search-location"
                    ></font-awesome-icon>
                </b-button>

                <b-button
                    type=""
                    @click="getDirections"
                    title="Get directions to this site"
                >
                    <font-awesome-icon :icon="'directions'"></font-awesome-icon>
                </b-button>
            </div>
        </div>

        <h3
            v-if="selectedLayerName"
            class="subtitle is-6 margin-top-15 is-capitalized"
        >
            Layer: {{ selectedLayerName }}
        </h3>

        <div>
            <h3 class="subtitle is-5 margin-top-15 is-capitalized">
                <span v-if="siteName">
                    {{ siteName }}
                </span>
                <span v-else>
                    Site Details
                </span>
            </h3>

            <div v-if="featureSelected">
                <site-notifications />

                <div
                    v-for="(fields, dataSource) in selectedFeatureFields"
                    :key="dataSource"
                >
                    <div v-if="selectedFeatureLayer.source == 'internal'">
                        <internal-site-details
                            :fields="fields"
                            :dataSource="dataSource"
                        ></internal-site-details>
                    </div>

                    <div v-else>
                        <external-site-details
                            :fields="fields"
                            :dataSource="dataSource"
                        ></external-site-details>
                    </div>
                </div>

                <propman-leases
                    v-if="fetchedFeatureLeases"
                    :fetched-feature-leases="fetchedFeatureLeases"
                    :fetched-feature-rent="fetchedFeatureRent"
                ></propman-leases>
            </div>

            <site-documents v-if="portalAllowsDocUploads" />

            <h3 class="subtitle is-5 margin-top-15">Feature Reference</h3>
            <div v-if="featureSelected">
                <b-collapse
                    class="card margin-top-15"
                    aria-id="feature-ref-center"
                    :open="false"
                >
                    <div
                        slot="trigger"
                        slot-scope="props"
                        class="card-header"
                        role="button"
                        aria-controls="feature-ref-center"
                    >
                        <p class="card-header-title is-capitalized">
                            Feature Centre Point
                        </p>
                        <a class="card-header-icon">
                            <font-awesome-icon
                                :icon="
                                    props.open
                                        ? ['fas', 'caret-up']
                                        : ['fas', 'caret-down']
                                "
                            />
                        </a>
                    </div>
                    <div class="card-content">
                        <ul
                            class="selected-feature-list"
                            v-if="
                                selectedFeatureW3W &&
                                    selectedFeatureW3W.coordinates
                            "
                        >
                            <li>
                                <strong>Latitude: </strong>
                                {{ selectedFeatureW3W.coordinates.lat }}
                            </li>
                            <li>
                                <strong>Longitude: </strong>
                                {{ selectedFeatureW3W.coordinates.lng }}
                            </li>
                            <li>
                                <strong>What3Words: </strong>
                                <a
                                    :href="selectedFeatureW3W.map"
                                    target="_blank"
                                >
                                    {{ selectedFeatureW3W.words }}
                                </a>
                            </li>
                            <li>
                                <strong>Nearest Place: </strong>
                                {{ selectedFeatureW3W.nearestPlace }}
                            </li>
                        </ul>
                    </div>
                </b-collapse>

                <b-collapse
                    class="card"
                    aria-id="feature-ref-poly-area"
                    :open="false"
                >
                    <div
                        slot="trigger"
                        slot-scope="props"
                        class="card-header"
                        role="button"
                        aria-controls="feature-ref-poly-area"
                    >
                        <p class="card-header-title is-capitalized">
                            Mapped Area
                        </p>
                        <a class="card-header-icon">
                            <font-awesome-icon
                                :icon="
                                    props.open
                                        ? ['fas', 'caret-up']
                                        : ['fas', 'caret-down']
                                "
                            />
                        </a>
                    </div>

                    <div class="card-content">
                        <p>
                            <strong>Given areas are approximate</strong>
                        </p>
                        <br />
                        <ul class="selected-feature-list">
                            <li v-if="area.radius">
                                <strong>Radius (m): </strong> {{ area.radius }}
                            </li>
                            <li v-if="area.circumference">
                                <strong>Circumference (m): </strong>
                                {{ area.circumference }}
                            </li>
                            <li><strong>Acres: </strong> {{ area.acre }}</li>
                            <li>
                                <strong>Hectares: </strong> {{ area.hectare }}
                            </li>
                            <li>
                                <strong>m<sup>2</sup>: </strong> {{ area.m2 }}
                            </li>
                            <li>
                                <strong>km<sup>2</sup>: </strong> {{ area.km2 }}
                            </li>
                            <li>
                                <strong>ft<sup>2</sup>: </strong> {{ area.ft2 }}
                            </li>
                        </ul>
                    </div>
                </b-collapse>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

import turfArea from "@turf/area";
import FeatureStateHelpers from "@/mixins/FeatureStateHelpers.js";
import MapStateHelpers from "@/mixins/MapStateHelpers.js";
import ClientDetailsHelpers from "@/mixins/ClientDetailsHelpers.js";

import SidebarHeader from "@/components/sidebar/SidebarHeader.vue";

import InternalSiteDetails from "@/components/sidebar/FeatureDetails/InternalSiteDetails.vue";
import ExternalSiteDetails from "@/components/sidebar/FeatureDetails/ExternalSiteDetails.vue";
import PropmanLeases from "@/components/sidebar/FeatureDetails/PropmanLeases.vue";

import SiteDocuments from "@/components/sidebar/FeatureDetails/SiteDocuments.vue";
import SiteNotifications from "@/components/sidebar/FeatureDetails/SiteNotifications.vue";

import { saveAs } from "file-saver";

export default {
    name: "FeatureDetails",
    mixins: [FeatureStateHelpers, MapStateHelpers, ClientDetailsHelpers],
    components: {
        SidebarHeader,
        InternalSiteDetails,
        ExternalSiteDetails,
        PropmanLeases,
        SiteDocuments,
        SiteNotifications
    },
    props: {},
    data() {
        return {
            loadingData: false
        };
    },
    created(){
        
    },
    mounted() {
        this.$eventHub.$on("site-data-loading", loading => {
            this.loadingData = loading;
        });
    },
    computed: {
        // vuex getters mapped
        ...mapGetters({
            // featureSelected: "mapState/featureSelected",
            // selectedFeature: "mapState/selectedFeature",
            layers: "mapState/layers"
        }),
        selectedLayerName: function() {
            let selectedLayer = this.$store.getters["mapState/selectedLayer"];
            return selectedLayer ? selectedLayer.name : false;
        },
        siteName: function() {
            // console.log('get site name');

            let selectedLayer = this.$store.getters["mapState/selectedLayer"];
            if (this.featureSelected && selectedLayer) {
                // selected layer can sometimes be out of whack if it gets changed pragmatically
                // if( !selectedLayer ) {
                //     selectedLayer = this.layers.find(l => l._id.$oid === selectedSite._site.layerIdId.$oid)
                // }

                let site = selectedLayer.sites.find(
                    s => s._id.$oid === this.selectedFeature._site.siteId.$oid
                );
                if (site) {
                    // is there a display ref field set?
                    // used for custom layers
                    if (site.displayRef) {
                        // return site[site.displayRef]
                        //     ? site[site.displayRef]
                        //     : false;
                        return site[site.displayRef] || false;
                    }

                    // fg managed layers have multiple field collections
                    if (site.fields) {
                        if (site.fields.portal) {
                            let displayRef = site.fields.portal.find(
                                field => field.isDisplayRef
                            );
                            return displayRef && displayRef.value
                                ? displayRef.value
                                : false;
                        }

                        // is there a field translation set
                        if (this.fieldTranslation) {
                            // prioritise propman fields
                            if (site.fields.propman) {
                                let fields = site.fields.propman;
                                let fieldMap = this.fieldMapFor(
                                    "propman",
                                    "propman"
                                );

                                return (
                                    this.getDisplayRefFromMap(
                                        fields,
                                        fieldMap
                                    ) || "error"
                                );
                            }

                            if (site.fields.geostore) {
                                let fields = site.fields.geostore;
                                let fieldMap = this.fieldMapFor(
                                    selectedLayer.popup,
                                    "geostore"
                                );

                                return (
                                    this.getDisplayRefFromMap(
                                        fields,
                                        fieldMap
                                    ) || "error"
                                );
                            }
                        }
                    }
                }
            }
            return false;
        },
        fetchedFeatureLeases() {
            // console.log(this.fetchedFeatureData.leases);
            if (
                !this.fetchedFeatureData ||
                !this.fetchedFeatureData.leases ||
                this.fetchedFeatureData.leases === "undefined" ||
                this.fetchedFeatureData.leases.length == 0
            ) {
                return false;
            }

            return this.fetchedFeatureData.leases;
        },
        fetchedFeatureRent() {
            // console.log(this.fetchedFeatureData.leases);
            if (
                !this.fetchedFeatureData ||
                !this.fetchedFeatureData.rent ||
                this.fetchedFeatureData.rent === "undefined" ||
                this.fetchedFeatureData.rent.length == 0
            ) {
                return [];
            }

            return this.fetchedFeatureData.rent;
        },
        additionalData: function() {
            // console.log('selectedFeatureFields');
            let layer = this.layers.find(
                l => l._id.$oid == this.selectedFeature._site.layerId.$oid
            );
            let site = layer.sites.find(
                s => s._id.$oid == this.selectedFeature._site.siteId.$oid
            );

            // console.log(site.fields);

            return this.selectedFeature._additionalData.siteDetails;
        },
        centre: function() {
            // console.log('feature details:center');
            // console.log(this.fetchedFeatureData);

            // let data = this.selectedFeature._additionalData.siteDetails;
            // return data.centre;
            // return this.fetchedFeatureData.siteDetails.centre;
            return this.selectedFeature
                ? this.getCentrePoint(this.selectedFeature)
                : false;
        },
        w3w: function() {
            // let data = this.selectedFeature._additionalData.siteDetails;
            // return data.w3w;
            return this.fetchedFeatureData.siteDetails.w3w;
        },
        area: function() {
            // console.log('feature details: area');

            let areaObject = {};

            let m2;
            let radM = false;
            if (this.selectedFeature instanceof L.Circle) {
                // console.log('circle selected');
                // console.log(this.selectedFeature.getRadius());

                radM = this.selectedFeature.getRadius();
                areaObject.radius = radM.toFixed(2);
                areaObject.circumference = (2 * Math.PI * radM).toFixed(2);
                m2 = Math.PI * (radM * radM);
            } else {
                let geoJson = this.selectedFeature.toGeoJSON();
                // console.log(geoJson);
                m2 = turfArea(geoJson);
            }

            let km2 = m2 * 0.000001;
            let acre = m2 * 0.0002471;
            let hectare = m2 * 0.0001;
            let ft2 = m2 * 10.76;

            // let areaObject = {
            areaObject.km2 = km2.toFixed(2);
            areaObject.m2 = m2.toFixed(2);
            areaObject.acre = acre.toFixed(2);
            areaObject.hectare = hectare.toFixed(2);
            areaObject.ft2 = ft2.toFixed(2);
            // };

            return areaObject;
        }
    },

    watch: {
        // selectedFeature: function() {
        //     console.log('feature changed');
        //     console.log(this.selectedFeature);
        //     console.log(this.fetchedFeatureData);
        // }
    },
    methods: {
        formatDateField(dateStringVal) {
            if (!dateStringVal || dateStringVal == "") {
                return "Not Set";
            }
            return new Date(dateStringVal).toLocaleDateString("en-GB", {
                weekday: "long",
                year: "numeric",
                month: "long",
                day: "numeric"
            });
        },
        dateChanged(newDate, field) {
            field.value = newDate.toISOString();
        },
        recentreOnFeature() {
            // console.log("featureDetails.vue: refocusMap");
            // console.log(this.selectedFeature);
            // console.log(this.fetchedFeatureData);
            // console.log(this.centre);

            this.latitude = this.centre.lat;
            this.longitude = this.centre.lng;
        },
        fitToFeature() {
            // console.log("FeatureDeatils: fitToFeature");
            // console.log(this.selectedFeature.getBounds());

            if (typeof this.selectedFeature.getBounds === "function") {
                this.$store.getters["mapState/map"].fitBounds(
                    this.selectedFeature.getBounds(),
                    {
                        // paddingTopLeft: new point(300, 0),
                        // paddingBottomRight: new point(10, 0)
                        paddingTopLeft: [500, 50]
                    }
                );

                // console.log(this.$store.getters["mapState/map"].getZoom());

                // this.zoom -= 1;
            } else {
                this.recentreOnFeature();
                this.zoom = 16;
            }
            // console.log('zoom');
            // console.log(this.zoom);
        },
        getDirections() {
            // centre.lat
            // centre.lng

            window.open(
                `https://www.google.com/maps/dir/?api=1&destination=(${this.centre.lat}, ${this.centre.lng})`,
                "_blank"
            );
        }
    }
};
</script>

<style lang="scss" scoped>
// #feature-details-header-actions{
//  button{
//      border: 1px solid;
//  }
// }
// .nested-label-field{
//     .label {
//         text-transform: capitalize;
//     }
// }

// .is-capitalized{
//     // .label {
//         text-transform: capitalize;
//     // }
// }

.table-title {
    margin: 15px 0;
    font-size: 1rem;
}
// ul.selected-feature-list {
//     font-size: 1.2em;
//     list-style-type: none;
//     ul {
//         list-style-position: inside;
//         list-style-type: disc;
//     }
// }
</style>

<style lang="scss">
// #feature-details-header-actions{
//  button{
//      border: 1px solid;
//  }
// }
// .nested-label-field{
//     .label {
//         text-transform: capitalize;
//     }
// }

// .is-capitalized{
//     // .label {
//         text-transform: capitalize;
//     // }
// }

// .table-title {
//     margin: 15px 0;
//     font-size: 1rem;
// }
ul.selected-feature-list {
    font-size: 1.2em;
    list-style-type: none;
    ul {
        list-style-position: inside;
        list-style-type: disc;
    }
}
</style>
