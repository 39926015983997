<template>
    <div id="site-details">

        <div class="modal-card" style="width: auto">
            <header class="modal-card-head">
                <p class="modal-card-title">Import Layer</p>
            </header>

            <!-- <section v-if="isLoading" class="modal-card-body">
                <b-loading :is-full-page="false" :active.sync="isLoading" :can-cancel="false" />
            </section> -->

            <section class="modal-card-body">
                <b-tabs v-model="importTypeTab" type="is-boxed" id="user-type-tabs">
                    <b-tab-item
                        label="Geometry Import (Geostore)"
                    >
                        <geostore-layer-importer :layer="layer" @gs-import-success="successfulImport" />
                    </b-tab-item>

                    <b-tab-item
                        label="Data Import"
                    >
                    <layer-data-importer :layer="layer" @data-import-success="successfulImport"/>
                    </b-tab-item>
                </b-tabs>
            </section>
            
            <footer class="modal-card-foot">
                <button class="button" @click="$parent.close()">Close Importer</button>
            </footer>
        </div>
    </div>
</template>

<script>

import uuidv1 from "uuid/v1";

import ClientDetailsHelpers from "@/mixins/ClientDetailsHelpers.js";

import GeostoreLayerImporter from "@/components/forms/importer/GeostoreLayerImporter.vue";
import LayerDataImporter from '@/components/forms/importer/LayerDataImporter.vue';

export default {
    name: "LayerImporterModal",
    mixins: [ClientDetailsHelpers],
    components: {GeostoreLayerImporter, LayerDataImporter},
    props: {
        // documentDefaults: Object
        layer: Object
    },
    data() {
        return {
            importTypeTab: 0,
        };
    },
    computed: {
        // isLoading: function() {
        //     // console.log('in computed prop');
        //     // console.log(this.documentDefaults);
        //     // console.log(this.documentDefaults == null);
        //     return this.documentDefaults == null;
        // }
    },
    watch: {
    },
    mounted() {

    },
    methods: {
        successfulImport() {
            this.$emit('import-success', true);
            this.$parent.close();
            this.$buefy.toast.open(`Successful Import`);
        },
    }
};
</script>

<style lang="scss" scoped></style>
