var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('loading-notification',{attrs:{"is-loading":_vm.isLoading}}),_c('sidebar-header',{attrs:{"isLoading":_vm.isLoading,"header-title":"Portal Actions"}}),(_vm.sysAccess < 3)?_c('div',[_c('h3',{staticClass:"subtitle is-5 margin-top-15"},[_vm._v("Portal Administration")]),_c('div',{staticClass:"buttons"},[_c('b-button',{attrs:{"type":"","title":"Add a new portal"},on:{"click":_vm.launchNewPortalModal}},[_c('font-awesome-layers',[_c('font-awesome-icon',{staticClass:"fa-w-18",style:(_vm.$iconStyle),attrs:{"icon":['fad', 'circle-notch'],"transform":"grow-16"}}),_c('font-awesome-icon',{staticClass:"fa-w-18",attrs:{"icon":['fas', 'plus']}})],1)],1)],1)]):_vm._e(),_c('h3',{staticClass:"subtitle is-5 margin-top-15"},[_vm._v("Portal Selection")]),_c('div',{attrs:{"id":"portal-selection"}},_vm._l((_vm.chunkedUserPortals),function(userPortals,i){return _c('div',{key:i,staticClass:"columns"},_vm._l((userPortals),function(userPortal){return _c('div',{key:userPortal['_id']['$oid'],staticClass:"column",class:_vm.computedColsSize},[_c('div',{staticClass:"portal-select"},[_c('div',{staticClass:"card client-portal-select"},[_c('div',{staticClass:"card-header",style:(userPortal['_id']['$oid'] === _vm.selectedPortal
                                    ? 'background-color: #DDD;'
                                    : '')},[_c('div',{staticClass:"card-header-title"},[_c('div',{staticClass:"buttons header-with-button",staticStyle:{"align-items":"center"}},[(
                                            userPortal['_id']['$oid'] ===
                                                _vm.selectedPortal
                                        )?_c('b-button',{staticClass:"placeholder-button"},[_c('font-awesome-icon',{staticClass:"selected-portal-icon",attrs:{"icon":['fas', 'check-circle'],"color":'#7BB626',"transform":"grow-8","title":"this is the portal that is currently selected"}})],1):_c('b-button',{staticClass:"has-margin-right-10",attrs:{"type":"is-success","title":"change portal"},on:{"click":function($event){return _vm.changeClientPortal(
                                                userPortal._id,
                                                userPortal.name
                                            )}}},[_c('font-awesome-icon',{attrs:{"icon":'check'}})],1),(_vm.sysAccess < 3)?_c('b-button',{staticClass:"has-margin-right-10",attrs:{"type":"is-primary","title":"Edit portal"},on:{"click":function($event){return _vm.launchEditPortalModal(
                                                userPortal
                                            )}}},[_c('font-awesome-icon',{attrs:{"icon":'edit'}})],1):_vm._e()],1)])]),_c('div',{staticClass:"card-content"},[_c('div',{staticClass:"client-portal-title-text"},[_c('h5',{staticClass:"subtitle is-5"},[_vm._v(" "+_vm._s(userPortal.name)+" ")])]),_c('div',{staticClass:"card-image"},[(userPortal['logo'])?_c('figure',{staticClass:"image"},[_c('img',{attrs:{"src":`data:image/png;base64,${
                                                userPortal['logo']
                                            }`,"alt":`${userPortal.name} logo`}})]):_c('div',{staticClass:"is-capitalized",attrs:{"id":"placeholder-logo"}},[_vm._v(" Logo Missing for Client ")])])])])])])}),0)}),0)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }