import Vue from "vue";
// let cookie = require('tiny-cookie');
// Vue.use(VueCookie);
import axios from "axios";
import router from "@/router";
import store from "@/store";
const url = require('url');

// encapsulate the logic required for requests to pp back-end

// get the token
// let token = Vue.cookie.get('token');

// set the defaults required across requests
const ppClient = axios.create({
    baseURL: process.env.VUE_APP_BACKEND_BASE_URL,
    withCredentials: true
    // set this dynamically with the below interceptor
    // headers: {
    //     // Authorization: token
    //     Authorization: Vue.cookie.get('token')
    // }
});

// get the token for the user and inject it into the request
ppClient.interceptors.request.use(function(config) {
    // console.log('in interceptor');
    let requestRoute = config.url;

    let token = Vue.cookie.get("token");
    // console.log(token);
    let currentRoute = router.currentRoute.name;
    // console.log(currentRoute);
    // if there's no auth token and we're not in the 
    // login page or home page (demo requests) 
    // then redirect to the login page

    let noToken = ['login', 'home'];
    
    if ( !(noToken.includes(currentRoute)) ) {
        if (!token) {
            router.push("login");
        }
    }

    config.headers["Authorization"] = token;
    // console.log('ppclient before');
    if(!config.headers["Request-Portal"]) {
        let cp = store.getters["mapState/selectedPortal"];
        config.headers["Request-Portal"] = cp;
    }
    
    // console.log(`ppClient after ${cp}`);

    return config;
});

ppClient.interceptors.response.use(
    response => {
        // console.log("in response interceptor");
        // console.log("response");
        // console.log(response);
        let currentRoute = router.currentRoute.name;
        // console.log('currentroute');
        // console.log(currentRoute);

        if (response.status === 401) {
            if (currentRoute !== "login") {
                router.push("login");
            }
        } else {
            // session exp gets set by login form for /login route
            let noSessionData = [
                "login",
                "verify",
                "reset-password",
                "home"
            ];

            // console.log('response.config.url');
            // console.log(response.config.url);

            if ( !(noSessionData.includes(currentRoute)) && response.config.url != '/logout' ) {
                
                // console.log('sess exp header');
                // console.log(response.headers["session-expires"]);

                let body = response.data.body;
                // console.log(body);
                
                // non json responses will have the session expires header set on the server
                // @todo: unfortunately, in order for the sessions to be dynamic
                // the session expiry needed adding to both the local storage and vuex
                // this is because I couldn't get the cookies to be reactive
                // and local storage doesn't persist across requests (page refreshes)
                // id like to create a helper class to centralise management of the session
                // data.
                let sessionExp = body ? body.session.exp : response.headers["session-expires"];
                // console.log("expires");
                // console.log(new Date(sessionExp));
                store.commit("sessionState/setExpires", new Date(sessionExp));
                Vue.cookie.set("exp", sessionExp, { SameSite: 'Lax' });

                // again, some nonjson responses (eg. downloading docs) can't use the response body for storing variables
                let sysRole = (body && body.acp && body.acp.sysRole) ? body.acp.sysRole : parseInt(response.headers["system-access-control"]);
                let portalRole = (body && body.acp && body.acp.portalRole) ? body.acp.sysRole : parseInt(response.headers["portal-access-control"]);
                let puid = (body && body.puid) ? body.puid :  response.headers["puid"];

                store.commit("sessionState/setSysAccess", sysRole);
                store.commit("sessionState/setPortalAccess", portalRole);
                store.commit("sessionState/setPuid", puid);

                if(body && body.acp){
                    if(body.acp.sysRole) { store.commit("sessionState/setSysAccess", body.acp.sysRole); }
                    if(body.acp.portalRole) { store.commit("sessionState/setPortalAccess", body.acp.portalRole); }
                    if(body.puid) { store.commit("sessionState/setPuid", body.puid); }
                }
                

                // console.log('in ppclient');
                // console.log(body);

                
            }
            return response;
        }
    },
    error => {
        // console.log(error.config);
        
        if (error.response) {
            // Request made and server responded
            console.log("error.response");
            // console.log(error.response.data);
            // console.log(error.response.status);
            // console.log(error.response.headers);
            if (error.response.status === 401 && router.currentRoute.name != 'login') {
                router.push("login");
                //place your reentry code
            }
        } else if (error.request) {
            // The request was made but no response was received
            console.log("error.request");
            // console.log(error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log("undefined error");

            // console.log("Error", error.message);
            // this.errors.push(
            //     "Something went wrong. Please try again"
            // );
        }
        return Promise.reject(error);
    }
);

export default ppClient;
