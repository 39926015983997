<template>
    <div>
        <loading-notification :is-loading="isLoading"></loading-notification>
        <sidebar-header :isLoading="isLoading" header-title="Portal Actions" />

        <div v-if="sysAccess < 3">
            <h3 class="subtitle is-5 margin-top-15">Portal Administration</h3>

            <div class="buttons">
                <b-button
                    type=""
                    title="Add a new portal"
                    @click="launchNewPortalModal"
                >
                    <font-awesome-layers>
                        <font-awesome-icon
                            :icon="['fad', 'circle-notch']"
                            class="fa-w-18"
                            transform="grow-16"
                            :style="$iconStyle"
                        />
                        <!-- :style="$iconStyle" -->
                        <font-awesome-icon
                            :icon="['fas', 'plus']"
                            class="fa-w-18"
                        />
                    </font-awesome-layers>
                </b-button>
            </div>
        </div>

        <h3 class="subtitle is-5 margin-top-15">Portal Selection</h3>
        <div id="portal-selection">
            <div
                class="columns"
                v-for="(userPortals, i) in chunkedUserPortals"
                :key="i"
            >
                <div
                    class="column"
                    :class="computedColsSize"
                    v-for="userPortal in userPortals"
                    :key="userPortal['_id']['$oid']"
                >
                    <div class="portal-select">
                        <div class="card client-portal-select">
                            <div
                                class="card-header"
                                :style="
                                    userPortal['_id']['$oid'] === selectedPortal
                                        ? 'background-color: #DDD;'
                                        : ''
                                "
                            >
                                <div class="card-header-title">
                                    <div
                                        class="buttons header-with-button"
                                        style="align-items: center;"
                                    >
                                        <b-button
                                            v-if="
                                                userPortal['_id']['$oid'] ===
                                                    selectedPortal
                                            "
                                            class="placeholder-button"
                                        >
                                            <!-- outlined
                                        disabled -->
                                            <font-awesome-icon
                                                :icon="['fas', 'check-circle']"
                                                :color="'#7BB626'"
                                                transform="grow-8"
                                                class="selected-portal-icon"
                                                title="this is the portal that is currently selected"
                                            />
                                        </b-button>
                                        <b-button
                                            v-else
                                            class="has-margin-right-10"
                                            type="is-success"
                                            title="change portal"
                                            @click="
                                                changeClientPortal(
                                                    userPortal._id,
                                                    userPortal.name
                                                )
                                            "
                                        >
                                            <font-awesome-icon
                                                :icon="'check'"
                                            />
                                        </b-button>

                                        <b-button
                                            v-if="sysAccess < 3"
                                            class="has-margin-right-10"
                                            type="is-primary"
                                            title="Edit portal"
                                            @click="
                                                launchEditPortalModal(
                                                    userPortal
                                                )
                                            "
                                        >
                                            <font-awesome-icon :icon="'edit'" />
                                        </b-button>
                                    </div>
                                </div>
                                <!-- <span
                                    class="card-header-icon is-pulled-right"
                                    v-if="
                                        userPortal['_id']['$oid'] ===
                                            selectedPortal
                                    "
                                >
                                    <font-awesome-icon
                                        :icon="['fas', 'check-circle']"
                                        :color="'#7BB626'"
                                        transform="grow-8"
                                        class="selected-portal-icon"
                                        title="this is the portal that is currently selected"
                                    />
                                </span> -->
                            </div>
                            <div class="card-content">
                                <div class="client-portal-title-text">
                                    <h5 class="subtitle is-5">
                                        {{ userPortal.name }}
                                    </h5>
                                </div>
                                <div class="card-image">
                                    <figure
                                        v-if="userPortal['logo']"
                                        class="image"
                                    >
                                        <img
                                            :src="
                                                `data:image/png;base64,${
                                                    userPortal['logo']
                                                }`
                                            "
                                            :alt="`${userPortal.name} logo`"
                                        />
                                        <!-- <img
                                            v-else
                                            src="https://via.placeholder.com/300x100?text=Logo Missing for Client"
                                            alt=""
                                        /> -->
                                    </figure>
                                    <div
                                        v-else
                                        class="is-capitalized"
                                        id="placeholder-logo"
                                    >
                                        Logo Missing for Client
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ClientDetailsHelpers from "@/mixins/ClientDetailsHelpers.js";
import LayerStateHelpers from "@/mixins/LayerStateHelpers.js";
import FeatureStateHelpers from "@/mixins/FeatureStateHelpers.js";
import FieldMapHelpers from "@/mixins/FieldMapHelpers.js";
import NotificationHelpers from "@/mixins/NotificationHelpers.js";
import AccessControlHelpers from "@/mixins/AccessControlHelpers.js";

import LoadingNotification from "@/components/notifications/LoadingNotification.vue";
import SidebarHeader from "@/components/sidebar/SidebarHeader.vue";
import PortalConfigModal from "@/components/modals/PortalConfigModal.vue";

import chunk from "chunk";

import { FontAwesomeLayers } from "@fortawesome/vue-fontawesome";
export default {
    name: "PortalActions",
    mixins: [
        ClientDetailsHelpers,
        LayerStateHelpers,
        FeatureStateHelpers,
        FieldMapHelpers,
        NotificationHelpers,
        AccessControlHelpers
    ],
    components: {
        LoadingNotification,
        SidebarHeader,
        FontAwesomeLayers,
        PortalConfigModal
    },
    props: {
        isLoading: Boolean
    },
    data() {
        return {
            // isLoading: true,
            newPortalModalActive: false
        };
    },
    computed: {
        chunkedUserPortals() {
            let currentWidth = this.$store.getters["sidebarState/width"];
            // console.log(chunk(this.userPortals, currentWidth));
            // return this.userPortals;
            return chunk(this.userPortals, currentWidth);
        },
        computedColsSize() {
            let currentWidth = this.$store.getters["sidebarState/width"];
            switch (currentWidth) {
                case 1:
                    return "is-full";
                    break;
                case 2:
                    return "is-one-half";
                    break;
                case 3:
                    return "is-one-third";
                    break;

                default:
                    return "is-one-quarter";
                    break;
            }
        }
    },
    mounted() {},
    methods: {
        launchNewPortalModal() {
            let props = {
                mode: "new"
            };
            this.$buefy.modal.open({
                component: PortalConfigModal,
                props: props,
                parent: this
            });
        },
        launchEditPortalModal(portal) {
            // console.log('edit portal');
            // console.log(portal);

            let props = {
                mode: "edit",
                editPortalData: portal
            };
            this.$buefy.modal.open({
                component: PortalConfigModal,
                props: props,
                parent: this,
                events: {
                    "portal-updated": updatedPortal => {
                        // replace the portal object and trigger reactive hooks
                        let objIndex = this.userPortals.findIndex(
                            portal => portal._id.$oid == updatedPortal._id.$oid
                        );

                        this.$set(this.userPortals, objIndex, {
                            logo: updatedPortal.logo,
                            name: updatedPortal.name,
                            _id: updatedPortal._id
                        });

                        // currently selected portal was updated
                        // reload to propogate any changes
                        if (this.selectedPortal === updatedPortal._id.$oid) {
                            this.changeClientPortal(
                                updatedPortal._id,
                                updatedPortal.name
                            );
                        }
                    }
                }
            });
        },
        changeClientPortal(id, name) {
            this.$eventHub.$emit("set-sidebar-loading-state", true);

            this.$store.commit("mapState/setTileProviders", []);

            this.layers = [];
            this.layerSummaries = null;
            this.selectedLayer = null;
            this.selectedFeature = null;
            this.selectedPortal = id.$oid;
            this.clientLogo = null;
            this.clientName = null;
            this.documentConfig = {};
            this.notifications = [];
            this.portalAllowsManagedLayers = false;
            this.portalAllowsClientLayers = false;
            this.portalAllowsDocUploads = false;
            this.clientPrivacyPolicy = null;

            /**
             * @todo reset pm controls
             */

            // alert(`changing to client ${id.$oid}`);
            this.$ppClient
                .get(`/portal/${id.$oid}`)
                .then(response => {
                    // console.log(response);

                    let body = response.data.body;
                    // console.log("change portal");
                    // console.log(body);
                    if (body.portal) {
                        this.$store.commit(
                            "mapState/setTileProviders",
                            body.portal.config.tileProviders || []
                        );

                        // JSON responses are automatically parsed.
                        this.layers = body.portal.layers;
                        this.layerSummaries = body.portal.layerSummaries;
                        // this.$store.commit("mapState/setLayers", body.portal.layers);
                        this.clientLogo = body.portal.config.logo;
                        this.clientName = body.portal.config.name;
                        this.documentConfig = body.portal.config.documents;
                        this.fieldTranslation =
                            body.portal.config.fieldTranslation;
                        this.notifications = body.notifications;

                        this.portalAllowsManagedLayers =
                            body.portal.config.allowManagedLayers || false;
                        this.portalAllowsClientLayers =
                            body.portal.config.allowClientLayers || false;
                        this.portalAllowsDocUploads =
                            body.portal.config.allowDocUploads || false;

                        this.clientPrivacyPolicy =
                            body.portal.config.privacyPolicy || null;

                        // set the center point and zoom
                        // use defaults if not set
                        if (body.mapDefaults) {
                            let mapDefaults = body.mapDefaults;
                            this.latitude = mapDefaults.center
                                ? mapDefaults.center.lat
                                : 52.7445;
                            this.longitude = mapDefaults.center
                                ? mapDefaults.center.lng
                                : -1.4757;
                            this.zoom = mapDefaults.zoom || 13;
                        } else {
                            this.latitude = 52.7445;
                            this.longitude = -1.4757;
                            this.zoom = 13;
                        }
                    } else {
                    }

                    this.$eventHub.$emit("set-sidebar-loading-state", false);

                    // console.log('fieldTranslation');
                    // console.log(this.fieldTranslation);

                    // this.userPortals = body.userPortals;
                    // this.isLoading = false;
                })
                .catch(e => {
                    this.$eventHub.$emit("set-sidebar-loading-state", false);
                    console.log(e);
                });
        }
    }
};
</script>

<style lang="scss" scoped>
.placeholder-button {
    background-color: transparent;
    &:focus,
    &:hover,
    &:active {
        border-color: transparent;
        box-shadow: none;
        cursor: default;
    }
}
.fa-stack {
    font-size: 0.5em;
}
i {
    vertical-align: middle;
}

#placeholder-logo {
    height: 100px;
    width: 100%;
    background-color: #d4d4d7;
    // background-color: #898A93;
    // color: rgb(40, 41, 43);
    color: #898a93;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5em;
    // margin: 0 auto;
}

div#portal-selection {
    padding-top: 15px;

    .client-portal-select {
        width: 85%;
        // border: 1px solid #DDDDDD;
        margin-bottom: 10px;
        padding: 5px;

        // &:hover {
        //     background-color: #dddddd;
        // }

        .selected-portal-icon {
            font-size: 1.5em;
        }
    }

    .client-portal-title-text {
        margin-right: 15px;
        margin-bottom: 15px;
    }
}
</style>
