<template>
    <div id="login-form">
        <b-loading
            :is-full-page="true"
            :active.sync="isLoading"
            :can-cancel="false"
        ></b-loading>
        <section class="hero">
            <div class="hero-body">
                <div class="columns" v-if="errors.length > 0">
                    <div class="column is-half is-offset-one-quarter errors">
                        <div class="notification is-danger">
                            <ul>
                                <li v-for="(error, i) in errors" :key="i">
                                    <!-- {{ error }} -->
                                    <span class="content" v-html="error"></span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="columns">
                    <div class="column is-half is-offset-one-quarter has-text-centered">
                        <img id="banner-logo" src="@/assets/logo.svg">
                        <h1 class="title is-2" id="banner-title">
                            PROPERTY PORTAL
                        </h1>
                    </div>
                </div>

                <div class="columns">
                    <div class="column is-half is-offset-one-quarter">
                        <b-field
                            horizontal
                            label="Email"
                            :type="
                                errorFields.includes('email') ? 'is-danger' : ''
                            "
                        >
                            <b-input
                                @keyup.native.enter="submit"
                                @keyup.native="removeErrorField('email')"
                                name="email"
                                v-model="email"
                            ></b-input>
                        </b-field>

                        <b-field
                            horizontal
                            label="Temp Password"
                            :type="
                                errorFields.includes('tmpPassword')
                                    ? 'is-danger'
                                    : ''
                            "
                        >
                            <b-input
                                @keyup.native.enter="submit"
                                @keyup.native="removeErrorField('tmpPassword')"
                                name="tmp-password"
                                type="password"
                                password-reveal
                                v-model="tmpPassword"
                            ></b-input>
                        </b-field>
                        <hr />
                        <b-notification type="is-info" has-icon>
                            Password must contain:
                            <div class="content">
                                <ul>
                                    <li>At least 1 lowercase letter.</li>
                                    <li>At least 1 uppercase letter.</li>
                                    <li>At least 1 numeric character.</li>
                                    <li>At least 1 special character.</li>
                                    <li>Be at least 8 characters long.</li>
                                </ul>
                            </div>
                        </b-notification>

                        <b-field
                            horizontal
                            label="New Password"
                            :type="
                                errorFields.includes('newPassword')
                                    ? 'is-danger'
                                    : ''
                            "
                        >
                            <b-input
                                @keyup.native.enter="submit"
                                @keyup.native="removeErrorField('newPassword')"
                                name="new-password"
                                type="password"
                                password-reveal
                                v-model="newPassword"
                            ></b-input>
                        </b-field>

                        <b-field
                            horizontal
                            label="Confirm Password"
                            :type="
                                errorFields.includes('passwordConf')
                                    ? 'is-danger'
                                    : ''
                            "
                        >
                            <b-input
                                @keyup.native.enter="submit"
                                @keyup.native="removeErrorField('passwordConf')"
                                name="confirm-password"
                                type="password"
                                password-reveal
                                v-model="passwordConf"
                            ></b-input>
                        </b-field>

                        <b-field horizontal
                            ><!-- Label left empty for spacing -->
                            <p class="control">
                                <button
                                    class="button is-primary"
                                    @click="handleSubmit"
                                >
                                    Confirm
                                </button>
                            </p>
                        </b-field>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import axios from "axios";
export default {
    name: "LoginForm",
    components: {},
    props: {
        token: String
    },
    data() {
        return {
            email: "",
            tmpPassword: "",
            newPassword: "",
            passwordConf: "",
            errors: [],
            errorFields: [],
            isLoading: false
            //   test: this.$store.getters.test
        };
    },
    mounted() {
        // console.log(this.$route);
    },
    computed: {
        // passwordStrength: function() {
        //     // Create an array and push all possible values that you want in password
        //         var matchedCase = new Array();
        //         matchedCase.push("[$@$!%*#?&]"); // Special Charector
        //         matchedCase.push("[A-Z]");      // Uppercase Alpabates
        //         matchedCase.push("[0-9]");      // Numbers
        //         matchedCase.push("[a-z]");     // Lowercase Alphabates
        //         // Check the conditions
        //         var ctr = 0;
        //         for (var i = 0; i < matchedCase.length; i++) {
        //             if (new RegExp(matchedCase[i]).test(password)) {
        //                 ctr++;
        //             }
        //         }
        // }
    },
    methods: {
        validatePassword() {
            // let passwordRegEx = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*\?])(?=.{8,})/;
            let passwordRegEx = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\!\@\#\$\%\^\&\*\?\)\(\+\=\.\<\>\{\}\[\]\:\;\'\"\|\~\`\_\-\\\/])(?=.{8,})/;

            return passwordRegEx.test(this.newPassword);
        },
        handleSubmit() {
            this.validateForm().then(valid => {
                if (valid) {
                    this.compileFormData().then(
                        formData => {
                            // console.log("formData");
                            // console.log(formData);
                            // console.log(this.camelToKebab(this.userType));

                            this.submit(formData);
                        },
                        error => {
                            console.log("error in compileFormData");
                            console.log(error);
                        }
                    );
                }
            });
        },
        async validateForm() {
            // reset errors for validation pass
            this.errorFields = [];
            this.errors = [];

            if (!this.token) {
                this.errors.push(
                    "Token is missing. did you follow the link from your verification email?"
                );
            }

            if (this.email === "") {
                this.errorFields.push("email");
                this.errors.push("Email: is required");
            } else {
                let emailRegEx = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                if (!emailRegEx.test(this.email)) {
                    this.errorFields.push("email");
                    this.errors.push("Email: please check format");
                }
            }

            if (this.tmpPassword === "") {
                this.errorFields.push("tmpPassword");
                this.errors.push("Temp Password: is required");
            }

            if (!this.validatePassword()) {
                this.errorFields.push("newPassword");
                this.errors.push(`New Password Must Conatin:
                    <ul>
                        <li> At least 1 lowercase letter. </li>
                        <li> At least 1 uppercase letter. </li>
                        <li> At least 1 numeric character. </li>
                        <li> At least 1 special character. </li>
                        <li> Be at least 8 characters long. </li>
                    </ul>
                `);
            }

            if (this.newPassword !== this.passwordConf) {
                this.errorFields.push("newPassword");
                this.errorFields.push("passwordConf");
                this.errors.push("Confirmation: doesn't match new password");
            }

            return this.errors.length === 0;
        },
        async compileFormData() {
            return {
                token: this.token,
                email: this.email,
                tmpPassword: this.tmpPassword,
                newPassword: this.newPassword
            };
        },
        async submit(formData) {
            // clear session data
            this.$cookie.remove("token");
            this.$store.commit("sessionState/setExpires", null);

            this.isLoading = true;
            this.$ppClient
                .post(
                    `${process.env.VUE_APP_BACKEND_BASE_URL}/${this.$route.name}`,
                    formData
                )
                .then(response => {
                    // console.log('verify response');
                    // console.log(response);

                    if (response.status === 200) {
                        let body = response.data.body;
                        if (body.status.valid) {
                            // console.log("response in verify");
                            // console.log(body);

                            this.$router.push({
                                name: "login",
                                params: { passedMessages: body.status.messages }
                            });
                            // this.$router.go(0);

                            // set session data
                            // let token = body.token;
                            // this.$cookie.set("token", token.val, {});
                            // this.$cookie.set("exp", token.exp, {});
                            // this.$store.commit("sessionState/setExpires", new Date( token.exp ));

                            // // this.$emit('loggedIn')
                            // if (this.$route.params.nextUrl != null) {
                            //     this.$router.push(this.$route.params.nextUrl).catch((err) => {
                            //         throw new Error(`Problem handling something: ${err}.`);
                            //     });
                            // } else {
                            //     // where do we send them by default?
                            //     // eg admin users etc
                            //     this.$router.push("/map").catch((err) => {
                            //         throw new Error(`Problem handling something: ${err}.`);
                            //     });
                            // }
                        } else {
                            this.isLoading = false;
                            this.errors = body.status.errors;
                        }
                    } else {
                        this.isLoading = false;
                        this.errors = response.data.body.errors;
                    }
                })
                .catch(error => {
                    this.errorFields = [
                        "email",
                        "tmpPassword",
                        "newPassword",
                        "passwordConf"
                    ];
                    this.errors.push("Something went wrong, please try again");
                    this.isLoading = false;
                    if (error.response) {
                        // Request made and server responded
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);

                        let errors = error.response.data.errors;
                        //   console.log(e.response);
                        this.errors = errors;
                    } else if (error.request) {
                        // The request was made but no response was received
                        console.log("error.request");

                        console.log(error.request);
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        console.log("undefined error");

                        console.log("Error", error.message);
                        // this.errors.push(
                        //     "Something went wrong. Please try again"
                        // );
                    }
                });
        },
        // async setCookie(token) {
        //     this.$cookie.set("token", token.val, {
        //         expires: token.exp
        //         // Secure: true,
        //         // SameSite: 'None',
        //     });
        //     return true;
        // },
        removeErrorField(field) {
            this.errorFields = this.errorFields.filter(el => el !== field);
            this.errors = [];
        }
    }
};
</script>

<style lang="scss">
.field-label.wide-label {
    flex-grow: 2;
}
</style>
