<template>
    <div id="selected-layer-details">
        <sidebar-header headerTitle="Site List"></sidebar-header>
        <h3 v-if="layerSelected" class="subtitle is-5 margin-top-15 is-capitalized">
            Layer: {{ selectedLayerName }}
        </h3>

        <h6 v-if="layerSelected" class="subtitle is-6 margin-top-15 is-capitalized">
            # Sites: {{ selectedLayerSites.length }}
        </h6>

        <b-table
            v-if="layerSelected"
            id="layer-details-table"
            class="trans-table layer-table"
            :data.sync="transformedSelectedLayerSites"
            default-sort="name"
            :row-class="(row, index) => isSelectedFeature(row)"
        >
        <!-- @sort="sort" -->
            <template slot-scope="props" class="test-class">
                <b-table-column
                    field="displayName"
                    label="Name"
                    sortable
                    :id="`site-table-${props.row._id.$oid}`"
                >
                    {{ props.row.displayName | scolon2space }}
                </b-table-column>

                <b-table-column field="show" label="Show">
                    <b-button
                        type="is-white"
                        @click="showFeature(props.row)"
                        title="Reveal this site on the map"
                    >
                        <font-awesome-icon :icon="'eye'"/>
                    </b-button>
                </b-table-column>

                <b-table-column field="show-feature-details" label="Site">
                    <b-button
                        @click="changeTab(props.row, 'feature-details')"
                        title="Reveal site and display the details for it"
                    >
                        <font-awesome-icon
                            :icon="['fad', 'city']"
                            :style="$iconStyle"
                        />
                    </b-button>
                </b-table-column>
            </template>
        </b-table>
    </div>
</template>

<script>
import { point } from "leaflet";
import { mapGetters } from "vuex";
import MapStateHelpers from "@/mixins/MapStateHelpers.js";
import FeatureStateHelpers from "@/mixins/FeatureStateHelpers.js";
import FieldMapHelpers from "@/mixins/FieldMapHelpers.js";

import SidebarHeader from "@/components/sidebar/SidebarHeader.vue";

export default {
    name: "LayerDetails",
    mixins: [MapStateHelpers, FeatureStateHelpers, FieldMapHelpers],
    components: { SidebarHeader },
    props: {},
    data() {
        return {};
    },
    created() {
        // as the display ref is deeply nested.
        // we listen to this event fired from FeatureDetails
        // and then manually update the data being used to populate
        // the table
        this.$eventHub.$on("portal-site-data-updated", args => {
            // console.log('global event');

            // console.log(
            //     args
            // );
            // console.log(args.updatedFields);
            // console.log(this.selectedLayerSites);

            let siteDataToUpdate = this.selectedLayerSites.find(
                site => site._id.$oid === args.siteId
            );

            args.updatedFields.forEach(updated => {
                siteDataToUpdate[updated.id] = updated.value;
            });
        });
    },
    computed: {
        // vuex getters mapped
        ...mapGetters({
            layerSelected: "mapState/layerSelected",
            selectedLayer: "mapState/selectedLayer",
            featureSelected: "mapState/featureSelected"
        }),
        selectedLayerSites: function() {
            // console.log(this.selectedLayer.sites);
            return this.selectedLayer.sites;
        },
        // to make the table sortable, the site names need transforming before adidng to columns
        transformedSelectedLayerSites: function() {
            // console.log('transformedSelectedLayerSites');
            // console.log(this.selectedLayerSites);
            
            
            let transformed = [];
            for(let site of this.selectedLayerSites) {
                // console.log(site);
                site.displayName = this.getSiteName(site);
                transformed.push(site);
            }

            return transformed;
        },
        selectedLayerName: function() {
            return this.selectedLayer ? this.selectedLayer.name : false;
        }

    },
    mounted() {
        // console.log(this.$refs.layerDetailsTable);
    },
    methods: {
        isSelectedFeature(row) {
            if (this.featureSelected) {
                if (row._id.$oid === this.selectedFeature._site.siteId.$oid) {
                    return "is-selected";
                }
            }
            return false;
        },
        sort(field, order) {
            // console.log(field, order);
        },

        getSiteName(row) {
            // console.log("layerDetails: getSiteName");
            // console.log(row);
            // console.log(this.selectedLayer);
            

            // is there a display ref field set?
            // used for custom layers
            if ( row.displayRef ) {
                // return row[row.displayRef]
                //     ? row[row.displayRef]
                //     : "Display Ref not set";
                return row[row.displayRef] || "Display Ref not set";
            }

            // fg managed layers have multiple field collections
            if( row.fields ) {

                if( row.fields.portal ) {
                    let displayRef = row.fields.portal.find(field => field.isDisplayRef );
                    return displayRef && displayRef.value
                    ? displayRef.value
                    : "Display Ref not set";
                }

                // is there a field translation set
                if( this.fieldTranslation ) {
                    // prioritise propman fields
                    if ( row.fields.propman ){
                        let fields = row.fields.propman;
                        let fieldMap = this.fieldMapFor('propman', 'propman');

                        return this.getDisplayRefFromMap(fields, fieldMap) || 'error';                        
                    }

                    if ( row.fields.geostore ){
                        let fields = row.fields.geostore;
                        let fieldMap = this.fieldMapFor(this.selectedLayer.popup, 'geostore');

                        return this.getDisplayRefFromMap(fields, fieldMap) || 'error';                        
                    }
                }
                

            }

            return "Site name not set";
        },
        async changeTab(selectedLayerSite, tabId) {
            await this.showFeature(selectedLayerSite);
            this.$nextTick(() => {
                let sidebar = this.$store.getters["sidebarState/sidebar"];
                // ensure the panel isn't disabled
                sidebar.enablePanel(tabId);
                sidebar.open(tabId);
            });
        },
        async showFeature(selectedLayerSite) {
            // console.log('layerDetails: showFeature()');
            // console.log(selectedLayerSite);
            // console.log(this.selectedLayer);

            // force the layer to be visible (so you can actually see it on the map)
            this.selectedLayer.visible = 1;

            // console.log("show feature");
            // console.log(selectedLayerSite);
            // console.log(this.selectedLayer);
            // console.log(this.$store.getters['mapState/map']);

            // let feature = this.selectedLayer.geometry[selectedLayerSite.featureType].find(function(f){
            //     console.log(`feature site id: ${f.siteId}`);
            //     console.log(`selected site id: ${selectedLayerSite._id.$oid}`);

            // });
            this.$nextTick(() => {
                let feature = this.selectedLayer.geometry[
                    selectedLayerSite.featureType
                ].find(f => f.siteId.$oid == selectedLayerSite._id.$oid);
                
                let mapFeature = this.$store.getters["mapState/map"]._layers[
                    feature._leaflet_id
                ];
                // console.log('feature');
                // console.log(feature);
                // console.log(mapFeature);
                // console.log('bounds');
                // console.log(mapFeature.getBounds().getCenter());

                // if(typeof mapFeature.getBounds === 'function') {
                //     this.$store.getters['mapState/map'].fitBounds(mapFeature.getBounds(), {
                //         // paddingTopLeft: new point(300, 0),
                //         // paddingBottomRight: new point(10, 0)
                //         paddingTopLeft  : [500, 50]
                //         });
                //     // this.zoom -= 1;
                // }else{
                //     this.latitude = mapFeature._latlng.lat;
                //     this.longitude = mapFeature._latlng.lng;
                // }

                // if(typeof mapFeature.getBounds === 'function') {
                //     let centrePoint = mapFeature.getBounds().getCenter();

                //     this.latitude = centrePoint.lat;
                //     this.longitude = centrePoint.lng;
                //     // this.zoom -= 1;
                // }else{
                //     this.latitude = mapFeature._latlng.lat;
                //     this.longitude = mapFeature._latlng.lng;
                // }

                let centrePoint = this.getCentrePoint(mapFeature);
                this.latitude = centrePoint.lat;
                this.longitude = centrePoint.lng;

                // console.log('style test');
                // console.log(this.$store.getters['mapState/featureStyle']('Point', 'defaultStyle'));

                // reset styling for any selected feature
                if (this.$store.getters["mapState/featureSelected"]) {
                    this.$store.getters["mapState/selectedFeature"].setStyle(
                        this.$store.getters["mapState/featureStyle"](
                            feature.type,
                            "defaultStyle",
                            this.selectedLayer.color
                        )
                    );
                }
                this.$store.commit("mapState/setSelectedFeature", mapFeature);
                mapFeature.setStyle(
                    this.$store.getters["mapState/featureStyle"](
                        feature.type,
                        "focusStyle"
                    )
                );
            });
            // so we can await the function completion
            return true;
        }
    }
};
</script>

<style lang="scss" scoped></style>
