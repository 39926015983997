<!-- Unlike the other map element components, this one may render a circle-marker or circle -->
<!-- I've doneit like this so I don't have to break the GeoJson specification in the back-end -->
<template>
    <!-- <l-circle-marker
        :options="{pmIgnore: true}"
        :radius="12"
        :stroke="true"
        :weight="0.1"
        :color="color"
        :fillOpacity="0.6"
        :fillColor="color"
        :lat-lng="getLatLng(feature.coordinates[1], feature.coordinates[0])"
        @add="added"
        @click="clicked"
        @mouseover="mouseover"
        @mouseout="mouseout"
    > -->
    <!-- @pm:edit="updateGeo" -->
    <!-- <l-tooltip v-if="popup !== false">
            <div>
                <h4> {{ popup.title }} </h4>
            </div>
        </l-tooltip> -->
    <!-- </l-circle-marker> -->

    <component
        :is="pointRenderer"
        :radius="radius"
        :stroke="true"
        :weight="0.1"
        :color="color"
        :fillOpacity="0.6"
        :fillColor="color"
        :lat-lng="getLatLng(feature.coordinates[1], feature.coordinates[0])"
        @add="added"
        @click="clicked"
        @mouseover="mouseover"
        @mouseout="mouseout"
    >
        <!-- @pm:edit="updateGeo" -->
        <l-tooltip
            :options="{ permanent: false, sticky: true }"
            v-if="displayRef"
        >
            <div>
                <!-- <h4> {{ popup.title }} </h4> -->
                <h4>{{ displayRef }}</h4>
            </div>
        </l-tooltip>
    </component>
</template>

<script>
import { latLng } from "leaflet";

import { LCircleMarker, LCircle, LTooltip } from "vue2-leaflet";

import MapFeature from "./MapFeature.js";
export default {
    extends: MapFeature,
    name: "MCircleMarker",
    components: {
        LCircleMarker,
        LCircle,
        LTooltip
    },
    props: {
        // title for tooltip
        color: String,
        // point: Object,
        layerId: String,
        layer: Object,
        feature: Object
    },
    data() {
        return {
            // defaultStyle: {
            //     weight: "0.1",
            //     fillOpacity: "0.6"
            // },
            // focusStyle: {
            //     weight: "3",
            //     color: '#FFF',
            //     fillOpacity: "0.8"
            // }
        };
    },
    mounted() {
        // this.$children[0].circleOptions.pmIgnore = true;
        // console.log(this.$children[0]);
        // console.log('circleMarker');
        // console.log(this.displayRef);
    },
    computed: {
        pointRenderer: function() {
            // console.log('pointRenderer');
            // console.log('feature');
            // console.log(this.feature);
            
            
            
            return this.feature.radius ? LCircle : LCircleMarker;
        },
        radius: function() {
            return this.pointRenderer == LCircle ? this.feature.radius : 12;
        }
    },
    methods: {
        getLatLng(lat, lng) {
            return latLng(lat, lng);
        }
    }
};
</script>

<style lang="scss" scoped></style>
