export default {
    computed: {
        // ...mapGetters({
        //     zoom: 'mapState/zoom',
        //     latitude: 'mapState/latitude',
        //     longitude: 'mapState/longitude',
        // }),
        map: {
            get() {
                return this.$store.getters["mapState/map"];
            },
            set(map) {
                this.$store.commit("mapState/setMap", map);
            }
        },
        zoom: {
            get() {
                return this.$store.getters["mapState/zoom"];
            },
            set(newVal) {
                this.$store.commit("mapState/setZoom", Number(newVal));
            }
        },
        latitude: {
            get() {
                return this.$store.getters["mapState/latitude"];
            },
            set(newVal) {
                this.$store.commit("mapState/setLatitude", newVal);
            }
        },
        longitude: {
            get() {
                return this.$store.getters["mapState/longitude"];
            },
            set(newVal) {
                this.$store.commit("mapState/setLongitude", newVal);
            }
        },
        lastClicked: {
            get() {
                return this.$store.getters["mapState/lastClicked"];
            },
            set(w3wResponse) {
                this.$store.commit("mapState/setLastClicked", w3wResponse);
            }
        },
        tileProviders: {
            get() {
                return this.$store.getters["mapState/tileProviders"];
            },
            set(tileProviders) {
                this.$store.commit("mapState/setTileProviders", tileProviders);
            }
        },
        selectedBaseLayer: {
            get() {
                return this.$store.getters["mapState/selectedBaseLayer"];
            },
            set(baseLayer) {
                this.$store.commit("mapState/setSelectedBaseLayer", baseLayer);
            }
        }
    },
}