<template>
<!-- controls the rendering of a single summary-->
    <div class="property-summary">
        <doughnut :chart-data="compileChartData(summary)" :options="options" class="chart-container" :styles="chartStyle" :width="380" :height="380"></doughnut>
        <summary-legend v-if="chart !== null" :chart="chart" :title="titleize( title )"></summary-legend>
    </div>
</template>

<script>

import Doughnut from "@/components/charts/Doughnut.js";
import PSummaryLegend from "@/components/sidebar/PSummaryLegend.vue";

const randomColor = require('randomcolor');

export default {
    // this component is required due to the way
    // chartjs/vue-chartjs generate html legends
    // as some summaries may have a lot of items in legends
    name: "PropertySummary",
    components: {Doughnut, summaryLegend: PSummaryLegend},
    props: {
        summary: {
            type: Object,
            default: null
        },
        title: String
    },
    data() { 
        return {
            // legend: null,
            chart: null,
            options: {
                legendCallback: this.setChart,
                // responsive: true,
                responsive: false,
                // maintainAspectRatio: true,
                // maintainAspectRatio: false,
                legend: {
                    // position: 'left'
                    display: false
                },
                title: {
                    display: true,
                    text: this.titleize( this.title )
                }
            },
            chartStyle: {
                // position: 'relative',
                width: '380px',
                height: '380px',
                "min-height": '380px',
                "min-width": '380px',
                // width: '100% !important',
                // margin: '0 auto'
            }
        } 
    },
    mounted () {
    },
    methods: {
        titleize( text ) {

        let spaced = text.replace( /([A-Z])/g, " $1" );
        return spaced.charAt(0).toUpperCase() + spaced.slice(1);
        },
        setChart(chart) {
            // we set the chart here so that we can use it in the legend component
            this.chart = chart;
        },
        compileChartData( summary ) {
            let labels = [];
            // let dataSets = [{
            let data = [];
            let colors = [];

            // let hues = ['red', 'orange', 'yellow', 'green', 'blue', 'purple']
            //     let hue = hues[Math.floor(Math.random() * hues.length)]
            //     console.log('hue');
            //     console.log(hue);
            // // }];
            for(let label in summary) {
                // console.log('compile');
                
                // console.log(datum);

                labels.push(label);
                data.push( summary[label] );


                
                
                colors.push( randomColor() ); 

                
            }
            let chartData = {
                labels: labels,
                datasets: [{
                    backgroundColor: colors,
                    data: data
                }]
            };
            // console.log(chartData);
            
            return chartData;
        }
    }
};
</script>
