var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"new-portal-modal"}},[_c('b-loading',{attrs:{"is-full-page":false,"active":_vm.loadingData,"can-cancel":false},on:{"update:active":function($event){_vm.loadingData=$event}}},[_c('font-awesome-icon',{style:(_vm.$iconStyle),attrs:{"icon":['fad', 'sync'],"size":"4x","spin":""}})],1),_c('div',{staticClass:"modal-card",staticStyle:{"width":"auto"}},[_c('header',{staticClass:"modal-card-head"},[_c('p',{staticClass:"modal-card-title"},[_vm._v(_vm._s(_vm.title))]),(_vm.mode === 'edit')?_c('span',{staticClass:"has-text-right"},[_c('div',{staticClass:"buttons"},[(_vm.sysAccess <= 1 && _vm.portalAccess <= 1)?_c('b-button',{attrs:{"type":"is-warning"},on:{"click":_vm.launchImporter}},[_vm._v(" Importer ")]):_vm._e(),_c('b-button',{attrs:{"type":"is-danger"},on:{"click":_vm.confirmDeleteLayer}},[_vm._v(" Delete Layer ")])],1)]):_vm._e()]),_c('section',{staticClass:"modal-card-body"},[_c('section',[(_vm.errors.length > 0)?_c('b-notification',{staticClass:"margin-bottom-15",attrs:{"type":"is-danger","role":"alert","aria-close-label":"Close notification"}},[_c('ul',_vm._l((_vm.errors),function(error,i){return _c('li',{key:i},[_vm._v(" "+_vm._s(error)+" ")])}),0)]):_vm._e()],1),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('b-field',{staticClass:"nested-label-field",attrs:{"grouped":""}},[_c('b-field',{attrs:{"expanded":"","label":"Layer Name","type":_vm.errorFields.includes('name')
                                    ? 'is-danger'
                                    : ''}},[_c('b-input',{attrs:{"size":"is-small"},nativeOn:{"keyup":function($event){return _vm.removeErrorField('name')}},model:{value:(_vm.name),callback:function ($$v) {_vm.name=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"name"}})],1),_c('b-field',{attrs:{"type":_vm.errorFields.includes('color')
                                    ? 'is-danger'
                                    : ''}},[_c('template',{slot:"label"},[_vm._v(" Colour "),_c('help-tooltip',{attrs:{"position":"is-bottom-left","padding-side":"left"}},[_c('p',[_vm._v(" When picking a colour, you may want to change the opacity. ")]),_c('p',[_vm._v(" Not changing this, will mean that you will not be able to see the map or any other features below the layer. ")]),_c('p',[_vm._v(" A good default value is 0.5 (can be changed directly in the 'A' text box) ")])])],1),_c('b-dropdown',{staticStyle:{"padding-left":"5px"},attrs:{"id":"color-picker-dropdown","position":"is-bottom-left"},on:{"active-change":function($event){return _vm.removeErrorField('color')}}},[_c('div',{attrs:{"slot":"trigger"},slot:"trigger"},[_c('div',{attrs:{"id":"color-picker-wrap"}},[_c('b-tag',{staticClass:"color-picker-tag",class:_vm.errorFields.includes(
                                                    'color'
                                                )
                                                    ? 'has-error'
                                                    : '',style:(_vm.colorTagStyle)},[_c('span',[_vm._v(" "+_vm._s(!_vm.color ? "Choose A Color" : _vm.color)+" ")]),(
                                                    _vm.errorFields.includes(
                                                        'color'
                                                    )
                                                )?_c('span',{staticClass:"icon is-right has-text-danger is-small"},[_c('i',{staticClass:"fas fa-exclamation-circle"})]):_vm._e()]),_c('div',{attrs:{"id":"color-picker-checkboard"}})],1)]),_c('b-dropdown-item',{attrs:{"id":"color-picker-dropdown-item","focusable":false,"custom":""}},[_c('sketch-picker',{attrs:{"id":"color-picker","presetColors":[
                                            'rgba(165, 0, 0, 0.5)',
                                            'rgba(255, 0, 0, 0.5)',
                                            'rgba(255, 128, 0, 0.5)',
                                            'rgba(255, 255, 0, 0.5)',
                                            'rgba(0, 128, 0, 0.5)',
                                            'rgba(0, 255, 0, 0.5)',
                                            'rgba(0, 255, 144, 0.5)',
                                            'rgba(0, 255, 255, 0.5)',
                                            'rgba(0, 128, 255, 0.5)',
                                            'rgba(128, 128, 255, 0.5)',
                                            'rgba(0, 0, 255, 0.5)',
                                            'rgba(0, 0, 109, 0.5)',
                                            'rgba(128, 0, 255, 0.5)',
                                            'rgba(128, 0, 128, 0.5)',
                                            'rgba(255, 0, 255, 0.5)',
                                            'rgba(255, 128, 255, 0.5)'
                                        ]},on:{"input":function($event){return _vm.updateColor($event)}},model:{value:(_vm.colors),callback:function ($$v) {_vm.colors=$$v},expression:"colors"}})],1)],1)],2)],1)],1)]),_c('h3',{staticClass:"subtitle is-5 header-with-button",class:_vm.errorFields.includes('fields') ? 'has-text-danger' : ''},[_vm._v(" Layer Fields "),_c('help-tooltip',{attrs:{"padding-side":"left"}},[_c('p',[_vm._v(" These are the fields for each site in this layer. ")])]),_c('b-button',{staticClass:"has-margin-left-5",attrs:{"type":"","title":"Add a field to the layer"},on:{"click":_vm.addField}},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'plus']}})],1)],1),_c('section',[(_vm.mode === 'edit')?_c('b-notification',{staticClass:"margin-bottom-15",attrs:{"type":"is-warning","role":"alert","aria-close-label":"Close notification"}},[_c('p',[_vm._v(" Editing field types could cause unexpected results. ")]),_c('p',[_vm._v(" If you wish to change the type of a field, you should first delete the old field and recreate it. ")])]):_vm._e()],1),_vm._l((_vm.fields),function(field){return _c('div',{key:field.index,staticClass:"columns nested-form-fields"},[_c('div',{staticClass:"column"},[_c('b-field',{staticClass:"nested-label-field",attrs:{"grouped":""}},[_c('b-field',{attrs:{"label":"Field Name","type":_vm.errorFields.includes(`${field.index}-name`)
                                    ? 'is-danger'
                                    : ''}},[_c('b-input',{attrs:{"size":"is-small"},nativeOn:{"keyup":function($event){return _vm.removeErrorField(`${field.index}-name`)},"change":function($event){return _vm.handleFieldNameChange($event, field)}},model:{value:(field.name),callback:function ($$v) {_vm.$set(field, "name", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"field.name"}})],1),_c('b-field',{attrs:{"label":"Field Type","type":_vm.errorFields.includes(`${field.index}-type`)
                                    ? 'is-danger'
                                    : ''}},[_c('b-select',{attrs:{"size":"is-small","disabled":_vm.editingExistingField(field)},nativeOn:{"change":function($event){return _vm.fieldTypeChange(field)}},model:{value:(field.type),callback:function ($$v) {_vm.$set(field, "type", $$v)},expression:"field.type"}},_vm._l((_vm.fieldTypes),function(fieldType){return _c('option',{key:fieldType.value,domProps:{"value":fieldType.value}},[_vm._v(_vm._s(fieldType.name))])}),0)],1),(field.type == 'string')?_c('div',{staticClass:"field"},[_c('b-field',{attrs:{"type":_vm.errorFields.includes(
                                        `${field.index}-length`
                                    )
                                        ? 'is-danger'
                                        : ''}},[_c('template',{slot:"label"},[_vm._v(" Max Lengh "),_c('help-tooltip',{attrs:{"position":"is-top-right","padding-side":"left","item-width":"20vw"}},[_c('p',[_vm._v(" Max Length is 255 characters by default. ")]),_c('p',[_vm._v(" Use this field to further limit the number of characters. ")]),_c('p',[_vm._v(" leave blank to use the deafualt maximum value of 255 characters. ")])])],1),_c('b-input',{attrs:{"size":"is-small","type":"number","min":"0","step":"1","disabled":_vm.editingExistingField(field)},nativeOn:{"keyup":function($event){return _vm.removeErrorField(
                                            `${field.index}-length`
                                        )}},model:{value:(field.length),callback:function ($$v) {_vm.$set(field, "length", $$v)},expression:"field.length"}})],2)],1):_vm._e(),(field.type == 'select')?_c('div',{staticClass:"field"},[_c('b-field',{attrs:{"type":_vm.errorFields.includes(
                                        `${field.index}-options`
                                    )
                                        ? 'is-danger'
                                        : ''}},[_c('template',{slot:"label"},[_vm._v(" Selection Choices "),_c('help-tooltip',{attrs:{"padding-side":"left","item-width":"21vw"}},[_c('p',[_vm._v(" Type in the options you would like to add to the select field. ")]),_c('p',[_vm._v(" After each option press 'enter' or 'comma' to add it. ")]),_c('p',[_vm._v(" you can also copy and paste terms seperated by commas eg: ")]),_c('br'),_c('p',[_vm._v(" option 1, option 2, option 3 ")]),_c('br'),_c('p',[_vm._v(" then press enter to add all options. ")]),_c('p',[_vm._v(" Options can be deleted by using the `backspace` key or clicking the 'x' icon. ")])])],1),_c('b-taginput',{staticStyle:{"max-width":"25vw"},attrs:{"size":"is-small","allow-new":true,"placeholder":field.options.length == 0
                                            ? 'Add some options'
                                            : ''},on:{"add":function($event){return _vm.removeErrorField(
                                            `${field.index}-options`
                                        )}},model:{value:(field.options),callback:function ($$v) {_vm.$set(field, "options", $$v)},expression:"field.options"}})],2)],1):_vm._e(),(
                                field.type == 'string' ||
                                    field.type == 'number'
                            )?_c('b-field',{attrs:{"custom-class":_vm.errorFields.includes('disp')
                                    ? 'has-text-danger'
                                    : ''}},[_c('template',{slot:"label"},[_c('span',[_vm._v(" Ref. ")]),_c('help-tooltip',{attrs:{"padding-side":"left","item-width":"15vw"}},[_c('p',[_vm._v(" You need a field to use as a display reference. ")]),_c('p',[_vm._v(" This is used for distinguishing the various sites. ")]),_c('p',[_vm._v(" For example, in the site list tab. ")]),_c('p',[_vm._v(" It needs to be either a text or number field ")])])],1),_c('b-switch',{attrs:{"type":"is-success"},on:{"input":function($event){return _vm.handleDisplayRefChange(
                                        $event,
                                        field.index
                                    )}},model:{value:(field.isDisplayRef),callback:function ($$v) {_vm.$set(field, "isDisplayRef", $$v)},expression:"field.isDisplayRef"}})],2):_vm._e(),(field.type != 'checkbox')?_c('b-field',{attrs:{"label":"Required"}},[_c('b-switch',{attrs:{"type":"is-success"},model:{value:(field.required),callback:function ($$v) {_vm.$set(field, "required", $$v)},expression:"field.required"}})],1):_vm._e(),_c('b-field',{attrs:{"label":"Delete"}},[_c('b-button',{attrs:{"size":"is-small","type":"","title":"Delete this field"},on:{"click":function($event){return _vm.confirmRemoveField(field)}}},[_c('font-awesome-icon',{staticClass:"fa-w-18",attrs:{"icon":['fas', 'trash']}})],1)],1)],1)],1)])})],2),_c('footer',{staticClass:"modal-card-foot"},[_c('button',{staticClass:"button",attrs:{"type":"button"},on:{"click":function($event){return _vm.$parent.close()}}},[_vm._v(" Cancel ")]),_c('button',{staticClass:"button is-primary",on:{"click":_vm.submit}},[_vm._v(" "+_vm._s(_vm.mode === "new" ? "Create" : "Update")+" ")])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }