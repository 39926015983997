import axios from 'axios';

// encapsulate the logic required for requests to w3w api

// set the defaults required across requests
const w3wClient = axios.create({
    baseURL: process.env.VUE_APP_W3W_BASE_URL,
    params: {
        key: process.env.VUE_APP_W3W_KEY
    }
});

w3wClient.interceptors.request.use((config) => {
    config.params = config.params || {};
    config.params['key'] = process.env.VUE_APP_W3W_KEY;
    return config;
});

export default w3wClient;