<template>
    <b-dropdown
        v-bind="dropdownProps"
        :style="`padding-${paddingSide}: ${paddingAmount}`"
    >
        <p slot="trigger">
            <font-awesome-icon
                :icon="['fas', 'info-circle']"
                class="info-dropdown"
            ></font-awesome-icon>
        </p>
        <b-dropdown-item
            :focusable="false"
            custom
            id="help-tooltip-dd-item"
            style="min-width: 250px;"
            :style="itemWidth ? `width: ${itemWidth}` : ''"
        >
            <div style="font-size: 0.8rem; font-weight: 400; width: 100%">
                <slot></slot>
            </div>
        </b-dropdown-item>
    </b-dropdown>
</template>

<script>
export default {
    name: "HelpTooltip",
    components: {},
    props: {
        // see https://buefy.org/documentation/dropdown for possible vals
        // defaults to bottom right
        // can also use is-top-right, is-top-left, is-bottom-left
        position: String,
        // left or right padding
        paddingSide: String,
        // amount in px (eg 5px)
        paddingAmount: {
            type: String,
            default: "5px"
        },
        itemWidth: String
    },
    data() {
        return {};
    },
    computed: {
        // dynamic assignment of props for the b-dropdown component
        // the default of bottom right, can only be used by not setting any position prop
        // @see https://vuejs.org/v2/guide/components-props.html#Passing-the-Properties-of-an-Object
        dropdownProps() {
            let dropdownProps = {};
            if (this.position) {
                dropdownProps.position = this.position;
            }
            return dropdownProps;
        }
    },
    mounted() {},
    methods: {}
};
</script>

<style lang="scss" scoped>
.info-dropdown:hover {
    color: var(--fg-grey);
}
</style>

<style lang="scss">
#help-tooltip-dd-item {
    p {
        margin-bottom: 5px;
    }
}
</style>
