<template>
    <div id="new-portal-modal">
        <!-- <b-loading
            :is-full-page="false"
            :active.sync="loadingData"
            :can-cancel="false"
        >
            <font-awesome-icon
                :icon="['fad', 'sync']"
                :style="$iconStyle"
                size="4x"
                spin
            />
        </b-loading> -->

        <div class="modal-card" style="width: auto">
            <header class="modal-card-head">
                <p v-if="mode == 'new'" class="modal-card-title">New Portal</p>
                <p v-else class="modal-card-title">
                    Edit Portal: {{ editPortalData.name }}
                </p>

                <span v-if="mode === 'edit'" class="has-text-right">
                    <div class="buttons">
                        <b-button type="is-danger" @click="confirmDeletePortal">
                            Delete Portal
                        </b-button>
                    </div>
                </span>
            </header>

            <b-loading
                :is-full-page="false"
                :active.sync="loadingData"
                :can-cancel="false"
            >
                <font-awesome-icon
                    :icon="['fad', 'sync']"
                    :style="$iconStyle"
                    size="4x"
                    spin
                />
            </b-loading>

            <section class="modal-card-body">
                <section>
                    <b-notification
                        type="is-danger"
                        class="margin-bottom-15"
                        role="alert"
                        v-if="errors.length > 0"
                        aria-close-label="Close notification"
                    >
                        <ul>
                            <li v-for="(error, i) in errors" :key="i">
                                {{ error }}
                            </li>
                        </ul>
                    </b-notification>
                </section>
                <div class="columns">
                    <div class="column is-half is-text-center">
                        <h6>
                            <b-field
                                label="Portal Logo:"
                                class="nested-label-field"
                                horizontal
                            >
                                <b-dropdown>
                                    <p slot="trigger">
                                        <font-awesome-icon
                                            :icon="['fas', 'info-circle']"
                                            class="info-dropdown"
                                        />
                                    </p>
                                    <b-dropdown-item
                                        :focusable="false"
                                        custom
                                        style="width: 30vw;"
                                    >
                                        <span style="font-size: 13px;">
                                            <strong>Portal Logo:</strong><br />
                                            <p>
                                                For best results, portal logos
                                                should be a 2:1 ratio of width
                                                to height <br />
                                                (eg, 400 x 200, 800 X 400 etc)
                                            </p>
                                            <p>
                                                However, after uploading, you
                                                can use this box to manipulate
                                                the image (scroll/pinch to zoom
                                                and drag to move).
                                            </p>
                                            <p>
                                                Try and get the logo as
                                                centrally aligned as possible.
                                            </p>
                                        </span>
                                    </b-dropdown-item>
                                </b-dropdown>
                            </b-field>
                            <!-- <b-button @click="centerImage">
                                        center image
                                    </b-button> -->

                            <!-- <b-button @click="saveTest">
                                        save image
                                    </b-button> -->
                        </h6>
                        <croppa
                            :width="400"
                            :height="200"
                            :show-loading="true"
                            :prevent-white-space="false"
                            remove-button-color="black"
                            v-model="croppaImage"
                            :initial-image="loadedLogo"
                            @file-choose="fileChoose"
                            @initial-image-loaded="initImage($event)"
                        >
                            <!-- <img
                                v-if="mode == 'edit' && editPortalData.logo"
                                slot="initial"
                                :src="
                                    `data:image/png;base64,${editPortalData.logo}`
                                "
                            /> -->
                        </croppa>
                        <!-- canvas-color="white" -->
                    </div>
                    <!-- :quality="2" -->
                    <div class="column is-half">
                        <b-field
                            label="Portal Name:"
                            class="nested-label-field"
                            :type="
                                errorFields.includes('portalName')
                                    ? 'is-danger'
                                    : ''
                            "
                        >
                            <b-input
                                @keyup.native="removeErrorField('portalName')"
                                size="is-small"
                                v-model.trim="portalName"
                            >
                            </b-input>
                        </b-field>

                        <h6 class="title is-6">Layer Configuration:</h6>
                        <!-- internal layers -->
                        <div class="field">
                            <b-switch
                                type="is-success"
                                v-model="allowClientLayers"
                            >
                                Allow Client Layers
                            </b-switch>

                            <b-dropdown
                                position="is-top-left"
                                style="padding-left: 5px;"
                            >
                                <p slot="trigger">
                                    <font-awesome-icon
                                        :icon="['fas', 'info-circle']"
                                        class="info-dropdown"
                                    />
                                </p>
                                <b-dropdown-item :focusable="false" custom>
                                    <span style="font-size: 13px;">
                                        <p>
                                            These Layers can be managed directly
                                            by authorized users.
                                        </p>
                                    </span>
                                </b-dropdown-item>
                            </b-dropdown>
                        </div>

                        <!-- external layers -->
                        <div class="field">
                            <b-switch
                                type="is-success"
                                v-model="allowManagedLayers"
                            >
                                Allow FG Managed Layers
                            </b-switch>

                            <b-dropdown
                                position="is-bottom-left"
                                style="padding-left: 5px;"
                            >
                                <p slot="trigger">
                                    <font-awesome-icon
                                        :icon="['fas', 'info-circle']"
                                        class="info-dropdown"
                                    />
                                </p>
                                <b-dropdown-item :focusable="false" custom>
                                    <span style="font-size: 13px;">
                                        <p>
                                            FG Managed Layers currently include
                                            propman and registered titles.
                                        </p>
                                        <p>
                                            They will require setting up on
                                            Geostore.
                                        </p>
                                    </span>
                                </b-dropdown-item>
                            </b-dropdown>
                        </div>

                        <!-- <b-field grouped> -->
                        <b-field
                            label="Geostore Client:"
                            class="nested-label-field"
                            v-if="allowManagedLayers"
                        >
                            <b-field
                                :type="
                                    errorFields.includes('geostoreClientId')
                                        ? 'is-danger'
                                        : ''
                                "
                            >
                                <b-select
                                    placeholder="Please Select A Geostore Client"
                                    v-model.trim="geostoreClientId"
                                    @change.native="geostoreClientIdUpdated"
                                    size="is-small"
                                    expanded
                                >
                                    <option
                                        v-for="fetchedGSClient in fetchedGeostoreClients"
                                        :key="fetchedGSClient._id.$oid"
                                        :value="fetchedGSClient._id.$oid"
                                        >{{ fetchedGSClient.longName }}
                                    </option>
                                </b-select>
                            </b-field>
                        </b-field>
                    </div>
                </div>

                <div v-show="allowManagedLayers">
                    <h3 class="subtitle is-5 margin-top-15">
                        FG Managed Layers

                        <b-button
                            type=""
                            title="Add an FG managed Layer"
                            @click="addExternalLayer"
                            class="has-margin-left-5"
                        >
                            <font-awesome-icon :icon="['fas', 'plus']" />
                        </b-button>
                    </h3>

                    <div
                        class="columns"
                        v-show="allowManagedLayers && propmanClientIdRequired"
                    >
                        <div class="column">
                            <b-field grouped class="nested-label-field">
                                <b-field
                                    expanded
                                    label="Propman Client ID:"
                                    class="nested-label-field"
                                    :type="
                                        errorFields.includes('propmanClientId')
                                            ? 'is-danger'
                                            : ''
                                    "
                                >
                                    <!-- <b-select
                                        placeholder="Please Select A Propman Client"
                                        v-model.trim="propmanClientId"
                                        @change.native="propmanClientIdUpdated"
                                        size="is-small"
                                        expanded
                                    >
                                        <option
                                            v-for="fetchedPMClient in fetchedPropmanClients"
                                            :key="fetchedPMClient.clientId"
                                            :value="fetchedPMClient.clientId"
                                            >{{ fetchedPMClient.clientId }} ({{
                                                fetchedPMClient.name
                                            }})
                                        </option>
                                    </b-select> -->

                                    <b-autocomplete
                                        ref="propmanClientSelect"
                                        v-model="propmanFilterTerm"
                                        placeholder="Please Select A Propman Client, type to filter"
                                        :open-on-focus="true"
                                        :data="filteredPropmanData"
                                        :loading="propmanClientsLoading"
                                        field="clientId"
                                        @select="propmanClientIdUpdated"
                                        clearable
                                    >
                                    <!-- propmanClientId -->
                                        <template slot-scope="props">
                                            {{ props.option.clientId }} ({{ props.option.name }})
                                        </template>
                                    </b-autocomplete>
                                </b-field>

                                <!-- <b-field
                                    label="Propman Office:"
                                    class="nested-label-field"
                                    :type="
                                        errorFields.includes('propmanOffice')
                                            ? 'is-danger'
                                            : ''
                                    "
                                >
                                    <b-input
                                        size="is-small"
                                        v-model.trim="propmanOffice"
                                        @keyup.native="
                                            removeErrorField('propmanOffice')
                                        "
                                        disabled
                                    >
                                    </b-input>
                                </b-field> -->

                                <div class="field">
                                    <p class="control">
                                        <label class="label"
                                            >&nbsp;<br
                                        /></label>
                                        <b-switch
                                            type="is-success"
                                            v-model="propmanAltRef"
                                        >
                                            Use Alternate Ref
                                        </b-switch>

                                        <b-dropdown
                                            position="is-top-left"
                                            style="padding-left: 5px;"
                                        >
                                            <p slot="trigger">
                                                <font-awesome-icon
                                                    :icon="[
                                                        'fas',
                                                        'info-circle'
                                                    ]"
                                                    class="info-dropdown"
                                                />
                                            </p>
                                            <b-dropdown-item
                                                :focusable="false"
                                                custom
                                            >
                                                <span style="font-size: 13px;">
                                                    <p>
                                                        Some clients use the
                                                        'UNITADDRESS' field in
                                                        propman to join data to
                                                        Geostore.
                                                    </p>
                                                    <p>
                                                        Does this client need to
                                                        use that instead of the
                                                        default 'Property-No'
                                                        field?
                                                    </p>
                                                </span>
                                            </b-dropdown-item>
                                        </b-dropdown>
                                    </p>
                                </div>
                            </b-field>
                        </div>
                    </div>

                    <div
                        class="columns nested-form-fields"
                        v-for="externalLayer in externalLayers"
                        :key="externalLayer.index"
                    >
                        <div class="column">
                            <b-field grouped class="nested-label-field">
                                <b-field
                                    label="Layer Type"
                                    :type="
                                        errorFields.includes(
                                            `${externalLayer.index}-type`
                                        )
                                            ? 'is-danger'
                                            : ''
                                    "
                                >
                                    <b-select
                                        size="is-small"
                                        v-model="externalLayer.type"
                                        @change.native="
                                            removeErrorField(
                                                `${externalLayer.index}-type`
                                            )
                                        "
                                    >
                                        <option
                                            v-for="layerType in geostoreLayerTypes"
                                            :key="layerType.value"
                                            :value="layerType.value"
                                            >{{ layerType.name }}</option
                                        >
                                    </b-select>
                                </b-field>

                                <!-- @todo: these can be selects by using the  -->
                                <!-- getLayerDetails with clientId param -->
                                <b-field
                                    label="Geostore Layer"
                                    :type="
                                        errorFields.includes(
                                            `${externalLayer.index}-geostoreJID`
                                        )
                                            ? 'is-danger'
                                            : ''
                                    "
                                    expanded
                                >
                                    <!-- <b-input
                                        size="is-small"
                                        v-model="externalLayer.geostoreJID"
                                    ></b-input> -->
                                    <b-select
                                        v-if="fetchedGeostoreLayers.length > 0"
                                        v-model="externalLayer.geostoreJID"
                                        @change.native="
                                            removeErrorField(
                                                `${externalLayer.index}-geostoreJID`
                                            )
                                        "
                                        size="is-small"
                                        expanded
                                    >
                                        <option
                                            v-for="fetchedGSLayer in fetchedGeostoreLayers"
                                            :key="fetchedGSLayer.job_id"
                                            :value="fetchedGSLayer.job_id"
                                            >{{ fetchedGSLayer.layer }} -
                                            {{ fetchedGSLayer.status }}
                                        </option>
                                    </b-select>
                                    <b-input
                                        v-else
                                        size="is-small"
                                        placeholder="Please load Geostore Layers"
                                        disabled
                                    ></b-input>
                                </b-field>

                                <b-field expanded>
                                    <template slot="label">
                                        Display Name
                                        <b-dropdown
                                            position="is-top-right"
                                            style="padding-left: 5px;"
                                        >
                                            <p slot="trigger">
                                                <font-awesome-icon
                                                    :icon="[
                                                        'fas',
                                                        'info-circle'
                                                    ]"
                                                    class="info-dropdown"
                                                />
                                            </p>
                                            <b-dropdown-item
                                                :focusable="false"
                                                custom
                                            >
                                                <span
                                                    style="font-size: 13px; font-weight: 400;"
                                                >
                                                    <p>
                                                        Leave blank to use the
                                                        geostore layer name.
                                                    </p>
                                                </span>
                                            </b-dropdown-item>
                                        </b-dropdown>
                                    </template>
                                    <b-input
                                        size="is-small"
                                        v-model="externalLayer.name"
                                    ></b-input>
                                </b-field>

                                <b-field
                                    :type="
                                        errorFields.includes(
                                            `${externalLayer.index}-color`
                                        )
                                            ? 'is-danger'
                                            : ''
                                    "
                                >
                                    <template slot="label">
                                        Colour
                                        <help-tooltip
                                            position="is-bottom-left"
                                            padding-side="left"
                                        >
                                            <p>
                                                When picking a colour, you may
                                                want to change the opacity.
                                            </p>
                                            <p>
                                                Not changing this, will mean
                                                that you will not be able to see
                                                the map or any other features
                                                below the layer.
                                            </p>
                                            <p>
                                                A good default value is 0.5 (can
                                                be changed directly in the 'A'
                                                text box)
                                            </p>
                                        </help-tooltip>
                                    </template>
                                    <b-dropdown
                                        id="color-picker-dropdown"
                                        position="is-top-left"
                                        style="padding-left: 5px;"
                                        @active-change="
                                            removeErrorField(
                                                `${externalLayer.index}-color`
                                            )
                                        "
                                    >
                                        <div slot="trigger">
                                            <div id="color-picker-wrap">
                                                <b-tag
                                                    class="color-picker-tag"
                                                    :class="
                                                        errorFields.includes(
                                                            `${externalLayer.index}-color`
                                                        )
                                                            ? 'has-error'
                                                            : ''
                                                    "
                                                    :style="
                                                        colorTagStyle(
                                                            externalLayer
                                                        )
                                                    "
                                                >
                                                    <span>
                                                        {{
                                                            !externalLayer.color
                                                                ? "Choose A Color"
                                                                : externalLayer.color
                                                        }}
                                                    </span>
                                                    <span
                                                        v-if="
                                                            errorFields.includes(
                                                                `${externalLayer.index}-color`
                                                            )
                                                        "
                                                        class="icon is-right has-text-danger is-small"
                                                    >
                                                        <i
                                                            class="fas fa-exclamation-circle"
                                                        ></i>
                                                    </span>
                                                </b-tag>
                                                <div
                                                    id="color-picker-checkboard"
                                                ></div>
                                            </div>
                                        </div>
                                        <b-dropdown-item
                                            id="color-picker-dropdown-item"
                                            :focusable="false"
                                            custom
                                        >
                                            <sketch-picker
                                                id="color-picker"
                                                :presetColors="[
                                                    'rgba(165, 0, 0, 0.5)',
                                                    'rgba(255, 0, 0, 0.5)',
                                                    'rgba(255, 128, 0, 0.5)',
                                                    'rgba(255, 255, 0, 0.5)',
                                                    'rgba(0, 128, 0, 0.5)',
                                                    'rgba(0, 255, 0, 0.5)',
                                                    'rgba(0, 255, 144, 0.5)',
                                                    'rgba(0, 255, 255, 0.5)',
                                                    'rgba(0, 128, 255, 0.5)',
                                                    'rgba(128, 128, 255, 0.5)',
                                                    'rgba(0, 0, 255, 0.5)',
                                                    'rgba(0, 0, 109, 0.5)',
                                                    'rgba(128, 0, 255, 0.5)',
                                                    'rgba(128, 0, 128, 0.5)',
                                                    'rgba(255, 0, 255, 0.5)',
                                                    'rgba(255, 128, 255, 0.5)'
                                                ]"
                                                v-model="externalLayer.colorObj"
                                                @input="
                                                    updateColor(
                                                        $event,
                                                        externalLayer
                                                    )
                                                "
                                            />
                                            <!-- may need to create objects for each layer -->
                                            <!-- v-model="externalLayer.colorObj" -->
                                            <!-- :value="!externalLayer.color ? '#00FF00' : externalLayer.color" -->
                                        </b-dropdown-item>
                                    </b-dropdown>
                                </b-field>

                                <b-field label="">
                                    <p class="control">
                                        <label class="label"
                                            >&nbsp;<br
                                        /></label>
                                        <b-button
                                            size="is-small"
                                            type=""
                                            title="Delete this layer"
                                            @click="
                                                removeExternalLayer(
                                                    externalLayer.index
                                                )
                                            "
                                        >
                                            <font-awesome-icon
                                                :icon="['fas', 'trash']"
                                                class="fa-w-18"
                                            />
                                        </b-button>
                                    </p>
                                </b-field>
                            </b-field>
                        </div>
                    </div>
                </div>

                <div class="columns">
                    <!-- additional config -->
                    <div class="column">
                        <h6 class="title is-6">Additional Details:</h6>
                        <div id="client-privacy-wrap" class="margin-bottom-15">
                            <b-field
                                class="nested-label-field"
                                :type="
                                    errorFields.includes('privacyPolicy')
                                        ? 'is-danger'
                                        : ''
                                "
                            >
                                <template slot="label">
                                    <span>
                                        Client Privacy Policy:
                                    </span>

                                    <help-tooltip
                                        position="is-top-right"
                                        padding-side="left"
                                        item-width="15vw"
                                    >
                                        <p>
                                            Please provide a url for the client
                                            privacy policy.
                                        </p>
                                        <p>
                                            This includes the http:// part of
                                            the url.
                                        </p>
                                        <p>
                                            Get the client to send you a link to
                                            it and make sure you check that it
                                            works before adding it here.
                                        </p>
                                    </help-tooltip>
                                </template>
                                <b-input
                                    @keyup.native="
                                        removeErrorField('privacyPolicy')
                                    "
                                    size="is-small"
                                    v-model.trim="privacyPolicy"
                                >
                                </b-input>
                            </b-field>
                        </div>

                        <div id="master-map-wrap" class="margin-bottom-15">
                            <!-- master map-->
                            <div class="field">
                                <b-switch
                                    type="is-success"
                                    v-model="allowMasterMap"
                                >
                                    Enable Master Map
                                </b-switch>

                                <b-dropdown
                                    position="is-top-left"
                                    style="padding-left: 5px;"
                                >
                                    <p slot="trigger">
                                        <font-awesome-icon
                                            :icon="['fas', 'info-circle']"
                                            class="info-dropdown"
                                        />
                                    </p>
                                    <b-dropdown-item :focusable="false" custom>
                                        <span style="font-size: 13px;">
                                            <p>
                                                Will the client be able to use
                                                Master Map as a base layer?
                                            </p>
                                        </span>
                                    </b-dropdown-item>
                                </b-dropdown>
                            </div>
                        </div>

                        <!-- document upload -->
                        <div class="field">
                            <b-switch
                                type="is-success"
                                v-model="allowDocUploads"
                            >
                                Allow Document uploads
                            </b-switch>

                            <b-dropdown
                                position="is-top-left"
                                style="padding-left: 5px;"
                            >
                                <p slot="trigger">
                                    <font-awesome-icon
                                        :icon="['fas', 'info-circle']"
                                        class="info-dropdown"
                                    />
                                </p>
                                <b-dropdown-item :focusable="false" custom>
                                    <span style="font-size: 13px;">
                                        <p>
                                            Will the client be able to upload
                                            documents?
                                        </p>
                                    </span>
                                </b-dropdown-item>
                            </b-dropdown>
                        </div>
                    </div>
                    <!-- document categories -->
                    <div class="column">
                        <div v-if="allowDocUploads">
                            <h3 class="subtitle is-5 margin-top-15">
                                Document Categories
                                <!-- <span class="buttons"> -->
                                <b-button
                                    type=""
                                    title="Add a new Category"
                                    @click="addDocumentCategory"
                                    class="has-margin-left-5"
                                >
                                    <font-awesome-icon
                                        :icon="['fas', 'plus']"
                                    />
                                </b-button>

                                <b-button
                                    type=""
                                    title="Load Default Categories"
                                    @click="loadDefaultCategories"
                                    class="has-margin-left-5"
                                >
                                    <font-awesome-icon
                                        v-if="loadingDefaultCats"
                                        :icon="['fad', 'sync']"
                                        :style="$iconStyle"
                                        spin
                                    />

                                    <font-awesome-icon
                                        v-else
                                        :icon="['fas', 'list']"
                                    />
                                </b-button>
                                <!-- </span> -->
                            </h3>

                            <div id="category-menu">
                                <b-menu :accordion="false">
                                    <b-menu-list>
                                        <b-menu-item
                                            v-for="category in documentCategories"
                                            :key="category.uuid"
                                            :active="false"
                                            :expanded="
                                                expandedCategories.includes(
                                                    category.name
                                                )
                                            "
                                            @click="
                                                categoryMenuItemClicked(
                                                    $event,
                                                    category.name
                                                )
                                            "
                                        >
                                            <!-- @click="
                                        categoryMenuItemClicked($event, category.name)
                                    " -->
                                            <template
                                                slot="label"
                                                slot-scope="props"
                                            >
                                                <b-dropdown
                                                    aria-role="list"
                                                    class="is-pulled-left"
                                                    position="is-top-right"
                                                    @click.native.stop
                                                >
                                                    <b-button
                                                        type=""
                                                        class="has-margin-right-5 is-small"
                                                        slot="trigger"
                                                    >
                                                        <b-icon
                                                            pack="fas"
                                                            icon="ellipsis-v"
                                                            size="is-small"
                                                        >
                                                        </b-icon>
                                                    </b-button>
                                                    <!-- @click="addDocumentSubCategory" -->
                                                    <!-- <b-icon icon="dots-vertical" slot="trigger"></b-icon> -->
                                                    <b-dropdown-item
                                                        aria-role="listitem"
                                                        @click="
                                                            addDocumentSubCategory(
                                                                $event,
                                                                category.uuid
                                                            )
                                                        "
                                                    >
                                                        Add a subcategory
                                                        <b-icon
                                                            class="has-margin-left-5"
                                                            pack="fas"
                                                            icon="plus"
                                                            size="is-small"
                                                        >
                                                        </b-icon>
                                                    </b-dropdown-item>
                                                    <b-dropdown-item
                                                        aria-role="listitem"
                                                        @click="
                                                            editDocumentCategory(
                                                                $event,
                                                                category.uuid
                                                            )
                                                        "
                                                    >
                                                        Edit
                                                        <b-icon
                                                            class="has-margin-left-5"
                                                            pack="fas"
                                                            icon="edit"
                                                            size="is-small"
                                                        >
                                                        </b-icon>
                                                    </b-dropdown-item>
                                                    <b-dropdown-item
                                                        aria-role="listitem"
                                                        @click="
                                                            removeDocumentCategory(
                                                                $event,
                                                                category.uuid
                                                            )
                                                        "
                                                    >
                                                        Delete
                                                        <b-icon
                                                            class="has-margin-left-5"
                                                            pack="fas"
                                                            icon="trash"
                                                            size="is-small"
                                                        >
                                                        </b-icon>
                                                    </b-dropdown-item>
                                                </b-dropdown>
                                                <div style="width: 100%">
                                                    <!-- @click="
                                                categoryMenuItemClicked(
                                                    category.name
                                                )
                                            " -->
                                                    {{ category.name }}
                                                    <font-awesome-icon
                                                        class="has-margin-left-5"
                                                        :icon="
                                                            props.expanded
                                                                ? [
                                                                    'fas',
                                                                    'caret-up'
                                                                    ]
                                                                : [
                                                                    'fas',
                                                                    'caret-down'
                                                                ]
                                                        "
                                                    />
                                                </div>

                                                <!-- <font-awesome-icon
                                            :icon="
                                                expandedCategories.includes(
                                                    category.name
                                                )
                                                    ? ['fas', 'caret-up']
                                                    : ['fas', 'caret-down']
                                            "
                                        /> -->
                                            </template>
                                            <b-menu-item
                                                v-for="subcat in category.subcats"
                                                :key="subcat.name"
                                            >
                                                <template slot="label">
                                                    <!-- slot-scope="props" -->
                                                    <b-dropdown
                                                        aria-role="list"
                                                        class="is-pulled-left"
                                                        position="is-top-right"
                                                    >
                                                        <b-button
                                                            type=""
                                                            class="has-margin-right-5 is-small"
                                                            slot="trigger"
                                                        >
                                                            <b-icon
                                                                pack="fas"
                                                                icon="ellipsis-v"
                                                                size="is-small"
                                                            >
                                                            </b-icon>
                                                        </b-button>
                                                        <!-- @click="addDocumentSubCategory" -->
                                                        <!-- <b-icon icon="dots-vertical" slot="trigger"></b-icon> -->

                                                        <b-dropdown-item
                                                            aria-role="listitem"
                                                            @click="
                                                                editDocumentSubCategory(
                                                                    $event,
                                                                    category.uuid,
                                                                    subcat.uuid
                                                                )
                                                            "
                                                        >
                                                            Edit
                                                            <b-icon
                                                                class="has-margin-left-5"
                                                                pack="fas"
                                                                icon="edit"
                                                                size="is-small"
                                                            >
                                                            </b-icon>
                                                        </b-dropdown-item>
                                                        <b-dropdown-item
                                                            aria-role="listitem"
                                                            @click="
                                                                removeDocumentSubCategory(
                                                                    $event,
                                                                    category.uuid,
                                                                    subcat.uuid
                                                                )
                                                            "
                                                        >
                                                            Delete
                                                            <b-icon
                                                                class="has-margin-left-5"
                                                                pack="fas"
                                                                icon="trash"
                                                                size="is-small"
                                                            >
                                                            </b-icon>
                                                        </b-dropdown-item>
                                                    </b-dropdown>
                                                    {{ subcat.name }}
                                                </template>
                                            </b-menu-item>
                                        </b-menu-item>
                                    </b-menu-list>
                                </b-menu>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <footer class="modal-card-foot">
                <div class="footer-buttons-left">
                    <button
                        class="button"
                        type="button"
                        @click="$parent.close()"
                    >
                        Cancel
                    </button>
                    <button class="button is-primary" @click="submit">
                        {{ mode == "new" ? "Create" : "Update" }}
                    </button>
                </div>

                <div class="footer-buttons-right" v-if="mode == 'edit'">
                    <button
                        class="button is-warning"
                        @click="handleSearchIndex"
                    >
                        Re-Index for Search
                    </button>
                </div>
            </footer>
        </div>
    </div>
</template>

<script>
import "vue-croppa/dist/vue-croppa.css";
import Croppa from "vue-croppa";
import { Sketch } from "vue-color";
import uuidv1 from "uuid/v1";
import DocCategory from "./DocCategory.vue";
// import ColorPicker from '@radial-color-picker/vue-color-picker';

import HelpTooltip from "@/components/notifications/HelpTooltip.vue";
import ColourHelpers from "@/mixins/ColourHelpers.js";

export default {
    name: "PortalConfigModal",
    mixins: [ColourHelpers],
    components: {
        croppa: Croppa.component,
        // "slider-picker": Slider,
        "sketch-picker": Sketch,
        HelpTooltip
    },
    props: {
        mode: String,
        editPortalData: Object
    },
    data() {
        return {
            loadingData: false,
            loadingDefaultCats: false,
            errorNotificationIsActive: true,
            portalName: "",
            croppaImage: {},
            loadedLogo: null,
            allowManagedLayers: false,
            externalLayers: [],
            allowClientLayers: false,
            internalLayers: [],
            allowDocUploads: false,
            docCatgeoryMappings: [],
            allowMasterMap: false,
            privacyPolicy: "",
            geostoreClientId: null,
            geostoreClientIdError: false,
            geostoreLayerTypes: [
                { value: "propman", name: "Propman" },
                { value: "registeredtitle", name: "Registered Title" },
                { value: "geostore", name: "Geostore" }
            ],
            fetchedGeostoreLayers: [],
            propmanClientId: null,
            propmanKey: "",
            propmanOffice: "central",
            propmanAltRef: false,
            errorFields: [],
            errors: [],
            // formData: {}
            fetchedGeostoreClients: [],
            fetchedPropmanClients: [],
            propmanFilterTerm: "", 
            expandedCategories: [],
            propmanClientsLoaded: false,
            portalConfigLoaded: false,
            // @todo make configurable and server-side
            // documentDefaults: {
            //     general: { retention: 5.0, uuid: uuidv1() },
            //     registeredtitle: { retention: 10.0, uuid: uuidv1() },
            //     client: { retention: 25.0, uuid: uuidv1() },
            //     fg: { retention: 6.0, uuid: uuidv1() }
            // },
            documentDefaults: null,
            documentCategories: [],
            users: []
        };
    },
    watch: {
        // propmanClientsLoaded
        requiredPropmanDataforEditLoaded: function(loaded, oldVal) {
            if(loaded) {
                this.$refs.propmanClientSelect.setSelected(this.propmanClientId);
            }
        }
    },
    mounted() {
        this.$nextTick(()=>{
            if (this.mode == "edit") {
                this.loadingData = true;
                this.fetchUsers();
            }
            // get the document defaults from server (currently only retentionPolicy)
            // this.setDocDefaults();

            this.fetchGeostoreClients().then(response => {
                // console.log(this.fetchedGeostoreClients);

                this.fetchPropmanClients();
                // console.log("mounted");
                // console.log(this.mode);
                // fetch portal configs from server
                if (this.mode == "edit") {
                    // this.loadingData = true;
                    let url = `portal/${this.editPortalData._id.$oid}/config`;

                    this.$ppClient.get(url).then(response => {
                        // console.log("edit portal");
                        // console.log(response);

                        let body = response.data.body;
                        let cfg = body.portal;

                        let logo = cfg.logo;

                        if (logo.image) {
                            this.loadedLogo = new Image();
                            this.loadedLogo.src = `data:${logo.contentType};base64,${logo.image}`;
                            this.loadedLogo.setAttribute(
                                "crossorigin",
                                "anonymous"
                            );
                            this.loadedLogo.setAttribute("name", logo.filename);
                            // this.croppaImage.chosenFile.name = logo.filename;
                            this.croppaImage.refresh();

                            // console.log(this.croppaImage.getChosenFile());
                        }

                        this.portalName = cfg.name;

                        this.allowManagedLayers = cfg.allowManagedLayers || false;

                        this.privacyPolicy = cfg.privacyPolicy || "";

                        // console.log('in mounted');
                        // console.log(this.privacyPolicy);

                        // transform the external layers in
                        if (cfg.geostore && cfg.geostore.clientId) {
                            this.geostoreClientId = cfg.geostore.clientId;
                            this.geostoreClientIdUpdated();
                        }
                        if (cfg.propman) {
                            this.propmanClientId = cfg.propman.client;
                            this.propmanAltRef = cfg.propman.cemex_ref;
                            
                        }
                        this.externalLayers = cfg.layers
                            ? this.transformExternalLayersIn(cfg.layers)
                            : [];

                        this.allowClientLayers = cfg.allowClientLayers || false;

                        this.allowDocUploads = cfg.allowDocUploads || false;
                        this.documentCategories = cfg.documents.cats || [];

                        this.allowMasterMap = cfg.allowMasterMap || false;

                        this.loadingData = false;
                        this.portalConfigLoaded = true;
                    });
                }
            });
        });
    },
    computed: {
        propmanClientIdRequired: function() {
            return this.externalLayers.some(e => e.type === "propman");
        },
        propmanClientsLoading: function() {
            return !this.propmanClientsLoaded;
        },
        requiredPropmanDataforEditLoaded: function() {
            return this.mode === 'edit' && this.propmanClientsLoaded && this.portalConfigLoaded;
        },
        filteredPropmanData() {
            // console.log('filter outer');
            // console.log(this.fetchedPropmanClients);

            // if(this.fetchedPropmanClients.length === 0) return [];

            return this.fetchedPropmanClients.filter(client => {
                // console.log('filter inner');
                // console.log(client);
                return (
                    client.name
                        .toString()
                        .toLowerCase()
                        .indexOf(this.propmanFilterTerm.toLowerCase()) >= 0
                    
                    ||

                    client.clientId 
                        .toString()
                        .toLowerCase()
                        .indexOf(this.propmanFilterTerm.toLowerCase()) >= 0
                )
            })
        },
        // currentTab: {
        //     get() {
        //         return this.$store.getters["sidebarState/currentTab"];
        //     },
        //     set(newVal) {
        //         this.$store.commit("sidebarState/setCurrentTab", newVal);
        //     }
        // },
    },
    methods: {
        // setDocDefaults() {
        //     let url = `document/defaults`;
        //     this.$ppClient.get(url).then(response => {

        //         delete response.data.body.session;

        //         let body = response.data.body;

        //         console.log(body);

        //         this.documentDefaults = body;

        //         // this.documentDefaults = body;

        //         // return body;

        //     });
        // },
        // getRetention( rpId ) {

        //     // console.log('getREtention');
        //     // console.log(rpId);
        //     // console.log(this.documentDefaults);

        //     // doc defaults not yet loaded
        //     if(!this.documentDefaults.retentionPolicies){ return false; }
        //     // handle possible extra $oid property
        //     if( rpId.$oid) { rpId = rpId.$oid; }

        //     return this.documentDefaults.retentionPolicies.find(
        //         rp => rp._id.$oid  == rpId
        //     ).retention;
        // },
        fetchUsers() {
            let url = `/portal/${this.editPortalData._id.$oid}/users`;
            this.$ppClient.get(url).then(resp => {
                // console.log("fetch users");
                // console.log(resp);
                this.users = resp.data.body.users;
            });
        },
        confirmDeletePortal() {
            let userJoin = this.users.map(u => u.name).join("</li><li>");
            let userList = `<ul id="user-list"><li>${userJoin}</li></ul>`;

            let message = `<p>Are you sure you want to delete:</p>
                <br>
                <p><strong>${this.editPortalData.name}</strong></p>
                <br>
                <p>This will permanently remove ALL associated data.</p>
                <br>
                <p><strong>This action cannot be undone</strong></p>
                <br>
                <p>The following users have access to this portal. You should either delete them if they only have access to this portal or remove their access.</p>
                ${userList}
                `;

            this.$buefy.dialog.confirm({
                message: message,
                html: true,
                type: "is-danger",
                hasIcon: true,
                icon: `exclamation-circle`,
                onConfirm: () => this.deletePortal()
            });
        },
        deletePortal() {
            this.loadingData = true;
            let url = `/portal/${this.editPortalData._id.$oid}`;

            this.$ppClient
                .delete(url)
                .then(response => {
                    this.loadingData = false;

                    this.$buefy.toast.open({
                        type: "is-success",
                        message: "deleted portal"
                    });

                    this.$router.go();
                })
                .catch(error => {
                    this.loadingData = false;
                    this.$buefy.toast.open({
                        type: "is-danger",
                        message: "Error deleting portal. Please try again."
                    });
                    console.log("error deleteing portal");
                    console.log(error);
                    if (error.response) {
                        console.log(error.response);
                    }
                });
        },
        initImage(e) {
            // console.log("init image");
            // console.log(e);
            // console.log(this.croppaImage);
        },
        fileChoose(file) {
            // console.log("fileChoose");
            // console.log(this.croppaImage);
            // console.log(file);
        },
        transformExternalLayersIn(layers) {
            let transformed = [];

            for (let layer of layers) {
                // object to use as v-model for color picker
                // seems to be the only way to get the picker to load with
                // the correct alpha value
                let colorObj = layer.color
                    ? this.getColorObj(
                        `${layer.color}${this.alphaToHex(
                            layer.opacity || 0.5
                        )}`
                    )
                    : this.getColorObj(
                        `rgba(51, 136, 255, ${layer.opacity || 0.5})`
                    );

                let transLayer = {
                    // temp id for reliably deleting new layers (no mongo id)
                    index: layer.id,
                    name: layer.name,
                    geostoreJID: layer.jid,
                    color: layer.color,
                    opacity: layer.opacity || 0.5,
                    colorObj: colorObj,
                    type: layer.popup
                };
                transformed.push(transLayer);
            }
            return transformed;
        },
        transformDocCategoriesIn(cats) {},
        categoryMenuItemClicked(e, category) {
            // console.log("clicked");
            // console.log(category);
            // console.log(e.target);

            if (this.expandedCategories.includes(category)) {
                this.expandedCategories = this.expandedCategories.filter(
                    item => item !== category
                );
            } else {
                this.expandedCategories.push(category);
            }

            // console.log(this.expandedCategories);
        },
        uniqueCategory(newCat) {
            let nameExists = this.documentCategories.some(
                e => e.name.toUpperCase() === newCat.name.toUpperCase()
            );
            let uuidExists = this.documentCategories.some(
                e => e.uuid.toUpperCase() === newCat.uuid.toUpperCase()
            );

            return !(nameExists || uuidExists);
        },
        loadDefaultCategories() {
            this.loadingDefaultCats = true;

            let url = "/document/defaults/categories";
            this.$ppClient.get(url).then(response => {
                let body = response.data.body;
                // console.log(body);
                // this.documentCategories = [...this.documentCategories, ...body.defaultCategories];

                // console.log(this.documentCategories);
                // this.loadingDefaultCats = false;

                for (let cat of body.defaultCategories) {
                    if (this.uniqueCategory(cat)) {
                        this.documentCategories.push(cat);
                    }
                }

                this.loadingDefaultCats = false;
            });
        },
        addDocumentCategory(category) {
            let newCat = {
                uuid: uuidv1(),
                name: "",
                subcats: []
            };
            let modalProps = {
                catProp: newCat
                // "documentDefaults": this.documentDefaults
            };
            // have to work around 'this' scoping issue
            let self = this;
            // launchNewPortalModal() {
            let modalInstance = this.$buefy.modal.open({
                component: DocCategory,
                parent: this,
                props: modalProps,
                events: {
                    // docDefaultsFetched( docDefaults ) {
                    //     self.documentDefaults = docDefaults;
                    //     // stupid workaround for non-reactive props of buefy modal
                    //     modalProps.documentDefaults = docDefaults;
                    //     modalInstance.$forceUpdate();

                    // },
                    updateCat(category) {
                        newCat = {
                            uuid: uuidv1(),
                            name: category.name,
                            retentionPolicy: category.retentionPolicy,
                            subcats: category.subcats.map(subcat => ({
                                uuid: uuidv1(),
                                name: subcat
                            }))
                        };
                        if (self.uniqueCategory(newCat)) {
                            self.documentCategories.push(newCat);
                        }
                        // console.log(self.documentCategories);
                        // newCat = category;
                        // console.log(newCat);
                    }
                }
            });
        },
        editDocumentCategory(e, uuid) {
            let editCat = this.documentCategories.find(
                item => item.uuid === uuid
            );

            let cloneCat = Object.assign({}, editCat);

            // map subcats in (stored in db as object array [{name: 'name1'}, {name: 'name2'}])
            cloneCat.subcats = editCat.subcats.map(subcat => subcat.name);

            // console.log("clonecat");
            // console.log(cloneCat);

            this.$buefy.modal.open({
                component: DocCategory,
                parent: this,
                props: {
                    "cat-prop": cloneCat
                },
                events: {
                    updateCat(category) {
                        // console.log('category');
                        // console.log(category);

                        // assign edited values
                        editCat.name = category.name;
                        // editCat.type = category.type;
                        editCat.subcats = category.subcats.map(subcat => ({
                            uuid: uuidv1(),
                            name: subcat
                        }));
                    }
                }
            });
        },
        removeDocumentCategory(e, uuid) {
            // @todo confirm
            this.documentCategories = this.documentCategories.filter(
                item => item.uuid !== uuid
            );
        },

        addDocumentSubCategory(e, uuid) {
            let cat = this.documentCategories.find(item => item.uuid === uuid);

            this.$buefy.dialog.prompt({
                message: `Add a subcategory to ${cat.name}`,
                inputAttrs: {
                    placeholder: "Subcategpry name"
                },
                trapFocus: true,
                onConfirm: value =>
                    cat.subcats.push({ uuid: uuidv1(), name: value })
            });
        },
        editDocumentSubCategory(e, cUuid, scUuid) {
            let cat = this.documentCategories.find(item => item.uuid === cUuid);

            let subcat = cat.subcats.find(sc => sc.uuid === scUuid);

            // let subcat = this.documentCategories.find(category => category.subcats.find(sc => sc.uuid === uuid));

            this.$buefy.dialog.prompt({
                message: `Edit subcategory`,
                inputAttrs: {
                    placeholder: "Subcategory name",
                    value: subcat.name
                },
                trapFocus: true,
                onConfirm: value => (subcat.name = value)
            });
        },
        removeDocumentSubCategory(e, cUuid, scUuid) {
            let cat = this.documentCategories.find(item => item.uuid === cUuid);

            // let subcat = cat.subcats.find(sc => sc.uuid === scUuid);
            cat.subcats = cat.subcats.filter(subcat => subcat.uuid !== scUuid);
        },
        async fetchGeostoreClients() {
            return this.$ppClient
                .get("/geostore/clients")
                .then(response => {
                    // console.log(response.data);

                    let body = response.data.body;
                    this.fetchedGeostoreClients = body.geostoreClients;

                    // if (body.geostoreLayers.length === 0) {
                    //     this.errors.push(
                    //         `No Layers found in Geostore for given key (Client ID) - status ${response.status}`
                    //     );
                    // }

                    // this.fetchedGeostoreLayers = body.geostoreLayers;
                    // this.fetchedFeatureData = body;

                    // JSON responses are automatically parsed.
                    // this.layers = body.portal.layers;
                    // this.$store.commit("mapState/setLayers", body.portal.layers);
                })
                .catch(e => {
                    // console.log(e.response.data);

                    if (e.response.status === 404) {
                        if (
                            !this.errors.includes(
                                `${e.response.data.errors[0].message} - status ${e.response.status}`
                            )
                        ) {
                            this.errors.push(
                                `${e.response.data.errors[0].message} - status ${e.response.status}`
                            );
                        }
                    }
                });
        },
        fetchPropmanClients() {
            this.$ppClient
                .get("/propman/clients")
                .then(response => {
                    console.log('propman clients');
                    console.log(response.data);

                    let body = response.data.body;
                    this.fetchedPropmanClients = body.propmanClients;
                    this.propmanClientsLoaded = true;
                })
                .catch(e => {
                    // console.log(e.response.data);

                    if (e.response && e.response.status && e.response.status === 404) {
                        if (
                            !this.errors.includes(
                                `${e.response.data.errors[0].message} - status ${e.response.status}`
                            )
                        ) {
                            this.errors.push(
                                `${e.response.data.errors[0].message} - status ${e.response.status}`
                            );
                        }
                    } else {
                        console.log(JSON.parse(JSON.stringify(e)));
                    }
                });
        },

        handleSearchIndex() {
            this.$buefy.dialog.confirm({
                html: true,
                title: "Submit portal for search index?",
                message: `<p>This operation is not usually required.</p>
                    <p>It will trigger the re-indexing of all the portal client layers for search.</p>
                    <p><strong>Note, Propman data is indexed independently and performing this action will not affect popman data.</strong></p>
                    <hr />
                    <p>We use a different service to allow users to search their sites.</p>
                    <p>If they ever experience any issues with searching for client layer data, start by performing this action.</p>
                    <p>portals with lots of sites may take a while to re-index.</p>
                    <p>If the problem persists contact the systems team.</p>`,
                onConfirm: () => this.submitForIndex()
            });
        },
        submitForIndex() {
            this.loadingData = true;
            let url = `/search/index/${this.editPortalData._id.$oid}`;
            this.$ppClient
                .get(url)
                .then(resp => {
                    // console.log("index resp");
                    // console.log(resp);
                    this.loadingData = false;
                    this.$buefy.toast.open({
                        type: "is-success",
                        message: `Portal Indexed`
                    });
                })
                .catch(err => {
                    this.loadingData = false;
                    this.$buefy.toast.open({
                        type: "is-danger",
                        message: `Something went wrong`
                    });
                });
        },
        submit() {
            this.validateForm().then(
                valid => {
                    if (valid) {
                        this.compileFormData().then(
                            formData => {
                                // console.log(JSON.stringify(formData));

                                this.mode == "new"
                                    ? this.submitNew(formData)
                                    : this.submitUpdate(formData);
                            },
                            error => {
                                console.log("error in compileFormData");
                                console.log(error);
                            }
                        );
                    }
                },
                error => {
                    console.log("error in validateForm promise");
                    console.log(error);
                }
            );

            // console.log(formData);

            // window.setTimeout(2);
            // for ( var pair of formData.entries() ) {
            //         console.log(pair[0]+ ': ' + pair[1]);
            //     }
        },
        submitNew(formData) {
            this.loadingData = true;
            this.$ppClient
                .post("/portal", formData, {
                    headers: {
                        "Content-Type": "multipart/form-data"
                        // 'Content-Type': 'x-www-form-urlencoded'
                    }
                })
                .then(response => {
                    // console.log(response.data);

                    let body = response.data.body;

                    if (body.portalCreated) {
                        this.loadingData = false;
                        // this.$store.commit(
                        //     "mapState/addUserPortal",
                        //     body.newPortal
                        // );
                        this.$buefy.toast.open({
                            message: `Portal Created, don't forget to add some users`
                        });
                        this.$parent.close();
                    }
                })
                .catch(error => {
                    if (error.response) {
                        this.loadingData = false;
                        // Request made and server responded
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                    } else if (error.request) {
                        // The request was made but no response was received
                        console.log(error.request);
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        console.log("Error", error.message);
                    }
                });
        },
        submitUpdate(formData) {
            // console.log("submitUpdate");
            this.loadingData = true;
            // console.log(Object.fromEntries(formData));

            let url = `/portal/${this.editPortalData._id.$oid}`;
            this.$ppClient
                .patch(url, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data"
                        // 'Content-Type': 'x-www-form-urlencoded'
                    }
                })
                .then(response => {
                    // console.log(response.data);

                    let body = response.data.body;
                    // console.log(body);

                    if (body.portalUpdated) {
                        this.loadingData = false;
                        this.$emit("portal-updated", body.updatedPortal);
                        this.$buefy.toast.open({
                            message: `Successfully Updated Portal`
                        });
                        this.$parent.close();
                    } else {
                        this.loadingData = false;
                        this.$buefy.toast.open({
                            message: `Something Went Wrong! Update failed.`,
                            type: "is-danger"
                        });
                    }
                })
                .catch(error => {
                    this.loadingData = false;
                    if (error.response) {
                        // Request made and server responded
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                    } else if (error.request) {
                        // The request was made but no response was received
                        console.log(error.request);
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        console.log("Error", error.message);
                    }
                });
        },
        async compileFormData() {
            // console.log(this.croppaImage.chosenFile);

            var formData = new FormData();

            formData.append("portalName", this.portalName);

            if (this.privacyPolicy.length > 0) {
                formData.append("privacyPolicy", this.privacyPolicy);
            }

            let logo = false;
            if (this.croppaImage.hasImage()) {
                let blob = await this.croppaImage.promisedBlob();
                if (this.croppaImage.chosenFile) {
                    formData.append(
                        "logo",
                        blob,
                        this.croppaImage.chosenFile.name
                    );
                } else if (this.loadedLogo) {
                    // console.log('FD loaded logo');
                    // console.log(this.loadedLogo);

                    formData.append(
                        "logo",
                        blob,
                        this.loadedLogo.getAttribute("name")
                    );
                }
            } else {
                formData.append("logo", false);
            }

            // need geostore key
            if (this.allowManagedLayers) {
                formData.append("geostoreClientId", this.geostoreClientId);

                // propman key required
                if (this.propmanClientIdRequired) {
                    formData.append("propmanClientId", this.propmanClientId);
                    // formData.append("propmanKey", this.propmanKey);
                    formData.append("propmanOffice", this.propmanOffice);
                    formData.append("propmanAltRef", this.propmanAltRef);
                }

                // => foreach external layer
                let composedExternalLayers = [];
                for (let externalLayer of this.externalLayers) {
                    // GSJID, ID, name
                    let gsLayer = this.fetchedGeostoreLayers.find(
                        gsLayer => gsLayer.job_id === externalLayer.geostoreJID
                    );
                    let name =
                        externalLayer.name !== ""
                            ? externalLayer.name
                            : gsLayer.layer;

                    composedExternalLayers.push({
                        name: name,
                        id: gsLayer._id.$oid,
                        jid: gsLayer.job_id,
                        type: externalLayer.type,
                        color: externalLayer.color,
                        opacity: externalLayer.opacity || 0.5
                    });
                }

                formData.append(
                    "externalLayers",
                    JSON.stringify(composedExternalLayers)
                );
            } else {
                formData.append("externalLayers", false);
            }

            // convenience for showing aspects of UI
            formData.append("allowManagedLayers", this.allowManagedLayers);
            // renaming but may be used elsewehere
            formData.append("allowClientLayers", this.allowClientLayers);
            formData.append("internalSiteList", this.allowClientLayers);
            formData.append("allowDocUploads", this.allowDocUploads);
            formData.append("allowMasterMap", this.allowMasterMap);

            let documentsObj = {
                // defaults: this.documentDefaults,
                cats: this.documentCategories
            };

            formData.append("documents", JSON.stringify(documentsObj));

            return formData;

            //  =>  if display name not set, use GS layer name
        },
        async validateForm() {
            // reset errors for validation pass
            this.errorFields = [];
            this.errors = [];
            // validate name
            if (this.portalName === "") {
                this.errorFields.push("portalName");
                this.errors.push("Portal Name: is required");
            }

            // console.log("validate form");
            // console.log(this.privacyPolicy);
            // console.log(this.privacyPolicy.length);
            // really simple url validation for privacy policy
            if (this.privacyPolicy.length > 0) {
                let validPolicyUrl = true;
                try {
                    let url = new URL(this.privacyPolicy);
                    validPolicyUrl =
                        url.protocol === "http:" || url.protocol === "https:";
                } catch (_) {
                    validPolicyUrl = false;
                }

                if (!validPolicyUrl) {
                    this.errorFields.push("privacyPolicy");
                    this.errors.push("Privacy Policy URL: is invalid");
                }
            }

            // if allow external layers:
            // => validate the geostore key (JID) is not empty
            if (this.allowManagedLayers) {
                if (this.geostoreClientId === "") {
                    this.errorFields.push("geostoreClientId");
                    this.errors.push("Geostore Key: is required");
                }

                // if propman key required, are correct atts set
                if (this.propmanClientIdRequired) {
                    if (this.propmanClientId === "") {
                        this.errorFields.push("propmanClientId");
                        this.errors.push("Propman Client ID: is required");
                    }
                }

                // => foreach external layer
                for (let externalLayer of this.externalLayers) {
                    // console.log(externalLayer);
                    //  =>  layer type selected
                    if (externalLayer.type === "") {
                        this.errorFields.push(`${externalLayer.index}-type`);
                        // we only need to add the message once but it may apply to multiple layers
                        if (!this.errors.includes("Layer Type: is required")) {
                            this.errors.push("Layer Type: is required");
                        }
                    }
                    //  =>  Geostore Layer:
                    //      =>  selected
                    if (externalLayer.geostoreJID === "") {
                        this.errorFields.push(
                            `${externalLayer.index}-geostoreJID`
                        );
                        // we only need to add the message once but it may apply to multiple layers
                        if (
                            !this.errors.includes("Geostore Layer: is required")
                        ) {
                            this.errors.push("Geostore Layer: is required");
                        }
                        //      =>  id/jid matches the entered JID
                    } else {
                        let gsLayer = this.fetchedGeostoreLayers.find(
                            gsLayer =>
                                gsLayer.job_id === externalLayer.geostoreJID
                        );
                        // console.log('in validation');
                        // console.log(gsLayer);
                        // console.log(this.geostoreClientId);
                        if (this.geostoreClientId !== gsLayer.clientId) {
                            this.errorFields.push(
                                `${externalLayer.index}-geostoreJID`
                            );
                            // we only need to add the message once but it may apply to multiple layers
                            if (
                                !this.errors.includes(
                                    "Geostore Layer: Does not match the given key"
                                )
                            ) {
                                this.errors.push(
                                    "Geostore Layer: Does not match the given key"
                                );
                            }
                        }
                    }

                    //  =>  color is set
                    if (!externalLayer.color) {
                        this.errorFields.push(`${externalLayer.index}-color`);
                        // we only need to add the message once but it may apply to multiple layers
                        if (
                            !this.errors.includes("Layer Colour: is required")
                        ) {
                            this.errors.push("Layer Colour: is required");
                        }
                    }
                }
            }
            // was the form valid?
            return this.errors.length === 0;
        },
        removeErrorField(field) {
            this.errorFields = this.errorFields.filter(el => el !== field);
            this.errors = [];
        },
        geostoreClientIdUpdated() {
            this.removeErrorField("geostoreClientId");
            this.loadGSClientLayers();
        },
        propmanClientIdUpdated(clientObj) {
            console.log('pman client id updated');
            console.log(clientObj);
            this.removeErrorField("propmanClientId");

            if(clientObj && clientObj.clientId) {
                this.propmanClientId = clientObj.clientId;
            } 
            else if(typeof clientObj === 'string' && clientObj.length > 0) {
                this.propmanClientId = clientObj;
            }
            else {
                this.propmanClientId = "";
            }
            
            
            // this.loadGSClientLayers();
        },
        loadGSClientLayers() {
            if (this.geostoreClientId === "") {
                // this.geostoreClientIdError = true;
                this.errorFields.push("geostoreClientId");
                this.errors.push("Geostore Key: is required");
                return;
            }

            let url = `/layer/geostore/list/${this.geostoreClientId}`;

            this.$ppClient
                .get(url)
                .then(response => {
                    // console.log(response.data);

                    let body = response.data.body;

                    if (body.geostoreLayers.length === 0) {
                        this.errors.push(
                            `No Layers found in Geostore for given key (Client ID) - status ${response.status}`
                        );
                    }

                    this.fetchedGeostoreLayers = body.geostoreLayers;
                    // this.fetchedFeatureData = body;

                    // JSON responses are automatically parsed.
                    // this.layers = body.portal.layers;
                    // this.$store.commit("mapState/setLayers", body.portal.layers);
                })
                .catch(e => {
                    // console.log(e.response.data);

                    if (e.response.status === 404) {
                        this.errorFields.push("geostoreClientId");
                        this.errors.push(
                            `${e.response.data.errors[0].message} - status ${e.response.status}`
                        );
                    }
                });
        },
        colorTagStyle: function(extLayer) {
            if (extLayer.color) {
                let bgColor = `${extLayer.color}${this.alphaToHex(
                    extLayer.opacity || 1
                )}`;
                let textColor = this.getContrastedColor(extLayer.color);
                // console.log("compColor");
                // console.log(bgColor);
                // console.log(textColor);

                return `background-color: ${bgColor}; color: ${textColor}`;
            }
            return false;
        },
        updateColor(newVal, layer) {
            // console.log(newVal);
            // console.log(layer);

            layer.color = newVal.hex;
            layer.opacity = newVal.a;
        },
        addExternalLayer() {
            let newLayer = {
                // temp id for reliably deleting new layers (no mongo id)
                index: uuidv1(),
                name: "",
                geostoreJID: "",
                color: false,
                opacity: 0.5,
                colorObj: this.getColorObj(`rgba(51, 136, 255, 0.5)`),
                type: ""
            };

            this.externalLayers.push(newLayer);
        },
        removeExternalLayer(index) {
            // get index of object with id:37
            let removeIndex = this.externalLayers
                .map(function(item) {
                    return item.index;
                })
                .indexOf(index);

            // remove object
            this.externalLayers.splice(removeIndex, 1);
        },
        getContrastedColor(hex) {
            if (!hex) {
                return "black";
            }

            // console.log(hex);

            /*
			From this W3C document: http://www.webmasterworld.com/r.cgi?f=88&d=9769&url=http://www.w3.org/TR/AERT#color-contrast

			Color brightness is determined by the following formula:
			((Red value X 299) + (Green value X 587) + (Blue value X 114)) / 1000

            I know this could be more compact, but I think this is easier to read/explain.

            */

            let threshold = 130; /* about half of 256. Lower threshold equals more dark text on dark background  */

            let hRed = hexToR(hex);
            let hGreen = hexToG(hex);
            let hBlue = hexToB(hex);

            function hexToR(h) {
                return parseInt(cutHex(h).substring(0, 2), 16);
            }
            function hexToG(h) {
                return parseInt(cutHex(h).substring(2, 4), 16);
            }
            function hexToB(h) {
                return parseInt(cutHex(h).substring(4, 6), 16);
            }
            function cutHex(h) {
                return h.charAt(0) == "#" ? h.substring(1, 7) : h;
            }

            let cBrightness = (hRed * 299 + hGreen * 587 + hBlue * 114) / 1000;
            if (cBrightness > threshold) {
                return "black";
            } else {
                return "white";
            }
        },
        saveTest(type, compressionRate) {
            this.croppaImage.generateBlob(
                blob => {
                    var url = URL.createObjectURL(blob);
                    // console.log(url);
                    var a = document.createElement("a");
                    a.download = "filename";
                    a.href = url;
                    a.click();
                    URL.revokeObjectURL(url);
                },
                type,
                compressionRate
            );
        },
        centerImage() {
            // console.log("centre image");
            // console.log("croppaImage");
            // console.log(this.croppaImage);

            let metadata = this.croppaImage.getMetadata();
            // console.log("metadata");
            // console.log(metadata);

            // console.log("image data");
            // console.log(this.croppaImage._data.imgData);

            // let ctx = this.croppaImage.ctx;

            let cWidth = this.croppaImage.width;
            let cHeight = this.croppaImage.height;

            // console.log("cWidth");
            // console.log(cWidth);

            // console.log("cHeight");
            // console.log(cHeight);

            let imgData = this.croppaImage._data.imgData;
            let iWidth = imgData.width / 2;
            let iHeight = imgData.height / 2;

            // console.log("iWidth");
            // console.log(iWidth);

            // console.log("iHeight");
            // console.log(iHeight);

            let wDiff = cWidth - iWidth;
            // console.log("wDiff");
            // console.log(wDiff);
            // if < 0
            // image wider than canvas

            // this.croppaImage.moveRightwards(wDiff / 2);

            let hDiff = cHeight - iHeight;
            // console.log("hDiff");
            // console.log(hDiff);

            // let ctx = this.croppaImage._data.ctx;
            let ctx = this.croppaImage._data.canvas.getContext("2d");
            // console.log("ctx");
            // console.log(ctx);

            // var image = new Image();
            // image.src = 'http://t2.gstatic.com/images?q=tbn:ANd9GcQQveW9AJCxOC8Phnq3vptJIxPFHlxNw63q4pudc66dM4O96vtm';
            let image = new Image(this.croppaImage._data.image);
            image.width = iWidth;
            image.height = iHeight;

            if (metadata.startX < 0) {
                this.croppaImage.moveRightwards(metadata.startX);
            } else {
                this.croppaImage.moveLeftwards(metadata.startX);
            }

            if (metadata.startY < 0) {
                this.croppaImage.moveDownwards(metadata.startY);
            } else {
                this.croppaImage.moveUpwards(metadata.startY);
            }

            if (wDiff > 0) {
                this.croppaImage.moveRightwards((iWidth * metadata.scale) / 2);
            } else {
                this.croppaImage.moveLeftwards((iWidth * metadata.scale) / 2);
            }

            if (hDiff < 0) {
                this.croppaImage.moveDownwards((iHeight * metadata.scale) / 2);
            } else {
                this.croppaImage.moveUpwards((iHeight * metadata.scale) / 2);
            }

            // var scale = Math.min(canvas.width / img.width, canvas.height / img.height);
            // var w = img.width * scale;
            // var h = img.height * scale;
            // var left = canvas.width / 2 - w / 2;
            // var top = canvas.height / 2 - h / 2;
        }
    }
};
</script>

<style lang="scss">
#color-picker-dropdown {
    &:focus {
        outline: none;
    }

    & div:focus,
    & a:focus {
        outline: none;
    }

    & a:hover {
        background-color: transparent;
    }
}
#color-picker {
    // &:focus{
    //     outline: none;
    // }
    // & div:focus{
    //     outline: none;
    // }
    .vc-slider-swatches {
        display: none !important;
    }
}
</style>

<style lang="scss" scoped>
.columns.nested-form-fields {
    .column {
        padding-top: 0;
        padding-bottom: 0;
    }
}

.info-dropdown:hover {
    color: var(--fg-grey);
}

.croppa-container {
    border: 2px solid hsl(0, 0%, 86%);
    background-color: #ffffff;
}

// .color-picker-tag {
//     &:hover {
//         cursor: pointer;
//     }

//     &.has-error {
//         border: 1px solid red;
//     }
// }

#color-picker-dropdown-item {
    display: flex;
    align-items: middle;
    justify-content: center;
}

#color-picker-wrap {
    width: 100px;
    height: 24px;
    #color-picker-checkboard {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        // width: 100%;
        // height: 100%;
        // checkerboard pattern
        background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAMElEQVQ4T2N89uzZfwY8QFJSEp80A+OoAcMiDP7//483HTx//hx/Ohg1gIFx6IcBALl+VXknOCvFAAAAAElFTkSuQmCC");
        background-size: contain;
        overflow: hidden;

        border-radius: 4px;

        // z-index: 39;
    }

    .color-picker-tag {
        // width: 100%;
        // height: 100%;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;

        z-index: 1;

        &:hover {
            cursor: pointer;
        }

        &.has-error {
            border: 1px solid red;
        }
    }
}

#master-map-wrap {
    margin-top: 15px;
}

.modal-card-foot {
    justify-content: space-between;
}
</style>

<style lang="scss">
#category-menu {
    .menu-list a {
        //     .dropdown-content{
        //     display: flex;
        //     align-items: middle !important;
        // }
        display: flex;
        align-items: center;
        //   justify-content: center;

        &.is-active {
            background-color: transparent;
            color: #4a4a4a;

            &:hover {
                background-color: whitesmoke;
                color: #363636;
            }
        }
    }

    // div.dialog.modal.is-active {
    //     div.media-content {
    //         li {
    //             list-style-type: disc;
    //         }
    //     }
    // }

    #user-list {
        list-style-type: disc !important;
    }
}
</style>
