<template>
    <div id="site-documents">
        <div>
            <h3 class="subtitle is-5 margin-top-15">
                Documents
            </h3>
            <b-button
                v-if="portalAccess < 3"
                @click="launchDocUploadModal"
                type=""
                :title="
                    !docUploadEnabled
                        ? cantUploadReason
                        : 'Upload a file for the site'
                "
                :disabled="!docUploadEnabled"
            >
                <font-awesome-icon :icon="'upload'"></font-awesome-icon>
            </b-button>
            <!-- {{fetchedFeatureDocs}} -->

            <!-- site docs -->
            <div v-if="fetchedFeatureDocs">
                <b-collapse
                    v-for="(subCat, category) in fetchedFeatureDocs"
                    :key="category"
                    class="card margin-top-15"
                    :aria-id="category"
                    :open="false"
                >
                    <div
                        slot="trigger"
                        slot-scope="props"
                        class="card-header"
                        role="button"
                        :aria-controls="category"
                    >
                        <p class="card-header-title is-capitalized">
                            {{ category }}
                        </p>
                        <a class="card-header-icon">
                            <font-awesome-icon
                                :icon="
                                    props.open
                                        ? ['fas', 'caret-up']
                                        : ['fas', 'caret-down']
                                "
                            />
                        </a>
                    </div>

                    <div class="card-content">
                        <b-loading
                            :is-full-page="false"
                            :active.sync="loadingData"
                            :can-cancel="false"
                        >
                            <font-awesome-icon
                                :icon="['fad', 'sync']"
                                :style="$iconStyle"
                                size="4x"
                                spin
                            />
                        </b-loading>
                        <div
                            v-for="(files, subCatName) in subCat"
                            :key="subCatName"
                        >
                            <p class="table-title">
                                <strong>{{ subCatName }}</strong>
                            </p>

                            <b-table
                                v-if="featureSelected"
                                class="trans-table file-table"
                                :data="files"
                                detailed
                                detail-key="id"
                            >
                                <!--  -->
                                <template slot-scope="props">
                                    <b-table-column field="title" label="Title">
                                        <span>{{ props.row.title }}</span>
                                        <!-- {{ props.row.title }} -->
                                    </b-table-column>

                                    <b-table-column
                                        field="download"
                                        label="Download"
                                    >
                                        <b-button
                                            type=""
                                            @click="downloadDocument(props.row)"
                                        >
                                            <font-awesome-icon
                                                :icon="'download'"
                                            ></font-awesome-icon>
                                        </b-button>
                                    </b-table-column>
                                </template>

                                <!-- detail row -->
                                <template slot="detail" slot-scope="props">
                                    <h3
                                        class="subtitle is-6 header-with-button"
                                    >
                                        Notifications:
                                        <b-button
                                            type=""
                                            title="Add a site notification"
                                            @click="
                                                launchNewNotificationModal(
                                                    props.row
                                                )
                                            "
                                            class="has-margin-left-10"
                                        >
                                            <font-awesome-icon
                                                :icon="['fas', 'plus']"
                                            />
                                        </b-button>
                                    </h3>
                                    <div v-if="docHasNotifications(props.row)">
                                        <doc-notifications
                                            :docNotifications="
                                                getNotificationsForDoc(
                                                    props.row
                                                )
                                            "
                                        />
                                    </div>
                                    <div v-else>
                                        No Notifications
                                    </div>
                                    <hr class="has-margin-bottom-10" />

                                    <article class="media">
                                        <ul>
                                            <!-- <li>
                                                <strong>Id: </strong>
                                                {{ props.row["id"] }}
                                            </li> -->
                                            <li>
                                                <strong>File Name: </strong>
                                                {{ props.row["filename"] }}
                                            </li>

                                            <li>
                                                <strong>File Size: </strong>
                                                {{ props.row["fileSize"] }}
                                            </li>

                                            <li>
                                                <strong>Descrition: </strong>
                                                {{ props.row["description"] }}
                                            </li>

                                            <li>
                                                <strong>Valid From: </strong>
                                                {{
                                                    props.row.valid.from
                                                        ? new Date(
                                                            props.row.valid.from
                                                        ).toLocaleDateString()
                                                        : ""
                                                }}
                                            </li>

                                            <li>
                                                <strong>Valid to: </strong>
                                                {{
                                                    props.row.valid.to
                                                        ? new Date(
                                                            props.row.valid.to
                                                        ).toLocaleDateString()
                                                        : ""
                                                }}
                                            </li>

                                            <li>
                                                <strong>Upload Date: </strong>
                                                {{ props.row["uploadDate"] }}
                                            </li>

                                            <li>
                                                <strong>Uploaded By: </strong>
                                                {{ props.row["uploadedBy"] }}
                                            </li>
                                        </ul>
                                    </article>

                                    <div
                                        v-if="portalAccess < 3"
                                        class="buttons"
                                    >
                                        <b-button
                                            type=""
                                            @click="
                                                launchDocUpdateModal(
                                                    props.row,
                                                    category,
                                                    subCatName
                                                )
                                            "
                                        >
                                            <font-awesome-icon :icon="'edit'">
                                            </font-awesome-icon>
                                        </b-button>
                                        <b-button
                                            v-if="portalAccess < 2"
                                            @click="
                                                confirmDeleteDocument(
                                                    props.row,
                                                    category,
                                                    subCatName
                                                )
                                            "
                                        >
                                            <font-awesome-icon :icon="'trash'">
                                            </font-awesome-icon>
                                        </b-button>
                                    </div>
                                </template>
                            </b-table>
                        </div>
                    </div>
                </b-collapse>
            </div>
        </div>

        <!-- registered titles -->
        <div v-if="fetchedFeatureTitles">
            <b-collapse
                class="card margin-top-15"
                aria-id="registered-titles"
                :open="false"
            >
                <div
                    slot="trigger"
                    slot-scope="props"
                    class="card-header"
                    role="button"
                    aria-controls="registered-titles"
                >
                    <p class="card-header-title is-capitalized">
                        Registered Titles
                    </p>
                    <a class="card-header-icon">
                        <font-awesome-icon
                            :icon="
                                props.open
                                    ? ['fas', 'caret-up']
                                    : ['fas', 'caret-down']
                            "
                        />
                    </a>
                </div>
                <div class="card-content">
                    <b-table
                        v-if="featureSelected"
                        class="trans-table file-table"
                        :data="fetchedFeatureTitles"
                        detailed
                        detail-key="id"
                    >
                        <!--  -->
                        <template slot-scope="props">
                            <b-table-column field="title" label="Title">
                                <!-- <b-tooltip :label="props.row.description" position="is-right"> -->
                                <span>
                                    {{ props.row.title }}
                                </span>

                                <!-- </b-tooltip> -->
                            </b-table-column>

                            <b-table-column field="download" label="Download">
                                <b-button type="">
                                    <font-awesome-icon :icon="'download'" />
                                </b-button>
                            </b-table-column>
                        </template>
                        <!-- detail row -->
                        <template slot="detail" slot-scope="props">
                            <h3 class="subtitle is-6 header-with-button">
                                Notifications:
                                <b-button
                                    type=""
                                    title="Add a site notification"
                                    @click="launchNewNotificationModal"
                                    class="has-margin-left-10"
                                >
                                    <font-awesome-icon
                                        :icon="['fas', 'plus']"
                                    />
                                </b-button>
                            </h3>
                            <div v-if="docHasNotifications(props.row)">
                                <doc-notifications
                                    :docNotifications="
                                        getNotificationsForDoc(props.row)
                                    "
                                />
                            </div>
                            <div v-else>
                                No Notifications
                            </div>
                            <hr class="has-margin-bottom-10" />

                            <article class="media">
                                <ul>
                                    <li>
                                        <strong>File Name: </strong>
                                        {{ props.row["filename"] }}
                                    </li>

                                    <li>
                                        <strong>File Size: </strong>
                                        {{ props.row["fileSize"] }}
                                    </li>

                                    <li>
                                        <strong>Upload Date: </strong>
                                        {{ props.row["uploadDate"] }}
                                    </li>

                                    <li>
                                        <strong>Uploaded By: </strong>
                                        {{ props.row["uploadedBy"] }}
                                    </li>
                                </ul>
                            </article>
                        </template>
                    </b-table>
                </div>
            </b-collapse>
        </div>

        <div
            v-if="!fetchedFeatureDocs && !fetchedFeatureTitles"
            class="card margin-top-15"
        >
            <div class="card-content">
                <p>No Documents Found For This Site.</p>
            </div>
        </div>
    </div>
</template>

<script>
import FeatureStateHelpers from "@/mixins/FeatureStateHelpers.js";
import NotificationHelpers from "@/mixins/NotificationHelpers.js";
import AccessControlHelpers from "@/mixins/AccessControlHelpers.js";
import utils from "@/mixins/utils.js";

import DocManagement from "@/components/modals/DocManagement.vue";
import DocNotifications from "@/components/sidebar/FeatureDetails/DocNotifications.vue";

export default {
    name: "SiteDocuments",
    mixins: [FeatureStateHelpers, NotificationHelpers, AccessControlHelpers, utils],
    components: { DocManagement, DocNotifications },
    props: {},
    data() {
        return {
            loadingData: false
        };
    },
    mounted() {},
    computed: {
        fetchedFeatureDocs() {
            if (
                !this.fetchedFeatureData ||
                !this.fetchedFeatureData.siteDocuments ||
                this.fetchedFeatureData.siteDocuments === "undefined" ||
                this.fetchedFeatureData.siteDocuments.length == 0
            ) {
                return false;
            }
            return this.fetchedFeatureData.siteDocuments;
        },
        fetchedFeatureTitles() {
            if (
                !this.fetchedFeatureData ||
                !this.fetchedFeatureData.registeredTitles ||
                this.fetchedFeatureData.registeredTitles === "undefined" ||
                this.fetchedFeatureData.registeredTitles.length == 0
            ) {
                return false;
            }
            return this.fetchedFeatureData.registeredTitles;
        },
        cantUploadReason: function() {
            if (!this.propmanCodeCheck) {
                return "You need a valid propman code to upload documents to this site.";
            }
        },
        propmanCodeCheck: function() {
            // is there a propman code for the geometry (if the site is a propman site)
            if (
                this.fetchedFeatureData &&
                this.fetchedFeatureData.siteType === "propman"
            ) {
                return this.fetchedFeatureData.siteDetails["geom"][
                    "features"
                ][0]["properties"]["Propman"];
            }
            return true;
        },
        docUploadEnabled: function() {
            // console.log("docUploafdEnabled");
            // console.log("featureData");
            // console.log(this.fetchedFeatureData);

            return this.fetchedFeatureData && this.propmanCodeCheck;
        }
    },
    methods: {
        launchNewNotificationModal(document) {
            let selectedPortal = this.$store.getters["mapState/selectedPortal"];
            let selectedLayer = this.$store.getters["mapState/selectedLayer"];
            // console.log('launch modal');
            // console.log(this.selectedPortal);
            let props = {
                mode: "new",
                notificationType: "document",
                portalId: selectedPortal,
                siteId: this.selectedFeature._site.siteId.$oid,
                layerId: selectedLayer._id.$oid,
                doc: document
            };
            this.launchNotificationModal(props);
        },
        docHasNotifications(document) {
            return this.notifications.some(
                n => n.type == "document" && n.documentId == document.id
            );
        },
        getNotificationsForDoc(document) {
            // let nots = this.notifications.filter( n => n.type == 'document' && n.documentId == document.id);
            // console.log('nots');
            // console.log(nots);
            // console.log(this.notifications);
            // console.log(document);

            return this.notifications.filter(
                n => n.type == "document" && n.documentId == document.id
            );
        },
        launchDocUploadModal() {
            this.$buefy.modal.open({
                component: DocManagement,
                props: {
                    mode: "new"
                    // selectedFeature: this.selectedFeature
                },
                parent: this,
                events: {
                    "trigger-add-notification": doc => {
                        // console.log('add-notification');
                        // console.log(doc);
                        this.launchNewNotificationModal(doc);
                    }
                }
            });
        },
        launchDocUpdateModal(fileMeta, category, subCat) {
            // clone the file meta object so that changes in the modal don't affect the
            // file meta object in memory.
            // we'll propagate the changes after the update succeeds
            let metaClone = Object.assign(
                {
                    category: category,
                    subCategory: subCat
                },
                fileMeta
            );

            this.$buefy.modal.open({
                component: DocManagement,
                props: {
                    mode: "edit",
                    fileMeta: metaClone
                    // selectedFeature: this.selectedFeature
                },
                parent: this
            });
        },
        confirmDeleteDocument(fileMeta, category, subCat) {
            let message = `<p>Are you sure you want to permanently delete:</p>
                <br>
                <p><strong>${ this.sanitize( fileMeta.title ) }</strong></p>`;

            this.$buefy.dialog.confirm({
                message: message,
                type: "is-danger",
                hasIcon: true,
                icon: `exclamation-circle`,
                onConfirm: () =>
                    this.deleteDocument(fileMeta.id, category, subCat)
            });
        },
        deleteDocument(fileId, category, subCat) {
            this.loadingData = true;
            // console.log("delete document");
            // console.log(this.fetchedFeatureData.siteDocuments);
            // console.log(category);
            // console.log(subCat);
            // this.fetchedFeatureData.siteDocuments[category][subCat].splice(this.fetchedFeatureData.siteDocuments[category][subCat].findIndex( id => id === fileMeta.id ), 1);
            // // docs = docs.filter(doc => doc.id !== fileMeta.id );
            // console.log(docs);
            // console.log(docs.find( doc => doc.id === fileMeta.id ));

            this.$ppClient
                .delete(`/document/${fileId}`)
                .then(response => {
                    // console.log("response");
                    // console.log(response);

                    let body = response.data.body;
                    // console.log(body);
                    if (body.status === "success") {
                        this.loadingData = false;
                        // clean the document from the UI
                        let docCategoryTree = this.fetchedFeatureDocs;
                        let subCats = docCategoryTree[category];
                        let docs = docCategoryTree[category][subCat];
                        // remove the document from correct sub category
                        docs.splice(docs.findIndex(id => id === fileId), 1);

                        // remove that subcategory if there are no doc left in it
                        if (docs.length === 0) {
                            delete subCats[subCat];
                        }

                        // remove the category if there are now no subcategories in it
                        if (
                            Object.keys(docCategoryTree[category]).length === 0
                        ) {
                            delete docCategoryTree[category];
                        }

                        // remove the site document property if there are no documents left in any category/sub category
                        // ensures the 'No Documents Found' message shows again.
                        if (Object.keys(docCategoryTree).length === 0) {
                            this.fetchedFeatureData.siteDocuments = false;
                        }

                        this.$eventHub.$emit("document-deleted", fileId);

                        this.$buefy.toast.open({
                            message: `Document Deleted`
                        });
                    } else {
                        this.loadingData = false;
                        this.$buefy.toast.open({
                            message: `Problem Deleting Document`,
                            type: "is-danger"
                        });
                    }
                })
                .catch(error => {
                    this.loadingData = false;
                    if (error.response) {
                        // Request made and server responded
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                    } else if (error.request) {
                        // The request was made but no response was received
                        console.log("error.request");

                        console.log(error.request);
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        console.log("undefined error");

                        console.log("Error", error.message);
                    }
                });
        },
        downloadDocument(fileMeta) {
            // console.log("download");
            // console.log(fileMeta);

            this.$ppClient
                .get(`/document/${fileMeta.id}`, { responseType: "blob" })
                .then(response => {
                    // console.log('downlaod doc');

                    // console.log("response");
                    // console.log(response);

                    // we use saveAs as it has better browser compatability
                    // than doing a manual trigger
                    saveAs(new Blob([response.data]), fileMeta.filename);
                })
                .catch(error => {
                    if (error.response) {
                        // Request made and server responded
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                    } else if (error.request) {
                        // The request was made but no response was received
                        console.log("error.request");

                        console.log(error.request);
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        console.log("undefined error");

                        console.log("Error", error.message);
                    }
                });
        }
    }
};
</script>

<style lang="scss" scoped></style>
