<template>
    <!-- controls the rendering of all returned summaries -->
    <div id="property-summaries" class="has-margin-top-15">
        <div v-for="(summaryType, label) in layerSummaries" :key="label">
            <h6 class="title is-6 is-capitalized">{{ label }} Overview</h6>
            <div class="columns" :class="currentWidth < 3 ? 'is-multiline' : ''">
                
                <div
                    v-for="(summary, type) in summaryType"
                    :key="`${label}-${type}`"
                    class="column" 
                    :class="computedColsSize"
                >
                    <div class="tile">
                        <property-summary :summary="summary" :title="type"></property-summary>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import LayerStateHelpers from "@/mixins/LayerStateHelpers.js";
import PropertySummary from "@/components/sidebar/PropertySummary.vue";

export default {
    name: "PropertySummaries",
    mixins: [LayerStateHelpers],
    components: { PropertySummary },
    props: {},
    data() {
        return {};
    },
    computed: {
        currentWidth() {
            return this.$store.getters["sidebarState/width"];
            // console.log(chunk(this.userPortals, currentWidth));
            // return this.userPortals;
            // return chunk(this.userPortals, currentWidth);

        },
        computedColsSize() {
            // let currentWidth = this.$store.getters["sidebarState/width"];
            // this.currentWidth
            switch (this.currentWidth) {
                case 1:
                    return "is-full";
                    break;
                case 2:
                    return "is-one-half";
                    break;
                case 3:
                    return "is-one-third";
                    break;

                default:
                    return "is-one-quarter";
                    break;
            }
        }
    },
    mounted() {},
    methods: {}
};
</script>

<style lang="scss" scoped>
// #property-summaries{
//     width: 100%
// }
// canvas {
// width: 100% !important;
// }
</style>
