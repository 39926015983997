<template>
    <l-polygon
        :lat-lngs="getLatLngs(feature.coordinates)"
        :stroke="true"
        :weight="defaultStyle.weight"
        :opacity="defaultStyle.opacity"
        :color="color"
        :fillOpacity="opacity ? parseFloat( opacity ) : 0.5"
        :fillColor="color"
        :options="{fillPattern: fillPattern}"
        @add="added"
        @click="clicked"
        @mouseover="mouseover"
        @mouseout="mouseout"
    >
        <l-tooltip :options="{permanent: false, sticky: true}" v-if="displayRef">
            <div>
                <!-- <h4> {{ popup.title }} </h4> -->
                <h4> {{ displayRef }} </h4>
            </div>
        </l-tooltip>
    </l-polygon>
    <!--  -->
</template>

<script>
import { latLng } from "leaflet";
import { LPolygon, LTooltip } from "vue2-leaflet";
import MapFeature from './MapFeature.js';
export default {
    extends: MapFeature,
    name: "MPolygon",
    components: {
        LPolygon, 
        LTooltip
    },
    props: {
        // title for tooltip
        color: String,
        opacity: Number,
        // polygon: Object,
        layerId: String,
        layer: Object,
        fillPattern: Object
    },
    data() {
        return {
            // stripes: this.$store.getters["mapState/stripePattern"],
            // defaultStyle: {
            //     weight: 1,
            //     opacity: 1,
            //     color: this.color,
            //     fillOpacity: 0.6,
            // },
            // focusStyle: {
            //     weight: 3,
            //     opacity: 1,
            //     color: '#FFF',
            //     fillOpacity: 0.8,
            // }
        }
    },
    mounted () {
        // console.log(this.stripes);
        
    },
    methods: {
        getLatLngs(coordinates) {
            // console.log('GLLs');
            // console.log(coordinates);
            
            
            // return coordinates.map( c => [c[0], c[1]]);
            return coordinates.map(outer =>
                outer.map(inner => [inner[1], inner[0]])
            );
        }
    }
};
</script>

<style lang="scss" scoped>
    // .leaflet-tooltip{
    //     h4 {
    //         font-size: 1.2em;
    //     }
    // }
</style>
