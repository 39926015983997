<template>
    <div id="user-contacts">
        <div class="columns">
            <div class="column">
                <b-field grouped class="nested-label-field">
                    <b-field
                        expanded
                        label="User Name"
                        :type="
                            errorFields.includes('user') ? 'is-danger' : ''
                        "
                    >
                        <b-select
                            v-model="selectedUserId"
                            @change.native="$emit('remove-error-field', 'user')"
                            size="is-small"
                            placeholder="Select a user"
                            :disabled="portalUsersLoading || mode === 'edit'"
                            :loading="portalUsersLoading"
                        >
                            <option
                                v-for="portalUser in portalUsers"
                                :key="portalUser._id.$oid"
                                :value="portalUser._id.$oid"
                                
                            >
                                <!-- :disabled="mode === 'new' && portalUser.alreadyAdded" -->
                                {{ portalUser.name }}
                            </option>
                        </b-select>
                    </b-field>
                </b-field>
                <b-field
                        custom-class="is-small"
                        label="Description"
                        :type="errorFields.includes('description') ? 'is-danger' : ''"
                    >
                        <b-input 
                            :disabled="!userData || (userData && userData.userType == 'ad')" size="is-small" 
                            @keyup.native="$emit('remove-error-field', 'description')"
                            v-model="description" 
                            maxlength="50"
                        />
                            
                    </b-field>
            </div>
            
            
            <div class="column is-two-thirds">
                <div class="card">
                    <div class="card-content" v-if="userDataLoading">
                        <b-loading
                            :is-full-page="false"
                            :active.sync="userDataLoading"
                            :can-cancel="false"
                        >
                            <p class="title is-6 has-text-centered">
                                <font-awesome-icon
                                    :icon="['fad', 'sync']"
                                    :style="$iconStyle"
                                    size="2x"
                                    spin
                                />
                            </p>
                        </b-loading>
                    </div>

                    <div v-else class="card-content">
                        <div v-if="userData">
                            <div class="content">
                                <strong>Name: </strong>
                                {{ userData.name }}
                                <br />
                                <strong>Email: </strong>
                                {{ userData.email }}
                                <br />
                                <strong>Telephone: </strong>
                                {{ userData.phone }}
                                <br />
                                <strong>Mobile: </strong>
                                {{ userData.mobile }}
                            </div>
                        </div>

                        <div v-else>
                            <p class="title is-6 has-text-centered">
                                Please select a user
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ClientDetailsHelpers from "@/mixins/ClientDetailsHelpers.js";

import HelpTooltip from "@/components/notifications/HelpTooltip.vue";

export default {
    name: "UserContacts",
    mixins: [ClientDetailsHelpers],
    components: {HelpTooltip},
    props: {
        mode: {
            type: String,
            required: true,
            validator: function(value) {
                // The value must match one of these strings
                return ["new", "edit"].indexOf(value) !== -1;
            }
        },
        errorFields: Array,
        selectedTab: Number,
        contact: Object
    },
    data() { 
        return {
            portalUsersLoading: true,
            portalUsers: [],
            selectedUserId: null,
            userDataLoading: false,
            userData: null,
            description: '',
        } 
    },
    mounted () {
        if( this.mode == 'new' ) {
            // load users that are assigned to this portal
            this.loadPortalUsers();
        }

        if( this.mode == 'edit' ) {
            
            // populate the user select box 
            // and trigger loading the user data
            this.portalUsersLoading = false;
            this.portalUsers.push({
                "_id" : this.contact.userId,
                name: this.contact.name
            });
            this.selectedUserId = this.contact.userId.$oid;
            // non ad users can have the description edited
            if(this.contact.userType = 'ext') {
                this.description = this.contact.description;
            }
            
        }
    },
    watch: {
        "selectedUserId": function() {
            // gets reinitialised (nulled) if tab changed
            if( this.selectedUserId ) {
                this.userDataLoading = true;
                let url = `/users/${this.selectedUserId}`;
                this.$ppClient.get(url).then(response => {
                    let body = response.data.body;

                    this.userData = body.user;
                    this.userDataLoading = false;
                    if( body.user.userType == 'ad' ) {
                        this.description = body.user.jobTitle;
                    } else {
                        // don't clear the field if editing an ext user contact
                        if(this.mode != 'edit') {
                            this.description = '';
                        }
                        
                    }

                    this.$emit('user-contact-id-changed', {userId: body.user._id.$oid, userType: body.user.userType});
                });
            }
            
        },
        description: function() {
            this.$emit('user-contact-description-changed', this.description);
        },
        selectedTab: function() {
            this.selectedUserId = null;
            this.userData = null;
            this.description = '';
        }
    },
    methods: {
        loadPortalUsers() {
            let url = `/portal/${this.selectedPortal}/users`;
            this.$ppClient.get(url).then(response => {
                let body = response.data.body;

                this.portalUsers = body.users;
                this.portalUsersLoading = false;
            });
        },
        removeErrorField( field ) {

        }
    }
};
</script>

<style lang="scss" scoped></style>
