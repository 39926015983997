<template>
    <div id="site-details-notifications">
        <div>
            <b-collapse
                class="card"
                aria-id="map-details-last-clicked"
                :open="siteNotifications.length > 0"
            >
                <div
                    slot="trigger"
                    slot-scope="props"
                    class="card-header"
                    role="button"
                    aria-controls="map-details-last-clicked"
                >
                    <p class="card-header-title is-capitalized">
                        Notifications
                    </p>
                    <a class="card-header-icon">
                        <font-awesome-icon
                            :icon="
                                props.open
                                    ? ['fas', 'caret-up']
                                    : ['fas', 'caret-down']
                            "
                        />
                    </a>
                </div>

                <div class="card-content">
                    <div class="buttons">
                        <b-button
                            type=""
                            title="Add a site notification"
                            @click="launchNewNotificationModal"
                            class="has-margin-left-10"
                        >
                            <font-awesome-icon :icon="['fas', 'plus']" />
                        </b-button>
                    </div>

                    <b-table
                        v-if="siteNotifications.length > 0"
                        :data="siteNotifications"
                    >
                        <template slot-scope="props">
                            <b-table-column field="date" label="Date">
                                {{
                                    new Date(
                                        props.row.notificationDate
                                    ).toLocaleDateString()
                                }}
                            </b-table-column>

                            <b-table-column field="title" label="Title">
                                {{ props.row.title }}
                            </b-table-column>

                            <b-table-column
                                field="show-notification"
                                label="View"
                                centered
                            >
                                <b-button
                                    @click="launchEditNotificationModal(props.row)"
                                    title="reveal in notifications tab"
                                >
                                    <font-awesome-icon :icon="'eye'" />
                                </b-button>
                            </b-table-column>
                        </template>
                    </b-table>

                    <div v-else>
                        No Notifications.
                    </div>
                </div>
            </b-collapse>
        </div>
    </div>
</template>

<script>
import FeatureStateHelpers from "@/mixins/FeatureStateHelpers.js";
import NotificationHelpers from "@/mixins/NotificationHelpers.js";

export default {
    name: "SiteNotifications",
    mixins: [FeatureStateHelpers, NotificationHelpers],
    components: {},
    props: {},
    data() {
        return {};
    },
    computed: {
        siteNotifications: function() {
            // console.log('site notifications');
            // console.log(this.selectedFeature);

            if (this.selectedFeature) {
                // console.log(this.selectedFeature._site.siteId.$oid);

                return this.notifications.filter(n => {
                    // console.log(n);

                    return (
                        n.type == "site" &&
                        n.siteId === this.selectedFeature._site.siteId.$oid
                    );
                });
            }
            return [];
        }
    },
    mounted() {
        // console.log(this.selectedFeature);
    },
    methods: {
        launchNewNotificationModal() {
            let selectedPortal = this.$store.getters["mapState/selectedPortal"];
            // console.log('launch modal');
            // console.log(this.selectedPortal);
            let props = {
                mode: "new",
                notificationType: "site",
                portalId: selectedPortal,
                siteId: this.selectedFeature._site.siteId.$oid,
                layerId: this.selectedLayer._id.$oid
            };
            this.launchNotificationModal(props);
        }
    }
};
</script>

<style lang="scss" scoped></style>
