/**
 * Geoman needs a good bit of hacking to make it work with layers (feature groups)
 * This mixin is just here to move all hacking into a single file and unpollute the map component
 */

import LayerStateHelpers from "@/mixins/LayerStateHelpers.js";

export default {
    mixins: [LayerStateHelpers],
    data() {
        return {
            // map: this.$store.getters["mapState/map"]
        }
    },
    computed: {
        
    },
    watch: {
        selectedLayer: function() {
            let selectedFeatures = this.map.pm.map._layers;
            this.disableLayerDrag(selectedFeatures);
        }
    },
    methods: {
        overrideGeomanModeEvents() {
            // .enable({
            //     allowSelfIntersection: false,
            //   });
            this.overrideDragModeEvent();
            this.overrideEditModeEvent();
        },
        overrideDragModeEvent() {
            this.map.pm._fireDragModeEvent = function(enabled) {    
                let eType = enabled ? 'enabled' : 'disabled';
                map.fire(`pm:globaldragmode${eType}`, {
                enabled,
                map: map,
                });
            };
        },
        overrideEditModeEvent() {
            this.map.pm.toggleGlobalEditMode = function () {
                return;
            };
            this.map.pm._fireEditModeEvent = function(enabled) {    
                let eType = enabled ? 'enabled' : 'disabled';
                map.fire(`pm:globaleditmode${eType}`, {
                enabled,
                map: map,
                });
            };
        },
        dragEnabled(e) {
            // console.log('dragEnabled');
            // console.log(e);
            // console.log(this.map.pm);
            
            // this.map.pm.toggleGlobalEditMode();
            // console.log(this.selectedLayer);
            // this.localDragMode = true;

            // if ( e.enabled == true ) {
                // console.log(this.map);
                // this.map.pm.disableGlobalDragMode();
                let selectedFeatures = this.map.pm.map._layers;
                this.disableLayerDrag(selectedFeatures);
                // this.map.pm.map._globalDragMode = false;
                // this.map.pm.Toolbar.toggleButton('dragMode', true);
                selectedFeatures = this.selectedLayer.featureGroup._layers;
                // console.log('selectedFeatures');
                // console.log(selectedFeatures);
                
                this.enableLayerDrag(selectedFeatures);
                
                // this.localDragMode = false;
                // console.log(this.localDragMode);
                
            // }else{
            //     this.map.pm.Toolbar.toggleButton('dragMode');
            // }
        },
        enableLayerDrag( selectedFeatures ) {
            // console.log('enableLayerDrag');
            // console.log('selectedFeatures');
            // console.log(selectedFeatures);

            for(let layerId in selectedFeatures){
                let layer = selectedFeatures[layerId];
                // console.log('layer');
                // console.log(layer);
                if(layer._layers){
                    let nestedLayers = layer._layers;
                    this.enableLayerDrag(nestedLayers);
                }else{
                    if (layer.pm) {
                        layer.pm.enableLayerDrag();
                    }
                }
            }
        },
        dragDisabled(e) {

            // console.log('drag disabled');
            // console.log(e);
            // console.log(`local drag mode = ${this.localDragMode}`);
            this.map.pm.disableGlobalDragMode();
            let selectedFeatures = this.selectedLayer.featureGroup._layers;
            this.disableLayerDrag(selectedFeatures);
            // this.map.pm.Toolbar.toggleButton('dragMode');
            
            // if(!this.localDragMode){
            //     console.log('here');
                
            //     this.map.pm.disableGlobalDragMode();
            //     this.map.pm.Toolbar.toggleButton('dragMode', false);
            // }
            
        },
        disableLayerDrag( selectedFeatures ) {
            // console.log('in disable layer drag');
            // console.log(selectedFeatures);
            
            for(let layerId in selectedFeatures){
                let layer = selectedFeatures[layerId];
                if(layer._layers){
                    let nestedLayers = layer._layers;
                    this.disableLayerDrag(nestedLayers);
                }else{
                    if (layer.pm) {
                        layer.pm.disableLayerDrag();
                    }
                }
            }
        },
        editEnabled(e) {
            // console.log('editEnabled');
            if( !this.selectedFeature ) {
                this.$buefy.toast.open('Please select a feature to edit');
                this.map.pm.disableGlobalEditMode();
                return;
            } else {
                this.enableSelectedFeatureEdit();
            }
        },
        enableSelectedFeatureEdit( ) {
            // console.log('enableSelectedFeatureEdit');
            // console.log( this.selectedFeature );

            if( this.selectedFeature ) {

                // multipolygons handled differently
                if( this.selectedFeature._layers) {
                    for( let layerId in this.selectedFeature._layers) {
                        // console.log('layerId');
                        // console.log(layerId);
                        
                        this.selectedFeature._layers[layerId].pm.enable({
                            allowSelfIntersection: false,
                        });
                    }
                } else {
                    this.selectedFeature.pm.enable({
                        allowSelfIntersection: false,
                    });
                }
            }
        },
        enableLayerEdit( selectedFeatures ) {
            
            for(let layerId in selectedFeatures){
                let layer = selectedFeatures[layerId];
                if(layer._layers){
                    let nestedLayers = layer._layers;
                    this.enableLayerEdit(nestedLayers);
                }else{
                    if (layer.pm) {
                        layer.pm.enable({allowSelfIntersection: false,});
                    }
                }
            }
        },
        editDisabled(e) {

            // console.log('drag disabled');
            // console.log(e);
            // console.log(`local drag mode = ${this.localDragMode}`);
            this.map.pm.disableGlobalEditMode();
            let selectedFeatures = this.selectedLayer.featureGroup._layers;
            this.disableLayerEdit(selectedFeatures);
            // this.map.pm.Toolbar.toggleButton('dragMode');
            
            // if(!this.localDragMode){
            //     console.log('here');
                
            //     this.map.pm.disableGlobalDragMode();
            //     this.map.pm.Toolbar.toggleButton('dragMode', false);
            // }
            
        },
        disableLayerEdit( selectedFeatures ) {
            // console.log('in disable layer drag');
            // console.log(selectedFeatures);
            
            
            for(let layerId in selectedFeatures){
                let layer = selectedFeatures[layerId];
                if(layer._layers){
                    let nestedLayers = layer._layers;
                    this.disableLayerEdit(nestedLayers);
                }else{
                    if (layer.pm) {
                        layer.pm.disable();
                    }
                }
            }
        },
    }
}