var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"manage-users"}},[_c('sidebar-header',{attrs:{"header-title":"Manage Users","show-client-logo":false}}),_c('b-loading',{attrs:{"is-full-page":false,"active":_vm.loadingData,"can-cancel":false},on:{"update:active":function($event){_vm.loadingData=$event}}},[_c('font-awesome-icon',{style:(_vm.$iconStyle),attrs:{"icon":['fad', 'sync'],"size":"4x","spin":""}})],1),_c('div',{staticClass:"margin-top-15",attrs:{"id":"manage-users-header-actions"}},[_c('div',{staticClass:"buttons"},[_c('b-button',{attrs:{"type":"","title":"Add a user to Property portal"},on:{"click":_vm.launchNewUserModal}},[_c('font-awesome-icon',{attrs:{"icon":'user-plus'}})],1)],1),_c('b-tabs',{attrs:{"id":"user-list-tabs","type":"is-boxed"},model:{value:(_vm.userListTab),callback:function ($$v) {_vm.userListTab=$$v},expression:"userListTab"}},[_c('b-tab-item',{attrs:{"label":"FG Users"}},[_c('b-table',{staticClass:"trans-table",attrs:{"data":_vm.adUsers,"default-sort":"name"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('b-table-column',{attrs:{"sortable":"","field":"name","label":"Name"}},[_vm._v(" "+_vm._s(props.row.name)+" ")]),_c('b-table-column',{attrs:{"field":"edit-user","label":"Edit","centered":""}},[_c('b-button',{attrs:{"disabled":_vm.editUserDisabled(props.row)},on:{"click":function($event){return _vm.launchEditUserModal(props.row)}}},[_c('font-awesome-icon',{attrs:{"icon":'edit'}})],1)],1),_c('b-table-column',{attrs:{"field":"disable-user","label":"Active","centered":""}},[_c('b-switch',{attrs:{"disabled":_vm.editUserDisabled(props.row),"type":"is-success"},on:{"input":function($event){return _vm.handleUserActive(
                                        $event,
                                        props.row._id.$oid
                                    )}},model:{value:(props.row.active),callback:function ($$v) {_vm.$set(props.row, "active", $$v)},expression:"props.row.active"}})],1)]}}])})],1),_c('b-tab-item',{attrs:{"label":"External Users"}},[_c('b-table',{staticClass:"trans-table",attrs:{"data":_vm.extUsers,"default-sort":"name"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('b-table-column',{attrs:{"sortable":"","field":"name","label":"Name"}},[_vm._v(" "+_vm._s(props.row.name)+" ")]),_c('b-table-column',{attrs:{"field":"edit-user","label":"Edit","centered":""}},[_c('b-button',{on:{"click":function($event){return _vm.launchEditUserModal(props.row)}}},[_c('font-awesome-icon',{attrs:{"icon":'edit'}})],1)],1),_c('b-table-column',{attrs:{"field":"disable-user","label":"Active","centered":""}},[_c('b-switch',{attrs:{"disabled":!props.row.verified,"type":"is-success"},on:{"input":function($event){return _vm.handleUserActive(
                                        $event,
                                        props.row._id.$oid
                                    )}},model:{value:(props.row.active),callback:function ($$v) {_vm.$set(props.row, "active", $$v)},expression:"props.row.active"}})],1)]}}])})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }