<template>
    <div
        id="sidebar"
        class="leaflet-sidebar collapsed"
        ref="sidebar"
        data-leaflet-sidebar
    >
        <!-- Nav tabs -->
        <div class="leaflet-sidebar-tabs">
            <ul role="tablist">
                <!-- top aligned tabs -->
                <li>
                    <a
                        href="#home"
                        ref="home"
                        @click="currentTab = 'home'"
                        role="tab"
                        title="Dashboard"
                    >
                        <!-- <font-awesome-icon
              :icon="['fas', 'map-signs']"
              :color="$styles.fgBlue"
            /> -->
                        <font-awesome-icon
                            :icon="['fad', 'home']"
                            :style="$iconStyleInverse"
                        />
                    </a>
                </li>
                <li>
                    <a
                        href="#layer-management"
                        @click="currentTab = 'layer-management'"
                        role="tab"
                        title="Layer Management"
                    >
                        <font-awesome-icon
                            :icon="['fad', 'layer-group']"
                            :style="$iconStyle"
                        />
                    </a>
                </li>
                <li :class="{ disabled: !layerSelected }">
                    <a
                        href="#layer-details"
                        role="tab"
                        @click="currentTab = 'layer-details'"
                        title="Site List"
                    >
                        <font-awesome-icon
                            :icon="['fad', 'th-list']"
                            :style="hasDisabledStyling(layerSelected)"
                        />
                    </a>
                </li>
                <li :class="{ disabled: !featureSelected }" ref="siteTab">
                    <a
                        href="#feature-details"
                        role="tab"
                        title="Selected Site Details"
						@click="currentTab = 'feature-details'"
                    >
                        <font-awesome-icon
                            :icon="['fad', 'city']"
                            :style="hasDisabledStyling(featureSelected)"
                        />
                    </a>
                </li>
                <li>
                    <a
                        href="#user-notifications"
                        role="tab"
                        title="Notification Centre"
						@click="currentTab = 'user-notifications'"
                    >

                        <font-awesome-icon
                            v-if="dueNotifications"
                            :icon="['fas', 'comment-exclamation']"
                            :color="$styles.fgRed"
                        />

                        <font-awesome-icon
                            v-else
                            :icon="['fas', 'comment-lines']"
                            :color="$styles.fgBlue"
                        />
                    </a>
                </li>
                <li>
                    <!-- v-show="userPortals.length > 1" -->
                    <a href="#portals" role="tab" title="Change Portal" @click="currentTab = 'portals'">
                        <font-awesome-icon
                            :icon="['fad', 'dot-circle']"
                            :style="$iconStyle"
                        />
                    </a>
                </li>

                <li v-if="sysAccess < 3">
                    <a
                        href="#users"
                        role="tab"
                        title="Manage Users"
                        @click="currentTab = 'users'"
                    >
                        <font-awesome-icon
                            :icon="['fad', 'users-cog']"
                            :style="$iconStyle"
                        />
                    </a>
                </li>

                <li>
                    <a href="#contact-details" 
                        role="tab"
                        title="Portal Contacts"
                        @click="currentTab = 'contacts'"
                    >
                        <font-awesome-icon
                            :icon="['fad', 'phone-rotary']"
                            :style="$iconStyle"
                        />
                    </a>
                </li>
            </ul>

            <!-- bottom aligned tabs -->
            <!-- <ul role="tablist">
                <li>
                    <a href="#legend" role="tab" title="Legend">
                        <font-awesome-icon
                            :icon="['fas', 'question']"
                            :color="$styles.fgBlue"
                        />
                    </a>
                </li>
                <li>
                    <a href="#profile" role="tab" title="User Profile">
                        <font-awesome-icon
                            :icon="['fad', 'address-card']"
                            :style="$iconStyleInverse"
                        />
                    </a>
                </li>
                <li>
                    <a href="#contact-details" role="tab">
                        <font-awesome-icon
                            :icon="['fad', 'phone-rotary']"
                            :style="$iconStyle"
                        />
                    </a>
                </li>
            </ul> -->
        </div>

        <!-- Tab panes -->
        <div class="leaflet-sidebar-content">
            <div class="leaflet-sidebar-pane" id="home">
                <dashboard :is-loading="isLoading" />
            </div>

            <div class="leaflet-sidebar-pane" id="layer-management">
                <layer-management
                    @layerRenamed="layerRenamed"
                    :isLoading="isLoading"
                    :currentTab="currentTab"
                />
            </div>

            <div class="leaflet-sidebar-pane" id="layer-details">
                <layer-details />
            </div>

            <div class="leaflet-sidebar-pane" id="feature-details">
                <feature-details />
            </div>

            <div class="leaflet-sidebar-pane" id="user-notifications">
                <notifications :isLoading="isLoading" />
            </div>

            <div class="leaflet-sidebar-pane" id="portals">
                <portal-actions :is-loading="isLoading"/>
            </div>

            <div 
                v-if="sysAccess < 3" 
                class="leaflet-sidebar-pane" 
                id="users"
            >
                <manage-users :currentTab="currentTab" />
            </div>

            <!-- bottom tab panels -->
            <!-- <div class="leaflet-sidebar-pane" id="legend">
                <MapLegendSidebar />
            </div>

            <div class="leaflet-sidebar-pane" id="profile">
                <user-profile />
            </div> -->

            <div class="leaflet-sidebar-pane" id="contact-details">
                <PortalContacts :is-loading="isLoading"/>
            </div>
        </div>
    </div>
</template>
<script>
// convenience getters from vuex
import { mapGetters } from "vuex";
import { FontAwesomeLayers } from "@fortawesome/vue-fontawesome";

import AccessControlHelpers from "@/mixins/AccessControlHelpers.js";

import Dashboard from "@/components/sidebar/Dashboard.vue";
import LayerManagement from "@/components/sidebar/LayerManagement";
import LayerDetails from "@/components/sidebar/LayerDetails";
import FeatureDetails from "@/components/sidebar/FeatureDetails/FeatureDetails.vue";
import Notifications from "@/components/sidebar/Notifications/Notifications.vue";
import PortalActions from "@/components/sidebar/PortalActions.vue";
import ManageUsers from "@/components/sidebar/ManageUsers.vue";

import PortalContacts from "@/components/sidebar/PortalContacts.vue";

// import UserProfile from "@/components/sidebar/UserProfile.vue";
// import MapLegendSidebar from "@/components/sidebar/MapLegendSidebar.vue";

import LoadingNotification from "@/components/notifications/LoadingNotification.vue";



export default {
    name: "Sidebar",
    mixins: [AccessControlHelpers],
    components: {
        FontAwesomeLayers,
        Dashboard,
        LoadingNotification,
        // MapLegendSidebar,
        LayerManagement,
        LayerDetails,
        FeatureDetails,
        Notifications,
        PortalActions,
        ManageUsers,
        PortalContacts,
        // UserProfile
    },
    props: {
        // currentZoom: Number,
        userPortals: Array,
        isLoading: Boolean
    },
    data() {
        return {
            // currentTab: "home",
            currentWidth: 1
            //   latitude: this.lat,
            //   longitude: this.long
        };
    },
    // created() {
    //     this.$eventHub.$on("set-sidebar-loading-state", isLoading => {
    //         this.isLoading = isLoading;
    //     });
    // },
    mounted() {
        // console.log(this.currentTab);
    },
    computed: {
        // vuex getters mapped
        ...mapGetters({
            zoom: "mapState/zoom",
            latitude: "mapState/latitude",
            longitude: "mapState/longitude",
            layerSelected: "mapState/layerSelected",
            featureSelected: "mapState/featureSelected",
            notifications: "mapState/notifications"
        }),
        dueNotifications: function() {
            let nDates = this.notifications.map( n => new Date(n.notificationDate));
            // dates get stored in db as midnight on the selected day
            let today = new Date();
            today.setHours(0, 0, 0, 0);

            return nDates.some( date => date <= today);
        },
        sidebarWidth: function() {
            return `wide-sidebar-${this.currentWidth}`;
        },
        currentTab: {
            get() {
                return this.$store.getters["sidebarState/currentTab"];
            },
            set(newVal) {
                // console.log('set tab');
                // console.log(newVal);

                this.$store.commit("sidebarState/setCurrentTab", newVal);

                // console.log(this.currentTab);
            }
        }
    },
    methods: {
        scrollTest() {
            // console.log(document.getElementById('site-table-5b30e75549bbff4195335f9a'));
            // document.getElementById('site-table-5b30e75549bbff4195335f9a').scrollIntoView();
            // var container = document.getElementById('selected-layer-details');
            // var element = document.getElementById('site-table-5b30e75549bbff4195335f9a');
            // container.scrollTop = element.offsetTop;
        },
        increaseWidth() {
            if (this.currentWidth < 2) {
                this.currentWidth++;
            }
        },
        reduceWidth() {
            if (this.currentWidth > 0) {
                this.currentWidth--;
            }
        },
        // setCurrentTab(selectedTab) {
        //   this.currentTab = selectedTab;
        // },
        layerRenamed(layerId, newName) {
            // console.log("newName");
            // console.log(newName);

            this.$emit("layerRenamed", layerId, newName);
        },
        hasDisabledStyling(constraint) {
            let disabledIconStyle = {
                "--fa-primary-color": "#C0C0C0",
                "--fa-secondary-color": "	#DCDCDC",
                "--fa-secondary-opacity": 1.0
            };
            return constraint ? this.$iconStyle : disabledIconStyle;
        }
    }
};
</script>

<style lang="scss">
div.field.nested-label-field {
    & label {
        font-size: 0.75rem;
    }
}
.trans-table{
  table.table{
    background-color: rgba(255, 255, 255, 0) !important;
  }
}
</style>

<style lang="scss">
div[data-leaflet-sidebar].leaflet-sidebar {
    .leaflet-sidebar-tabs,
    .leaflet-sidebar-content {
        background-color: rgba(255, 255, 255, 0.85);
    }

    .leaflet-sidebar-header {
        text-align: center;
        background-color: var(--fg-grey-med);
    }

    .leaflet-sidebar-tabs {
        li.active {
            background-color: var(--fg-grey-med);
        }
    }

    // div#portal-selection{
    //   padding-top: 15px;

    //   .client-portal-select {
    //     width: 85%;
    //     // border: 1px solid #DDDDDD;
    //     margin-bottom: 10px;
    //     padding: 5px;

    //     &:hover {
    //       background-color: #DDDDDD;
    //     }
    //   }
    // }

    // &.collapsed{
    //   width: 60px !important;
    //   max-width: 60px !important;
    // }

    // .leaflet-sidebar-tabs{
    //   width: 60px !important;
    //   max-width: 60px !important;
    // }

    &.wide-sidebar-1:not(.collapsed) {
        // need px vals to allow auto-pan
        // but % vals do also work to make sidebar wider
        width: 600px !important;
        max-width: 600px !important;

        // width: 75%;
        // right: 25%;
    }

    &.wide-sidebar-2:not(.collapsed) {
        // need px vals to allow auto-pan
        // but % vals do also work to make sidebar wider
        width: 1000px !important;
        max-width: 1000px !important;

        // width: 75%;
        // right: 25%;
    }
}
</style>
