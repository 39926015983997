<template>
    <div id="property-search">
        <div>
            <b-collapse
                class="card"
                aria-id="search-results-collapse"
                :open="true"
            >
                <div
                    slot="trigger"
                    slot-scope="props"
                    class="card-header"
                    role="button"
                    aria-controls="search-results-collapse"
                >
                    <p class="card-header-title is-capitalized">
                        Property Search
                    </p>
                    <a class="card-header-icon">
                        <font-awesome-icon
                            :icon="
                                props.open
                                    ? ['fas', 'caret-up']
                                    : ['fas', 'caret-down']
                            "
                        />
                    </a>
                </div>

                <div class="card-content">
                    <div v-if="propertySearchEnabled">
                        <div>
                            <p>
                                <b-field label="Search:" class="nested-label-field">
                                    <b-input
                                        v-model="searchTerm"
                                        v-debounce="search"
                                        size="is-small"
                                    >
                                    </b-input>
                                </b-field>
                            </p>
                        </div>

                        <div id="property-search">
                            <b-loading
                                :is-full-page="false"
                                :active.sync="resultsLoading"
                                :can-cancel="false"
                            ></b-loading>

                            <div v-if="searchTerm.length < 3" id="no-search-term">
                                <b-message>
                                    Please type at least three characters to start
                                    searching.
                                </b-message>
                            </div>

                            <div
                                v-else-if="searchResults.length === 0"
                                id="no-search-results"
                            >
                                <b-message>
                                    Nothing matched your search.
                                </b-message>
                            </div>

                            <div v-else id="search-results">
                                <br />
                                <div class="has-margin-10">
                                    Search Results:
                                </div>
                                <!-- ({{ searchResults.length }}) -->
                                <b-table
                                    :data="searchResults"
                                    ref="table"
                                    detailed
                                    detail-key="siteId"
                                >
                                    <!-- :show-detail-icon="showDetailIcon" -->

                                    <!-- <propman-result-row :props="props"/> -->

                                    <template slot-scope="props">
                                        <!-- <b-table-column>
                                            {{ props.index + 1 }}
                                        </b-table-column> -->

                                        <b-table-column
                                            sortable
                                            field="description"
                                            label="Site"
                                            :style="layerColourStyle(props.row)"
                                        >
                                            <span
                                                v-html="props.row.displayRef"
                                            ></span>
                                        </b-table-column>

                                        <b-table-column
                                            field="property-no"
                                            label="Show"
                                        >
                                            <b-button
                                                type="is-white"
                                                @click="showFeature(props.row)"
                                                title="Reveal this site on the map"
                                            >
                                                <font-awesome-icon
                                                    :icon="'eye'"
                                                ></font-awesome-icon>
                                            </b-button>
                                        </b-table-column>

                                        <b-table-column
                                            field="change-site"
                                            label="Details"
                                        >
                                            <b-button
                                                @click="
                                                    changeTab(
                                                        props.row,
                                                        'feature-details'
                                                    )
                                                "
                                                title="Reveal site and display the details for it"
                                            >
                                                <font-awesome-icon
                                                    :icon="['fad', 'city']"
                                                    :style="$iconStyle"
                                                ></font-awesome-icon>
                                            </b-button>
                                        </b-table-column>
                                    </template>

                                    <template slot="detail" slot-scope="props">
                                        <article class="media">
                                            <ul>
                                                <li>
                                                    <strong>Layer: </strong>
                                                    <span>
                                                        {{
                                                            getLayerName(props.row)
                                                        }}</span
                                                    >
                                                </li>
                                                <li
                                                    v-for="(fieldVal,
                                                    fieldName) in props.row.fields"
                                                    :key="fieldName"
                                                >
                                                    <strong class="is-capitalized"
                                                        >{{ fieldName }}:
                                                    </strong>
                                                    <span v-html="fieldVal"></span>
                                                </li>

                                                <!-- <li>
                                                    <strong>Address: </strong>
                                                    <span
                                                        v-html="
                                                            props.row['address']
                                                        "
                                                    ></span>
                                                </li>
                                                <li>
                                                    <strong>Post Code: </strong>
                                                    <span
                                                        v-html="
                                                            props.row['post-code']
                                                        "
                                                    ></span>
                                                </li>
                                                <li>
                                                    <strong>Type: </strong>
                                                    <span
                                                        v-html="
                                                            props.row['propdesc']
                                                        "
                                                    ></span>
                                                </li>
                                                <li>
                                                    <strong>Propman: </strong>
                                                    {{ props.row["property-no"] }}
                                                </li> -->
                                            </ul>
                                        </article>
                                    </template>
                                </b-table>
                            </div>
                        </div>

                        <!-- <b-dropdown-item
                            v-for="result in searchResults"
                            :key="result['property-no']"
                            aria-role="listitem"
                            >{{ result.description }}</b-dropdown-item
                        > -->
                    </div>
                    <div v-else>
                        We're currently updating our search abilities to bring you the best possible service, please bear with us while we complete the work.
                    </div>
                </div>
            </b-collapse>
        </div>
    </div>
</template>

<script>
import { debounce } from "vue-debounce";

// import PropmanResultRow from "@/components/forms/propertySearch/PropmanResultRow.vue"

import LayerStateHelpers from "@/mixins/LayerStateHelpers.js";
import FeatureStateHelpers from "@/mixins/FeatureStateHelpers.js";
import MapStateHelpers from "@/mixins/MapStateHelpers.js";
import ColourHelpers from "@/mixins/ColourHelpers.js";

export default {
    name: "PropertySearch",
    components: {},
    mixins: [
        LayerStateHelpers,
        FeatureStateHelpers,
        MapStateHelpers,
        ColourHelpers
    ],
    props: {},
    data() {
        return {
            searchTerm: "",
            searchResults: [],
            resultsLoading: false,
            searchNotificationOpen: true,
            propertySearchEnabled: JSON.parse(process.env.VUE_APP_PROPERTY_SEARCH_ENABLED)
        };
    },
    mounted() {},
    computed: {
        // propmanClientCode: function() {
        //     let currrentPortalId = this.$store.getters["mapState/selectedPortal"];
        //     let currentUserPortals = this.$store.getters["mapState/userPortals"];
        //     // return currentUserPortals.find(portal => )
        // },
        selectedPortalId: {
            get() {
                return this.$store.getters["mapState/selectedPortal"];
            },
            set(portalId) {
                this.$store.commit("mapState/setSelectedPortal", portalId);
            }
        }
    },
    methods: {
        search() {
            // console.log("search");
            // console.log(this.searchTerm);
            // console.log(this.selectedPortal);
            // console.log(this.$store.getters["mapState/userPortals"]);

            if (this.searchTerm.length >= 3) {
                this.resultsLoading = true;
                let params = {
                    // selectedPortalId: this.$store.getters[
                    //     "mapState/selectedPortal"
                    // ],
                    selectedPortalId: this.selectedPortalId,
                    searchTerm: this.searchTerm
                };

                this.$ppClient
                    .get(`/search/property`, { params: params })
                    .then(response => {
                        // console.log("search response");
                        // console.log(response);

                        let body = response.data.body;
                        this.searchResults = body.searchResults;
                        this.resultsLoading = false;
                    })
                    .catch(e => {
                        console.log(e);
                    });
            } else {
                this.resultsLoading = false;
                this.searchResults = [];
            }
        },
        getPortalLayer(siteId) {
            let portalLayers = this.layers.filter(l => !l.externalLayer);
            // console.log(portalLayers);
        },
        getPropmanLayer(pmanCode) {
            let propmanLayers = this.layers.filter(
                l => l.externalLayer && l.popup == "propman"
            );

            let featureLayer = propmanLayers.find(pmanLayer => {
                return pmanLayer.sites.some(site => {
                    let propCode = site.fields.geostore.Propman;
                    if (propCode) {
                        propCode = propCode.replace(/^0+/, "");
                    }
                    // console.log(site.fields.geostore.Propman);
                    // console.log(propCode);
                    return propCode == pmanCode;
                });
            });

            return featureLayer;
        },
        layerColourStyle(row) {
            let layerColor, layerOpacity;
            if (row.dataSource === "portal.sites") {
                layerColor = row._layer.color;
                layerOpacity = row._layer.opacity;
            } else {
                let featureLayer = this.getPropmanLayer(row.siteId);
                if (featureLayer) {
                    layerColor = featureLayer.color;
                    layerOpacity = featureLayer.opacity;
                }
            }

            return layerColor
                ? `border-left: 3px solid ${layerColor}${this.alphaToHex(
                    layerOpacity || 1
                )}`
                : "";
        },
        getLayerName(row) {
            // console.log(row);

            if (row.dataSource === "portal.sites") {
                return row._layer.name;
            } else {
                let featureLayer = this.getPropmanLayer(row.siteId);
                return featureLayer ? featureLayer.name.trim() : "unknown";
            }
        },

        async changeTab(row, tabId) {
            // need to let the show feature function finish executing
            // so that the tab is no longer disabled
            var featureSelected = await this.showFeature(row);
            this.$nextTick(() => {
                if (featureSelected) {
                    let sidebar = this.$store.getters["sidebarState/sidebar"];
                    sidebar.enablePanel(tabId);
                    sidebar.open(tabId);
                }
            });
        },
        // @todo extend when not just propman properties
        async showFeature(row) {
            let siteId = row.siteId;

            let featureLayer, selectedLayerSite;
            // show feature from a client layer
            if (row.dataSource === "portal.sites") {
                featureLayer = this.layers.find(
                    l => l._id.$oid === row._layer.id
                );
                if (featureLayer) {
                    selectedLayerSite = featureLayer.sites.find(
                        s => s._id.$oid === siteId
                    );
                }

                if (!featureLayer || !selectedLayerSite) {
                    this.showPortalError(row);
                    return false;
                }
            } else {
                // show a feature from a propman layer
                featureLayer = this.getPropmanLayer(siteId);
                if (featureLayer) {
                    selectedLayerSite = featureLayer.sites.find(
                        s =>
                            s.features.properties.Propman.replace(/^0+/, "") ==
                            siteId
                    );
                }

                if (!featureLayer || !selectedLayerSite) {
                    this.showPropmanError(row);
                    return false;
                }
            }

            // console.log("in search");
            // console.log("featureLayer");
            // console.log(featureLayer);
            // console.log("selectedLayerSite");
            // console.log(selectedLayerSite);

            this.selectedLayer = featureLayer;
            this.selectedLayer.visible = 1;

            // console.log("slSite");
            // console.log(selectedLayerSite);

            // allows for the map to be updated
            this.$nextTick(() => {
                // @todo refactor
                // @todo extract into helper, also used in LayerDetails::showFetaure
                // maybe generalise the show fetaure functionality
                let feature = this.selectedLayer.geometry[
                    selectedLayerSite.featureType
                ].find(f => f.siteId.$oid == selectedLayerSite._id.$oid);
                let mapFeature = this.$store.getters["mapState/map"]._layers[
                    feature._leaflet_id
                ];

                let centrePoint = this.getCentrePoint(mapFeature);
                this.latitude = centrePoint.lat;
                this.longitude = centrePoint.lng;

                // reset styling for any selected feature
                if (this.$store.getters["mapState/featureSelected"]) {
                    this.$store.getters["mapState/selectedFeature"].setStyle(
                        this.$store.getters["mapState/featureStyle"](
                            feature.type,
                            "defaultStyle",
                            this.selectedLayer.color
                        )
                    );
                }
                this.$store.commit("mapState/setSelectedFeature", mapFeature);
                mapFeature.setStyle(
                    this.$store.getters["mapState/featureStyle"](
                        feature.type,
                        "focusStyle"
                    )
                );
            });

            return true;
        },
        showPropmanError(row) {
            const notif = this.$buefy.notification.open({
                duration: 10000,
                // indefinite: true,
                message: `
                <h4 class="notification-header">Couldn't load item on the map.</h4>
                <br>
                <p>${row.displayRef} (propman: ${row.siteId})</p>
                <br>
                <p>Does it have geometry added and are all external references correct?</p>
                `,
                position: "is-top-left",
                // container: '#search-notifications',
                type: "is-danger",
                hasIcon: true
            });
        },
        showPortalError(row) {
            const notif = this.$buefy.notification.open({
                duration: 10000,
                // indefinite: true,
                message: `
                <h4 class="notification-header">Couldn't load item on the map.</h4>
                <br>
                <p>${row.displayRef}</p>
                <br>
                <p>Does it have geometry added and are all external references correct?</p>
                `,
                position: "is-top-left",
                // container: '#search-notifications',
                type: "is-danger",
                hasIcon: true
            });
        }
    },
    watch: {
        // searchTerm: function() {
        //     // this.search();
        //     debounce(() => console.log(`searchTerm changed to ${this.searchTerm}`), '400ms');
        // }
        selectedPortalId: function() {
            this.searchTerm = "";
            this.searchResults = [];
        }
    }
};
</script>

<style lang="scss">
div.notices {
    z-index: 2050;
    article {
        .notification-header {
            // font-size: 1.2em;
            font-weight: bold;
        }
    }
}
span.search-match {
    background-color: yellow;
}
</style>

<style lang="scss" scoped>
#no-search-results {
    min-height: 50px;
}
#property-search {
    position: relative;
}
</style>
