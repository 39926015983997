var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"site-documents"}},[_c('div',[_c('h3',{staticClass:"subtitle is-5 margin-top-15"},[_vm._v(" Documents ")]),(_vm.portalAccess < 3)?_c('b-button',{attrs:{"type":"","title":!_vm.docUploadEnabled
                    ? _vm.cantUploadReason
                    : 'Upload a file for the site',"disabled":!_vm.docUploadEnabled},on:{"click":_vm.launchDocUploadModal}},[_c('font-awesome-icon',{attrs:{"icon":'upload'}})],1):_vm._e(),(_vm.fetchedFeatureDocs)?_c('div',_vm._l((_vm.fetchedFeatureDocs),function(subCat,category){return _c('b-collapse',{key:category,staticClass:"card margin-top-15",attrs:{"aria-id":category,"open":false},scopedSlots:_vm._u([{key:"trigger",fn:function(props){return _c('div',{staticClass:"card-header",attrs:{"role":"button","aria-controls":category}},[_c('p',{staticClass:"card-header-title is-capitalized"},[_vm._v(" "+_vm._s(category)+" ")]),_c('a',{staticClass:"card-header-icon"},[_c('font-awesome-icon',{attrs:{"icon":props.open
                                    ? ['fas', 'caret-up']
                                    : ['fas', 'caret-down']}})],1)])}}],null,true)},[_c('div',{staticClass:"card-content"},[_c('b-loading',{attrs:{"is-full-page":false,"active":_vm.loadingData,"can-cancel":false},on:{"update:active":function($event){_vm.loadingData=$event}}},[_c('font-awesome-icon',{style:(_vm.$iconStyle),attrs:{"icon":['fad', 'sync'],"size":"4x","spin":""}})],1),_vm._l((subCat),function(files,subCatName){return _c('div',{key:subCatName},[_c('p',{staticClass:"table-title"},[_c('strong',[_vm._v(_vm._s(subCatName))])]),(_vm.featureSelected)?_c('b-table',{staticClass:"trans-table file-table",attrs:{"data":files,"detailed":"","detail-key":"id"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('b-table-column',{attrs:{"field":"title","label":"Title"}},[_c('span',[_vm._v(_vm._s(props.row.title))])]),_c('b-table-column',{attrs:{"field":"download","label":"Download"}},[_c('b-button',{attrs:{"type":""},on:{"click":function($event){return _vm.downloadDocument(props.row)}}},[_c('font-awesome-icon',{attrs:{"icon":'download'}})],1)],1)]}},{key:"detail",fn:function(props){return [_c('h3',{staticClass:"subtitle is-6 header-with-button"},[_vm._v(" Notifications: "),_c('b-button',{staticClass:"has-margin-left-10",attrs:{"type":"","title":"Add a site notification"},on:{"click":function($event){return _vm.launchNewNotificationModal(
                                                props.row
                                            )}}},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'plus']}})],1)],1),(_vm.docHasNotifications(props.row))?_c('div',[_c('doc-notifications',{attrs:{"docNotifications":_vm.getNotificationsForDoc(
                                                props.row
                                            )}})],1):_c('div',[_vm._v(" No Notifications ")]),_c('hr',{staticClass:"has-margin-bottom-10"}),_c('article',{staticClass:"media"},[_c('ul',[_c('li',[_c('strong',[_vm._v("File Name: ")]),_vm._v(" "+_vm._s(props.row["filename"])+" ")]),_c('li',[_c('strong',[_vm._v("File Size: ")]),_vm._v(" "+_vm._s(props.row["fileSize"])+" ")]),_c('li',[_c('strong',[_vm._v("Descrition: ")]),_vm._v(" "+_vm._s(props.row["description"])+" ")]),_c('li',[_c('strong',[_vm._v("Valid From: ")]),_vm._v(" "+_vm._s(props.row.valid.from ? new Date( props.row.valid.from ).toLocaleDateString() : "")+" ")]),_c('li',[_c('strong',[_vm._v("Valid to: ")]),_vm._v(" "+_vm._s(props.row.valid.to ? new Date( props.row.valid.to ).toLocaleDateString() : "")+" ")]),_c('li',[_c('strong',[_vm._v("Upload Date: ")]),_vm._v(" "+_vm._s(props.row["uploadDate"])+" ")]),_c('li',[_c('strong',[_vm._v("Uploaded By: ")]),_vm._v(" "+_vm._s(props.row["uploadedBy"])+" ")])])]),(_vm.portalAccess < 3)?_c('div',{staticClass:"buttons"},[_c('b-button',{attrs:{"type":""},on:{"click":function($event){return _vm.launchDocUpdateModal(
                                                props.row,
                                                category,
                                                subCatName
                                            )}}},[_c('font-awesome-icon',{attrs:{"icon":'edit'}})],1),(_vm.portalAccess < 2)?_c('b-button',{on:{"click":function($event){return _vm.confirmDeleteDocument(
                                                props.row,
                                                category,
                                                subCatName
                                            )}}},[_c('font-awesome-icon',{attrs:{"icon":'trash'}})],1):_vm._e()],1):_vm._e()]}}],null,true)}):_vm._e()],1)})],2)])}),1):_vm._e()],1),(_vm.fetchedFeatureTitles)?_c('div',[_c('b-collapse',{staticClass:"card margin-top-15",attrs:{"aria-id":"registered-titles","open":false},scopedSlots:_vm._u([{key:"trigger",fn:function(props){return _c('div',{staticClass:"card-header",attrs:{"role":"button","aria-controls":"registered-titles"}},[_c('p',{staticClass:"card-header-title is-capitalized"},[_vm._v(" Registered Titles ")]),_c('a',{staticClass:"card-header-icon"},[_c('font-awesome-icon',{attrs:{"icon":props.open
                                ? ['fas', 'caret-up']
                                : ['fas', 'caret-down']}})],1)])}}],null,false,1482631283)},[_c('div',{staticClass:"card-content"},[(_vm.featureSelected)?_c('b-table',{staticClass:"trans-table file-table",attrs:{"data":_vm.fetchedFeatureTitles,"detailed":"","detail-key":"id"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('b-table-column',{attrs:{"field":"title","label":"Title"}},[_c('span',[_vm._v(" "+_vm._s(props.row.title)+" ")])]),_c('b-table-column',{attrs:{"field":"download","label":"Download"}},[_c('b-button',{attrs:{"type":""}},[_c('font-awesome-icon',{attrs:{"icon":'download'}})],1)],1)]}},{key:"detail",fn:function(props){return [_c('h3',{staticClass:"subtitle is-6 header-with-button"},[_vm._v(" Notifications: "),_c('b-button',{staticClass:"has-margin-left-10",attrs:{"type":"","title":"Add a site notification"},on:{"click":_vm.launchNewNotificationModal}},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'plus']}})],1)],1),(_vm.docHasNotifications(props.row))?_c('div',[_c('doc-notifications',{attrs:{"docNotifications":_vm.getNotificationsForDoc(props.row)}})],1):_c('div',[_vm._v(" No Notifications ")]),_c('hr',{staticClass:"has-margin-bottom-10"}),_c('article',{staticClass:"media"},[_c('ul',[_c('li',[_c('strong',[_vm._v("File Name: ")]),_vm._v(" "+_vm._s(props.row["filename"])+" ")]),_c('li',[_c('strong',[_vm._v("File Size: ")]),_vm._v(" "+_vm._s(props.row["fileSize"])+" ")]),_c('li',[_c('strong',[_vm._v("Upload Date: ")]),_vm._v(" "+_vm._s(props.row["uploadDate"])+" ")]),_c('li',[_c('strong',[_vm._v("Uploaded By: ")]),_vm._v(" "+_vm._s(props.row["uploadedBy"])+" ")])])])]}}],null,false,1325267372)}):_vm._e()],1)])],1):_vm._e(),(!_vm.fetchedFeatureDocs && !_vm.fetchedFeatureTitles)?_c('div',{staticClass:"card margin-top-15"},[_vm._m(0)]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-content"},[_c('p',[_vm._v("No Documents Found For This Site.")])])
}]

export { render, staticRenderFns }